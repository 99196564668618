unit xweb.xdata.client;

interface

uses xdata.web.client, classes, sysutils, system.generics.collections, rtti, js, web, jsdelphisystem, Types, x.json, x.json.Types;

// Experimental xData web client with support for service interfaces
// One service per connection atm

type
  txXDataWebClient = class(txDataWebClient)
  private

  public
    tp: TRttiInterfaceType;
    function Service<T>: T;
    procedure GetServiceInterface(p: Pointer; guid: tGuid; iName: string; out Intf);

  published

  end;

implementation

{ txXDataWebClient }

procedure txXDataWebClient.GetServiceInterface(p: Pointer; guid: tGuid; iName: string; out Intf);
{ procedure test(Method: TRttiMethod; const Args: TArray<TValue>; out Result: TValue); async;
  var
  s: string;
  ActionName: string;
  response: txdataClientResponse;
  j: jsvalue;
  s2: string;
  jo: tjsObject;
  begin
  ActionName:=iName + '.' + Method.name;
  console.log('Action', actionname);
  response := await(RawInvokeasync(ActionName, Args));
  jo := response.resultasObject;
  s2 := TJSJSON.stringify(jo);

  console.log('Response', response);
  console.log('Json', s2);

  Result:=tvalue.fromjsvalue(j);
  end; }
[ASync]
  function testJS(const aMethodName: String; const Args: TJSValueDynArray): JSValue; async;
  var
    ActionName: string;
    response: txdataClientResponse;
    aMethod: trttiMethod;
    v, f: tvalue;
    aObject: tobject;
    jso: tJsObject;
    jsv: jsValue;

  begin
    aMethod := tp.getmethod(aMethodName);

    ActionName := iName + '.' + aMethodName;
    // console.log( actionname, amethod);

    response := await(RawInvokeasync(ActionName, Args));
    // console.log('Response', response);

    if aMethod.returnType.typekind = tkClass then
    begin

      if response.statuscode=200 then
      begin
      xjo(aObject, aMethod.returnType, response.resultasobject);
      Result := aObject;
      end else result:=nil;
    end
    else
    begin
     if response.result is tJsObject then jsv:=response.ResultAsObject['value'] else jsv:=response.result;

      tvalue.make(jsv, aMethod.returnType.handle, v);
      Result := v.AsJSValue;
    end;

  end;

var

  v: tVirtualInterface;
begin
  v := tVirtualInterface.Create(p);
  v.QueryInterface(guid, Intf);

  v.oninvokejs := @testJS;

end;

function txXDataWebClient.Service<T>: T;
var

  rt: tRTTIContext;
begin
  rt := tRTTIContext.Create;
  tp := TRttiInterfaceType(rt.GetType(typeInfo(T)));

  GetServiceInterface(typeInfo(T), tp.guid, tp.handle.name, Result);
end;

end.
