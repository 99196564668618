unit x.Status;

interface
    uses
  classes, rtti, system.Generics.Collections, uiTypes,  x.cfg.classes, sysutils,   x.controls, x.appstyler,

{$IFDEF fwfmx}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts, FMX.skia, FMX.TMSFNCGraphicstypes,
  FMX.TMSFNCSplitter,
  FMX.TMSFNCTreeViewBase,
  FMX.TMSFNCTreeViewData, FMX.TMSFNCCustomTreeView, FMX.TMSFNCTreeView,
  FMX.TMSFNCObjectInspector, x.icons.all,  liblogtest;
{$ENDIF}
{$IFDEF fwVCL}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, windows, messages, scGPControls, scgpExtControls, vcl.skia, scControls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, x.icons.all,   liblogtest;

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
  VCL.TMSFNCObjectInspector, liblogtest;
{$ENDIF}
{$IFDEF fwweb}
VCL.Controls, VCL.Forms, VCL.Graphics, VCL.Dialogs, VCL.StdCtrls, VCL.ExtCtrls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage,

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,  system.types,
  VCL.TMSFNCObjectInspector, x.icons.all, liblogtest;
{$ENDIF}

type

txStatusItem=class(txRectangle)
  private
    Ftext: string;
    procedure SetText(const Value: string);
  public
   fLabel: txLabel;
    procedure CreateLabel;
 procedure CreateImage;
 procedure doLayout;
 function getREqHeight: Integer;

 constructor create(aOwner: tcomponent); overload; override;
  published
    property ReqHeight: Integer read getREqHeight;
    property text: string read Ftext write SetText;
end;

txStatusItems=tobjectlist<txStatusItem>;

txStatusBar=class(txRectangle)
  private
    Fitems: txStatusItems;
  public
  procedure doLayout;
 function getREqHeight: Integer;
  constructor create(aOwner: tcomponent); overload; override;
  constructor create(aOwner: tcomponent; aNum: integer) ; overload;
  function addItem: txStatusItem;
  published
     property ReqHeight: Integer read getREqHeight;
     property items: txStatusItems read Fitems write Fitems;
end;

implementation

{ txStatusItem }

constructor txStatusItem.create(aOwner: tcomponent);
begin
  inherited;

  CreateLabel;
end;

procedure txStatusItem.CreateImage;
begin

end;

procedure txStatusItem.CreateLabel;
begin
  if not assigned(fLabel) then
   begin
   fLabel:=txLabel.create(self);
  fLabel.parent:=self;
  {$IFNDEF fwweb} flabel.StyleElements:=[];  {$ENDIF}


  //flabel.Font.name:=textFont.name;
   // flabel.Font.size:=textFont.size;
    //if textfont.style=[tFontStyle.fsbold] then flabel.bold:=true;

    //flabel.TextSettings.FontColor:=xStyler.xAppStyle.h1.Color;

      //flabel.TextSettings.font.Assign(AppStyle.h1);     //?
      flabel.WordWrap:=false;

   // flabel.Align:=AlignLeft;
     flabel.AutoSize:=true;

   end;
end;

procedure txStatusItem.doLayout;
var
 x1: integer;
begin
 height:=reqHeight;
 x1:=4;
{ if assigned(fimage) then
 begin
   SetPosX(fimage, Padding.left);
   SetPosy(fimage, trunc((height-fimage.height)/2));
   x1:=padding.left+fimage.width+ImageMargin;
 end;    }
 if assigned(fLabel) then
 begin
   setposx(flabel, x1);
    SetPosy(fLabel, trunc((height-flabel.height)/2));

 end;
 width:=flabel.width+4 + x1;
end;

function txStatusItem.getREqHeight: Integer;
var
 y1,y2: integer;
begin
 if assigned(fLabel) then y1:=fLabel.Height else y1:=0;
 y2:=0;
 //if assigned(fImage) then y2:=fImage.height else y2:=0;
 result:=y1+y2+padding.top+padding.bottom;
  alog.send('ReqHiehgt', result);

end;


procedure txStatusItem.SetText(const Value: string);
begin

    CreateLabel;
  fLabel.Caption:=value;
  flabel.xtext:=value;
  flabel.AutoSize:=true;
  Ftext := Value;
  doLayout;
end;

{ txStatusBar }

function txStatusBar.addItem: txStatusItem;
begin
 result:=txStatusItem.create(self);
 result.parent:=self;
 result.align:=AlignLeft;
 result.Stroke.color:=clLtGray;
 result.Stroke.kind:=txBrushkind.solid;
  result.Stroke.thickness:=0.5;

 result.xBorder.sides:=[txSide.Right];
  Items.add(result);
end;

constructor txStatusBar.create(aOwner: tcomponent);
begin
  inherited;
   parent:=txWinControlClass(aOwner);
  Items:=txStatusItems.create;
end;

constructor txStatusBar.create(aOwner: tcomponent; aNum: integer);
var
 i: integer;
begin
 Create(aOWner);
 for i := 1 to aNum do
   begin
     AddItem.text:='Status Item ' + inttostr(I);
   end;
   DoLayout;
end;

procedure txStatusBar.doLayout;
var
 aItem: txStatusItem;
 w: integer;
begin
w:=0;
 for aItem in Items do
   begin
   w:=w+aitem.width;
end;
width:=w+4;


end;

function txStatusBar.getREqHeight: Integer;
begin

end;

end.
