unit x.fs.client.TV;

interface
//{$INCLUDE project.inc}
uses
  system.classes, system.rtti, system.Generics.Collections, system.uiTypes, x.cfg.classes, system.sysutils,
 system.typinfo, system.types, x.fncNodes, x.icons.all,
  x.fs.client, system.ioutils, x.fs.types, x.json,x.xdata.connect, x.filter,


  {$IFDEF fwfmx}
  {$DEFINE FMXLIB}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts, FMX.skia, FMX.TMSFNCGraphicstypes,
  FMX.TMSFNCSplitter,
  FMX.TMSFNCTreeViewBase, FMX.TMSFNCBitmapContainer, FMX.ListBox,
  FMX.TMSFNCTreeViewData, FMX.TMSFNCCustomTreeView, FMX.TMSFNCTreeView,
  FMX.TMSFNCObjectInspector, fmx.TMSFNCGraphics, fmx.tmsfnctypes, fmx.TMSFNCCustomControl, liblogtest,  x.styler,
{$ENDIF}
{$IFDEF fwVCL}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, windows, messages, scGPControls, scgpExtControls, vcl.skia, scControls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.TMSFNCBitmapContainer,

VCL.TMSFNCTreeViewBase,
VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, vcl.Menus,
VCL.TMSFNCObjectInspector, VCL.TMSFNCGraphics, vcl.tmsfnctypes, VCL.TMSFNCCustomControl, liblogtest , x.styler;
{$ENDIF}
{$IFDEF fwweb}
js, web,
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.TMSFNCBitmapContainer, xdata.Web.Client,vcl.menus,

VCL.TMSFNCTreeViewBase,
VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
VCL.TMSFNCObjectInspector, VCL.TMSFNCGraphics, vcl.tmsfnctypes, VCL.TMSFNCCustomControl, liblogtest, jsDelphiSystem;
{$ENDIF}

type
  txFSTV = class;

  txFSTVStringEvent = procedure(sender: txfsTV; S: string) of object;
  txFSTVFileEvent=procedure(sender: txfsTV; afile: tbsFile) of object;
  txFSTVLookup = tdictionary<string, ttmsfncTreeViewNode>;
  txFSTVListLookup = tDictionary<ttmsfncTreeViewNode, ttmsfncTreeViewNode>;
  txfstvRoot=(roPathName, roServerName);
  txFSTV = class(txTVClass)
  private
    Furi: string;
    Froot: string;
    Ffs: txFS;
    FList: tStringList;
    procedure SetRoot(const Value: string);
    procedure setURI(const Value: string);
  public
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); override;
    procedure ExportNotification(AState: TTMSFNCTreeViewExportState; ARow: Integer); override;
    procedure DoBeforeSortNodes(ASortColumn: Integer; ASortMode: TTMSFNCTreeViewNodesSortMode; var ACanSort: Boolean); override;
    procedure DoAfterSortNodes(ASortColumn: Integer; ASortMode: TTMSFNCTreeViewNodesSortMode); override;
    procedure DoBeforeDrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw:
      Boolean); override;
    procedure DoAfterDrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind); override;
    procedure DoBeforeCutToClipboard(var ACanCut: Boolean); override;
    procedure DoBeforeCopyToClipboard(var ACanCopy: Boolean); override;
    procedure DoBeforePasteFromClipboard(var ACanPaste: Boolean); override;
    procedure DoAfterCutToClipboard; override;
    procedure DoAfterCopyToClipboard; override;
    procedure DoAfterPasteFromClipboard; override;
    procedure DoBeforeReorderNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode; var ACanReorder: Boolean); override;
    procedure DoAfterReorderNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDropNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode; var ACanDrop: Boolean); override;
    procedure DoAfterDropNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace);
    procedure DoBeforeDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace);
    procedure DoBeforeDrawColumnHeader(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AAllow: Boolean; var ADefaultDraw:
      Boolean);
      override;
    procedure DoAfterDrawColumnHeader(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind); override;
    procedure DoBeforeDrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; var AAllow: Boolean; var ADefaultDraw: Boolean); override;
    procedure DoAfterDrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer); override;
    procedure DoBeforeDrawNodeColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; var AAllow: Boolean; var ADefaultDraw: Boolean); override;
    procedure DoAfterDrawNodeColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer); override;
    procedure DoBeforeDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AAllow: Boolean; var
      ADefaultDraw: Boolean); override;
    procedure DoAfterDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind); override;
    procedure DoBeforeDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean; var ADefaultDraw: Boolean); override;
    procedure DoAfterDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoCustomizeFilterListBox(AColumn: Integer; AListBox: TListBox); override;
    procedure DoGetColumnText(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AText: string); override;
    procedure DoBeforeDrawColumnText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string; var AAllow: Boolean); override;
    procedure DoAfterDrawColumnText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string); override;
    procedure DoGetGroupText(AGroup: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AText: string); override;
    procedure DoBeforeDrawGroupText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string; var
      AAllow: Boolean); override;
    procedure DoAfterDrawGroupText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string); override;
    procedure DoNodeClick(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoNodeValueClick(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoNodeMouseLeave(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoNodeMouseEnter(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoNodeDblClick(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoGetNumberOfNodes(ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer); override;
    procedure DoGetNodeText(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: string); override;
    procedure DoGetNodeData(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoGetNodeTitle(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var ATitle: string); override;
    procedure DoGetNodeTitleExpanded(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AExpanded: Boolean); override;
    procedure DoGetNodeTrimming(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); override;
    procedure DoGetNodeHorizontalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeVerticalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeWordWrapping(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AWordWrapping: Boolean); override;
    procedure DoGetNodeTitleTrimming(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); override;
    procedure DoGetNodeTitleHorizontalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeTitleVerticalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeTitleWordWrapping(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AWordWrapping: Boolean); override;
    procedure DoGetNodeExtraSize(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AExtraSize: Single); override;
    procedure DoGetNodeTitleExtraSize(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleExtraSize: Single); override;
    procedure DoGetColumnTrimming(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var ATrimming: TTMSFNCGraphicsTextTrimming); override;
    procedure DoGetColumnHorizontalTextAlign(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetColumnVerticalTextAlign(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetColumnWordWrapping(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AWordWrapping: Boolean); override;
    procedure DoGetNodeCheckType(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType); override;
    procedure DoGetNodeHeight(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double); override;
    procedure DoGetNodeIcon(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap); override;
    procedure DoGetNodeIconSize(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; AIcon: TTMSFNCBitmap; var AIconWidth: Double; var AIconHeight: Double); override;
    procedure DoGetNodeSelectedColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSelectedStrokeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSides(ANode: TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides); override;
    procedure DoGetNodeRounding(ANode: TTMSFNCTreeViewVirtualNode; var ARounding: Integer; var ACorners: TTMSFNCGraphicsCorners); override;
    procedure DoGetNodeDisabledColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeDisabledStrokeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeStrokeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSelectedTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeDisabledTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSelectedTitleColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeDisabledTitleColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeTitleColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); override;
    procedure DoIsNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode; var AExpanded: Boolean); override;
    procedure DoIsNodeExtended(ANode: TTMSFNCTreeViewVirtualNode; var AExtended: Boolean); override;
    procedure DoIsNodeChecked(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AChecked: Boolean); override;
    procedure DoIsNodeVisible(ANode: TTMSFNCTreeViewVirtualNode; var AVisible: Boolean); override;
    procedure DoIsNodeEnabled(ANode: TTMSFNCTreeViewVirtualNode; var AEnabled: Boolean); override;
    procedure DoBeforeDrawNodeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: string; var AAllow: Boolean); override;
    procedure DoAfterDrawNodeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: string); override;
    procedure DoBeforeDrawNodeTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ATitle: string; var AAllow: Boolean); override;
    procedure DoAfterDrawNodeTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ATitle: string); override;
    procedure DoBeforeDrawNodeIcon(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap; var AAllow: Boolean); override;
    procedure DoAfterDrawNodeIcon(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap); override;
    procedure DoBeforeDrawNodeExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean); override;
    procedure DoDrawNodeExtra({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF; {%H-}AColumn: Integer; {%H-}ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoAfterDrawNodeExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean); override;
    procedure DoDrawNodeTitleExtra({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF; {%H-}AColumn: Integer; {%H-}ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoAfterDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawNodeCheck(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap; var AAllow: Boolean);
      override;
    procedure DoAfterDrawNodeCheck(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap); override;
    procedure DoBeforeDrawNodeExpand(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AExpand: TTMSFNCBitmap; var AAllow: Boolean);
      override;
    procedure DoAfterDrawNodeExpand(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AExpand: TTMSFNCBitmap); override;
    procedure DoBeforeSelectNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanSelect: Boolean); override;
    procedure DoBeforeSelectAllNodes(var ACanSelect: Boolean); override;
    procedure DoBeforeUnSelectNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanUnSelect: Boolean); override;
    procedure DoCustomizeInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor); override;
    procedure DoBeforeSizeColumn(AColumn: Integer; AColumnSize: Double; var ANewColumnSize: Double; var AAllow: Boolean); override;
    procedure DoAfterSizeColumn(AColumn: Integer; AColumnSize: Double); override;
    procedure DoBeforeUpdateNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AText: string; var ACanUpdate: Boolean); override;
    procedure DoNodeChanged(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoAfterUpdateNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoUpdateNodeText({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AText: string); override;
    procedure DoUpdateNodeTitle({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ATitle: string); override;
    procedure DoBeforeExpandNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanExpand: Boolean); override;
    procedure DoAfterExpandNode(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeCollapseNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanCollapse: Boolean); override;
    procedure DoAfterCollapseNode(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanCheck: Boolean); override;
    procedure DoAfterCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoBeforeUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanUnCheck: Boolean); override;
    procedure DoAfterUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoNodeAnchorClick(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: string); override;
    procedure DoNodeTitleAnchorClick(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: string); override;
    procedure DoColumnAnchorClick(AColumn: Integer; AAnchor: string); override;
    procedure DoGetHTMLTemplateValue(ANodeValue: TTMSFNCTreeViewNodeValue; AName: string; var AValue: string); override;
    procedure DoGetHTMLTemplate(ANodeValue: TTMSFNCTreeViewNodeValue; AColumnIndex: Integer; var AHTMLTemplate: string); override;

  private
    FrootURL: string;

    FLineStyle: txixLineStyle;
    FBorderColor: ttmsfncGraphicsColor;
    FLineColor: ttmsfncGraphicsColor;
    FTextColor: ttmsfncGraphicsColor;
    FBGstyle: txBGStyle;
    FSelLineColor: ttmsfncGraphicsColor;
    FSelTextColor: ttmsfncGraphicsCOlor;
    FSelNodeColor: ttmsfncGraphicsColor;
    FBGColor: TTMSFNCGraphicsColor;
    FNoSelectStyle: boolean;
    FShowChanges: boolean;
    FChangeTimeout: integer;
    FBRLineBreaks: boolean;
    FColVMargin: integer;
    FChangedColor: TTMSfncGraphicsColor;
    FtopFont: tFont;
    fColHMargin: integer;

    Ficonsize: integer;
    Fpath: string;
    Fnode: ttmsfncTreeViewNode;
    FonPathChanged: txfsTVStringEvent;
    Fbsfiles: tbsFileList;
    FListView: ttmsfncTreeView;
    FPathLookup: txFSTVLookup;
    FSelFolder: string;
    FSelFile: string;
    FSelFolderNode: ttmsfncTreeViewNode;
    FSelFileNode: ttmsfncTreeViewNode;
    FListViewLookup: txFSTVListLookup;
    FListViewFolderNode: ttmsfncTreeViewNode;
    FListViewReverseLookup: txFSTVListLookup;
    FListViewNode: ttmsfncTreeViewNode;
    FonStatus: tXDStatusEvent;
    Fbsfile: tbsfile;
    FonBSFIle: txFSTVFileEvent;
    Fnodefn: string;
    FLive: boolean;
    FshowColumns: boolean;
    FTopColor: ttmsfncGraphicsColor;
    FixStyle: txIXStyle;
    FTopHeight: integer;
    FFontSize: Integer;
    FColSeperatorColor: TTMSFNCGraphicsColor;
    FEnableColAutoWidth: boolean;
    FEnableAutoWidth: boolean;
    FEnableAutoHeight: boolean;
    FEnableMenu: boolean;
    FrootOption: txfstvRoot;
    FNormalFontSize: integer;
    FinitDone: boolean;

    procedure fsError(acode: integer; aMsg: string; aobject: tobject);
    procedure fsStatus(aStatus: txdataStatus);
    procedure setBGColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBGStyle(const Value: txBGStyle);
    procedure SetBorderColor(const Value: ttmsfncGraphicsColor);
    procedure SetLineColor(const Value: ttmsfncGraphicsColor);
    procedure SetNoSelectStyle(const Value: boolean);
    procedure doAllFontChange(sender: tobject);
    procedure SetPath(const Value: string);
    function GetNode: ttmsfncTreeViewNode;
    procedure setListView(const Value: ttmsfncTreeView);
    procedure setNode(const Value: ttmsfncTreeViewNode);
    procedure SetSelFileNode(const Value: ttmsfncTreeViewNode);
    procedure SetSelFolderNode(const Value: ttmsfncTreeViewNode);
    function GetSelFile: string;
    function GetSelFolder: string;
    procedure setbsfile(const Value: tbsfile);
    function GetNodefn: string;
    procedure SetShowColumns(const Value: boolean);
    procedure SetTextColor(const Value: ttmsfncGraphicsCOlor);
    procedure setIXStyle(const Value: txIXStyle);
    procedure SetTopHeight(const Value: integer);
    procedure SetFontSize(const Value: Integer);
    procedure SetEnableMenu(const Value: boolean);
    function GetURL: string;
    function getPathNoRoot: string;
    procedure SetcTextHeight(const Value: single);
  public
    procedure REsize; override;
    procedure ListViewGetNodeData(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure ListViewGetNodeColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
    procedure ListViewGetNodeTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor);
    procedure ListViewGetNodeText(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: string);
    procedure ListViewGetNodeSides(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides);
    procedure ListViewGetNodeSelectedColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
    procedure ListViewGetNodeIconSize(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; AIcon: TTMSFNCBitmap; var AIconWidth, AIconHeight: Double);
    procedure ListViewGetNodeIcon(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap);
    procedure ListViewGetNodeSelectedTextColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor);
    procedure ListViewAfterSelectNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure ListViewGetNodeHeight(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double);
    procedure ListViewClick(sender: tobject);
    procedure ListViewMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    procedure UpdateListView;
    procedure TVMouseDown(Sender: TObject; Button: TMouseButton;  Shift: TShiftState; X, Y: Integer);

  public
    icons: txIcons;
    ExpandIcon: txIcon;
    CollapseIcon: txIcon;
    RootIcon, RootIconClosed, RootIconOpen: txIcon;
    tnc: integer;
    xFilter: txFilter;
    pm: tPopupMenu;
    NeededWidth: integer;
    TimerColAutoWidth: TTimer;
    expanding: boolean;
    fcTextHeight: single;
    procedure TimerColAutoWidthTImer(sender: tobject);
    [async] procedure fsReady(Sender: TObject); async;
    [async] procedure UpdateList; async;
    function GotPath(aPath: string): boolean;
    function GetPath(aPath: string): ttmsfncTreeViewNode;
    procedure GetbsFiles;
    constructor create(aOwner: tcomponent); override;
    //Utils
    [async] procedure init; async;
    function cn(idx: integer): string;
    procedure InitMenu;
    procedure pmclick(sender: tobject);
    procedure pmOpen(sender: tobject);
    function pmAdd(acaption: string): tmenuItem;
    function NewCol(atext, aname: string; aAllowShow: boolean; awidth: integer=-1): ttmsfncTreeViewColumn;
    procedure APplyColumnStyles;
    procedure doColAutoWidth(aTimed: integer=-1);
    procedure doAutoWidth(aTimed: integer=-1);
    procedure doAutoHeight(aTimed: integer=-1);
    procedure FullExpand;
    procedure FullCollapse;
    [ASync] procedure IconsReady(sender: tobject); async;
    function isFolder(aname: string): boolean;
    function pp(aitem: string): string;
    function NA: TTMSFNCTreeViewNodesAppearance;
    function CA: ttmsfncTreeViewcolumnsAppearance;
    function col(idx: integer): ttmsfncTreeViewColumn; overload;
    function col(aname: string): ttmsfncTreeViewColumn; overload;
    function ItemIcon(aPath: string): string; overload;
    function ItemIcon(aNode: ttmsfncTreeViewNode): string; overload;
    function ItemIcon: string; overload;
    [async] function bs(anode: ttmsfncTreeViewNode = nil): tbsFIle; async;
  published
    property ctextHeight: single read FctextHeight write SetcTextHeight;
    property EnableColAutoWidth: boolean read FEnableColAutoWidth write FEnableColAutoWidth;
    property EnableAutoWidth: boolean read FEnableAutoWidth write FEnableAutoWidth;
    property EnableAutoHeight: boolean read FEnableAutoHeight write FEnableAutoHeight;
    property onPathChanged: txfsTVStringEvent read FonPathChanged write FonPathChanged;
    property path: string read fpath write SetPath;
    property URL: string read GetURL;
    property PathNoRoot: string read getPathNoRoot;
    property SelFolder: string read GetSelFolder;
    property SelFile: string read GetSelFile;
    property SelFolderNode: ttmsfncTreeViewNode read FSelFolderNode write SetSelFolderNode;
    property SelFileNode: ttmsfncTreeViewNode read FSelFileNode write SetSelFileNode;
    property ListViewFolderNode: ttmsfncTreeViewNode read FListViewFolderNode write FListViewFolderNode;
    property ListViewNode: ttmsfncTreeViewNode read FListViewNode write FListViewNode;
    property node: ttmsfncTreeViewNode read GetNode write setNode;
    property nodefn: string read GetNodefn;
    property uri: string read Furi write setURI;
    property root: string read Froot write SetRoot;
    property rootURL: string read FrootURL write FrootURL;
    property ListView: ttmsfncTreeView read FListView write setListView;
    property fs: txFS read Ffs write Ffs;
    property List: tStringList read FList write FList;
    property PathLookup: txFSTVLookup read FPathLookup write FPathLookup;
    property ListViewLookup: txFSTVListLookup read FListViewLookup write FListViewLookup;
    property ListViewReverseLookup: txFSTVListLookup read FListViewReverseLookup write FListViewReverseLookup;
    property bsfiles: tbsFileList read Fbsfiles write Fbsfiles;
    property LineStyle: txixLineStyle read FLineStyle write FLineStyle;
    property BGstyle: txBGStyle read FBGstyle write SetBGStyle;
    property BGColor: TTMSFNCGraphicsColor read FBGColor write setBGColor;
    property TopColor: ttmsfncGraphicsColor read FTopColor write FTopColor;
    property LineColor: ttmsfncGraphicsColor read FLineColor write SetLineColor;
    property TextColor: ttmsfncGraphicsColor read FTextColor write FTextColor;
    property SelTextColor: ttmsfncGraphicsCOlor read FSelTextColor write SetTextColor;
    property SelNodeColor: ttmsfncGraphicsColor read FSelNodeColor write FSelNodeColor;
    property SelLineColor: ttmsfncGraphicsColor read FSelLineColor write FSelLineColor;
    property BorderColor: ttmsfncGraphicsColor read FBorderColor write SetBorderColor;
    property NoSelectStyle: boolean read FNoSelectStyle write SetNoSelectStyle;
    property topFont: tFont read FtopFont write FtopFont;
    property ShowChanges: boolean read FShowChanges write FShowChanges;
    property ChangeTimeout: integer read FChangeTimeout write FChangeTimeout;
    property ChangedColor: TTMSfncGraphicsColor read FChangedColor write FChangedColor;
    property ColHMargin: integer read fColHMargin write fColHMargin;
    property ColVMargin: integer read FColVMargin write FColVMargin;
    property BRLineBreaks: boolean read FBRLineBreaks write FBRLineBreaks;
    property iconsize: integer read Ficonsize write Ficonsize;
    property onStatus: tXDStatusEvent read FonStatus write FonStatus;
    property bsfile: tbsfile read Fbsfile write setbsfile;
    property onBSFIle: txFSTVFileEvent read FonBSFIle write FonBSFIle;
    property Live: boolean read FLive write FLive;
    property showColumns: boolean read FshowColumns write SetShowColumns;
    property ixStyle: txIXStyle read FixStyle write setIXStyle;
    property TopHeight: integer read FTopHeight write SetTopHeight;
    property FontSize: Integer read FFontSize write SetFontSize;
    property ColSeperatorColor: TTMSFNCGraphicsColor read FColSeperatorColor write FColSeperatorColor;
    property EnableMenu: boolean read FEnableMenu write SetEnableMenu;
    property rootOption: txfstvRoot read FrootOption write FrootOption;
    property NormalFontSize: integer read FNormalFontSize write FNormalFontSize;
    property initDone: boolean read FinitDone write FinitDone;

  end;

implementation

{ txFSTV }

function txFSTV.NA: TTMSFNCTreeViewNodesAppearance;
begin
  result := NodesAppearance;

end;

function txFSTV.NewCol(atext, aname: string; aAllowShow: boolean;
  awidth: integer): ttmsfncTreeViewColumn;
begin
 result:=columns.add;
 result.text:=aText;
 result.name:=aname;
 result.data.AllowShow:=aAllowShow;
 result.UseDefaultAppearance:=false;
   result.Sorting := TTMSFNCTreeViewColumnSorting.tcsNormal;
   if awidth<>-1 then result.width:=awidth;

 ApplyColumnStyles;
end;



function txFSTV.bs(anode: ttmsfncTreeViewNode): tbsFIle;
begin

  result := nil;
  if anode = nil then anode := node;
  if anode = nil then
  begin

    exit;
  end;

  {if assigned(anode.DataObject) then
  begin

    result := tbsFile(anode.DataObject);
  end
  else
  begin}

    result := {$IFDEF fwweb}await({$ENDIF}(fs.getfile(anode.datastring, false)){$IFDEF fwweb}){$ENDIF};
   // result:=fs.getfile(anode.datastring, false);
    anode.DataObject := result;
  //end;
end;

function txFSTV.CA: ttmsfncTreeViewcolumnsAppearance;
begin
  result := ColumnsAppearance;
end;

function txFSTV.cn(idx: integer): string;
begin
 try
    result:='';
    result:=Lowercase(columns[idx].name);
 except
  on e: exception do
  begin

  end;

 end;

end;

function txFSTV.col(aname: string): ttmsfncTreeViewColumn;
var
 aColumn: ttmsfncTreeViewColumn;
 i: integer;
begin
 result:=nil;
 for I := 1 to columns.count do
 begin
    acolumn:=columns[i-1];
    if lowercase(acolumn.name)=lowercase(aname) then
    begin
      result:=aColumn;
      exit;
    end;
 end;

end;

function txFSTV.col(idx: integer): ttmsfncTreeViewColumn;
begin
  result := columns[idx] ;
end;

constructor txFSTV.create(aOwner: tcomponent);
begin
  inherited;
  ctextheight:=0;
  init;
end;

procedure txFSTV.DoAfterCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txFSTV.DoAfterCollapseNode(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
    if Expanding=false then
    doColAutoWidth(0);
end;

procedure txFSTV.DoAfterCopyToClipboard;
begin
  inherited;

end;

procedure txFSTV.DoAfterCutToClipboard;
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace);
begin

end;

procedure txFSTV.DoAfterDrawColumnHeader(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawColumnText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  AText: string);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawGroup(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace);
begin

end;

procedure txFSTV.DoAfterDrawGroupText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; AText: string);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
   if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  {$IFDEF fwweb}
  anode.node.data.DrawnHeight := arect.Bottom - arect.top;
  {$ELSE}

  anode.node.data.DrawnHeight := arect.Height;
  anode.node.data.drawnWidth:=arect.width;
  {$ENDIF}
end;

procedure txFSTV.DoAfterDrawNodeCheck(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ACheck: TTMSFNCBitmap);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNodeColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNodeExpand(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AExpand: TTMSFNCBitmap);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNodeExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNodeIcon(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AIcon: TTMSFNCBitmap);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNodeText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AText: string);
var
  tr                : trectf;
  w                 : integer;
begin
  inherited;
  if not assigned(ANode) then exit;
  if not assigned(ANode.Node) then exit;

  tr := RectF(ARect.Left + 4, ARect.Top + 2, 10000, ARect.bottom - 2);
  w := round(AGraphics.CalculateTextWidth(AText, tr, false, false));
  anode.node.data.DataInteger[AColumn] := w;
  columns[AColumn] .data.addCW(w, ARect);
  anode.node.data.DoneCW := true;
end;

procedure txFSTV.DoAfterDrawNodeTitle(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ATitle: string);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoAfterDrawSortIndicator(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind);
begin
  inherited;

end;

procedure txFSTV.DoAfterDropNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoAfterExpandNode(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  if Expanding=false then

  doColAutoWidth(0);
end;

procedure txFSTV.DoAfterPasteFromClipboard;
begin
  inherited;

end;

procedure txFSTV.DoAfterReorderNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoAfterSizeColumn(AColumn: Integer; AColumnSize: Double);
begin
  inherited;

end;

procedure txFSTV.DoAfterSortNodes(ASortColumn: Integer;
  ASortMode: TTMSFNCTreeViewNodesSortMode);
begin
  inherited;

end;

procedure txFSTV.DoAfterUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txFSTV.DoAfterUpdateNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txFSTV.DoBeforeCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACanCheck: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeCollapseNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanCollapse: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeCopyToClipboard(var ACanCopy: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeCutToClipboard(var ACanCut: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin

end;

procedure txFSTV.DoBeforeDrawColumnHeader(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  var AAllow, ADefaultDraw: Boolean);

var

  Corners           : TTMSFNCGraphicsSides;
  tm                : Double;
  bmHW              : integer;
  CF                : Double;
  col               : ttmsfncTreeViewColumn;
  Iconheight, IconWidth: integer;
  vMargin, hMargin  : integer;
  tr                : trectf;
begin
  inherited;
  col := columns[aColumn] ;
  aGraphics.font.assign(topfont);
  vMargin := colVMargin;
  hMargin := colHMargin;
  { AGraphics.Font.Color := ca.topfont.Color;
      AGraphics.DefaultTextFontColor := afont.Color;
      AGraphics.stroke.Color := afont.Color;
      AGraphics.Font.Style := afont.Style;
      AGraphics.Font.Name := afont.Name;
      AGraphics.Font.size := afont.size;  }
  agraphics.fill.Assign(col.TopFill);
  aGraphics.stroke.kind:=gskSolid;
  agraphics.stroke.width:=0.5;
  agraphics.stroke.color:=ColSeperatorColor;

  AGraphics.DrawRectangle(arect, [gsLeft,gstop, gsBottom]);

  if not assigned(col.data.xicon) then
  begin
    aAllow := false;
    tr := RectF(arect.left + hMargin, arect.top
      , 10000, ARect.bottom);

    AGraphics.DrawText(tr, col.text, false, gtaLeading, gtaCenter);
    exit;
  end;

  AAllow := false;
  // ADefaultDraw:=false;
 // tm := stroke.Width * 1.8;

  {$IFDEF fwweb}
  IconHeight := trunc(aRect.bottom - aREct.top) - (2 * vmargin);
  {$ELSE}
  IconHeight := trunc(aRect.Height) - (2 * vmargin);
  {$ENDIF}

  IconWidth := IconHeight;

  tr := RectF(arect.left + hMargin + IconWidth + hmargin, arect.top
    , 10000, ARect.bottom);

  AGraphics.DrawBitmapWithName(arect.left + hMargin, arect.top + vMargin, arect.left + IconWidth + hMargin, arect.top + vMargin + iconHeight + vMargin,
    col.data.xicon.bn, true, 1, true, true, true, false);
  AGraphics.DrawText(tr, col.text, false, gtaLeading, gtaCenter);


end;


procedure txFSTV.DoBeforeDrawColumnText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  AText: string; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawGroup(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin

end;

procedure txFSTV.DoBeforeDrawGroupText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; AText: string; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNode(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode; var AAllow,
  ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeCheck(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ACheck: TTMSFNCBitmap; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeExpand(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AExpand: TTMSFNCBitmap; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeIcon(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AIcon: TTMSFNCBitmap; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AText: string; var AAllow: Boolean);
var
  tr                : trectf;
  w                 : integer;
begin
  inherited;
  if not assigned(ANode) then exit;
  if not assigned(ANode.Node) then exit;
  if cTextHeight=0 then

  cTextHeight:= agraphics.CalculateTextHeight(atext);
  tr := RectF(ARect.Left + 4, ARect.Top + 2, 10000, ARect.bottom - 2);
  w := round(AGraphics.CalculateTextWidth(AText, tr, false, false));
  anode.node.data.DataInteger[AColumn] := w;
  columns[AColumn] .data.addCW(w, ARect);
  anode.node.data.DoneCW := true;
end;

procedure txFSTV.DoBeforeDrawNodeTitle(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ATitle: string; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDrawSortIndicator(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeDropNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode; var ACanDrop: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeExpandNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanExpand: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforePasteFromClipboard(var ACanPaste: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeReorderNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode; var ACanReorder: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeSelectAllNodes(var ACanSelect: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeSelectNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanSelect: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeSizeColumn(AColumn: Integer; AColumnSize: Double;
  var ANewColumnSize: Double; var AAllow: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeSortNodes(ASortColumn: Integer;
  ASortMode: TTMSFNCTreeViewNodesSortMode; var ACanSort: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACanUnCheck: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeUnSelectNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanUnSelect: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoBeforeUpdateNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AText: string; var ACanUpdate: Boolean);
begin
  inherited;

end;



procedure txFSTV.DoColumnAnchorClick(AColumn: Integer; AAnchor: string);
begin
  inherited;

end;

procedure txFSTV.DoCustomizeFilterListBox(AColumn: Integer;
  AListBox: TListBox);
begin
  inherited;

end;

procedure txFSTV.DoCustomizeInplaceEditor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  AInplaceEditor: TTMSFNCTreeViewInplaceEditor);
begin
  inherited;

end;

procedure txFSTV.DoDrawNodeExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoGetColumnHorizontalTextAlign(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txFSTV.DoGetColumnText(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AText: string);
begin
  inherited;

end;

procedure txFSTV.DoGetColumnTrimming(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  inherited;

end;

procedure txFSTV.DoGetColumnVerticalTextAlign(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txFSTV.DoGetColumnWordWrapping(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AWordWrapping: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoGetGroupText(AGroup: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AText: string);
begin
  inherited;

end;

procedure txFSTV.DoGetHTMLTemplate(ANodeValue: TTMSFNCTreeViewNodeValue;
  AColumnIndex: Integer; var AHTMLTemplate: string);
begin
  inherited;

end;

procedure txFSTV.DoGetHTMLTemplateValue(
  ANodeValue: TTMSFNCTreeViewNodeValue; AName: string; var AValue: string);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeCheckType(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;

  { if showChanges then
   begin
     if anode.node.data.changed then
     begin
       acolor := ChangedColor;
       // alog.send('Changed');
     end
     else
       aColor := BGColor;
   end
   else                   }
  acolor := BGColor;
end;

procedure txFSTV.DoGetNodeData(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeDisabledColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeDisabledStrokeColor(
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeDisabledTextColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeDisabledTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeExtraSize(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AExtraSize: Single);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeHeight(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AHeight: Double);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeHorizontalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeIcon(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap);
begin
  inherited;
  {if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;

  if acolumn=0 then
  begin
    if anode.node.DataBoolean=true then
    begin
     if anode.node.Expanded then

     aIcon.:=icons.geticon('openfolder-svg').bn else
     aIcon.name:=icons.geticon('folder-svg').bn;
    end else
    aIcon.name:=icons.geticon('file-svg').bn

  end;}

end;

procedure txFSTV.DoGetNodeIconSize(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ALarge: Boolean; AIcon: TTMSFNCBitmap; var AIconWidth,
  AIconHeight: Double);
begin
  inherited;
  AIconWidth := IconSize;
  aIconHeight := IconSize;
end;

procedure txFSTV.DoGetNodeRounding(ANode: TTMSFNCTreeViewVirtualNode;
  var ARounding: Integer; var ACorners: TTMSFNCGraphicsCorners);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeSelectedColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if noSelectStyle then
    aColor := na.fill.color
  else
    aColor := SelNodeCOlor;

end;

procedure txFSTV.DoGetNodeSelectedStrokeColor(
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  //if noSelectStyle then   acolor:=LineColor else
  //begin
  if anode = SelectedVirtualNode then
    aColor := selLineCOlor
  else
    aColor := LineColor;

end;

procedure txFSTV.DoGetNodeSelectedTextColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if noSelectStyle then

    ATextColor := TextColor
  else //columns[aColumn].font.color else
    aTextColor := SelTextColor;
end;

procedure txFSTV.DoGetNodeSelectedTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeSides(ANode: TTMSFNCTreeViewVirtualNode;
  var ASides: TTMSFNCGraphicsSides);
begin
  inherited;
  if linestyle = lsBottom then
    ASides := [gsBottom]
  else
    ASides := [] ;
end;

procedure txFSTV.DoGetNodeStrokeColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if assigned(anode) = false then exit;
  if anode = SelectedVirtualNode then
    aColor := SelLineCOlor
  else
    aColor := LineCOlor;

end;

procedure txFSTV.DoGetNodeText(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: string);
var
  s: string;
  nt: string;
 pn: ttmsfncTreeViewNode;
 cname: string;
begin
  inherited;

  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;

  s := anode.node.DataString;
  nt := pp(s);
  cname:=cn(aCOlumn);

  if cname = 'colitem' then
  begin
    if anode.node=nodes[0] then
    begin
      if rootOption=roServerName then atext:=fs.URIHost else atext:=nt;

    end else

    aText := nt;
  end;
  if cname = 'colisfolder' then
  begin
    if anode.node.DataBoolean = true then
      atext := '1'
    else
      aText := '0';

  end;
  if cname='ext' then
  begin
    if anode.node.DataBoolean = false then atext := extractFileExt(nt);

  end;
  if cname='colpath' then
  begin
  //  pn:=anode.node.GetParent;
    //if assigned(pn) then atext:=pn.text[0];
      atext:=anode.node.DataString;
     //alog.send(atext);

  end;

end;

procedure txFSTV.DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor);
begin
  inherited;
  ATextColor := Textcolor;
end;

procedure txFSTV.DoGetNodeTitle(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode;
  var ATitle: string);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleColor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AExpanded: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleExtraSize(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATitleExtraSize: Single);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleHorizontalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleTrimming(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleVerticalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTitleWordWrapping(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AWordWrapping: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeTrimming(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeVerticalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txFSTV.DoGetNodeWordWrapping(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AWordWrapping: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoGetNumberOfNodes(ANode: TTMSFNCTreeViewVirtualNode;
  var ANumberOfNodes: Integer);
begin
  inherited;

end;

procedure txFSTV.DoIsNodeChecked(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AChecked: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoIsNodeEnabled(ANode: TTMSFNCTreeViewVirtualNode;
  var AEnabled: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoIsNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  var AExpanded: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoIsNodeExtended(ANode: TTMSFNCTreeViewVirtualNode;
  var AExtended: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoIsNodeVisible(ANode: TTMSFNCTreeViewVirtualNode;
  var AVisible: Boolean);
begin
  inherited;

end;

procedure txFSTV.DoNodeAnchorClick(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AAnchor: string);
begin
  inherited;

end;

procedure txFSTV.DoNodeChanged(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoNodeClick(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  node := anode.node;
  Path := anode.node.datastring;

end;

procedure txFSTV.DoNodeDblClick(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  alog.send('DblClick');
end;

procedure txFSTV.DoNodeMouseEnter(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoNodeMouseLeave(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txFSTV.DoNodeTitleAnchorClick(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AAnchor: string);
begin
  inherited;

end;

procedure txFSTV.DoNodeValueClick(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txFSTV.DoUpdateNodeText(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AText: string);
begin
  inherited;

end;

procedure txFSTV.DoUpdateNodeTitle(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ATitle: string);
begin
  inherited;

end;

procedure txFSTV.ExportNotification(AState: TTMSFNCTreeViewExportState;
  ARow: Integer);
begin
  inherited;

end;

procedure txFSTV.fsError(acode: integer; aMsg: string; aobject: tobject);
begin
  alog.send('fsError: ' + amsg);
end;

procedure txFSTV.fsReady(Sender: TObject);
begin

  alog.send('fsReady');

  {$IFDEF fwweb}await({$ENDIF}fs.GetPathMap{$IFDEF fwweb}){$ENDIF};

  fs.ActiveRoot := fs.PathMap.pm(0);

  rooturl := fs.pathmap.pm(0).url;
  // url:=rootURL;
  Root := fs.PathMap.pm(0).path;
  if root[length(root)] <> '\' then root := root + '\';
  fs.AddClientInfo('WebFiles');
  alog.send('Root', root);

  UpdateList;
end;

procedure txFSTV.fsStatus(aStatus: txdataStatus);
begin
 alog.send('Status', astatus);
 if assigned(onStatus) then onStatus(aStatus);

end;

procedure txFSTV.FullCollapse;
begin
 beginupdate;
 Expanding:=true;
 nodeman.ResetCW;
 CollapseAll;

     nodes[0].expand(false);
 Expanding:=false;
 EndUpdate;
 doColAutoWidth(0);

end;

procedure txFSTV.FullExpand;
begin
   beginupdate;
 Expanding:=true;
 ExpandAll;
 Expanding:=false;
 EndUpdate;
 doColAutoWidth(0);
end;

procedure txFSTV.GetbsFiles;
{procedure OnResult(Response: TXDataClientResponse);
var
  aObject: tobject;
begin
  //    GreetResult := string(TJSObject(Response.Result)['value']);
  xjo(fbsfiles, response.resultasobject);

  console.log(fbsfiles);
end;    }

begin
  // alog.send('Getting Files');
  // fs.Connection.xdclient.RawInvoke('iFS.GetItems', ['', '*.*', true, true, true, true] , @OnResult);
end;

function txFSTV.GetNode: ttmsfncTreeViewNode;
begin
  Result := Fnode;
  result := SelectedNode;

end;

function txFSTV.GetNodefn: string;
begin
 if assigned(node) then result:=pp(node.DataString);

end;

function txFSTV.GetPath(aPath: string): ttmsfncTreeViewNode;
var
  n: ttmsfncTreeViewNode;
begin
  aPath := lowercase(apath);
  if PathLookup.TryGetValue(apath, n) then
    result := n
  else
    result := nil;

end;

function txFSTV.getPathNoRoot: string;
begin
 result:=fs.pathmap.removeroot(path);
end;

function txFSTV.GetSelFile: string;
begin
  if assigned(selFileNode) then result := selFileNode.DataString;

end;

function txFSTV.GetSelFolder: string;
begin
  if assigned(selFolderNode) then result := selFolderNode.DataString;

end;

function txFSTV.GetURL: string;
begin
 result:=fs.pathmap.tourl(path);
end;

function txFSTV.GotPath(aPath: string): boolean;
var
  n: ttmsfncTreeViewNode;
begin
  aPath := lowercase(apath);
  result := PathLookup.TryGetValue(apath, n);

end;

procedure txFSTV.IconsReady(sender: tobject);
procedure Loaded1;
var
 aIcon: ttmsfncBitmap;
begin
  aIcon :=icons.bc.findbitmap( expandIcon.bn);
  //aIcon.SetSize(16,16);
  na.expandnodeicon:=aIcon;
  na.ExpandNodeIconLarge:=aIcon;


end;
procedure Loaded2;
var
 aIcon: ttmsfncBitmap;
   begin
  aIcon :=icons.bc.findbitmap( collapseIcon.bn);
 // aIcon.SetSize(16,16);
  na.collapseNodeIcon.assign(aIcon);
  na.collapseNodeIcon.Assign(aIcon);
end;

var
 aIcon: ttmsfncBitmap;
 begin
 icons.geticon('openfolder-svg');
  icons.geticon('folder-svg');
  icons.geticon('file-svg');
  icons.geticon('up-svg');
   rootIconClosed:=icons.geticon('rootOrange-svg');
   rootIconOpen:=icons.geticon('root-svg');

      {$IFDEF fwweb}
      //beginupdate;

  expandIcon:=icons.geticon('expand-svg', @Loaded1);
  CollapseIcon:=icons.geticon('collapse-svg', @Loaded2);



  //endupdate;
  {$ELSE}
    expandIcon:=icons.geticon('expandwhite-svg');
  CollapseIcon:=icons.geticon('collapsewhite-svg');
  rooticon:=icons.geticon('root-svg');
   na.CollapseNodeIcon.LoadFromFile(collapseicon.fn);
   na.ExpandNodeIcon.LoadFromFile(expandicon.fn);
    na.CollapseNodeIconLarge.LoadFromFile(collapseicon.fn);
   na.ExpandNodeIconLarge.LoadFromFile(expandicon.fn);


 {  na.ExpandNodeIconLarge.svg.CustomFillColor:=gcWhite;
   na.ExpandNodeIcon.svg.CustomFillColor:=gcWhite;
   na.CollapseNodeIcon.svg.CustomFillColor:=gcWhite;
   na.CollapseNodeIconLarge.svg.CustomFillColor:=gcWhite;}


  {$ENDIF}

end;

procedure txFSTV.init;
var
  c: ttmsfncTreeViewColumn;
  i: integer;
begin
///////////////////////////////////////////////////////////////Timers
  TimerColAutoWidth:=tTimer.create(nil);
  timerColAutoWidth.Enabled:=false;
  TimerColAutoWidth.tag:=50;
  TimerColAutoWidth.OnTimer:=TimerColAutoWidthTImer;
///////////////////////////////////////////////////////////////FS Service
  fs := txfs.create;
  fs.onReady := fsReady;
  fs.onerror := fsError;
  fs.onStatus:=fsStatus;
  bsFiles := tbsFIleList.create;
  PathLookup := txFSTVLookup.create;
  ListViewLookup := txFSTVListLookup.Create;
  ListViewReverseLookup := txFSTVListLookup.create;
///////////////////////////////////////////////////////////////Icons
  icons := txIcons.create;
  icons.onReady:=IconsReady;
  icons.load(icons.uri,'txfstv', false);
     BitmapContainer := icons.bc;
     
///////////////////////////////////////////////////////////////Other
  onMouseDown:=tvMouseDown;
  //icons.RootPath := 'txfstv';
  List := tStringList.create;
  TopFont := tFont.Create;
  {$IFDEF fwfmx}
  topfont.OnChanged := DoAllFontChange;
  {$ELSE}
  topfont.OnChange := DoAllFontChange;
  {$ENDIF}

  ///////////////////////////////////////////////////////////////Columns

  colHMargin:=12;
  colvMargin:=4;

  c:=NewCol('Item', 'colItem', true);
  c.Visible:=true;

  c:=NewCol('Path', 'ColPath', true);
  c.Visible:=false;
  c.Sorting := TTMSFNCTreeViewColumnSorting.tcsRecursive;

  c:=NewCol('Ext', 'Ext', true);
  c.Visible:=false;

  c:=NewCol(' ', 'colIsDir', false);
  c.data.AllowShow:=false;
  c.width:=0;
  c.Visible:=false;

  ca.OptimizedColumnDisplay:=false;
  ca.FillEmptySpaces:=true;


  {$IFDEF fwfmx}
     topHeight:=60;
  {$ENDIF}
  {$IFDEF fwvcl}
     topHeight:=60;
  {$ENDIF}
  {$IFDEF fwweb}
     topHeight:=30;
  {$ENDIF}




  topfont.size := 12;
  ca.Stretch:=false;
  ca.StretchAll:=false;

  CA.topsize := 0;
  {$IFDEF fwfmx}
  topfont.family := 'Microsoft YaHei UI';
  {$ENDIF}
  {$IFDEF fwVCL}
  topfont.name := 'Microsoft YaHei UI';

  {$ENDIF}
     EnableColAutoWidth:=true;

  ///////////////////////////////////////////////////////////////Filter
    xfilter:=txFilter.create;
  xfilter.FieldNames.addpair('ext', 'Ext');
  xfilter.FieldNames.addpair('folder', 'colPath');
    xfilter.FieldNames.addpair('item', 'colItem');
    xfilter.DefCol:='colItem';
  xFilter.tv:=self;
  ///////////////////////////////////////////////////////////////Nodes
  na.font.color := gcBlack;
  na.ShowFocus := false;

  {$IFDEF fwweb}
     NA.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmFixed;
  {$ELSE}
      NA.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmVariable;
  {$ENDIF}
     rootOption:=roServerName;
    NA.FixedHeight := 30;

    na.SelectionArea := TTMSFNCTreeViewSelectionArea.tsaFromLevel;

  AdaptToStyle := false;

  NA.Stroke.kind := gskSolid;
  NA.stroke.width := 0.5;
  NA.stroke.Color := GCblack;
  NA.showlines := false;


  Stroke.kind := gskSolid;
  stroke.width := 0.5;
  stroke.Color := GCblack;
  stroke.kind := gsknone;
  ColumnStroke.kind := gskNone;
  ca.TopStroke.kind := gskNone;
  NA.ColumnStroke.kind := gskNone;

  na.ExpandWidth := 16;
  na.expandheight := 16;
     SelNodeColor := gcSkyblue;

  IconSize := 24;

   ///////////////////////////////////////////////////////////////Other
  LineStyle := lsNone;


  VerticalScrollBarVisible := true;
  HorizontalScrollBarVisible := false;

  Interaction.ReadOnly := true;
  BRLineBreaks := false;
  noSelectStyle := False;

  FontSize:=12;

  stroke.kind := gskNone;
  BGstyle := bgSingleColor;
  BGColor := gcWhite;

  TextColor := gcBlack;

  SelLineColor := gcWhite;


  InitMenu;
  InitDone:=true;
end;

procedure txFSTV.InitMenu;

var
 aItem: TMenuItem;
begin
  pM:=tPopupMenu.Create(self);
//  popupMenu:=pm;
  pm.OnPopup:=pmOPen;
  pmadd('Expand All');
  pmadd('Collapse All');
  pmadd('-');
  pmAdd('Show Columns');
  pmadd('Col auto width');
  pmadd('-');
  pmadd('Bigger Text');
  pmadd('Normal Text');
  pmadd('Smaller Text');
  pmadd('-');
  pmadd('Dont click');
  EnableMenu:=true;
end;

function txFSTV.pmAdd(acaption: string): tmenuItem;
var
 n: string;
begin
  n:=StringReplace(acaption, ' ', '', [rfIgnorecase, rfReplaceAll]);
  n:=lowercase(n);

   result:=tmenuItem.create(pm);
  result.caption:=aCaption;
   result.OnClick:=pmclick;
  pm.items.add(result);
  if acaption<>'-' then result.name:=n;
    result.tag:=pm.items.count;
end;

procedure txFSTV.pmclick(sender: tobject);
var
 aitem: tmenuitem;
 Item: string;
begin
 alog.send('pmClick', sender);
 aItem:=tmenuItem(sender);
 Item:= lowercase(aitem.name);

 if item='expandall' then FullExpand;
   if item='collapseall' then
   begin
    FullCollapse;
   end;
   if item='showcolumns' then
   begin
     ShowColumns:=not ShowColumns;
   end;
   if item='colautowidth' then
   begin
     doColAutoWidth;
   end;
   if item='biggertext' then
   begin
     FontSize:=FontSize+2;
     alog.send('Biggertext', fontsize);
   end;
   if item='smallertext' then
   begin
     FontSize:=FontSize-2;
          alog.send('smallertext', fontsize);
   end;
   if item='normaltext' then
   begin
     FontSize:=NormalFontSize;
   end;
   if item='dontclick' then
   begin
     alog.send('rooticonlosed', rootIconClosed);
     alog.send('rooticonOpen', rootIconOpen);
  nodes[0].ExpandedIconNames[0, false] := rooticonopen.bn;
   nodes[0].CollapsedIconNames[0, false] := rooticonclosed.bn;
     nodes[0].ExpandedIconNames[0, true] := rooticonopen.bn;
    nodes[0].CollapsedIconNames[0, true] := rooticonclosed.bn;

   end;



end;

procedure txFSTV.pmOpen(sender: tobject);
var
 aitem: tmenuitem;
begin
 aitem:=tmenuItem(pm.FindComponent('showcolumns'));
 if assigned(aitem) then aitem.Checked:=ShowColumns;

end;

procedure txFSTV.SetBGStyle(const Value: txBGStyle);
var
  c: ttmsfncTreeViewColumn;
  i: integer;
begin
  FBGstyle := Value;

  if value = BGTrans then
  begin
    na.fill.kind := gfkNone;
    Fill.kind := gfkNone;
  end
  else if value = bgSingleColor then
  begin
    na.fill.kind := gfksolid;
    fill.kind := gfksolid;
  end;

  for i := 1 to columns.count do
  begin
    c := col(i - 1);
    if value = BGTrans then
    begin
      na.fill.kind := gfkNone;
      c.fill.kind := gfkNone;
      c.topfill.kind := gfkNone;
      Fill.kind := gfkNone;
    end
    else if value = bgSingleColor then
    begin
      na.fill.kind := gfksolid;
      c.fill.kind := gfksolid;
      c.topfill.kind := gfksolid;
      fill.kind := gfksolid;
    end;
  end;

end;

procedure txFSTV.doAllFontChange(sender: tobject);
var
  c: ttmsfncTreeViewColumn;
  i: integer;
begin

  for i := 1 to columns.count do
  begin
    c := col(i - 1);
    c.topfont.assign(topFont);

  end;

end;

procedure txFSTV.doAutoHeight(aTimed: integer=-1);
begin

end;

procedure txFSTV.doAutoWidth(aTimed: integer=-1);
begin

end;



procedure txFSTV.doColAutoWidth(aTimed: integer=-1);
var
 i: integer;
 w: integer;
begin
   if atimed<>-1 then
   begin
     if timercolautowidth.Enabled then exit;

     if atimed<>0 then timerColAutoWidth.Interval:=aTimed else timerColAutoWidth.Interval:=timerColAutoWidth.tag;
      timerColAutoWidth.Enabled:=true;
      exit;
   end;
    alog.send('Doing ColAutoWidth');
   if EnableColAutoWidth=true then
      begin
        for i := 1 to columns.count do
        begin

          if columns[i - 1] .Visible then

            if getcolumnwidth(i - 1) <> columns[i - 1] .data.cw then
            begin
              if I = 1 then
                columns[i - 1] .Width := columns[i - 1] .data.cw + NA.ExpandWidth
              else
                columns[i - 1] .Width := columns[i - 1] .data.cw;

            end;
          columns[i - 1] .data.resetcw;
        end;
        w := trunc(columns[0] .data.width + columns[1] .data.width);

        NeededWidth := w;


      end;
end;

procedure txFSTV.setBGColor(const Value: TTMSFNCGraphicsColor);
var
  c: ttmsfncTreeViewColumn;
  i: integer;
begin
  FBGColor := Value;
  na.fill.color := value;
  fill.color := value;

   ApplyColumnStyles;

  setBGStyle(bgStyle)
end;

procedure txFSTV.APplyColumnStyles;
var
  c: ttmsfncTreeViewColumn;
  i: integer;
begin
beginupdate;
    for i := 1 to columns.count do
  begin
    c := col(i - 1);
    c.fill.Color := FBGColor;
    c.topfill.color := fTopColor;

    c.topfont.assign(topFont);
         c.topfont.color:=clwhite;
         c.font.size:=FontSize;
    if fShowColumns then
    begin
      c.TopStroke.kind:=gskNone;
      c.stroke.kind:=gskNone;
     c.BottomStroke.kind := gskNone;
    end else
    begin
      c.TopStroke.kind := gskNone;
   c.BottomStroke.kind := gskNone;
    c.Stroke.kind := gskNone;
    end;
  end;
  endupdate;
end;

procedure txFSTV.SetBorderColor(const Value: ttmsfncGraphicsColor);
begin
  FBorderColor := Value;
  Stroke.color := value;
end;

procedure txFSTV.setbsfile(const Value: tbsfile);
begin
  Fbsfile := Value;
  if assigned(onbsFile) then onBSFile(self, value);

end;

procedure txFSTV.SetcTextHeight(const Value: single);
begin
  FctextHeight := Value;
  if na.HeightMode= TTMSFNCTreeViewNodeHeightMode.tnhmFixed then
  begin
    if na.FixedHeight<>(value+1.5) then na.fixedheight:=(value+1.5);

  end;

end;

procedure txFSTV.SetEnableMenu(const Value: boolean);
begin
  FEnableMenu := Value;
  {if Assigned(pm) then
  begin
    if value=true then PopupMenu:=pm else popupmenu:=nil;

  end;}
end;

procedure txFSTV.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
begin
  inherited;

end;

procedure txFSTV.SetFontSize(const Value: Integer);
begin
ctextHeight:=0;
beginupdate;
if fFontSize=0 then NormalFontSize:=value;

  FFontSize := Value;
  NA.font.size:=value;
  APplyColumnStyles;

  endupdate;
  doColAutoWidth;
end;

procedure txFSTV.setIXStyle(const Value: txIXStyle);
begin
  FixStyle := Value;
  if value=dark then
  begin
    BGColor:=gcBlack;
    bgstyle:=bgSingleColor;
    textcolor:=gcWhite;

    SelNodeColor:=gcDarkorange;
    topColor:=2105376;
    topfont.color:=gcWhite;
    LineColor:=clwhite;
    colSeperatorCOlor:=5395026;
    ApplyColumnStyles;
  end else
  if value=light then
  begin
     BGColor:=gcWhite;
    bgstyle:=bgSingleColor;
    textcolor:=gcBlack;

    SelNodeColor:=gcSkyBlue;
    SelTextColor:=gcblack;
    topColor:=gcWhitesmoke;
    topfont.color:=gcblack;
    LineColor:=gcLightGray;
    colSeperatorCOlor:=gcBlack;
    ApplyColumnStyles;
  end;
end;

procedure txFSTV.SetLineColor(const Value: ttmsfncGraphicsColor);
begin
  FLineColor := Value;
end;

procedure txFSTV.setListView(const Value: ttmsfncTreeView);
begin

  {
   procedure ListViewGetNodeData(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tListViewGetNodeColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
    procedure ListViewGetNodeTextColor(Sender: TObject; ANode:TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:TTMSFNCGraphicsColor);
   procedure     ListViewGetNodeText(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: string);
    procedure    ListViewGetNodeSides(Sender: TObject; ANode:TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides);
    procedure ListViewGetNodeSelectedColor(Sender: TObject; ANode:TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
   procedure  ListViewGetNodeIconSize(Sender: TObject; ANode:TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; AIcon:TTMSFNCBitmap; var AIconWidth, AIconHeight: Double);
     procedure   ListViewGetNodeIcon(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap);
     procedure   ListViewGetNodeSelectedTextColor(Sender: TObject; ANode:TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:TTMSFNCGraphicsColor);

  }
  FListView := Value;
  value.OnGetNodeData := ListViewGetNodeData;
  value.OnGetNodeText := ListViewGetNodeText;
  value.OnGetNodeIcon := ListViewGetNodeIcon;
  value.OnGetNodeIconSize := ListViewGetNodeIconSize;
  value.OnGetNodeSides := ListViewGetNodeSides;
  value.OnGetNodeColor := ListViewGetNodeColor;
  value.OnGetNodeSelectedColor := ListViewGetNodeSelectedColor;
  value.OnGetNodeTextColor := ListViewGetNodeTextColor;
  value.OnGetNodeSelectedtextColor := ListViewGetNodeSelectedTextColor;
  value.OnAfterSelectNode := ListViewAfterSelectNode;
  value.OnGetNodeHeight := ListViewGetNodeHeight;
  value.BitmapContainer := bitmapContainer;
  value.onMouseUp:=ListViewMouseUp;
  //value.NodesAppearance.HeightMode:=TTMSFNCTreeViewNodeHeightMode.tnhmVariable;
 // value.NodesAppearance.VariableMinimumHeight:=5;
end;

procedure txFSTV.setNode(const Value: ttmsfncTreeViewNode);
begin
  fNode := value;

end;

procedure txFSTV.SetNoSelectStyle(const Value: boolean);
begin
  FNoSelectStyle := Value;
end;

procedure txFSTV.SetPath(const Value: string);
var
  n: ttmsfncTreeViewnode;
begin

  if assigned(node) then
    if sametext(node.DataString, value) then exit;

  n := GetPath(value);
  if assigned(n) then
  begin
    SelectedNode := n;
    Node := SelectedNode;
    ScrollToNode(node, true, TTMSFNCTreeViewNodeScrollPosition.tvnspMiddle, true);
    fpath := Value;
    if node.DataBoolean = true then
    begin
      selFolderNode := node;
      SelFileNode := nil;
    end
    else
    begin
      SelFolderNode := node.GetParent;
      SelFileNode := node;

    end;
    if assigned(onPathChanged) then onPathChanged(self, value);
  end;

  {
  if list.indexof(value) <> -1 then
  begin
    SelectedNode := ttmsfncTreeViewNode(list.objects[list.indexof(value)] );
    Node:=selectedNode;
    if assigned(onPathChanged) then onPathChanged(self, value);

  end;   }
end;

procedure txFSTV.SetRoot(const Value: string);
begin
  Froot := Value;
end;

procedure txFSTV.SetSelFileNode(const Value: ttmsfncTreeViewNode);
var
  ln: ttmsfncTreeViewNode;
begin
  FSelFileNode := Value;
  //bsfile:=bs(value);

  if not assigned(ListView) then exit;
  if value = nil then
  begin
    ListView.UnSelectAllNodes;
    exit;
  end;
  if ListViewLookup.trygetvalue(value, ln) then
  begin
    if ListView.selectednode <> ln then

      ListView.selectednode := ln;
  end;
end;

procedure txFSTV.SetSelFolderNode(const Value: ttmsfncTreeViewNode);
var
  ni, ln, pn: ttmsfncTreeViewNode;
  i: integer;
begin
  FSelFolderNode := Value;
  if not assigned(fListView) then exit;
  if not assigned(value) then
  begin
    ListView.nodes.clear;
    exit;
  end;
  if listviewFolderNode = value then exit;

  ListView.BeginUpdate;

  if value.DataBoolean = true then
  begin
    ListView.nodes.clear;
    ListViewLookup.clear;
    ListViewReverseLookup.Clear;

    pn := value.GetParent;
    if assigned(pn) then
    begin
      ln := listview.nodes.add;
      ListViewLookup.add(pn, ln);
      ListViewReverseLookup.add(ln, pn);
      //ln.text[0]:=pn.text[0];
      ln.AssignData(pn);
      ln.DataInteger := -1;
      ln.SetCollapsedIconNames([icons.geticon('up-svg').bn] , false);
      ln.SetExpandedIconNames([icons.geticon('up-svg').bn] , false);

    end;

    for i := 1 to value.nodes.count do
    begin
      ni := value.nodes[i - 1] ;
      ln := listview.nodes.add;
      ListViewLookup.add(ni, ln);
      ListViewReverseLookup.add(ln, ni);
      ln.AssignData(ni);
      //  ln.text[0]:=ni.text[0];
      ln.SetCollapsedIconNames([ni.collapsediconnames[0, false] ] , false);
      ln.SetExpandedIconNames([ni.ExpandedIconNames[0, false] ] , false);

    end;
    Listview.EndUpdate;
    fListViewFolderNode := value;
  end;

end;

procedure txFSTV.SetShowColumns(const Value: boolean);
var
 acolumn: ttmsfncTreeViewColumn;
 i: integer;
begin
  FshowColumns := Value;
  if value=true then
  begin
  ca.topsize:=topheight;
     for i := 1 to columns.count do
     begin
       acolumn:=columns[i-1];
       alog.send(acolumn.text, acolumn.data);
       if acolumn.data.AllowShow then

       acolumn.Visible:=true else acolumn.Visible:=false;
     end;

  interaction.ColumnSizing:=true;
  end else
  begin
    CA.TopSize:=0;

  interaction.ColumnSizing:=false;
  end;
  APplyColumnStyles;
  DoColAutoWidth(0);
end;

procedure txFSTV.SetTextColor(const Value: ttmsfncGraphicsCOlor);
begin
  FSelTextColor := Value;
  //topfont.Color:=value;
end;

procedure txFSTV.SetTopHeight(const Value: integer);
begin
  FTopHeight := Value;
  if showColumns then

  CA.TopSize:=value;
end;

procedure txFSTV.setURI(const Value: string);
begin
  Furi := Value;
  fs.uri := value;

end;

procedure txFSTV.TimerColAutoWidthTImer(sender: tobject);
begin
 ttimer(sender).Enabled:=false;
 doColAutoWidth;
end;

procedure txFSTV.TVMouseDown(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
var
  n                 : TTMSFNCTreeViewVirtualNode;

  idx: integer;
  c: ttmsfncTreeViewColumn;
begin

    idx:=XYToColumn(x,y,false);
    if idx>=0 then
    begin
      alog.send('its column ' + col(idx).text);
    end else
    begin
      n := XYToNode(x, y);
  if assigned(n) then alog.send('Its a node', n.text[0]);
    end;
   if button=mbRight then
   begin
  if EnableMenu then
  begin
    pm.popup(x,y);
  end;
   end;
end;

procedure txFSTV.ListViewAfterSelectNode(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
var
  myNode: ttmsfncTreeViewNode;
  CanChange: boolean;
begin
  if not assigned(anode) then exit;
  if not assigned(anode.Node) then exit;
  CanChange := true;
  if anode.node.DataBoolean = true then
  begin
    CanChange := fListViewnode = anode.node;
  end;
  if anode.node.DataInteger = -1 then
  begin
    CanChange := true;
  end;

  ListViewNode := anode.node;
  if canChange = false then exit;

  if ListViewReverseLookup.TryGetValue(anode.node, myNode) then
  begin
    // SelectedNode:=mynode;
    Path := mynode.DataString;
  end;

end;

procedure txFSTV.ListViewClick(sender: tobject);
begin
 alog.send('ListViewClick');
end;

procedure txFSTV.ListViewGetNodeColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin

end;

function txFSTV.isFolder(aname: string): boolean;
begin
  result := false;
  if aname = '' then exit;
  if aname[length(aname)] = '\' then result := true;

end;

function txFSTV.ItemIcon(aPath: string): string;
var
  n: ttmsfncTreeViewNode;
begin
  if PathLookup.TryGetValue(lowercase(apath), n) then
    result := ItemIcon(n);

end;

function txFSTV.ItemIcon(aNode: ttmsfncTreeViewNode): string;
begin
  result := anode.collapsediconnames[0, false] ;
end;

function txFSTV.ItemIcon: string;
begin
  result := ItemIcon(node);

end;

procedure txFSTV.ListViewGetNodeData(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
begin

end;

procedure txFSTV.ListViewGetNodeHeight(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHeight: Double);
begin
  //  aHeight:=4;
end;

procedure txFSTV.ListViewGetNodeIcon(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean;
  var AIcon: TTMSFNCBitmap);
begin

end;

procedure txFSTV.ListViewGetNodeIconSize(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean;
  AIcon: TTMSFNCBitmap; var AIconWidth, AIconHeight: Double);
begin
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  if anode.node.DataInteger = -1 then
  begin
    aIconWidth := iconSize;
    aIconHeight := iconSize;
  end
  else
  begin
    aIconWidth := iconSize;
    aIconWidth := iconSize;

  end;
end;

procedure txFSTV.ListViewGetNodeSelectedColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin

end;

procedure txFSTV.ListViewGetNodeSelectedTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin

end;

procedure txFSTV.ListViewGetNodeSides(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides);
begin

end;

procedure txFSTV.ListViewGetNodeText(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  AMode: TTMSFNCTreeViewNodeTextMode; var AText: string);
begin
  doGetNodeText(anode, acolumn, amode, atext);
end;

procedure txFSTV.ListViewGetNodeTextColor(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  if anode.DataInteger = -1 then
    atextColor := gcLightgray
  else

    atextcolor := gcBlack;
end;

procedure txFSTV.ListViewMouseUp(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
var
 mn: TTMSFNCTreeViewVirtualNode;
begin
  mn:=listview.XYToNode(x,y);
  if mn=nil then
  begin
  if SelFolderNode=nil then exit;

  path:=selFoldernode.DataString;
  end;


end;



function txFSTV.pp(aitem: string): string;
var
  s: string;
begin

  s := aitem;
  if isfolder(aitem) then
  begin
    s := copy(s, 1, length(s) - 1);
    if pos('\', s) <> 0 then s := ExtractFileName(s);
  end
  else
  begin
    s := ExtractFileName(aitem);
  end;
  Result := s;

end;

procedure txFSTV.REsize;
begin
  inherited;
if initdone=false then exit;

 docolAutowidth(0);
end;

procedure txFSTV.UpdateList;

  function isParent(afolder, aitem: string): boolean;
  var
    s: string;
    folder, item: string;
  begin
    folder := lowercase(afolder);
    item := lowercase(aitem);

    if isfolder(aitem) then item := copy(item, 1, length(item) - 1);

    if isfolder(aitem) then
      s := ExtractFileDir(item)
    else
      s := ExtractFilePath(item);

    //alog.send(aitem + ' - ' + s);
    if s[length(s)] <> '\' then s := s + '\';
    if folder[length(folder)] <> '\' then folder := folder + '\';
    s := lowercase(s);

    result := s = folder;

  end;
  procedure doItems(anodes: ttmsfncTreeViewNodes; asl: tStringList; idx: integer);
  var
    i: integer;
    rn, foldern, filen: ttmsfncTreeViewNode;
    afolder: string;
    s: string;
  begin
    afolder := asl[idx] ;
    foldern := anodes.add;
    foldern.dataString := afolder;
    PathLookup.add(lowercase(afolder), foldern);
    foldern.DataBoolean := true;
    // alog.send(   icons.geticon('openfolder-svg').bn);
 //  alog.send(    icons.geticon('folder-svg').bn);




    if idx=0 then  //root
    begin

   // foldern.ExpandedIconNames[0, false] := icons.geticon('root-svg').bn;
   // foldern.CollapsedIconNames[0, false] := icons.geticon('rootOrange-svg').bn;
     foldern.ExpandedIconNames[0, false] := rooticonopen.bn;
    foldern.CollapsedIconNames[0, false] := rooticonclosed.bn;
     foldern.ExpandedIconNames[0, true] := rooticonopen.bn;
    foldern.CollapsedIconNames[0, true] := rooticonclosed.bn;

    end else
    begin
    foldern.ExpandedIconNames[0, false] := icons.geticon('openfolder-svg').bn;
    foldern.CollapsedIconNames[0, false] := icons.geticon('folder-svg').bn;
    end;




    ////foldern.ExpandedIconNames[0,true]:=icons.geticon('openfolder-svg').bn;
    //foldern.CollapsedIconNames[0,true]:=icons.geticon('folder-svg').bn;

    asl.Objects[idx] := foldern;
    anodes := foldern.nodes;
    //   filen.text[1]:='1';
    // foldern.text[0]:=afolder;
    // alog.send(afolder);gm
    tnc := tnc + 1;
    //  foldern.text[0]:=aFolder;

    for i := idx + 1 to asl.count - 1 do
    begin
      s := asl[i] ;
      // alog.send(extractFilePath(s) + ' - ' + s);

      if isparent(afolder, s) then
      begin
        if isFolder(s) then
        begin
          doItems(foldern.nodes, asl, i);
        end
        else
        begin
          tnc := tnc + 1;
          filen := anodes.add;
          filen.DataString := s;
          PathLookup.add(lowercase(s), filen);
          filen.DataBoolean := false;
          filen.ExpandedIconNames[0, false] := icons.geticon('file-svg').bn;
          filen.CollapsedIconNames[0, false] := icons.geticon('file-svg').bn;

          asl.Objects[i] := filen;
          //  filen.text[0]:=s;
         // filen.text[1]:='0';

        end;
      end;

    end;

  end;
var
  s: string;
  sl: tStringList;
  rn, n: ttmsfncTreeViewNode;
  i: integer;
  aNodes: ttmsfncTreeViewNodes;
begin
  //sl := tStringlist.create;
  SL := list;
  //sl.sorted:=true;
  s := {$IFDEF fwweb}await({$ENDIF}fs.GetAllNames(''){$IFDEF fwweb}){$ENDIF};
  //alog.send(s);
  sl.text := s;
  // sl.insert(0, root);

  BeginUpdate;

  //rn:=nodes.add;
  //rn.text[0]:='Root';
  //anodes:=rn.nodes;
  tnc := 0;
  doItems(nodes, sl, 0);
  EndUpdate;
  ExpandNode(nodes[0] , false);
  alog.send('Nodes: ' + inttostr(tnc));
  alog.send('List: ' + inttostr(sl.count));
  alog.send('Iconscount', icons.icons.Count);
  {for i := 0 to sl.count-1 do
    begin
     s:=sl[i];
     n:=anodes.add;
     n.datastring:= s;
     if isfolder(s) then
     begin
      anodes:=
     end;
    end;   }
    Live:=true;
      doColAutoWidth(0);
end;

procedure txFSTV.UpdateListView;
var
  i: integer;
  n, ni, ln: ttmsfncTreeViewNode;
begin
  exit;
  if not assigned(fListView) then exit;
  n := SelectedNode;
  if not assigned(n) then exit;

  ListView.BeginUpdate;

  if n.DataBoolean = true then
  begin
    ListView.nodes.clear;
    ListViewLookup.clear;

    for i := 1 to n.nodes.count do
    begin
      ni := n.nodes[i - 1] ;
      ln := listview.nodes.add;
      ListViewLookup.add(ni, ln);
      ln.AssignData(ni);
      ln.text[0] := ni.text[0] ;
      ln.SetCollapsedIconNames([ni.collapsediconnames[0, false] ] , false);
      ln.SetExpandedIconNames([ni.ExpandedIconNames[0, false] ] , false);

    end;
  end
  else
  begin
    if ListViewLookup.trygetvalue(n, ln) then ListView.selectednode := ln;

  end;
  ListView.Endupdate;

end;

end.

