unit x.appinfo;

interface
  uses classes, system.Generics.Collections,

  {$IFDEF fwweb}
  web,js,
  {$ENDIF}
  liblogtest;

type txInfo=class
    private
    Fname: string;
    FxClass: string;
    Ficon: String;
    function GetIcon: String;
    public
     DataObjectUI: tobject;
     property icon: String read GetIcon write Ficon;
     constructor create; virtual;
    published
     property name: string read Fname write Fname;
     property xClass: string read FxClass write FxClass;

  end;

  txInfos=tobjectlist<txInfo>;

type txInfoMan=class
  private
    FInfos: txInfos;
  public

   constructor create;

     [Async] procedure GetIcons; async;
   procedure Add(aInfo: txInfo);
  published
   property Infos: txInfos read FInfos write FInfos;
end;



function xInfo: txInfoMan;

var
 InfoMan: txInfoMan;


implementation
     uses x.icons.all;

     function xInfo: txInfoMan;
     begin
       if not assigned(infoMan) then InfoMan:=txInfoMan.create;
       result:=infoMan;
     end;

{ txInfo }

constructor txInfo.create;
begin
 xInfo.add(self);

end;

function txInfo.GetIcon: String;
var
 s: string;
begin
 if fIcon<>'' then

  Result := Ficon else
  begin
    s:=xclass + '-svg';
    if xlicons.GetIcon(s)<>nil then result:=s else result:='x-svg';

  end;
end;

{ txInfoMan }

procedure txInfoMan.Add(aInfo: txInfo);
begin
 infos.add(ainfo);
end;

constructor txInfoMan.create;
begin
 infos:=txInfos.create;
 //txIcons.getxlibicons;
 //  GetIcons;
 {Icons:=txIcons.create;
 Icons.rootpath:='xlib';}
end;

procedure txInfoMan.GetIcons;
var
 x: integer;
begin
{$IFDEF fwweb}
  x:= await(xlicons.GetAllIcons);
  alog.send('GET ALL ICONS', x);
{$ELSE}
xlicons.GetAllIcons;
{$ENDIF}


end;

Initialization
 //xinfo:=txInfoMan.create;

end.
