unit x.css.color;

interface
      uses web, js, classes, x.css, vcl.controls;


    type txCSSFilters=class(txCSSBase)
    private
    Ffilters: tstringlist;
    public
     constructor create;
     procedure loadcss; override;
     function GetFilter(xCol: string): string;
    published
      property filters: tstringlist read Ffilters write Ffilters;

    end;

    var
     xcssFilters: txCSSFilters;

    function xFilter(e: tjsElement; color: string): boolean;
    procedure xSetBG(e: tjsHtmlElement; color: string); overload;
    procedure xSetBG(c: tcontrol; color: string);overload;

implementation

    procedure xSetBG(e: tjsHtmlElement; color: string); overload;
    begin
     if not assigned(e) then exit;

     if not assigned(e.style) then exit;
      if color='' then color:='rgba(255,255,255,0)';

    // console.log('xSetBG', color);
     e.style.setProperty('background-color',  color);
    end;

    procedure xSetBG(c: tcontrol; color: string);overload;
    begin
      xsetBG(tjsHtmlElement(c.elementHandle), color);
    end;

  function xFilter(e: tjsElement; color: string): boolean;

  begin
    xcss.removeClass(e, 'xfilter');
    e.classList.add('xfilter-'+color);
  end;
{ txCSSFilters }

constructor txCSSFilters.create;
begin
  mytype:='.xfilter';
  filters:=tstringlist.Create;
  filters.CaseSensitive:=false;
end;

function txCSSFilters.GetFilter(xCol: string): string;
var
 acss: TJSCSSStyleDeclaration;
begin
 result:='';
 acss:=get(fn(xcol));
 if assigned(acss) then
 begin
   result:=acss.getPropertyValue('filter');

 end;

end;

procedure txCSSFilters.loadcss;
var
 i: integer;
 s: string;
begin
  inherited;
  for i:=1 to mycss.Count do
  begin
    filters.AddObject(ln(mycss[i-1]), mycss.Objects[i-1]);
  end;
  console.log('Color Filters', filters.text);

end;

initialization
xcssFilters:=txcssFilters.create;

end.
