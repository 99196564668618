unit libloginfo;

interface
{$IFNDEF WEBLIB}

     {$STRONGLINKTYPES ON}
uses
{$IFDEF ANDROID}
  Androidapi.JNI.GraphicsContentViewText, Androidapi.Helpers, x.network.android,
{$ENDIF}
{$IFDEF IOS}
  IOSAPI.Foundation, MacAPI.Helpers, MacAPI.ObjectiveC,
{$ENDIF}
{$IFDEF MSWINDOWS}
  windows, msi_network, msi_machine, MSI_OS, dw.OSDevice.Win, msi_defs, msi_smBios,
{$ENDIF}
{$IFDEF MACOS}
{$ENDIF}
{$IFDEF fwvcl}
{$ENDIF}
{$IFDEF fwfmx}
{$ENDIF}
  fmx.graphics, classes, variants, system.uitypes, x.cloudlogging, system.messaging, sysutils, rtti, inifiles, dw.OSDevice,
  system.Generics.Collections, fmx.types, fmx.forms, libnetutils, json, x.appleModels, libloginfo.types, libfmxutils;



type
  tLogInfo = class
  private
    FDeviceID: string;
    FDeviceName: string;
    FDeviceOS: string;
    FDeviceOSVersion: string;
    FDevPlatform: string;
    FDeviceIP: string;
    FDeviceIPs: string;
    FDeviceBattery: string;
    FDeviceModel: string;
    FDeviceVendor: string;
    FDeviceUser: string;
    FAppName: string;
    FAppEXE: string;
    FAppVer: string;
    FDeviceScreenCount: integer;
    FDeviceScreenSize: string;
    FDeviceScale: string;
    Fgotinfo: boolean;
    FDeviceWidth: integer;
    FDeviceHeight: integer;
    FDeviceMAC: string;
    FDeviceHostName: string;
    FNetCards: tLogNetCards;
    FCustom1: string;
    FCustom2: string;
    function GetAppEXE: string;
    function GetAppName: string;
    function GetAppVer: string;
    function GetDeviceBattery: string;
    function GetDeviceID: string;
    function GetDeviceIP: string;
    function GetDeviceIPs: string;
    function GetDeviceModel: string;
    function GetDeviceName: string;
    function GetDeviceOS: string;
    function GetDeviceOSVersion: string;
    function GetDeviceScreenCount: integer;
    function GetDeviceScreenSize: string;
    function GetDeviceUser: string;
    function GetDeviceVendor: string;
    function GetDevPlatform: string;
    function GetDeviceScale: string;
    function GetDeviceHeight: integer;
    function GetDeviceWidth: integer;
    function GetDeviceMac: string;
    function GetDeviceHostName: string;
    function GetNetCards: tLogNetCards;
  public
{$IFDEF MSWINDOWS}
    MitecNetwork: tmitec_Network;
    MitecOS: TMiTeC_OperatingSystem;
    MitecBios: TMiTeC_SMBIOS;
{$ENDIF}
    json: string;
    SL: tStringlist;
    ParseError: string;
    ReadMode: boolean;
    constructor create;
    constructor createAndGet;
    procedure GetInfo;
    procedure GetInfoIf;
    function GetPrimaryDisplay: tdisplay;
    property gotinfo: boolean read Fgotinfo write Fgotinfo;
    function IPsAsList: tStringlist;
    function ParseIFConfig(s: string): tLogNetCards;
     {$IF Defined(MACOS)}

function GetDeviceModelNew: string;
{$ENDIF}
  published
    property DeviceID: string read GetDeviceID write FDeviceID;
    property DeviceHostName: string read GetDeviceHostName write FDeviceHostName;
    property DeviceMAC: string read GetDeviceMac write FDeviceMAC;
    property DeviceName: string read GetDeviceName write FDeviceName;
    property DeviceOS: string read GetDeviceOS write FDeviceOS;
    property DeviceOSVersion: string read GetDeviceOSVersion write FDeviceOSVersion;
    property DevPlatform: string read GetDevPlatform write FDevPlatform;
    property DeviceIP: string read GetDeviceIP write FDeviceIP;
    property DeviceIPs: string read GetDeviceIPs write FDeviceIPs;
    property DeviceBattery: string read GetDeviceBattery write FDeviceBattery;
    property DeviceModel: string read GetDeviceModel write FDeviceModel;
    property DeviceVendor: string read GetDeviceVendor write FDeviceVendor;
    property DeviceUser: string read GetDeviceUser write FDeviceUser;
    property AppName: string read GetAppName write FAppName;
    property AppEXE: string read GetAppEXE write FAppEXE;
    property AppVer: string read GetAppVer write FAppVer;
    property DeviceScreenCount: integer read GetDeviceScreenCount write FDeviceScreenCount;
    property DeviceScreenSize: string read GetDeviceScreenSize write FDeviceScreenSize;
    property DeviceScale: string read GetDeviceScale write FDeviceScale;
    property DeviceWidth: integer read GetDeviceWidth write FDeviceWidth;
    property DeviceHeight: integer read GetDeviceHeight write FDeviceHeight;
    property NetCards: tLogNetCards read GetNetCards write FNetCards;
    property Custom1: string read FCustom1 write FCustom1;
    property Custom2: string read FCustom2 write FCustom2;
  end;

implementation

uses x.json,


{$IFDEF MACOS}
// macOS
  Macapi.CoreFoundation, Macapi.Foundation, Macapi.Helpers, Macapi.IOKit, Macapi.AppKit,
  // Posix
  Posix.SysUtsname, Posix.SysSysctl,
  // DW
  DW.Macapi.IOKit, DW.Macapi.Helpers, DW.Macapi.Foundation,
{$ENDIF}




liblogtest;
{ tLogInfo }

procedure tLogInfo.GetInfo;
var
  jo: tJsonObject;
  jp: tJsonPair;
  i: integer;
  s: string;
  ms: string;
begin
  try
    ParseError := '';
{$IFDEF MSWINDOWS}
{$RANGECHECKS Off}
 try
    MitecBios.RefreshData;
 except
 on e: exception do
 begin

 end;

 end;
 try
    MitecNetwork.RefreshData;
  except
 on e: exception do
 begin

 end;

 end;
 try
    MitecOS.RefreshData([soOS]);

    except
 on e: exception do
 begin

 end;


 end;
{$RANGECHECKS On}
{$ELSE}
{$ENDIF}
    json := xj(self);
    if SL = nil then
      SL := tStringlist.create;
    SL.Clear;
    jo := tJsonObject.create;
    jo := tJsonObject.ParseJSONValue(json) as tJsonObject;
    for i := 1 to jo.Count do
    begin
      try
      jp := jo.Pairs[i - 1];
      s := jp.JsonString.value + '=' + jp.jsonValue.AsType<string>;
      s := StringReplace(s, '"', '', [rfReplaceAll]);
      s := StringReplace(s, '''', '''', [rfReplaceAll]);
      SL.Add(s);
      except
       on e: exception do
       begin

       end;

      end;
    end;
    gotinfo := true;
  except
    on e: exception do
    begin
      ParseError := e.Message;
    end;
  end;

end;

procedure tLogInfo.GetInfoIf;
begin
  if gotinfo = false then
    GetInfo;

end;

function tLogInfo.GetNetCards: tLogNetCards;
function FB(const bytes: cardinal): string;
const
  B = 1; // byte
  KB = 1024 * B; // kilobyte
  MB = 1024 * KB; // megabyte
  GB = 1024 * MB; // gigabyte
begin
  if bytes > GB then
    result := FormatFloat('#.## GB', bytes / GB)
  else if bytes > MB then
    result := FormatFloat('#.## MB', bytes / MB)
  else if bytes > KB then
    result := FormatFloat('#.## KB', bytes / KB)
  else
    result := FormatFloat('#.## bytes', bytes);
end;

var
  aCard: tlogNetCard;
 {$IFDEF MSWINDOWS} ma: tAdapter; {$ENDIF}
  i: integer;
  ips: tstringlist;
  ms: string;
begin
 if readMode then begin result:=fNetCards; exit; end;
  {if assigned(FNetCards) then
  begin
    Result := FNetCards;
    exit;
  end;      }
  FNetCards := tLogNetCards.create;
  Result := FNetCards;
{$IFDEF MSWINDOWS}
  for i := 1 to MitecNetwork.TCPIP.AdapterCount do
  begin
    ma := MitecNetwork.TCPIP.Adapter[i - 1];
    aCard := tLogNetCard.create;
    with aCard do
    begin
      Gateway_IPAddress := ma.Gateway_IPAddress.DelimitedText;
      MTU := UIntToStr (ma.MTU);
      name := ma.Name;
      DNSServers_IPv6 := ma.DNSServers.DelimitedText;
      EnableDHCP := ma.EnableDHCP;
      PrimaryWINS_IPv6 := ma.PrimaryWINS_IPv6.DelimitedText;
      Gateway_IPAddressMask := ma.Gateway_IPAddressMask.DelimitedText;
      IPv6Address := ma.IPv6Address.DelimitedText;
      DNSEnabled := ma.DNSEnabled;
      PrimaryWINS_IPAddress := ma.PrimaryWINS_IPAddress.DelimitedText;
      HaveWINS := ma.HaveWINS;
      IPv6Enabled := ma.IPv6Enabled;
      Alias := ma.Alias;
      DHCP_IPv6 := ma.DHCP_IPv6.DelimitedText;
      PrimaryWINS_IPAddressMask := ma.PrimaryWINS_IPAddressMask.DelimitedText;
      IPAddress := ma.IPAddress.DelimitedText;

      AdminStatus := UIntToStr (ma.AdminStatus);
      IPv4Enabled := ma.IPv4Enabled;
      NETBIOSEnabled := ma.NETBIOSEnabled;
      SecondaryWINS_IPAddress := ma.SecondaryWINS_IPAddress.DelimitedText;
      DHCP_IPAddress := ma.DHCP_IPAddress.DelimitedText;
      Address := ma.Address;
      MaxSpeed := UIntToStr (ma.MaxSpeed);
      IPAddressMask := ma.IPAddressMask.DelimitedText;
      DNSServers := ma.DNSServers.DelimitedText;
      AdapterName := ma.AdapterName;
      Gateway_IPv6 := ma.Gateway_IPv6.DelimitedText;
      SecondaryWINS_IPAddressMask := ma.SecondaryWINS_IPAddressMask.DelimitedText;
      DHCP_IPAddressMask := ma.DHCP_IPAddressMask.DelimitedText;
      DNSSuffix := ma.DNSSuffix;
      IntfIdx := UIntToStr (ma.IntfIdx);
      OperStatus := UIntToStr (ma.OperStatus);

      if MitecNetwork.TCPIP.BestInterfaceIdx =ma.IntfIdx then Best:=true;
      if  ma.OperStatus=0 then status:='Not connected' else
      if ma.OperStatus=5 then Status:='Connected' else
      Status:='Unknown';
      speed:=fb(ma.MaxSpeed);

    end;
    result.Add(acard);
  end;
{$ENDIF}
{$IF Defined(MACOS)}
//ips:=IPsAsList;
ms:= OSXShell2('ifconfig -v');
result:=ParseIFConfig(ms);
fNetCards:=result;

 { for i:=1 to IPs.Count do
  begin
   aCard := tLogNetCard.create;
   acard.IPAddress:=ips[i-1];
   result.Add(acard);

  end;     }
 // OSXShell2('ifconfig');
{$ENDIF}


end;

function tLogInfo.GetPrimaryDisplay: tdisplay;
var
  i: integer;
begin
  for i := 1 to screen.DisplayCount do
    if screen.Displays[i - 1].Primary then
      Result := screen.Displays[i - 1];

end;

function tLogInfo.IPsAsList: tStringlist;
begin
 //if readMode then begin result:=fIPsAsList; exit; end;
  try
    Result := tStringlist.create;
{$IFDEF MSWINDOWS}
    Result.AddStrings(MitecNetwork.IPAddresses);
{$ELSE}
    // result:=Tnw.getips(false).CommaText;#
    Result.AddStrings(tnw.GetIPs(false));
{$ENDIF}
  except
    on e: exception do
    begin
      // result:=e.Message;
    end;
  end;
//  fIPsAsList:=result;
end;

function tLogInfo.ParseIFConfig(s: string): tLogNetCards;
function GetItem(c,n: string): string;
var
 p, p2, p3: integer;
 s: string;
begin
 p:=pos(n, c);
 result:='';
 if p<>0 then
 begin
   p2:=pos(' ',c,p + length(n));
   p3:=pos(#13#10,c,p + length(n));
   if p3<p2 then p2:=p3;


     if p2=0 then p2:=length(c);
   s:=copy(c, p+length(n), p2-p-length(n));
   result:=s;
 end;
end;
function GetLineItem(c,n: string): string;
var
 p, p2: integer;
 s: string;
begin
result:='';
 p:=pos(n, c);
 if p<>0 then
 begin
   p2:=pos(#13#10,c,p);
   if p2=0 then p2:=length(c);

   s:=copy(c, p + length(n), p2-p-Length(n));
   result:=s;
 end;
end;

var
s2, line: string;
 p: integer;
 c: tstringlist;
 i: integer;
 acard: tLogNetCard;
 cItem: string;
 citems: tstringlist;
 cip: string;
begin
  result:=tLogNetCards.Create;
  c:=tStringlist.Create;
  c.Text:=s;
  citem:='';
  cItems:=tStringList.Create;
  for i:=1 to c.Count do
  begin
   line:=c[i-1];
   if line[1]<>#9 then
   begin
     if citem<>'' then
     begin
      cip:= getItem(citem,'inet ');
      if cip<>'' then
      begin
         acard:=tLogNetCard.create;
         p:=pos(':', citem);
         acard.AdapterName:=copy(citem,1,p-1);
         acard.Name:=acard.AdapterName;
         acard.Status:=getlineitem(citem,'status: ');
         acard.IPAddress:=cip;
         acard.Speed:=getlineitem(citem,'media: ');
         acard.mtu:=getItem(citem,'mtu ');
         acard.cardType:=getlineItem(citem, 'type: ');
         acard.LinkQuality:=getlineItem(citem, 'link quality: ');
         acard.LinkRate:=getlineItem(citem, 'link rate: ');
         acard.LowPowerMode:=getlineItem(citem, 'low power mode: ');
         acard.OSXDetail:=citem;
         if acard.Status='active' then acard.OperStatus:='1' else acard.OperStatus:='0';

         result.Add(acard);
        citems.Add(citem);
        //alog.Send('ITEM ' + inttostr(citems.Count), citem);
        if acard.operstatus='1' then alog.Send('   CARD ' + acard.IPAddress, acard);
        end;
     end;
     citem:= trimLeft(trimRight(line))+#13#10;
   end else
   begin
    citem:=cItem+trimLeft(trimRight(line))+#13#10;
   end;
  end;
  //alog.Send('citems', citems);
end;

constructor tLogInfo.createandget;
begin

{$IFDEF MSWINDOWS}
  MitecNetwork := tmitec_Network.create(nil);
  MitecOS := TMiTeC_OperatingSystem.create(nil);
  MitecBios := TMiTeC_SMBIOS.create(nil);
{$ELSE}
{$ENDIF}
sl:=tstringlist.Create;
REadMode:=false;
end;

constructor tLogInfo.create;
begin
   inherited;
  ReadMode:=true;
  netcards:=tLogNetCards.Create;
 // alog.send('Loginfo created in read mode');
end;

function tLogInfo.GetAppEXE: string;
var
  s: string;
begin
 if readMode then begin result:=fAppExe; exit; end;
{$IFDEF MSWINDOWS}
  Result := extractFileName(paramstr(0));
{$ENDIF}
{$IFDEF ANDROID}
  s := paramstr(0);
  s := StringReplace(s, '/', '\', [rfReplaceAll]);
  Result := extractFileName(s);
{$ENDIF}
{$IFDEF IOS}
  Result := '';
{$ENDIF}
fAppExe:=result;
end;

function tLogInfo.GetAppName: string;
{$IFDEF IOS}
var
  AppNameKey: Pointer;
  AppBundle: NSBundle;
  NSAppName: NSString;
{$ENDIF}
begin
 if readMode then begin result:=fAppName; exit; end;
{$IFDEF IOS}
  AppNameKey := (StrToNSStr('CFBundleName') as ILocalObject).GetObjectID;
  AppBundle := TNSBundle.Wrap(TNSBundle.OCClass.mainBundle);
  NSAppName := TNSString.Wrap(AppBundle.infoDictionary.objectForKey(AppNameKey));
  Result := UTF8ToString(NSAppName.UTF8String);
{$ELSEIF Defined(Android)}
  Result := TAndroidHelper.ApplicationTitle;

{$ELSE}
  Result := ChangeFileExt(extractFileName(GetModuleName(0)), '');
{$ENDIF}
fAppName:=result;
end;

function tLogInfo.GetAppVer: string;
begin
 if readMode then begin result:=fAppVer; exit; end;
{$IFDEF ANDROID}
  var
    PackageManager: JPackageManager;
  var
    PackageInfo: JPackageInfo;
  begin
    PackageManager := SharedActivityContext.getPackageManager;
    PackageInfo := PackageManager.getPackageInfo(SharedActivityContext.getPackageName, 0);
    Result := JStringToString(PackageInfo.versionName);
  End;
{$ELSEIF Defined(IOS)}
  var
    LValueObject: Pointer;
  begin
    Result := '';
    LValueObject := TNSBundle.Wrap(TNSBundle.OCClass.mainBundle).infoDictionary.objectForKey(StringToID('CFBundleVersion'));
    if LValueObject <> nil then
      Result := NSStrToStr(TNSString.Wrap(LValueObject));
  end;
{$ELSE}
  Result := tosdevice.GetPackageBuild + ' - ' + tosdevice.GetPackageVersion;
{$ENDIF}
fAppVer:=result;
end;

function tLogInfo.GetDeviceBattery: string;
begin
 if readMode then begin result:=fDeviceBattery; exit; end;
{$IFDEF MSWINDOWS}
{$ELSE}
{$ENDIF}
fDeviceBattery:=result;
end;

function tLogInfo.GetDeviceHeight: integer;
var
  pd: tdisplay;
begin
 if readMode then begin result:=fDeviceHeight; exit; end;
  pd := GetPrimaryDisplay;
  Result := Trunc(pd.boundsrect.height);
  fDeviceHeight:=result;
end;

function tLogInfo.GetDeviceHostName: string;
begin
 if readMode then begin result:=fDeviceHostName; exit; end;
  Result := tnw.GetHostName;
  fDeviceHostName:=result;
end;

function tLogInfo.GetDeviceID: string;
begin
  try
   if readMode then begin result:=fDeviceID; exit; end;
{$IFDEF MSWINDOWS}
    Result := '';
{$ELSE}
    Result := tosdevice.GetUniqueDeviceID;
{$ENDIF}
  except
    on e: exception do
    begin
      Result := e.Message;
    end;
  end;
  fDeviceID:=result;
end;

function tLogInfo.GetDeviceMac: string;
begin
 if readMode then begin result:=fDeviceMac; exit; end;
  try
{$IFDEF MSWINDOWS}
    Result := MitecNetwork.MACAddresses[0];
{$ELSE}
    Result := '';
{$ENDIF}
  except
    on e: exception do
    begin
      Result := e.Message;
    end;
  end;
  fDeviceMac:=result;
end;

function tLogInfo.GetDeviceIP: string;
var
  s: string;
  i: integer;
  alist: tStringlist;
begin
 if readMode then begin result:=fDeviceIP; exit; end;
  try
{$IFDEF ANDROID}
    Result := androidGetNet;
{$ELSEIF Defined(MSWINDOWS)}
    Result := MitecNetwork.IPAddresses[0];
{$ELSE}
    alist := tnw.GetIPs(false);
    for i := 1 to alist.Count do
    begin
      s := alist[i - 1];
      if pos('192.168.0', s) <> 0 then
      begin
        Result := s;
        exit;
      end;
    end;
    for i := 1 to alist.Count do
    begin
      s := alist[i - 1];
      if pos('192.168.', s) <> 0 then
      begin
        Result := s;
        exit;
      end;
    end;

    for i := 1 to alist.Count do
    begin
      s := alist[i - 1];
      if pos('192.', s) <> 0 then
      begin
        Result := s;
        exit;
      end;
      if pos('10.0', s) <> 0 then
      begin
        Result := s;
        exit;
      end;
    end;
    Result := alist[alist.Count - 1];

{$ENDIF}
  except
    on e: exception do
    begin
      Result := e.Message;
    end;
  end;
  fDeviceIP:=result;
end;

function tLogInfo.GetDeviceIPs: string;
begin
 if readMode then begin result:=fDeviceIPS; exit; end;
  try

{$IFDEF MSWINDOWS}
    Result := MitecNetwork.IPAddresses.CommaText;
{$ELSE}
    // result:=Tnw.getips(false).CommaText;#
    Result := tnw.GetIPs(false).Text;
{$ENDIF}
  except
    on e: exception do
    begin
      Result := e.Message;
    end;
  end;
  fDeviceIPs:=result;
end;

function tLogInfo.GetDeviceVendor: string;
var
  s: string;
begin
 if readMode then begin result:=fDeviceVendor; exit; end;
{$IFDEF MSWINDOWS}
  Result := MitecBios.SystemManufacturer;
{$ELSE}
  s := lowercase(GetDeviceOS);
  if pos('ios', s) <> 0 then
    Result := 'Apple';
  if pos('mac', s) <> 0 then
    Result := 'Apple';

{$ENDIF}
fDeviceVendor:=result;
end;

function tLogInfo.GetDeviceWidth: integer;
var
  pd: tdisplay;
begin
 if readMode then begin result:=fDeviceWidth; exit; end;
  pd := GetPrimaryDisplay;
  Result := Trunc(pd.boundsrect.Width);
  fDeviceWidth:=result;
end;

 {$IF Defined(MACOS)}

function tLogInfo.GetDeviceModelNew: string;
var
  LBuffer: TArray<Byte>;
  LLength: LongWord;
begin
sysctlbyname(MarshaledAString('hw.model'), nil, @LLength, nil, 0);
  SetLength(LBuffer, LLength);
  sysctlbyname(MarshaledAString('hw.model'), @LBuffer[0], @LLength, nil, 0);
  Result := string(MarshaledAString(@LBuffer[0]));
end;
{$ENDIF}



function tLogInfo.GetDeviceModel: string;
var
  sysmake, sysmodel, sysSerial, SYsMainBoardModel: string;
begin
 if readMode then begin result:=fDeviceModel; exit; end;
{$IFDEF MSWINDOWS}
  Result := MitecBios.SystemModel + ' - ' + MitecBios.MainBoardModel;
{$ELSE}
  Result := tosdevice.GetDeviceModel;
{$ENDIF}
{$IF Defined(MACOS)}
  Result := adl.lookup(GetDeviceModelNew);
{$ENDIF}
fDeviceModel:=result;
end;

function tLogInfo.GetDeviceName: string;
begin
 if readMode then begin result:=fDeviceName; exit; end;
  Result := tosdevice.GetDeviceName;
  fDeviceName:=result;
end;

function tLogInfo.GetDeviceOS: string;

begin
 if readMode then begin result:=fDeviceOS; exit; end;
  { TPlatform = (pfWindows, pfMacOS, pfiOS, pfAndroid, pfWinRT, pfLinux); }
  if tosversion.Platform = pfWindows then
    Result := 'Windows';
  if tosversion.Platform = pfMacOS then
    Result := 'MacOS';
  if tosversion.Platform = pfiOS then
    Result := 'iOS';
  if tosversion.Platform = pfAndroid then
    Result := 'Android';
  if tosversion.Platform = pfWinRT then
    Result := 'WindowsRT';
  if tosversion.Platform = pfLinux then
    Result := 'Linux';
  fDeviceOS:=result;
end;

function tLogInfo.GetDeviceOSVersion: string;
begin
 if readMode then begin result:=fDeviceOSVersion; exit; end;
{$IFDEF MSWINDOWS}
  Result := MitecOS.OSName + ' - ' + MitecOS.OSEdition + ' - ' + MitecOS.Version;
{$ELSE}
  Result := tosversion.Name;
{$ENDIF}
fDeviceOSVersion:=result;
end;

function tLogInfo.GetDeviceScale: String;
begin
 if readMode then begin result:=fDeviceScale; exit; end;
  Result := FloatToStr(GetPrimaryDisplay.Scale);
  fDeviceScale:=result;
end;

function tLogInfo.GetDeviceScreenCount: integer;
begin
 if readMode then begin result:=fDeviceScreenCount; exit; end;
  Result := screen.DisplayCount;
{$IFDEF MSWINDOWS}
{$ELSE}
{$ENDIF}
fDeviceScreenCount:=result;
end;

function tLogInfo.GetDeviceScreenSize: string;
var
  pd: tdisplay;
begin
 if readMode then begin result:=fDeviceScreenSize; exit; end;
  pd := GetPrimaryDisplay;
  Result := FloatToStr(pd.boundsrect.Width) + 'x' + FloatToStr(pd.boundsrect.height);
{$IFDEF MSWINDOWS}
{$ELSE}
{$ENDIF}
fDeviceScreenSize:=result;
end;

function tLogInfo.GetDeviceUser: string;
begin
 if readMode then begin result:=fDeviceUser; exit; end;
{$IFDEF MSWINDOWS}
  Result := MitecNetwork.LoggedUser;
{$ELSE}
  Result := '';
{$ENDIF}
fDeviceUser:=result;
end;

function tLogInfo.GetDevPlatform: string;
begin
 if readMode then begin result:=fDevPlatform; exit; end;
{$IFDEF fwVCL}
  Result := 'VCL';
{$ELSEIF DEFINED(fwfmx)}
  Result := 'FMX';
{$ELSE}
  Result := 'Undefined';
{$ENDIF}
fDevPlatform:=result;
end;

 initialization

 {$ELSE}

 uses classes, sysutils, libloginfo.types, web,JS;

 type tLogInfo=class
 private
        FDeviceID: string;
    FDeviceName: string;
    FDeviceOS: string;
    FDeviceOSVersion: string;
    FDevPlatform: string;
    FDeviceIP: string;
    FDeviceIPs: string;
    FDeviceBattery: string;
    FDeviceModel: string;
    FDeviceVendor: string;
    FDeviceUser: string;
    FAppName: string;
    FAppEXE: string;
    FAppVer: string;
    FDeviceScreenCount: integer;
    FDeviceScreenSize: string;
    FDeviceScale: string;
    Fgotinfo: boolean;
    FDeviceWidth: integer;
    FDeviceHeight: integer;
    FDeviceMAC: string;
    FDeviceHostName: string;
    FNetCards: tLogNetCards;
    FCustom1: string;
    FCustom2: string;
 public
      json: string;
    SL: tStringlist;
    ParseError: string;
    readmode:boolean;
    procedure GetInfo;
    procedure GetInfoIf;

    property gotinfo: boolean read Fgotinfo write Fgotinfo;
 constructor create;
    constructor createAndGet;


 published
   property DeviceID: string read FDeviceID write FDeviceID;
    property DeviceHostName: string read FDeviceHostName write FDeviceHostName;
    property DeviceMAC: string read FDeviceMAC write FDeviceMAC;
    property DeviceName: string read FDeviceName write FDeviceName;
    property DeviceOS: string read FDeviceOS write FDeviceOS;
    property DeviceOSVersion: string read FDeviceOSVersion write FDeviceOSVersion;
    property DevPlatform: string read FDevPlatform write FDevPlatform;
    property DeviceIP: string read FDeviceIP write FDeviceIP;
    property DeviceIPs: string read FDeviceIPs write FDeviceIPs;
    property DeviceBattery: string read FDeviceBattery write FDeviceBattery;
    property DeviceModel: string read FDeviceModel write FDeviceModel;
    property DeviceVendor: string read FDeviceVendor write FDeviceVendor;
    property DeviceUser: string read FDeviceUser write FDeviceUser;
    property AppName: string read FAppName write FAppName;
    property AppEXE: string read FAppEXE write FAppEXE;
    property AppVer: string read FAppVer write FAppVer;
    property DeviceScreenCount: integer read FDeviceScreenCount write FDeviceScreenCount;
    property DeviceScreenSize: string read FDeviceScreenSize write FDeviceScreenSize;
    property DeviceScale: string read FDeviceScale write FDeviceScale;
    property DeviceWidth: integer read FDeviceWidth write FDeviceWidth;
    property DeviceHeight: integer read FDeviceHeight write FDeviceHeight;
    property NetCards: tLogNetCards read FNetCards write FNetCards;
    property Custom1: string read FCustom1 write FCustom1;
    property Custom2: string read FCustom2 write FCustom2;
  end;

  var
   LogInfo: tLogInfo;

  implementation

   constructor TLogInfo.create;
   begin
     ReadMode:=true;
  netcards:=tLogNetCards.Create;
   end;

    constructor TLogInfo.createAndGet;
     begin
      readmode:=false;
      GetInfo;
   end;

       procedure TLogInfo.GetInfo;
      begin
              DeviceOSVersion:= window.navigator.userAgent;
  DeviceOS:= window.navigator.platform;
   AppName:= window.location.href;
     DeviceName:='WEB';
        DeviceHostName:=window.location.hostname;

         DeviceUser:= window.location.username ;
         gotInfo:=true;

      end;

    procedure TLogInfo.GetInfoIf;
    begin
      if gotinfo = false then
    GetInfo;
    end;

 initialization
 registerClass(tLogNetCard);
 LogInfo:=tLogInfo.createAndGet;

 {$ENDIF}


end.
