unit x.libx;

interface

 const LibXURL='https://x.am1.com/';
 const LibXFS='https://x.am1.com/fs2';
 const xIconsURL='https://x.am1.com/icons';
 const xAPPHTMLURL='https://x.am1.com/AppHTML';
 const xIconsDIR='d:\libx\icons';
 const xAppHTMLDIR='d:\libx\AppHTML';

 function xp(s: String): string;
 function xpi(s: String): string;
implementation

 function xp(s: String): string;
 begin
   result:=LibxURL + s;
 end;
  function xpi(s: String): string;
 begin
   result:=LibxURL + 'icons/' + s;
 end;
end.
