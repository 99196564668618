unit xweb.forms;

interface
uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Dialogs, WEBLib.Forms,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.Controls, Web, JS,
  Vcl.Controls, Vcl.StdCtrls, Vcl.Imaging.pngimage,
  system.Generics.Collections;


  type
  txFormCFG=class
    private
    Fform: TFOrm;
    public
     constructor create(aform: Tform=nil);
    published
     property form: TFOrm read Fform write Fform;
  end;

  type
  txFormList = tDictionary<Tform, txFormCFG>;



type
  txFormHelper = class helper for TForm
  public
    [async] procedure xShow; async; overload;
    [async] procedure xShow(IsModal, Isshadow: boolean; aborder: tFormBorderStyle = fbNone); async; overload;
    [async] procedure LoadAdd; async;
  private
    
  end;

  txFormMan=class
    private
    Fforms: txFormList;
    public
     constructor create;
     function AddForm(aForm: TForm): txFormCFG;
     function GotForm(aForm: Tform): boolean;
     function FormCFG(aform: TForm): txFormCFG;
     procedure HandleResize;
    published
     property forms: txFormList read Fforms write Fforms;

  end;

  function fm: txFormMan;

var
  ffm: txFormMan;

implementation

function fm: txFormMan;
begin
  if not assigned(ffm) then ffm:=txFormMan.create;
  result:=ffm;
end;

{ txFormHelper }

procedure txFormHelper.LoadAdd;
begin
  if fm.gotform(self)=false then
  begin
   Await(Tform, Tform(self).Load);
   fm.addForm(self);
  end;


end;

procedure txFormHelper.xShow(IsModal, isShadow: boolean; aborder: tFormBorderStyle);
begin
  window.location.hash := self.classname;
  LoadAdd;
  border := aBorder;
  Shadow := isShadow;
   caption:='Info';

  if isModal then
    Await(TModalResult, Execute)
  else
    execute;

end;

procedure txFormHelper.xShow;
begin
  xShow(false, true);
end;

{
procedure TFrmMain.btCreateSubF1Click(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
    if Assigned(frm) and (frm is TSubForm1) then
      ( frm as TSubForm1 ).lbTexts.Items.Assign(lbTextsMain.Items);
  end;

begin
  // destroy possible old form
  if Assigned(frm) then
    frm.Close;

  frm :=  TSubForm1.CreateNew(HostPanel.ElementID, @AfterCreate);
  btTransferGet.Enabled := true;
  btTransferSet.Enabled := true;
end;
}

{procedure txFormHelper.show;
begin
 Popup :=false;

  //Border := fbDialog;
  Border := fbDialogSizeable;

  // used to manage Back button handling to close subform
  window.location.hash := self.classname;
  //aClass:=  twebform(GetClass(classname));
  // load file HTML template + controls

  if formlist.indexof(self)=-1 then
  begin
  TAwait.ExecP<twebform>(twebform(self).Load);

   FormList.add(self);
       end;
  // init control after loading
  //newform.frm2Edit.Text := WebEdit1.Text;

  //try
    // excute form and wait for close
    TAwait.ExecP<TModalResult>(Execute);
    //ShowMessage('Form 2 closed with new value:"'+newform.frm2Edit.Text+'"');
    //WebEdit1.Text := newform.frm2Edit.Text;
 // finally
   // newform.Free;
  //end;

end;

procedure txFormHelper.ShowModal(sizeable: boolean);
var
 aclass: TWebFormClass;
begin
 Popup :=true;

  if sizeable then
  Border := fbDialogSizeable else
  //Border := fbDialog;
    Border:=fbNone;
               shadow:=false;

  // used to manage Back button handling to close subform
  window.location.hash := self.classname;
  //aClass:=  twebform(GetClass(classname));
  // load file HTML template + controls

    if formlist.indexof(self)=-1 then
  begin
  TAwait.ExecP<twebform>(twebform(self).Load);
  Formlist.add(self);
    end;

  // init control after loading
  //newform.frm2Edit.Text := WebEdit1.Text;

  //try
    // excute form and wait for close
    TAwait.ExecP<TModalResult>(Execute);
    //ShowMessage('Form 2 closed with new value:"'+newform.frm2Edit.Text+'"');
    //WebEdit1.Text := newform.frm2Edit.Text;
 // finally
   // newform.Free;
  //end;
end;      }

{ txFormMan }

function txFormMan.AddForm(aForm: TForm): txFormCFG;
var
 acfg: txFormCFG;
begin
   if GotFOrm(aform)=false then
  begin
    aCFG:=txFormCFG.create(aForm);
    forms.add(aForm, aCFG);
    result:=aCFG;
  end else result:=FormCFG(aform);
end;

constructor txFormMan.create;
begin
 forms:=txFormList.create;
end;

function txFormMan.FormCFG(aform: TForm): txFormCFG;
begin
 result:=nil;
 Forms.TryGetValue(aform, result);
end;

function txFormMan.GotForm(aForm: Tform): boolean;
begin
result:=FormCFG(aform)<>nil;
end;

procedure txFormMan.HandleResize;
var
 apair: tpair<Tform, txformCFG>;
 aForm: Tform;
 aCFG: txFormCFG;
begin
 for apair in forms do
   begin
    aForm:=apair.key;
    aCFG:=apair.value;
    if aForm.Visible then
    begin
      if ((aform.width>TForm(aform.owner).width) or (aform.height>TForm(aform.owner).height))  then
      begin
        aform.top:=0;
        aform.left:=0;
      end else
      begin
       aForm.left := trunc((TForm(aForm.owner).width - aform.width) / 2);
       aForm.top := trunc((TForm(aForm.Owner).height - aform.height) / 2);
      end;


    end;

   end;

end;

{ txFormCFG }

constructor txFormCFG.create(aform: Tform);
begin
 form:=aform;
end;

initialization


end.

