unit uInfo;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls,WEBLib.ExtCtrls,   Vcl.Controls,vcl.forms,   x.icons.all,

   Vcl.StdCtrls,  vcl.TMSFNCGraphics,  vcl.TMSFNCTypes, x.controls, x.cfg.classes, x.appinfo, x.controls.ix,vcl.TMSFNCGraphicsTypes,x.controls.extra;

   type
  TFormInfo = class(TForm)
    SB: TScrollBox;
    WebPanel1: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    [Async] procedure WebButton2Click(Sender: TObject); async;
    procedure WebFormShow(Sender: TObject);
    procedure WebPanel1Click(Sender: TObject);
  private
    { Private declarations }
  public
   icons: txIcons;
    aIcon: txIcon;
   ah: txHeader;
    procedure UpdateControls;
    [ASync] procedure LoadIcons; async;
  protected procedure LoadDFMValues; override; end;

var
  FormInfo: TFormInfo;

implementation

{$R *.dfm}

procedure TFormInfo.WebFormCreate(Sender: TObject);
begin

LoadIcons;
end;

procedure TFormInfo.LoadIcons;
begin
icons:=txIcons.create;

await(icons.load('https://x.am1.com/fs2', 'test'));

end;

procedure TFormInfo.UpdateControls;
var
 aInfo: txInfo;

 aFSIX: txix;
begin
 for ainfo in xinfo.infos do
   begin
     if not assigned(ainfo.DataObjectUI) then
    begin
      ah:=txHeader.Create(sb,4,ainfo.name, nil);
      ah.icons:=ainfo.icon;
      aFSIX := txix.Create(sb);
      ainfo.DataObjectUI := afsIX;
      aFSIX.ColTextLeft := '';
      aFSIX.ixType := txixType.ixInfo;
      aFSIX.Align := AlignTop;
     aFSIX.margins.right:=10;
                aFSIX.margins.bottom:=10;
            aFSIX.margins.left:=25;
                aFSIX.margins.top:=25;
      aFSIX.AlignWithMargins := true;
     // aFSIX.icons := MF.Icons;
      afsix.APplyDefaultStyle;
      //afsix.ApplyBGStyle then alog.error('NOBG');
      afsix.BGColor := $00FBF5F0;
      afsix.BorderColor := $00FBF5F0;
      //  afsix.SetColIcon(0, 'cubewhite-svg');
      afsix.LineStyle := txixLineStyle.lsBottom;

      afsix.LineColor := gcLightGray;
      afsix.ca.TopSize:=0;
      aFSIX.aObject := ainfo;


    end
    else
    begin
      afsIX := txix(ainfo.DataObjectUI);
      afsIX.UpdateValues;
    end;
    afsIX.doTimedAutoSize;
   end;

end;

procedure TFormInfo.WebButton1Click(Sender: TObject);
begin
close;
end;

procedure TFormInfo.WebButton2Click(Sender: TObject);


begin
//FNCIMG.url:='https://x.am1.com/icons/test/lock.svg';
{aIcon:=await(Icons.geticonA('lock-svg'));
console.log('Icon', aIcon);
  ah.icon:=aIcon;}

end;

procedure TFormInfo.WebFormShow(Sender: TObject);
begin
 UpdateControls;
end;

procedure TFormInfo.WebPanel1Click(Sender: TObject);
begin
 UpdateControls;

end;

procedure TFormInfo.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SB := TScrollBox.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);

  SB.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  try
    Name := 'FormInfo';
    Width := 549;
    Height := 488;
    Caption := 'Info';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    SB.SetParentComponent(Self);
    SB.Name := 'SB';
    SB.AlignWithMargins := True;
    SB.Left := 8;
    SB.Top := 100;
    SB.Width := 539;
    SB.Height := 385;
    SB.Margins.Left := 8;
    SB.Margins.Top := 3;
    SB.Margins.Right := 3;
    SB.Margins.Bottom := 3;
    SB.Align := alClient;
    SB.BorderStyle := bsNone;
    SB.Color := clWhite;
    SB.ScrollBars := ssVertical;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 549;
    WebPanel1.Height := 97;
    WebPanel1.Margins.Left := 3;
    WebPanel1.Margins.Top := 3;
    WebPanel1.Margins.Right := 3;
    WebPanel1.Margins.Bottom := 3;
    WebPanel1.Align := alTop;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 1;
    WebPanel1.TabOrder := 1;
    WebPanel1.Visible := False;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 522;
    WebButton1.Top := 3;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Margins.Left := 3;
    WebButton1.Margins.Top := 3;
    WebButton1.Margins.Right := 3;
    WebButton1.Margins.Bottom := 3;
    WebButton1.Caption := 'X';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel1);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 32;
    WebButton2.Top := 18;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Margins.Left := 3;
    WebButton2.Margins.Top := 3;
    WebButton2.Margins.Right := 3;
    WebButton2.Margins.Bottom := 3;
    WebButton2.Caption := 'WebButton2';
    WebButton2.ChildOrder := 1;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(WebPanel1);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 314;
    WebButton3.Top := 16;
    WebButton3.Width := 96;
    WebButton3.Height := 25;
    WebButton3.Margins.Left := 3;
    WebButton3.Margins.Top := 3;
    WebButton3.Margins.Right := 3;
    WebButton3.Margins.Bottom := 3;
    WebButton3.Caption := 'WebButton3';
    WebButton3.ChildOrder := 2;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
  finally
    SB.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
  end;
end;

end.
