unit x.dateUtils;

interface

  uses Classes, sysutils, dateUtils, liblogtest;

  type txTime=class
    private
    FStartTime: tDateTIme;
    FEndTime: tDateTime;
    Fms: int64;
    FSeconds: Int64;
    FMinutes: Int64;
    FHours: int64;
    Fdays: Int64;
    FSimpleString: String;
    FAgoString: string;
    function getms: int64;
    function GetSeconds: Int64;
    function GetHours: int64;
    function GetMinutes: Int64;
    function Getdays: Int64;
    function GetSimpleString: String;
    function GetAgoString: string;
    public
     constructor create;
     procedure SetEnd;
     procedure start;
     procedure stop;
     procedure test;
    published
     property StartTime: tDateTIme read FStartTime write FStartTime;
     property EndTime: tDateTime read FEndTime write FEndTime;
     property ms: int64 read getms write Fms;
     property Seconds: Int64 read GetSeconds write FSeconds;
     property Minutes: Int64 read GetMinutes write FMinutes;
     property Hours: int64 read GetHours write FHours;
     property days: Int64 read Getdays write Fdays;
     property SimpleString: String read GetSimpleString write FSimpleString;
     property AgoString: string read GetAgoString write FAgoString;

  end;

implementation

{ txTime }

constructor txTime.create;
begin
 StartTime:=now;

end;

function txTime.GetAgoString: string;
var
 d: double;
 r: string;
begin

   d:=DaySpan(endtime, startTIme);
   if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 0);
     result:=r + ' days ago';
     exit;
   end;


   d:=HourSPan(endtime, startTIme);
     if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 0);
     result:=r + ' hours ago';
          exit;
   end;

   d:=MinuteSpan(endtime, startTIme);
      if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 0);
     result:=r + ' minutes ago';
   end;
   d:=SecondSpan(endtime, startTIme);
      if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:='seconds ago';
          exit;
   end;
   d:=MilliSecondSpan(endtime, startTIme);
      if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:='Just now';
          exit;
   end;
end;

function txTime.Getdays: Int64;
begin
  Result := DaysBetween(endTime, StartTime);
end;

function txTime.GetHours: int64;
begin
  Result := HoursBetween(endTime, startTime);
end;

function txTime.GetMinutes: Int64;
begin
  Result := minutesBetween(EndTime, StartTIme);
end;

function txTime.getms: int64;
begin
  Result := milliSecondsBetween(endTime, startTIme);

end;

function txTime.GetSeconds: Int64;
begin
  Result := SecondsBetween(EndTime, StartTIme);
end;

function txTime.GetSimpleString: String;
var
 d: double;
 r: string;
begin

   d:=DaySpan(endtime, startTIme);
   if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:=r + ' days';
     exit;
   end;


   d:=HourSPan(endtime, startTIme);
     if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:=r + ' hours';
          exit;
   end;

   d:=MinuteSpan(endtime, startTIme);
      if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:=r + ' minutes';
          exit;
   end;
   d:=SecondSpan(endtime, startTIme);
      if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:=r + ' seconds';
          exit;
   end;
   d:=MilliSecondSpan(endtime, startTIme);
      if d>1 then
   begin
     r:=FloatToStrF(d, ffNumber, 15, 2);
     result:=r + ' milliseconds';
          exit;
   end;
end;

procedure txTime.SetEnd;
begin
 EndTime:=now;
end;

procedure txTime.start;
begin
StartTime:=now;
end;

procedure txTime.stop;
begin
 EndTime:=now;
  Alog.send('Time Taken: ' + simpleString);
end;

procedure txTime.test;
var
 dbl: double;
begin
 dbl:=MilliSecondSpan(endtime, startTIme);
 alog.send('MillisecondSpan', dbl);
  dbl:=SecondSpan(endtime, startTIme);
 alog.send('SecondSpan', dbl);
   dbl:=MinuteSPan(endtime, startTIme);
 alog.send('MinuteSPan', dbl);
   dbl:=HourSPan(endtime, startTIme);
 alog.send('HourSPan', dbl);

end;

end.
