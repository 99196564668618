unit xweb.cookies;

interface

uses weblib.Cookies, classes, sysutils, web, JS, vcl.TMSFNCTypes;

   type txCookies=class

     public
      cookies: TWebCookies;
      constructor create;
      procedure save(aobject: tobject; aname: string='');     overload;
      procedure save( astring: string; aname: string='');     overload;
      procedure save(aInt: integer; aname: string='');        overload;
      function get(aname: string; var aobject: tobject): boolean; overload;
      function get(aname: string; var astring: string): boolean;  overload;
      function get(aname: string; var aInt: integer): boolean;    overload;

     published
   end;

   var
    xcookies: txCookies;

implementation

{ txCookies }

constructor txCookies.create;
begin
  cookies:=twebcookies.Create;
  cookies.GetCookies;

end;

function txCookies.get(aname: string; var aInt: integer): boolean;
var
 acookie: tCookie;
begin
 try
    aCookie:=cookies.Find(aname);
  result:=acookie<>nil;
  if acookie<>nil then
  begin
    aInt:=strtoInt(acookie.Value);
  end;
  acookie.Free;
 except
    on e: tJSObject do
    begin
         result:=false;
      console.log('Exception');
    //  if E is tJSError then console.log('its a tjserror', E) else console.log('Not JS Error');
    end else
    begin
         result:=false;
      console.log('Exception not caught by JSObject');
    end;
 end;
end;

function txCookies.get(aname: string; var astring: string): boolean;
var
 acookie: tCookie;
begin
 try
    aCookie:=cookies.Find(aname);
  result:=acookie<>nil;
  if acookie<>nil then
  begin
      //  console.log('cookie', acookie);
    astring:=acookie.value;
  end else console.log('noi cookir');
  acookie.Free;
 except
    on e: tJSObject do
    begin
         result:=false;
      console.log('Exception');
    //  if E is tJSError then console.log('its a tjserror', E) else console.log('Not JS Error');
    end else
    begin
          result:=false;
      console.log('Exception not caught by JSObject');
    end;
 end;
end;

function txCookies.get(aname: string; var aobject: tobject): boolean;
var
 s: string;

 acookie: tCookie;
begin
 try
    aCookie:=cookies.Find(aname);
  result:=acookie<>nil;
  if acookie<>nil then
  begin
    s:=acookie.Value;
   // console.log('GOT COOKIE ' + aname, s);
    aobject.JSON:=s;
  end else
   console.log('NO COOKIE ' + aname);
//  acookie.Free;
 except
    on e: tJSObject do
    begin
     result:=false;
      console.log('Exception');
    //  if E is tJSError then console.log('its a tjserror', E) else console.log('Not JS Error');
    end else
    begin
          result:=false;
      console.log('Exception not caught by JSObject');
    end;
 end;
end;

procedure txCookies.save(aInt: integer; aname: string);
var
 acookie: tCookie;
begin
 try
   acookie:=cookies.Find(aname);
   if acookie=nil then acookie:=cookies.Add(aname, Inttostr(aInt)) else acookie.Value:=inttostr(aint);
   cookies.SetCookies;

 except
    on e: tJSObject do
    begin
      console.log('Exception');
     // if E is tJSError then console.log('its a tjserror', E) else console.log('Not JS Error');
    end else
    begin
      console.log('Exception not caught by JSObject');
    end;
 end;
end;

procedure txCookies.save(astring, aname: string);
var
 acookie: tCookie;
begin
 try
   acookie:=cookies.Find(aname);
   if acookie=nil then acookie:=cookies.Add(aname, aString) else acookie.Value:=aString;
   acookie.Expiry:=now+365;
   cookies.SetCookies;

 except
    on e: tJSObject do
    begin
      console.log('Exception');
    //  if E is tJSError then console.log('its a tjserror', E) else console.log('Not JS Error');
    end else
    begin
      console.log('Exception not caught by JSObject');
    end;
 end;
end;

procedure txCookies.save(aobject: tobject; aname: string);
var
 s: string;

 acookie: tcookie;
begin
 try
   acookie:=cookies.Find(aname);
   s:=aobject.ToJSON;
   if acookie=nil then
   begin
   //cookies.Add(aname, s);
   acookie:=cookies.Add(aname, s);


        acookie.Expiry:=now + 1000;
  // console.log('made new cookie: ' + aname, s);
   end
   else
   begin

   acookie.Value:=s;
   // console.log('Set existing cookie: '+aname, s);
   end;

   cookies.SetCookies;
// cookies.GetCookies;

 except
    on e: tJSObject do
    begin
      console.log('Exception');
     // if E is tJSError then console.log('its a tjserror', E) else console.log('Not JS Error');
    end else
    begin
      console.log('Exception not caught by JSObject');
    end;
 end;
end;

end.
