unit x.fncNodes;

interface

uses
  system.types, system.Generics.Collections, rtti, typinfo,  sysutils,  x.icons.all,

{$IFDEF fwfmx}
 FMX.TMSFNCTreeView, FMX.TMSFNCTreeViewData,  FMX.graphics, FMX.TMSFNCTypes, FMX.TMSFNCGraphics, FMX.TMSFNCGraphicsTypes,  liblogtest;
{$ENDIF}
{$IFDEF fwvcl}
 vcl.TMSFNCTreeView, vcl.TMSFNCTreeViewData,  vcl.graphics, vcl.TMSFNCTypes, vcl.TMSFNCGraphics, vcl.TMSFNCGraphicsTypes,  liblogtest;
{$ENDIF}
{$IFDEF fwweb}
  VCL.TMSFNCTreeView, VCL.TMSFNCTreeViewData,  VCL.graphics, VCL.TMSFNCTypes, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,   liblogtest;
{$ENDIF}






type
  txNodeMan = class;

  txTreeItemData=class
private


    Fnm: txNodeMan;
    fDataObject: array of TObject;
    fDataInteger: array of integer;
    fDataString: array of string;
    fColWidths: array of integer;
    Ftv: ttmsfncTreeView;
    Ftestnode: ttmsfncTreeViewNode;
    FColCount: integer;

    function GetDataObject(idx: integer): TObject;
    procedure SetDataObject(idx: integer; const Value: TObject);
    function GetDataInteger(idx: integer): integer;
    function GetDataString(idx: integer): string;
    procedure SetDataInteger(idx: integer; const Value: integer);
    procedure SetDataString(idx: integer; const Value: string);
    function GetTV: ttmsfncTreeView;  virtual;
    function GetColCount: integer;
  public

    constructor create; virtual;
    procedure UpdateArrays;



    property nm: txNodeMan read Fnm write Fnm;
    property tv: ttmsfncTreeView read GetTV;
    property ColCount: integer read GetColCount;
    property DataObject[idx: integer]: TObject read GetDataObject write SetDataObject;

    property DataInteger[idx: integer]: integer read GetDataInteger write SetDataInteger;
    property DataString[idx: integer]: string read GetDataString write SetDataString;
    property testnode: ttmsfncTreeViewNode read Ftestnode write Ftestnode;
  end;


  txNodeData = class  (txTreeItemData)
  private
    Fnode: ttmsfncTreeViewNode;
    FDrawnHeight: single;
    FDoneCW: boolean;
    Fchanged: boolean;
    FChangedTime: tDateTime;
    FObjectData: tObject;
    FreadOnly: boolean;
    FobjectList: tObject;
    FobjectIndex: integer;
    FisListObject: boolean;
    FisListItem: boolean;
    FDrawnWidth: single;

    function GetTV: ttmsfncTreeView;  override;
    procedure SetDrawnHeight(const Value: single);
    procedure SetDoneCW(const Value: boolean);
    procedure SetChanged(const Value: boolean);
    function GetChanged: boolean;
  public
    lastText: array of string;
    constructor create(anode: ttmsfncTreeViewNode);

    property node: ttmsfncTreeViewNode read Fnode write Fnode;
    property DrawnHeight: single read FDrawnHeight write SetDrawnHeight;
    property DrawnWidth: single read FDrawnWidth write FDrawnWidth;
    property DoneCW: boolean read FDoneCW write SetDoneCW;
    property changed: boolean read GetChanged write SetChanged;
    property ChangedTime: tDateTime read FChangedTime write FChangedTime;
    property ObjectData: tObject read FObjectData write FObjectData;
    property objectList: tObject read FobjectList write FobjectList;
    property objectIndex: integer read FobjectIndex write FobjectIndex;
    property isListObject: boolean read FisListObject write FisListObject;
    property isListItem: boolean read FisListItem write FisListItem;
    property readOnly: boolean read FreadOnly write FreadOnly;
  end;

  txColData=class(txTreeItemData)
    private
    Fcol: ttmsfncTreeViewColumn;
    FCW: integer;
    FMinCW: integer;
    FcwMargin: integer;
    FLastCW: integer;
    FNewWidth: integer;
    FWidth: integer;
    Ficon: string;
    FxIcon: txIcon;
    FAllowShow: boolean;
      function getTV: ttmsfncTreeView; override;
    procedure setCW(const Value: integer);
    function GetWidth: integer;
    procedure SetWidth(const Value: integer);
    public
     constructor create(acol: ttmsfncTreeViewColumn);
     procedure AddCW(awidth: integer; textRect: tRectF);
     procedure ResetCW;
    published
     property col: ttmsfncTreeViewColumn read Fcol write Fcol;
     property CW: integer read FCW write setCW;
     property MinCW: integer read FMinCW write FMinCW;
     property cwMargin: integer read FcwMargin write FcwMargin;
     property LastCW: integer read FLastCW write FLastCW;
     property NewWidth: integer read FNewWidth write FNewWidth;
     property Width: integer read GetWidth write SetWidth;
     property icon: string read Ficon write Ficon;
     property xIcon: txIcon read FxIcon write FxIcon;
     property AllowShow: boolean read FAllowShow write FAllowShow;
  end;

  txNodeDataList = tDictionary<ttmsfncTreeViewNode, txNodeData>;
  txColDataList = tDictionary<ttmsfncTreeViewColumn, txColData>;

  txNodeMan = class
  private
    Ftv: ttmsfncTreeView;
  public
    DataList: txNodeDataList;
    colList: txColDataList;
    constructor create;
    function data(acol: TTMSFNCTreeViewColumn): txColData; overload;
    function data(anode: ttmsfncTreeViewNode): txNodeData;   overload; //var..
     procedure ResetCW;
  published
    // property tv: ttmsfncTreeView read Ftv write Ftv;
  end;

  txNodeHelper = class helper for ttmsfncTreeViewNode
    function data: txNodeData;
  end;

  txColHelper=class helper for ttmsfnctreeviewcolumn
   function data: txColData;
  end;


function RectToStr(aRect: tRectF): string;

var
  NodeMan: txNodeMan;

implementation
 uses DateUtils;

function RectToStr(aRect: tRectF): string;
begin
  result := 'Left: ' + FloatToStr(trunc(aRect.Left)) + #13#10 + 'Right: ' + FloatToStr(trunc(aRect.right)) + #13#10 + 'Top: ' + FloatToStr(trunc(aRect.top)) +
    #13#10 + 'Bottom: ' + FloatToStr(trunc(aRect.bottom)) + #13#10;

end;
{ txNodeData }

constructor txNodeData.create(anode: ttmsfncTreeViewNode);
begin
  inherited Create;
  node := anode;
  fDrawnHeight:=-1;
  try
  SetLength(lastText, ttmsfncTreeView(node.TreeView).columns.count);
  except
   on e: exception do
   begin

   end;

  end;
end;

constructor txTreeItemData.create;
begin

end;

function txTreeItemData.GetColCount: integer;
begin
  result := tv.Columns.Count;
end;

function txTreeItemData.GetDataInteger(idx: integer): integer;
begin
  UpdateArrays;
  result := fDataInteger[idx];
end;

function txTreeItemData.GetDataObject(idx: integer): TObject;
begin
  UpdateArrays;
  result := fDataObject[idx]
end;

function txTreeItemData.GetDataString(idx: integer): string;
begin
  UpdateArrays;
  result := fDataString[idx];
end;

function txNodeData.GetChanged: boolean;
begin
result:=false;
if fchanged=false then exit else
begin
  if secondsBetween(now, ChangedTIme)>10 then
  begin
    fChanged:=false;
    result:=false;
  end else
   result:=true;
end;
end;

function txNodeData.GetTV: ttmsfncTreeView;
begin
  result := nil;
  if assigned(node) then

    result := ttmsfncTreeView(node.TreeView);

end;

procedure txNodeData.SetChanged(const Value: boolean);
begin
  Fchanged := Value;
  ChangedTime:=now;
end;

procedure txNodeData.SetDoneCW(const Value: boolean);
begin
  FDoneCW := Value;
end;

procedure txNodeData.SetDrawnHeight(const Value: single);
begin
  FDrawnHeight := Value;

end;

{procedure txNodeData.SetColTextWidth(acol: integer; awidth: integer; textRect: tRectF);
var
  c: integer;
  colLeft, colRight: single;
  w: single;
begin
  // SetLength(fColWidths, tv.Columns.Count);
  // if assigned(node.VirtualNode)=false then exit;

  // if not node.VirtualNode.Visible then exit;

  c := tv.Columns[acol].Tag;
  colLeft := tv.GetColumnPosition(acol);
  colRight := textRect.Left + awidth;

  w := (textRect.Left - colLeft) + awidth;
  if w = 0 then
    w := 25;

  if w > c then
    tv.Columns[acol].Tag := trunc(w);

end;      }



procedure txTreeItemData.SetDataInteger(idx: integer; const Value: integer);
begin
  UpdateArrays;
  fDataInteger[idx] := Value;
end;

procedure txTreeItemData.SetDataObject(idx: integer; const Value: TObject);
begin
  UpdateArrays;
  fDataObject[idx] := Value;
end;

procedure txTreeItemData.SetDataString(idx: integer; const Value: string);
begin
  UpdateArrays;
  fDataString[idx] := Value;
end;

procedure txTreeItemData.UpdateArrays;
begin
  SetLength(fDataObject, tv.Columns.Count);
  SetLength(fDataString, tv.Columns.Count);
  SetLength(fDataInteger, tv.Columns.Count);

end;

{ txNodeMan }

constructor txNodeMan.create;
begin
  DataList := txNodeDataList.create;
  colList:=txColDataList.create;
end;

function txNodeMan.data(anode: ttmsfncTreeViewNode): txNodeData;

begin
  if DataList.TryGetValue(anode, result) = false then
  begin
    result := txNodeData.create(anode);
    DataList.Add(anode, result);

  end;
end;

procedure txNodeMan.ResetCW;
var
 apair: tpair<ttmsfncTreeViewColumn, txCOlData>;
begin
 for apair in colList do
   begin
     apair.Value.ResetCW;
   end;

end;

function txNodeMan.data(acol: TTMSFNCTreeViewColumn): txColData;

begin
    if colList.TryGetValue(acol, result) = false then
  begin
    result := txColData.create(acol);
    colList.Add(acol, result);
  end ;
end;

{ txNodeHelper }

function txNodeHelper.data: txNodeData;
begin
  result := NodeMan.data(self);
end;

{ txTreeItemData }



function txTreeItemData.GetTV: ttmsfncTreeView;
begin

end;


{ txColData }

procedure txColData.AddCW( awidth: integer; textRect: tRectF);

 var
  c: integer;
  colLeft, colRight: single;
  w: single;
  aw: integer;
begin
              aw:=awidth+cwMargin;
  colLeft := tv.GetColumnPosition(col.index);
  colRight := textRect.Left + aw;

  w := (textRect.Left - colLeft) + aw;
  if w = 0 then
    w := 25;

  if w > fcw then
    fcw:= trunc(w);

end;


constructor txColData.create(acol: ttmsfncTreeViewColumn);
begin
  inherited create;
  col:=acol;
  mincw:=100;
  cw:=100;
  cwMargin:=50;
  NewWidth:=Width;
end;

function txColData.getTV: ttmsfncTreeView;
begin
  result := nil;
  if assigned(col) then

    result := ttmsfncTreeView(col.TreeView);
end;

function txColData.GetWidth: integer;
begin
  result:=Trunc(tv.GetColumnWidth(col.index));
end;

procedure txColData.ResetCW;
begin
   fcw:=minCW;
end;

procedure txColData.setCW(const Value: integer);
begin
 fLastCW:=fcw;
  FCW := Value;
end;

procedure txColData.SetWidth(const Value: integer);
begin
  col.Width:=value;
end;

{ txColHelper }

function txColHelper.data: txColData;
begin
   result := NodeMan.data(self);
end;

initialization

NodeMan := txNodeMan.create;

end.
