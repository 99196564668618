unit x.login.WebForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.ComCtrls,
  VCL.TMSFNCTouchKeyboard, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCCloudImage, VCL.TMSFNCToolBar,
  WEBLib.ExtCtrls, x.login.client, liblogtest, x.controls, AdvTypes, x.login.types, x.css.color,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCBitmapContainer, VCL.TMSFNCImage, x.css.slide,
  dxGDIPlusClasses;

type
  tPinMode = (pinLogin, pinNew, pinConfirm);
type
  tMsgMode = (MsgBack, MsgOK);

type
  tSwipe = (sdLeft, sdRight);

type
  TLoginForm = class(TForm)
    pUser: TPanel;
    pPW: TPanel;
    ePIN: TEdit;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    bNext: TTMSFNCToolBarButton;
    bLogin: TTMSFNCToolBarButton;
    pKeypad: TPanel;
    keypad: TTMSFNCTouchKeyboard;
    [Async]
    [Async]
    bAdd: TTMSFNCToolBarButton;
    WebPanel3: TPanel;
    eFirstName: TEdit;
    eLastName: TEdit;
    pSignedIn: TPanel;
    lbHello: TLabel;
    pMsg: TPanel;
    lbMsgHeading: TLabel;
    lbMsg: TLabel;
    WebPanel6: TPanel;
    TMSFNCCloudImage4: TTMSFNCCloudImage;
    IMGUser: TTMSFNCCloudImage;
    lbUser: TLabel;
    WebLabel3: TLabel;
    ShowTimer: TTimer;
    TMSFNCBitmapContainer1: TTMSFNCBitmapContainer;
    bClose: TTMSFNCToolBarButton;
    eUser: TEdit;
    WebPanel5: TPanel;
    TMSFNCToolBarButton4: TTMSFNCToolBarButton;
    TMSFNCToolBarButton3: TTMSFNCToolBarButton;
    TMSFNCToolBarButton6: TTMSFNCToolBarButton;
    TMSFNCToolBarButton1: TTMSFNCToolBarButton;
    btnMsgBack: TTMSFNCToolBarButton;
    btnMsgOK: TTMSFNCToolBarButton;
    bc: TTMSFNCBitmapContainer;
    bigcube: TTMSFNCCloudImage;
    ts: TTimer;

    procedure bCloseClick(Sender: TObject);
    [Async]
    procedure bLoginClick(Sender: TObject); async;
    [Async]
    procedure bAddClick(Sender: TObject); async;

    procedure bNextClick(Sender: TObject);
    procedure bSignoutClick(Sender: TObject);
    procedure ePINKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure eUserKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure IMGClick(Sender: TObject);
    procedure ShowTimerTimer(Sender: TObject);
    procedure btnMsgBackClick(Sender: TObject);
    procedure btnMsgOKClick(Sender: TObject);
    procedure pMsgKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure TMSFNCToolBarButton4Click(Sender: TObject);
    procedure tsTimer(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDOMContentLoaded(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FStage: tControl;
    Ferror: string;
    FJWT: string;
    Finfo: string;
    FonAfterLogin: tNotifyEvent;
    FLastState: tControl;
    fLastStage: tControl;
    FPinMode: tPinMode;
    FNewPin: string;
    FMsgmode: tMsgMode;
    FSlideFromLeft: tControl;
    FSlidefromRight: tControl;
    FSwipe: tSwipe;
    FStopSwipe: boolean;
    procedure SetStage(const Value: tControl);
    procedure SetError(const Value: string);
    procedure SetInfo(const Value: string);
    procedure SetPinMode(const Value: tPinMode);
    procedure SetMsgMode(const Value: tMsgMode);
    procedure setSlideFromlEft(const Value: tControl);
    procedure SetSlideFromRight(const Value: tControl);
    { Private declarations }
  public
    auser: txuser;
    c: tjsHtmlElement;
    procedure DoMsg(aMsg: string; isError: boolean; BackStage: tControl; okStage: tcontrol);
    procedure Reset;
    procedure loaded; override;
    procedure VisibleChanged; override;
    procedure DoStyling;
    procedure CheckFocus;
    procedure LoginReady(sender: tobject);
    property Stage: tControl read FStage write SetStage;
    property LastStage: tControl read fLastStage write fLastStage;
    property error: string read Ferror write SetError;
    property info: string read Finfo write SetInfo;
    property JWT: string read FJWT write FJWT;
    property onAfterLogin: tNotifyEvent read FonAfterLogin write FonAfterLogin;
    property PinMode: tPinMode read FPinMode write SetPinMode;
    property NewPin: string read FNewPin write FNewPin;
    property Msgmode: tMsgMode read FMsgmode write SetMsgMode;
    property SlideFromLeft: tControl read FSlideFromLeft write setSlideFromlEft;
    property SlidefromRight: tControl read FSlidefromRight write SetSlideFromRight;
    property Swipe: tSwipe read FSwipe write FSwipe;
    property StopSwipe: boolean read FStopSwipe write FStopSwipe;
  protected procedure LoadDFMValues; override; end;

var
  LoginForm: TLoginForm;

implementation

{$R *.dfm}

procedure TLoginForm.bAddClick(Sender: TObject);
begin
  aUser := Await(txUser, xlogin.createuser(euser.text, efirstname.text, elastname.text, epin.text));
  if assigned(auser) then
    console.log('Created user', auser)
  else
    console.log('auser is nil');

end;

procedure TLoginForm.bCloseClick(Sender: TObject);
begin
  Hide;
end;

procedure TLoginForm.bLoginClick(Sender: TObject);
var
  r: string;
begin

  if pinmode = pinnew then
  begin
    newPin := epin.text;
    pinMode := pinConfirm;
  end
  else if pinmode = pinconfirm then
  begin
    if epin.text <> Newpin then
    begin
      // Error := 'They don''t match';
      PinMode := pinconfirm;
      // Stage := pmsg;
      DoMsg('They don''t match', true, ppw, nil);

    end
    else
    begin

      DoMsg('Your PIN has been changed', false, nil, pSignedIn);
    end;
  end
  else if pinMode = pinLogin then
  begin
    //auser := TAwait.ExecP<txUser>(xlogin.login(euser.text, epin.text));
    auser := await(xlogin.CheckLogin(euser.text, epin.text));
    //auser:=await(xlogin.login(euser.text, epin.text));
    if not assigned(auser) then
    begin

      doMsg('That user and PIN combinmation was not accepted', true, nil, pUser);
      exit;
    end;
    auser := await(xlogin.login(euser.text, epin.text));
    if not assigned(auser) then
    begin
      doMsg('Unknown error', true, nil, pUser);
      exit;
    end;

    hide;
  end;
end;

procedure TLoginForm.bNextClick(Sender: TObject);
begin
  Error := '';

  stage := pPW;
  PinMode := pinLogin;
end;

procedure TLoginForm.bSignoutClick(Sender: TObject);
begin
  xLogin.logout;
  euser.text := '';
  stage := pUser;
end;

procedure TLoginForm.CheckFocus;
begin
  if fStage = puser then euser.SetFocus;
  if fstage = ppw then epin.SetFocus;

end;

procedure TLoginForm.DoMsg(aMsg: string; isError: boolean; BackStage,
  okStage: tcontrol);
begin
  lbMsg.Caption := aMsg;
  if isError then
    lbMsg.font.color := clRed
  else
    lbMsg.font.color := clred;
  if OKStage <> nil then
  begin
    btnMsgOK.Visible := true;
    BtnMsgOK.DataObject := OKStage;
  end
  else
    btnMsgOK.Visible := false;
  if BackStage <> nil then
  begin
    btnMsgBack.Visible := true;
    btnMsgBack.DataObject := BackStage;
  end
  else
    btnMsgBack.Visible := false;
  Stage := pMsg;
end;

procedure TLoginForm.DoStyling;
  procedure doControls(aParent: tWinControl);
  var
    ac: tcontrol;
    ap: TPanel;
    i: integer;
  begin

    xsetBG(aParent, 'white');
    //self.ElementPopupClassName:='box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px';
    for i := 1 to aParent.controlcount do
    begin
      ac := aparent.controls[i - 1] ;
      //ac.elementclassname:='xxx';
      xsetBG(ac, 'white');
      if ac is tWinControl then doControls(twinCOntrol(ac));

    end;

  end;
var
  ac: tcontrol;
  ap: TPanel;
  i: integer;
begin
  //c:=tjsHTMLElement(document.getElementById(FormContainer));
  //   console.log('FormContainer',c);
  xsetbg(self, 'white');

  for i := 1 to controlcount do
  begin
    ac := controls[i - 1] ;
    ac.elementclassname := 'xxx';
    ap := nil;
    if ac is TPanel then
    begin

      ap := TPanel(ac);
      console.log('Styling ' + ap.name);
      xsetBG(ap, 'white');
      ap.elementhandle.style.setProperty('border', 'none');
      //      ap.Align:=AlignClient;
      ap.width := ClientWidth;
      ap.height := ClientHeight;
      ap.top := 0;
      ap.left := 0 - ap.width;
    end;
    if ac is tWinControl then doControls(twinCOntrol(ac));
    if assigned(ap) then ap.Visible := false;

  end;
  //   euser.elementhandle.style.setProperty('', '0.75');
  //xsetbg(euser, 'rgba(0,255,0,0.5)');
  //xsetbg(webpanel4, 'rgba(0,255,0,0)');
  reset;
  bclose.left := width - bclose.width - 8;
  bClose.top := 8;
  bclose.Anchors := [TAnchorKind.aktop, tAnchorKind.akRight] ;
  bclose.BringToFront;

end;

procedure TLoginForm.ePINKeyUp(Sender: TObject; var Key: Word; Shift:
  TShiftState);
begin
  if key = VK_RETURN then
    bloginClick(nil)
  else if key = VK_ESCAPE then
    stage := LastStage;
end;

procedure TLoginForm.eUserKeyDown(Sender: TObject; var Key: Word; Shift:
  TShiftState);
begin
  if key = VK_REturn then
    bNextCLick(nil)
  else if key = VK_ESCAPE then
    euser.text := '';

end;

procedure TLoginForm.IMGClick(Sender: TObject);
begin
  doStyling;
end;

procedure TLoginForm.loaded;
begin
  inherited;
  console.log('LOADED LOADED');
end;

procedure TLoginForm.LoginReady(sender: tobject);
begin
  alog.send('Login service ready');
  SetStage(pUser);
end;

procedure TLoginForm.Reset;
begin
  epin.text := '';
  eUser.text := '';
  PinMode := pinLogin;
  //fstage:=nil;
  //lastStage:=nil;
  if xlogin.HasUser then
    stage := pSignedIn
  else

    Stage := pUser;
end;

procedure TLoginForm.SetError(const Value: string);
begin
  Ferror := Value;
  lbMsg.font.color := clRed;
  lbMsg.caption := value;
  msgMode := MsgBack;
end;

procedure TLoginForm.SetInfo(const Value: string);
begin
  Finfo := Value;
  lbMsg.font.color := clGreen;
  lbMsg.caption := value;
  msgMode := MsgOK;
end;

procedure TLoginForm.SetMsgMode(const Value: tMsgMode);
begin
  FMsgmode := Value;
  if value = msgBack then
  begin
    btnMsgBack.Visible := true;
    btnMsgOK.Visible := false;
  end
  else if value = msgOK then
  begin
    btnMsgBack.Visible := false;
    btnMsgOK.Visible := true;
  end;
end;

procedure TLoginForm.SetPinMode(const Value: tPinMode);
begin
  FPinMode := Value;
  if value = pinLogin then epin.TextHint := 'PIN';
  if value = pinNew then epin.TextHint := 'NEW PIN';
  if value = pinCOnfirm then epin.TextHint := '...and again';
  ePin.text := '';
end;

procedure TLoginForm.setSlideFromlEft(const Value: tControl);
begin
  if ts.Enabled then
    StopSwipe := true
  else
    StopSwipe := false;
  ts.tag := 0;
  //value.Visible := true;
  FSlideFromLeft := Value;
  Swipe := sdLeft;
  value.left := 0 - width;
  value.Visible := true;
  console.log('SlideFromLeft');
  ts.Enabled := true;
end;

procedure TLoginForm.SetSlideFromRight(const Value: tControl);
begin
  if ts.Enabled then
    StopSwipe := true
  else
    StopSwipe := false;
  ts.tag := 0;
  //value.Visible := true;
  FSlidefromRight := Value;
  Swipe := sdRight;
  value.left := width;
  value.Visible := true;
  console.log('SlidefromRight');
  ts.Enabled := true;
end;

procedure TLoginForm.SetStage(const Value: tControl);
begin
  if assigned(fStage) then
  begin
    lastStage := fStage;
    fstage.left := 0 - fstage.width;
    fStage.visible := false;

  end
  else
    LastStage := nil;

  FStage := Value;
  // value.Visible:=true;
   //value.visible := true;
   //xcSlide.slide(value, fromleft);
   {if assigned(LastStage) then
   begin
     if LastStage = pSignedin then
       SlideFromLeft := value
     else if LastStage = pMsg then
       SlideFromLeft := value   else
     if LastStage=ppw then
      begin
        if pinmode=pinlogin then slidefromRight:=value else slidefromLeft:=value;

      end
     else
       slidefromRight := value;
   end
   else
   begin
     value.left := 0;
     value.Visible := true;
   end; }
  SlideFromRight := value;

  if value = puser then
  begin
    { if xlogin.JWT <> '' then
     begin
       if error = '' then
       begin
         info := 'You are already logged in as ' + xlogin.user.user;
       end;

     end else info := 'Enter your Cubes e-mail'; }

    // euser.setfocus;
     //ActiveControl:=eUser;
  end
  else if value = ppw then
  begin
    //ePIN.setfocus;
  end
  else if value = pmsg then
  begin

  end
  else if value = psignedin then
  begin
    if xlogin.HasUser then
    begin
      try
        lbHello.Caption := 'Hello, ' + xlogin.user.FirstName;
        lbuser.caption := StringReplace(lbuser.caption, '%s', xlogin.user.user, [rfIgnorecase, rfReplaceAll] );
        imguser.url := xlogin.user.Avatar;
      except
        on e: exception do
        begin
          alog.error('Error getting user');
        end;
      end;

    end;
  end
  else
    alog.error('pSignedin but no user');

  // WebFormResize(nil);
   //bclose.BringToFront;

  //CheckFocus;
end;

procedure TLoginForm.ShowTimerTimer(Sender: TObject);
var
  p, f: tjsHTMLElement;
begin
  ShowTimer.enabled := false;
  //doStyling;
   //bigcube.bitmaps[0].BitmapName:='black';
  // console.log('SElf', parent);
  // console.log('self', COntainer);

     //  f:=  tjsHTMLElement(elementhandle);
     //  p:=tjsHTMLElement(f.parentElement);

  tjsHTMLElement(Container).style.setProperty('overflow', 'hidden');

end;

procedure TLoginForm.btnMsgBackClick(Sender: TObject);
begin
  stage := tcontrol(btnMsgBack.DataObject);
end;

procedure TLoginForm.btnMsgOKClick(Sender: TObject);
begin
  stage := tcontrol(btnMsgOK.DataObject);
end;

procedure TLoginForm.pMsgKeyUp(Sender: TObject; var Key: Word; Shift:
  TShiftState);
begin
  if btnmsgOK.Visible then
    btnMsgOKClick(nil)
  else if btnMsgBack.Visible then
    btnMsgBackClick(nil);

end;

procedure TLoginForm.TMSFNCToolBarButton4Click(Sender: TObject);
begin
  pinMode := pinnew;
  stage := pPW;
end;

procedure TLoginForm.tsTimer(Sender: TObject);
begin
  if swipe = sdLeft then
  begin
    // fslidefromleft.visible:=true;
      //     fslidefromleft.BringToFront;
    ts.tag := ts.tag + 1;
    if ((fSlideFromLeft.left >= 0) or (StopSwipe = true)) then
    begin
      alog.send('Abort', ts.tag);
      ts.enabled := false;
      StopSwipe := false;
      fSlideFromLeft.left := 0;
      fSlideFromLeft := nil;
      CheckFocus;
      exit;
    end;
    fslidefromleft.left := fSlideFromLeft.left + 6;
    //   if fslidefromleft.Visible=false then fSlideFromLeft.Visible:=true;

  end
  else
  begin
    // fSlideFromRight.visible:=true;
  //   fSLideFromRight.BringToFront;
    ts.tag := ts.tag + 1;
    if ((fSlideFromRight.left <= 0) or (StopSwipe = true)) then
    begin
      alog.send('Abort', ts.tag);
      ts.enabled := false;
      StopSwipe := false;
      fSlideFromRight.left := 0;
      fSlideFromRight := nil;
      CheckFocus;
      exit;
    end;
    fSlidefromRight.left := fSlidefromRight.left - 6;
    //   if fSlidefromRight.Visible=false then fSlidefromRight.Visible:=true;
  end;

end;

procedure TLoginForm.VisibleChanged;
begin
  inherited;
  console.log('VISIBLE CHANGED');
  if visible then CheckFocus;

end;

procedure TLoginForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := tCLoseAction.caHide;
end;

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
  console.log('WebFormCreate');
  width := puser.width + 10;
  height := puser.height + 10;
  if not assigned(xLogin) then
  begin
    xLogin := txLogin.create;
    xLogin.onReady := LoginReady;
    xlogin.uri := 'https://x.am1.com/fs2';
  end;

end;

procedure TLoginForm.WebFormDOMContentLoaded(Sender: TObject);
begin
  //doStyling;
   //reset;
end;

procedure TLoginForm.WebFormResize(Sender: TObject);
begin
  keypad.left := trunc((pkeypad.width - keypad.width) / 2);
  keypad.top := trunc((pkeypad.height - keypad.height) / 2);
end;

procedure TLoginForm.WebFormShow(Sender: TObject);
var
  p: tjsHTMLElement;
begin
  //  p:=tjsHTMLElement(Document.GetELementByID( self.FormCOntainer));
  // console.log('p', p);
  doStyling;
  console.log('WebFormShow');
  console.log('owner', owner);

  left := trunc((TForm(owner).width - width) / 2);
  top := trunc((TForm(Owner).height - height) / 2);
  tag := tag + 1;
  ShowTimer.Enabled := true;

  {puser.align := AlignClient;
  pPW.align := AlignCLient;
  pMsg.Align:=AlignCLient;
  pSignedin.Align:=AlignClient;}

end;

procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pUser := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  bNext := TTMSFNCToolBarButton.Create(Self);
  eUser := TEdit.Create(Self);
  bigcube := TTMSFNCCloudImage.Create(Self);
  pPW := TPanel.Create(Self);
  ePIN := TEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  bLogin := TTMSFNCToolBarButton.Create(Self);
  bAdd := TTMSFNCToolBarButton.Create(Self);
  pKeypad := TPanel.Create(Self);
  keypad := TTMSFNCTouchKeyboard.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  eFirstName := TEdit.Create(Self);
  eLastName := TEdit.Create(Self);
  pSignedIn := TPanel.Create(Self);
  lbHello := TLabel.Create(Self);
  lbUser := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  IMGUser := TTMSFNCCloudImage.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  TMSFNCToolBarButton4 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton3 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton6 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton1 := TTMSFNCToolBarButton.Create(Self);
  pMsg := TPanel.Create(Self);
  lbMsgHeading := TLabel.Create(Self);
  lbMsg := TLabel.Create(Self);
  WebPanel6 := TPanel.Create(Self);
  btnMsgBack := TTMSFNCToolBarButton.Create(Self);
  btnMsgOK := TTMSFNCToolBarButton.Create(Self);
  TMSFNCCloudImage4 := TTMSFNCCloudImage.Create(Self);
  TMSFNCBitmapContainer1 := TTMSFNCBitmapContainer.Create(Self);
  bClose := TTMSFNCToolBarButton.Create(Self);
  bc := TTMSFNCBitmapContainer.Create(Self);
  ShowTimer := TTimer.Create(Self);
  ts := TTimer.Create(Self);

  pUser.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  bNext.BeforeLoadDFMValues;
  eUser.BeforeLoadDFMValues;
  bigcube.BeforeLoadDFMValues;
  pPW.BeforeLoadDFMValues;
  ePIN.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  bLogin.BeforeLoadDFMValues;
  bAdd.BeforeLoadDFMValues;
  pKeypad.BeforeLoadDFMValues;
  keypad.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  eFirstName.BeforeLoadDFMValues;
  eLastName.BeforeLoadDFMValues;
  pSignedIn.BeforeLoadDFMValues;
  lbHello.BeforeLoadDFMValues;
  lbUser.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  IMGUser.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  TMSFNCToolBarButton4.BeforeLoadDFMValues;
  TMSFNCToolBarButton3.BeforeLoadDFMValues;
  TMSFNCToolBarButton6.BeforeLoadDFMValues;
  TMSFNCToolBarButton1.BeforeLoadDFMValues;
  pMsg.BeforeLoadDFMValues;
  lbMsgHeading.BeforeLoadDFMValues;
  lbMsg.BeforeLoadDFMValues;
  WebPanel6.BeforeLoadDFMValues;
  btnMsgBack.BeforeLoadDFMValues;
  btnMsgOK.BeforeLoadDFMValues;
  TMSFNCCloudImage4.BeforeLoadDFMValues;
  TMSFNCBitmapContainer1.BeforeLoadDFMValues;
  bClose.BeforeLoadDFMValues;
  bc.BeforeLoadDFMValues;
  ShowTimer.BeforeLoadDFMValues;
  ts.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Width := 1287;
    Height := 788;
    Caption := 'Cubes Login';
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    pUser.SetParentComponent(Self);
    pUser.Name := 'pUser';
    pUser.AlignWithMargins := True;
    pUser.Left := 101;
    pUser.Top := 54;
    pUser.Width := 428;
    pUser.Height := 303;
    pUser.Margins.Left := 3;
    pUser.Margins.Top := 3;
    pUser.Margins.Right := 3;
    pUser.Margins.Bottom := 3;
    pUser.BorderStyle := bsNone;
    pUser.ChildOrder := 3;
    pUser.ElementFont := efCSS;
    pUser.TabOrder := 0;
    WebPanel2.SetParentComponent(pUser);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 241;
    WebPanel2.Width := 428;
    WebPanel2.Height := 62;
    WebPanel2.Margins.Left := 7;
    WebPanel2.Margins.Top := 7;
    WebPanel2.Margins.Right := 7;
    WebPanel2.Margins.Bottom := 7;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 3;
    WebPanel2.TabOrder := 0;
    bNext.SetParentComponent(WebPanel2);
    bNext.Name := 'bNext';
    bNext.Left := 409;
    bNext.Top := 0;
    bNext.Width := 19;
    bNext.Height := 62;
    bNext.Margins.Left := 3;
    bNext.Margins.Top := 3;
    bNext.Margins.Right := 3;
    bNext.Margins.Bottom := 3;
    bNext.Align := alRight;
    bNext.ParentDoubleBuffered := False;
    bNext.DoubleBuffered := True;
    bNext.Font.Charset := DEFAULT_CHARSET;
    bNext.Font.Color := clWindowText;
    bNext.Font.Height := -22;
    bNext.Font.Name := 'Microsoft YaHei UI';
    bNext.Font.Style := [];
    bNext.ParentColor := True;
    bNext.TabOrder := 0;
    bNext.Visible := False;
    SetEvent(bNext, Self, 'OnClick', 'bNextClick');
    bNext.DropDownHeight := 268.593750000000000000;
    bNext.DropDownWidth := 268.593750000000000000;
    bNext.Text := 'Next';
    bNext.TextVisible := False;
    bNext.Bitmaps.Clear;
    with bNext.Bitmaps.Add do
    begin
      BitmapName := 'Item9';
    end;
    bNext.Layout := bblBitmap;
    bNext.BitmapVisible := True;
    bNext.BitmapContainer := TMSFNCBitmapContainer1;
    bNext.BitmapSize := 32.000000000000000000;
    bNext.LargeLayoutBitmapSize := 63.666667938232420000;
    bNext.Appearance.Transparent := True;
    bNext.Appearance.FlatStyle := True;
    bNext.ControlIndex := 0;
    eUser.SetParentComponent(WebPanel2);
    eUser.Name := 'eUser';
    eUser.Left := 0;
    eUser.Top := 0;
    eUser.Width := 409;
    eUser.Height := 62;
    eUser.Margins.Left := 3;
    eUser.Margins.Top := 3;
    eUser.Margins.Right := 3;
    eUser.Margins.Bottom := 3;
    eUser.Align := alClient;
    eUser.Alignment := taCenter;
    eUser.AutoCompletion := acNone;
    eUser.AutoSelect := False;
    eUser.BorderStyle := bsNone;
    eUser.ChildOrder := 1;
    eUser.Color := clBtnFace;
    eUser.Font.Charset := DEFAULT_CHARSET;
    eUser.Font.Color := clWindowText;
    eUser.Font.Height := -27;
    eUser.Font.Name := 'Microsoft YaHei UI';
    eUser.Font.Style := [];
    eUser.HeightPercent := 100.000000000000000000;
    eUser.ParentFont := False;
    eUser.ShowFocus := False;
    eUser.SpellCheck := False;
    eUser.TextHint := 'e-mail';
    eUser.WidthPercent := 100.000000000000000000;
    SetEvent(eUser, Self, 'OnKeyUp', 'eUserKeyDown');
    bigcube.SetParentComponent(pUser);
    bigcube.Name := 'bigcube';
    bigcube.AlignWithMargins := True;
    bigcube.Left := 3;
    bigcube.Top := 8;
    bigcube.Width := 422;
    bigcube.Height := 226;
    bigcube.Margins.Left := 3;
    bigcube.Margins.Top := 8;
    bigcube.Margins.Right := 3;
    bigcube.Margins.Bottom := 8;
    bigcube.Align := alClient;
    bigcube.ParentDoubleBuffered := False;
    bigcube.DoubleBuffered := True;
    bigcube.TabOrder := 1;
    bigcube.AspectRatio := False;
    bigcube.Bitmap.LoadFromFile('x.login.WebForm.pUser.bigcube.Bitmap.svg');
    bigcube.Stretch := True;
    bigcube.Stroke.Kind := gskNone;
    bigcube.URL := 'https://x.am1.com/icons/cubes.com/green.png';
    pPW.SetParentComponent(Self);
    pPW.Name := 'pPW';
    pPW.AlignWithMargins := True;
    pPW.Left := 462;
    pPW.Top := 52;
    pPW.Width := 452;
    pPW.Height := 372;
    pPW.Margins.Left := 3;
    pPW.Margins.Top := 3;
    pPW.Margins.Right := 3;
    pPW.Margins.Bottom := 3;
    pPW.BorderStyle := bsNone;
    pPW.ChildOrder := 3;
    pPW.ElementFont := efCSS;
    pPW.TabOrder := 1;
    ePIN.SetParentComponent(pPW);
    ePIN.Name := 'ePIN';
    ePIN.AlignWithMargins := True;
    ePIN.Left := 3;
    ePIN.Top := 196;
    ePIN.Width := 446;
    ePIN.Height := 49;
    ePIN.Margins.Left := 3;
    ePIN.Margins.Top := 3;
    ePIN.Margins.Right := 3;
    ePIN.Margins.Bottom := 3;
    ePIN.Align := alBottom;
    ePIN.Alignment := taCenter;
    ePIN.AutoCompletion := acNone;
    ePIN.AutoSelect := False;
    ePIN.BorderStyle := bsNone;
    ePIN.ChildOrder := 1;
    ePIN.Color := clWhite;
    ePIN.Font.Charset := DEFAULT_CHARSET;
    ePIN.Font.Color := clWindowText;
    ePIN.Font.Height := -27;
    ePIN.Font.Name := 'Microsoft YaHei UI';
    ePIN.Font.Style := [];
    ePIN.HeightPercent := 100.000000000000000000;
    ePIN.ParentFont := False;
    ePIN.PasswordChar := '*';
    ePIN.ShowFocus := False;
    ePIN.TextHint := 'PIN';
    ePIN.WidthPercent := 100.000000000000000000;
    SetEvent(ePIN, Self, 'OnKeyUp', 'ePINKeyUp');
    WebPanel1.SetParentComponent(pPW);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.AlignWithMargins := True;
    WebPanel1.Left := 7;
    WebPanel1.Top := 315;
    WebPanel1.Width := 438;
    WebPanel1.Height := 50;
    WebPanel1.Margins.Left := 7;
    WebPanel1.Margins.Top := 7;
    WebPanel1.Margins.Right := 7;
    WebPanel1.Margins.Bottom := 7;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 3;
    WebPanel1.TabOrder := 1;
    WebPanel1.Visible := False;
    bLogin.SetParentComponent(WebPanel1);
    bLogin.Name := 'bLogin';
    bLogin.Left := 293;
    bLogin.Top := 0;
    bLogin.Width := 145;
    bLogin.Height := 50;
    bLogin.Margins.Left := 3;
    bLogin.Margins.Top := 3;
    bLogin.Margins.Right := 3;
    bLogin.Margins.Bottom := 3;
    bLogin.Align := alRight;
    bLogin.ParentDoubleBuffered := False;
    bLogin.DoubleBuffered := True;
    bLogin.Font.Charset := DEFAULT_CHARSET;
    bLogin.Font.Color := clWindowText;
    bLogin.Font.Height := -22;
    bLogin.Font.Name := 'Microsoft YaHei UI';
    bLogin.Font.Style := [];
    bLogin.ParentColor := True;
    bLogin.TabOrder := 0;
    SetEvent(bLogin, Self, 'OnClick', 'bLoginClick');
    bLogin.DropDownHeight := 268.593750000000000000;
    bLogin.DropDownWidth := 268.593750000000000000;
    bLogin.Text := 'Login';
    bLogin.BitmapSize := 47.750000000000000000;
    bLogin.LargeLayoutBitmapSize := 63.666667938232420000;
    bLogin.Appearance.FlatStyle := True;
    bLogin.ControlIndex := 0;
    bAdd.SetParentComponent(WebPanel1);
    bAdd.Name := 'bAdd';
    bAdd.Left := 0;
    bAdd.Top := 0;
    bAdd.Width := 145;
    bAdd.Height := 50;
    bAdd.Margins.Left := 3;
    bAdd.Margins.Top := 3;
    bAdd.Margins.Right := 3;
    bAdd.Margins.Bottom := 3;
    bAdd.Align := alLeft;
    bAdd.ParentDoubleBuffered := False;
    bAdd.DoubleBuffered := True;
    bAdd.Font.Charset := DEFAULT_CHARSET;
    bAdd.Font.Color := clWindowText;
    bAdd.Font.Height := -22;
    bAdd.Font.Name := 'Microsoft YaHei UI';
    bAdd.Font.Style := [];
    bAdd.ParentColor := True;
    bAdd.TabOrder := 1;
    bAdd.Visible := False;
    SetEvent(bAdd, Self, 'OnClick', 'bAddClick');
    bAdd.DropDownHeight := 268.593750000000000000;
    bAdd.DropDownWidth := 268.593750000000000000;
    bAdd.Text := 'Add';
    bAdd.BitmapSize := 47.750000000000000000;
    bAdd.LargeLayoutBitmapSize := 63.666667938232420000;
    bAdd.Appearance.FlatStyle := True;
    bAdd.ControlIndex := 0;
    pKeypad.SetParentComponent(pPW);
    pKeypad.Name := 'pKeypad';
    pKeypad.Left := 0;
    pKeypad.Top := 0;
    pKeypad.Width := 452;
    pKeypad.Height := 193;
    pKeypad.Margins.Left := 7;
    pKeypad.Margins.Top := 7;
    pKeypad.Margins.Right := 7;
    pKeypad.Margins.Bottom := 7;
    pKeypad.Align := alClient;
    pKeypad.BorderStyle := bsNone;
    pKeypad.ChildOrder := 3;
    pKeypad.TabOrder := 2;
    keypad.SetParentComponent(pKeypad);
    keypad.Name := 'keypad';
    keypad.AlignWithMargins := True;
    keypad.Left := 40;
    keypad.Top := 24;
    keypad.Width := 75;
    keypad.Height := 101;
    keypad.Margins.Left := 3;
    keypad.Margins.Top := 3;
    keypad.Margins.Right := 3;
    keypad.Margins.Bottom := 3;
    keypad.AllowFocus := False;
    keypad.ParentDoubleBuffered := False;
    keypad.Color := clBtnFace;
    keypad.DoubleBuffered := True;
    keypad.Font.Charset := DEFAULT_CHARSET;
    keypad.Font.Color := clWindowText;
    keypad.Font.Height := -16;
    keypad.Font.Name := 'Segoe UI';
    keypad.Font.Style := [fsBold];
    keypad.TabOrder := 0;
    keypad.AllowAutoZoom := False;
    keypad.Fill.Kind := gfkNone;
    keypad.Fill.Color := clBtnFace;
    keypad.Stroke.Kind := gskNone;
    keypad.Stroke.Color := clLightgray;
    keypad.KeyboardType := ktNUMERIC;
    keypad.Keys.Clear;
    with keypad.Keys.Add do
    begin
      Caption := '7';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      X := 50.000000000000000000;
      Caption := '8';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      X := 100.000000000000000000;
      Caption := '9';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      Y := 50.000000000000000000;
      Caption := '4';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      X := 50.000000000000000000;
      Y := 50.000000000000000000;
      Caption := '5';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      X := 100.000000000000000000;
      Y := 50.000000000000000000;
      Caption := '6';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      Y := 100.000000000000000000;
      Caption := '1';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      X := 50.000000000000000000;
      Y := 100.000000000000000000;
      Caption := '2';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      X := 100.000000000000000000;
      Y := 100.000000000000000000;
      Caption := '3';
      Height := 50.000000000000000000;
      Width := 50.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    with keypad.Keys.Add do
    begin
      Y := 150.000000000000000000;
      Caption := '0';
      Height := 50.000000000000000000;
      Width := 150.000000000000000000;
      BorderColor := 14342874;
      BorderColorDown := 11908533;
      Color := clWhite;
      ColorDown := 15790320;
    end;
    keypad.SmallFont.Charset := DEFAULT_CHARSET;
    keypad.SmallFont.Color := clWindowText;
    keypad.SmallFont.Height := -26;
    keypad.SmallFont.Name := 'Segoe UI';
    keypad.SmallFont.Style := [fsBold];
    WebPanel3.SetParentComponent(pPW);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 248;
    WebPanel3.Width := 452;
    WebPanel3.Height := 60;
    WebPanel3.Margins.Left := 3;
    WebPanel3.Margins.Top := 3;
    WebPanel3.Margins.Right := 3;
    WebPanel3.Margins.Bottom := 3;
    WebPanel3.Align := alBottom;
    WebPanel3.ChildOrder := 3;
    WebPanel3.TabOrder := 3;
    WebPanel3.Visible := False;
    eFirstName.SetParentComponent(WebPanel3);
    eFirstName.Name := 'eFirstName';
    eFirstName.Left := 16;
    eFirstName.Top := 13;
    eFirstName.Width := 194;
    eFirstName.Height := 20;
    eFirstName.Margins.Left := 3;
    eFirstName.Margins.Top := 3;
    eFirstName.Margins.Right := 3;
    eFirstName.Margins.Bottom := 3;
    eFirstName.AutoCompletion := acNone;
    eFirstName.BorderStyle := bsNone;
    eFirstName.ChildOrder := 1;
    eFirstName.Color := clBtnFace;
    eFirstName.Font.Charset := DEFAULT_CHARSET;
    eFirstName.Font.Color := clWindowText;
    eFirstName.Font.Height := -16;
    eFirstName.Font.Name := 'Microsoft YaHei UI';
    eFirstName.Font.Style := [];
    eFirstName.HeightPercent := 100.000000000000000000;
    eFirstName.ParentFont := False;
    eFirstName.ShowFocus := False;
    eFirstName.TextHint := 'Firstname';
    eFirstName.WidthPercent := 100.000000000000000000;
    SetEvent(eFirstName, Self, 'OnKeyUp', 'eUserKeyDown');
    eLastName.SetParentComponent(WebPanel3);
    eLastName.Name := 'eLastName';
    eLastName.Left := 216;
    eLastName.Top := 13;
    eLastName.Width := 194;
    eLastName.Height := 20;
    eLastName.Margins.Left := 3;
    eLastName.Margins.Top := 3;
    eLastName.Margins.Right := 3;
    eLastName.Margins.Bottom := 3;
    eLastName.AutoCompletion := acNone;
    eLastName.BorderStyle := bsNone;
    eLastName.ChildOrder := 1;
    eLastName.Color := clBtnFace;
    eLastName.Font.Charset := DEFAULT_CHARSET;
    eLastName.Font.Color := clWindowText;
    eLastName.Font.Height := -16;
    eLastName.Font.Name := 'Microsoft YaHei UI';
    eLastName.Font.Style := [];
    eLastName.HeightPercent := 100.000000000000000000;
    eLastName.ParentFont := False;
    eLastName.ShowFocus := False;
    eLastName.TextHint := 'Lastname';
    eLastName.WidthPercent := 100.000000000000000000;
    SetEvent(eLastName, Self, 'OnKeyUp', 'eUserKeyDown');
    pSignedIn.SetParentComponent(Self);
    pSignedIn.Name := 'pSignedIn';
    pSignedIn.AlignWithMargins := True;
    pSignedIn.Left := 439;
    pSignedIn.Top := 410;
    pSignedIn.Width := 460;
    pSignedIn.Height := 301;
    pSignedIn.Margins.Left := 3;
    pSignedIn.Margins.Top := 3;
    pSignedIn.Margins.Right := 3;
    pSignedIn.Margins.Bottom := 3;
    pSignedIn.BorderStyle := bsNone;
    pSignedIn.ChildOrder := 3;
    pSignedIn.ElementFont := efCSS;
    pSignedIn.TabOrder := 2;
    lbHello.SetParentComponent(pSignedIn);
    lbHello.Name := 'lbHello';
    lbHello.Left := 82;
    lbHello.Top := 10;
    lbHello.Width := 153;
    lbHello.Height := 25;
    lbHello.Margins.Left := 3;
    lbHello.Margins.Top := 3;
    lbHello.Margins.Right := 3;
    lbHello.Margins.Bottom := 3;
    lbHello.Caption := 'you@cubes.com';
    lbHello.Font.Charset := DEFAULT_CHARSET;
    lbHello.Font.Color := clWindowText;
    lbHello.Font.Height := -19;
    lbHello.Font.Name := 'Microsoft YaHei UI';
    lbHello.Font.Style := [fsBold];
    lbHello.HeightPercent := 100.000000000000000000;
    lbHello.ParentFont := False;
    lbHello.WidthPercent := 100.000000000000000000;
    lbUser.SetParentComponent(pSignedIn);
    lbUser.Name := 'lbUser';
    lbUser.Left := 82;
    lbUser.Top := 41;
    lbUser.Width := 171;
    lbUser.Height := 21;
    lbUser.Margins.Left := 3;
    lbUser.Margins.Top := 3;
    lbUser.Margins.Right := 3;
    lbUser.Margins.Bottom := 3;
    lbUser.Caption := 'You'#39're signed in as %s';
    lbUser.Font.Charset := DEFAULT_CHARSET;
    lbUser.Font.Color := clWindowText;
    lbUser.Font.Height := -16;
    lbUser.Font.Name := 'Microsoft YaHei UI';
    lbUser.Font.Style := [];
    lbUser.HeightPercent := 100.000000000000000000;
    lbUser.ParentFont := False;
    lbUser.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(pSignedIn);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 339;
    WebLabel3.Top := 78;
    WebLabel3.Width := 65;
    WebLabel3.Height := 21;
    WebLabel3.Margins.Left := 3;
    WebLabel3.Margins.Top := 3;
    WebLabel3.Margins.Right := 3;
    WebLabel3.Margins.Bottom := 3;
    WebLabel3.Caption := 'You can:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Microsoft YaHei UI';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.Visible := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    IMGUser.SetParentComponent(pSignedIn);
    IMGUser.Name := 'IMGUser';
    IMGUser.Left := 3;
    IMGUser.Top := 13;
    IMGUser.Width := 73;
    IMGUser.Height := 68;
    IMGUser.Margins.Left := 3;
    IMGUser.Margins.Top := 3;
    IMGUser.Margins.Right := 3;
    IMGUser.Margins.Bottom := 3;
    IMGUser.ParentDoubleBuffered := False;
    IMGUser.Color := clBtnFace;
    IMGUser.DoubleBuffered := True;
    IMGUser.TabOrder := 0;
    IMGUser.Bitmaps.Clear;
    with IMGUser.Bitmaps.Add do
    begin
      BitmapName := 'Item8';
    end;
    IMGUser.BitmapContainer := bc;
    IMGUser.Stretch := True;
    IMGUser.Fill.Kind := gfkNone;
    IMGUser.Stroke.Kind := gskNone;
    WebPanel5.SetParentComponent(pSignedIn);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 80;
    WebPanel5.Top := 103;
    WebPanel5.Width := 242;
    WebPanel5.Height := 179;
    WebPanel5.Margins.Left := 3;
    WebPanel5.Margins.Top := 3;
    WebPanel5.Margins.Right := 3;
    WebPanel5.Margins.Bottom := 3;
    WebPanel5.BorderStyle := bsNone;
    WebPanel5.ChildOrder := 7;
    WebPanel5.TabOrder := 1;
    TMSFNCToolBarButton4.SetParentComponent(WebPanel5);
    TMSFNCToolBarButton4.Name := 'TMSFNCToolBarButton4';
    TMSFNCToolBarButton4.AlignWithMargins := True;
    TMSFNCToolBarButton4.Left := 3;
    TMSFNCToolBarButton4.Top := 3;
    TMSFNCToolBarButton4.Width := 78;
    TMSFNCToolBarButton4.Height := 22;
    TMSFNCToolBarButton4.Margins.Left := 3;
    TMSFNCToolBarButton4.Margins.Top := 3;
    TMSFNCToolBarButton4.Margins.Right := 3;
    TMSFNCToolBarButton4.Margins.Bottom := 3;
    TMSFNCToolBarButton4.ParentDoubleBuffered := False;
    TMSFNCToolBarButton4.DoubleBuffered := True;
    TMSFNCToolBarButton4.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton4.Font.Color := clWindowText;
    TMSFNCToolBarButton4.Font.Height := -12;
    TMSFNCToolBarButton4.Font.Name := 'Microsoft YaHei UI';
    TMSFNCToolBarButton4.Font.Style := [];
    TMSFNCToolBarButton4.ParentColor := True;
    TMSFNCToolBarButton4.TabOrder := 0;
    SetEvent(TMSFNCToolBarButton4, Self, 'OnClick', 'TMSFNCToolBarButton4Click');
    TMSFNCToolBarButton4.DropDownHeight := 268.593750000000000000;
    TMSFNCToolBarButton4.DropDownWidth := 268.593750000000000000;
    TMSFNCToolBarButton4.Text := 'Change PIN';
    TMSFNCToolBarButton4.HorizontalTextAlign := gtaLeading;
    TMSFNCToolBarButton4.BitmapSize := 47.750000000000000000;
    TMSFNCToolBarButton4.LargeLayoutBitmapSize := 63.666667938232420000;
    TMSFNCToolBarButton4.Appearance.ShowInnerStroke := False;
    TMSFNCToolBarButton4.Appearance.Rounding := 180.000000000000000000;
    TMSFNCToolBarButton4.Appearance.NormalFill.Kind := gfkSolid;
    TMSFNCToolBarButton4.Appearance.NormalFill.Color := clWhite;
    TMSFNCToolBarButton4.Appearance.NormalStroke.Kind := gskNone;
    TMSFNCToolBarButton4.Appearance.HoverFill.Kind := gfkSolid;
    TMSFNCToolBarButton4.Appearance.HoverFill.Color := clLightgray;
    TMSFNCToolBarButton4.Appearance.HoverStroke.Kind := gskNone;
    TMSFNCToolBarButton4.Appearance.DownFill.Kind := gfkSolid;
    TMSFNCToolBarButton4.Appearance.DownFill.Color := clDarkgray;
    TMSFNCToolBarButton4.Appearance.DownStroke.Kind := gskNone;
    TMSFNCToolBarButton4.Appearance.FlatStyle := True;
    TMSFNCToolBarButton4.ControlIndex := 0;
    TMSFNCToolBarButton3.SetParentComponent(WebPanel5);
    TMSFNCToolBarButton3.Name := 'TMSFNCToolBarButton3';
    TMSFNCToolBarButton3.AlignWithMargins := True;
    TMSFNCToolBarButton3.Left := 3;
    TMSFNCToolBarButton3.Top := 31;
    TMSFNCToolBarButton3.Width := 94;
    TMSFNCToolBarButton3.Height := 25;
    TMSFNCToolBarButton3.Margins.Left := 3;
    TMSFNCToolBarButton3.Margins.Top := 3;
    TMSFNCToolBarButton3.Margins.Right := 3;
    TMSFNCToolBarButton3.Margins.Bottom := 3;
    TMSFNCToolBarButton3.ParentDoubleBuffered := False;
    TMSFNCToolBarButton3.DoubleBuffered := True;
    TMSFNCToolBarButton3.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton3.Font.Color := clWindowText;
    TMSFNCToolBarButton3.Font.Height := -12;
    TMSFNCToolBarButton3.Font.Name := 'Microsoft YaHei UI';
    TMSFNCToolBarButton3.Font.Style := [];
    TMSFNCToolBarButton3.ParentColor := True;
    TMSFNCToolBarButton3.TabOrder := 1;
    TMSFNCToolBarButton3.DropDownHeight := 268.593750000000000000;
    TMSFNCToolBarButton3.DropDownWidth := 268.593750000000000000;
    TMSFNCToolBarButton3.Text := 'Change picture';
    TMSFNCToolBarButton3.HorizontalTextAlign := gtaLeading;
    TMSFNCToolBarButton3.BitmapSize := 47.750000000000000000;
    TMSFNCToolBarButton3.LargeLayoutBitmapSize := 63.666667938232420000;
    TMSFNCToolBarButton3.Appearance.ShowInnerStroke := False;
    TMSFNCToolBarButton3.Appearance.Rounding := 180.000000000000000000;
    TMSFNCToolBarButton3.Appearance.NormalFill.Kind := gfkSolid;
    TMSFNCToolBarButton3.Appearance.NormalFill.Color := clWhite;
    TMSFNCToolBarButton3.Appearance.NormalStroke.Kind := gskNone;
    TMSFNCToolBarButton3.Appearance.HoverFill.Kind := gfkSolid;
    TMSFNCToolBarButton3.Appearance.HoverFill.Color := clLightgray;
    TMSFNCToolBarButton3.Appearance.HoverStroke.Kind := gskNone;
    TMSFNCToolBarButton3.Appearance.DownFill.Kind := gfkSolid;
    TMSFNCToolBarButton3.Appearance.DownFill.Color := clDarkgray;
    TMSFNCToolBarButton3.Appearance.DownStroke.Kind := gskNone;
    TMSFNCToolBarButton3.Appearance.FlatStyle := True;
    TMSFNCToolBarButton3.ControlIndex := 0;
    TMSFNCToolBarButton6.SetParentComponent(WebPanel5);
    TMSFNCToolBarButton6.Name := 'TMSFNCToolBarButton6';
    TMSFNCToolBarButton6.AlignWithMargins := True;
    TMSFNCToolBarButton6.Left := 3;
    TMSFNCToolBarButton6.Top := 139;
    TMSFNCToolBarButton6.Width := 70;
    TMSFNCToolBarButton6.Height := 31;
    TMSFNCToolBarButton6.Margins.Left := 3;
    TMSFNCToolBarButton6.Margins.Top := 3;
    TMSFNCToolBarButton6.Margins.Right := 3;
    TMSFNCToolBarButton6.Margins.Bottom := 3;
    TMSFNCToolBarButton6.ParentDoubleBuffered := False;
    TMSFNCToolBarButton6.DoubleBuffered := True;
    TMSFNCToolBarButton6.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton6.Font.Color := clWindowText;
    TMSFNCToolBarButton6.Font.Height := -15;
    TMSFNCToolBarButton6.Font.Name := 'Microsoft YaHei UI';
    TMSFNCToolBarButton6.Font.Style := [];
    TMSFNCToolBarButton6.ParentColor := True;
    TMSFNCToolBarButton6.TabOrder := 2;
    SetEvent(TMSFNCToolBarButton6, Self, 'OnClick', 'bSignoutClick');
    TMSFNCToolBarButton6.DropDownHeight := 268.593750000000000000;
    TMSFNCToolBarButton6.DropDownWidth := 268.593750000000000000;
    TMSFNCToolBarButton6.Text := 'Sign out';
    TMSFNCToolBarButton6.HorizontalTextAlign := gtaLeading;
    TMSFNCToolBarButton6.BitmapSize := 47.750000000000000000;
    TMSFNCToolBarButton6.LargeLayoutBitmapSize := 63.666667938232420000;
    TMSFNCToolBarButton6.Appearance.ShowInnerStroke := False;
    TMSFNCToolBarButton6.Appearance.Rounding := 36.000000000000000000;
    TMSFNCToolBarButton6.Appearance.NormalFill.Kind := gfkSolid;
    TMSFNCToolBarButton6.Appearance.NormalFill.Color := clWhite;
    TMSFNCToolBarButton6.Appearance.NormalStroke.Kind := gskNone;
    TMSFNCToolBarButton6.Appearance.HoverFill.Kind := gfkSolid;
    TMSFNCToolBarButton6.Appearance.HoverFill.Color := clLightgray;
    TMSFNCToolBarButton6.Appearance.HoverStroke.Kind := gskNone;
    TMSFNCToolBarButton6.Appearance.DownFill.Kind := gfkSolid;
    TMSFNCToolBarButton6.Appearance.DownFill.Color := clDarkgray;
    TMSFNCToolBarButton6.Appearance.DownStroke.Kind := gskNone;
    TMSFNCToolBarButton6.Appearance.FlatStyle := True;
    TMSFNCToolBarButton6.ControlIndex := 0;
    TMSFNCToolBarButton1.SetParentComponent(WebPanel5);
    TMSFNCToolBarButton1.Name := 'TMSFNCToolBarButton1';
    TMSFNCToolBarButton1.AlignWithMargins := True;
    TMSFNCToolBarButton1.Left := 3;
    TMSFNCToolBarButton1.Top := 61;
    TMSFNCToolBarButton1.Width := 142;
    TMSFNCToolBarButton1.Height := 28;
    TMSFNCToolBarButton1.Margins.Left := 3;
    TMSFNCToolBarButton1.Margins.Top := 3;
    TMSFNCToolBarButton1.Margins.Right := 3;
    TMSFNCToolBarButton1.Margins.Bottom := 3;
    TMSFNCToolBarButton1.ParentDoubleBuffered := False;
    TMSFNCToolBarButton1.DoubleBuffered := True;
    TMSFNCToolBarButton1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton1.Font.Color := clWindowText;
    TMSFNCToolBarButton1.Font.Height := -12;
    TMSFNCToolBarButton1.Font.Name := 'Microsoft YaHei UI';
    TMSFNCToolBarButton1.Font.Style := [];
    TMSFNCToolBarButton1.ParentColor := True;
    TMSFNCToolBarButton1.TabOrder := 3;
    TMSFNCToolBarButton1.DropDownHeight := 268.593750000000000000;
    TMSFNCToolBarButton1.DropDownWidth := 268.593750000000000000;
    TMSFNCToolBarButton1.Text := 'Go to Cubes Clubhouse';
    TMSFNCToolBarButton1.HorizontalTextAlign := gtaLeading;
    TMSFNCToolBarButton1.BitmapSize := 47.750000000000000000;
    TMSFNCToolBarButton1.LargeLayoutBitmapSize := 63.666667938232420000;
    TMSFNCToolBarButton1.Appearance.ShowInnerStroke := False;
    TMSFNCToolBarButton1.Appearance.Rounding := 180.000000000000000000;
    TMSFNCToolBarButton1.Appearance.NormalFill.Kind := gfkSolid;
    TMSFNCToolBarButton1.Appearance.NormalFill.Color := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalStroke.Kind := gskNone;
    TMSFNCToolBarButton1.Appearance.HoverFill.Kind := gfkSolid;
    TMSFNCToolBarButton1.Appearance.HoverFill.Color := clLightgray;
    TMSFNCToolBarButton1.Appearance.HoverStroke.Kind := gskNone;
    TMSFNCToolBarButton1.Appearance.DownFill.Kind := gfkSolid;
    TMSFNCToolBarButton1.Appearance.DownFill.Color := clDarkgray;
    TMSFNCToolBarButton1.Appearance.DownStroke.Kind := gskNone;
    TMSFNCToolBarButton1.Appearance.FlatStyle := True;
    TMSFNCToolBarButton1.ControlIndex := 0;
    pMsg.SetParentComponent(Self);
    pMsg.Name := 'pMsg';
    pMsg.AlignWithMargins := True;
    pMsg.Left := 85;
    pMsg.Top := 388;
    pMsg.Width := 430;
    pMsg.Height := 290;
    pMsg.Margins.Left := 3;
    pMsg.Margins.Top := 3;
    pMsg.Margins.Right := 3;
    pMsg.Margins.Bottom := 3;
    pMsg.BorderStyle := bsNone;
    pMsg.ChildOrder := 3;
    pMsg.ElementFont := efCSS;
    pMsg.TabOrder := 3;
    SetEvent(pMsg, Self, 'OnKeyUp', 'pMsgKeyUp');
    lbMsgHeading.SetParentComponent(pMsg);
    lbMsgHeading.Name := 'lbMsgHeading';
    lbMsgHeading.Left := 117;
    lbMsgHeading.Top := 28;
    lbMsgHeading.Width := 82;
    lbMsgHeading.Height := 25;
    lbMsgHeading.Margins.Left := 3;
    lbMsgHeading.Margins.Top := 3;
    lbMsgHeading.Margins.Right := 3;
    lbMsgHeading.Margins.Bottom := 3;
    lbMsgHeading.Caption := 'Message';
    lbMsgHeading.Font.Charset := DEFAULT_CHARSET;
    lbMsgHeading.Font.Color := clWindowText;
    lbMsgHeading.Font.Height := -19;
    lbMsgHeading.Font.Name := 'Microsoft YaHei UI';
    lbMsgHeading.Font.Style := [fsBold];
    lbMsgHeading.HeightPercent := 100.000000000000000000;
    lbMsgHeading.ParentFont := False;
    lbMsgHeading.WidthPercent := 100.000000000000000000;
    lbMsg.SetParentComponent(pMsg);
    lbMsg.Name := 'lbMsg';
    lbMsg.Left := 117;
    lbMsg.Top := 81;
    lbMsg.Width := 275;
    lbMsg.Height := 159;
    lbMsg.Margins.Left := 3;
    lbMsg.Margins.Top := 3;
    lbMsg.Margins.Right := 3;
    lbMsg.Margins.Bottom := 3;
    lbMsg.AutoSize := False;
    lbMsg.Font.Charset := DEFAULT_CHARSET;
    lbMsg.Font.Color := clRed;
    lbMsg.Font.Height := -19;
    lbMsg.Font.Name := 'Segoe UI';
    lbMsg.Font.Style := [];
    lbMsg.HeightPercent := 100.000000000000000000;
    lbMsg.ParentFont := False;
    lbMsg.WordWrap := True;
    lbMsg.WidthPercent := 100.000000000000000000;
    WebPanel6.SetParentComponent(pMsg);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.AlignWithMargins := True;
    WebPanel6.Left := 7;
    WebPanel6.Top := 234;
    WebPanel6.Width := 416;
    WebPanel6.Height := 49;
    WebPanel6.Margins.Left := 7;
    WebPanel6.Margins.Top := 7;
    WebPanel6.Margins.Right := 7;
    WebPanel6.Margins.Bottom := 7;
    WebPanel6.Align := alBottom;
    WebPanel6.BorderStyle := bsNone;
    WebPanel6.ChildOrder := 3;
    WebPanel6.TabOrder := 0;
    btnMsgBack.SetParentComponent(WebPanel6);
    btnMsgBack.Name := 'btnMsgBack';
    btnMsgBack.AlignWithMargins := True;
    btnMsgBack.Left := 3;
    btnMsgBack.Top := 3;
    btnMsgBack.Width := 62;
    btnMsgBack.Height := 43;
    btnMsgBack.Margins.Left := 3;
    btnMsgBack.Margins.Top := 3;
    btnMsgBack.Margins.Right := 3;
    btnMsgBack.Margins.Bottom := 3;
    btnMsgBack.Align := alLeft;
    btnMsgBack.ParentDoubleBuffered := False;
    btnMsgBack.DoubleBuffered := True;
    btnMsgBack.Font.Charset := DEFAULT_CHARSET;
    btnMsgBack.Font.Color := clWindowText;
    btnMsgBack.Font.Height := -15;
    btnMsgBack.Font.Name := 'Microsoft YaHei UI';
    btnMsgBack.Font.Style := [];
    btnMsgBack.ParentColor := True;
    btnMsgBack.TabOrder := 0;
    SetEvent(btnMsgBack, Self, 'OnClick', 'btnMsgBackClick');
    btnMsgBack.DropDownHeight := 268.593750000000000000;
    btnMsgBack.DropDownWidth := 268.593750000000000000;
    btnMsgBack.Text := 'Back';
    btnMsgBack.BitmapSize := 47.750000000000000000;
    btnMsgBack.LargeLayoutBitmapSize := 63.666667938232420000;
    btnMsgBack.Appearance.ShowInnerStroke := False;
    btnMsgBack.Appearance.Rounding := 36.000000000000000000;
    btnMsgBack.Appearance.NormalFill.Kind := gfkSolid;
    btnMsgBack.Appearance.NormalFill.Color := clWhite;
    btnMsgBack.Appearance.NormalStroke.Kind := gskNone;
    btnMsgBack.Appearance.HoverFill.Kind := gfkSolid;
    btnMsgBack.Appearance.HoverFill.Color := clLightgray;
    btnMsgBack.Appearance.HoverStroke.Kind := gskNone;
    btnMsgBack.Appearance.DownFill.Kind := gfkSolid;
    btnMsgBack.Appearance.DownFill.Color := clDarkgray;
    btnMsgBack.Appearance.DownStroke.Kind := gskNone;
    btnMsgBack.Appearance.FlatStyle := True;
    btnMsgBack.ControlIndex := 0;
    btnMsgOK.SetParentComponent(WebPanel6);
    btnMsgOK.Name := 'btnMsgOK';
    btnMsgOK.AlignWithMargins := True;
    btnMsgOK.Left := 362;
    btnMsgOK.Top := 3;
    btnMsgOK.Width := 51;
    btnMsgOK.Height := 43;
    btnMsgOK.Margins.Left := 3;
    btnMsgOK.Margins.Top := 3;
    btnMsgOK.Margins.Right := 3;
    btnMsgOK.Margins.Bottom := 3;
    btnMsgOK.Align := alRight;
    btnMsgOK.ParentDoubleBuffered := False;
    btnMsgOK.DoubleBuffered := True;
    btnMsgOK.Font.Charset := DEFAULT_CHARSET;
    btnMsgOK.Font.Color := clWindowText;
    btnMsgOK.Font.Height := -15;
    btnMsgOK.Font.Name := 'Microsoft YaHei UI';
    btnMsgOK.Font.Style := [];
    btnMsgOK.ParentColor := True;
    btnMsgOK.TabOrder := 1;
    SetEvent(btnMsgOK, Self, 'OnClick', 'btnMsgOKClick');
    btnMsgOK.DropDownHeight := 268.593750000000000000;
    btnMsgOK.DropDownWidth := 268.593750000000000000;
    btnMsgOK.Text := 'OK';
    btnMsgOK.BitmapSize := 47.750000000000000000;
    btnMsgOK.LargeLayoutBitmapSize := 63.666667938232420000;
    btnMsgOK.Appearance.ShowInnerStroke := False;
    btnMsgOK.Appearance.Rounding := 36.000000000000000000;
    btnMsgOK.Appearance.NormalFill.Kind := gfkSolid;
    btnMsgOK.Appearance.NormalFill.Color := clWhite;
    btnMsgOK.Appearance.NormalStroke.Kind := gskNone;
    btnMsgOK.Appearance.HoverFill.Kind := gfkSolid;
    btnMsgOK.Appearance.HoverFill.Color := clLightgray;
    btnMsgOK.Appearance.HoverStroke.Kind := gskNone;
    btnMsgOK.Appearance.DownFill.Kind := gfkSolid;
    btnMsgOK.Appearance.DownFill.Color := clDarkgray;
    btnMsgOK.Appearance.DownStroke.Kind := gskNone;
    btnMsgOK.Appearance.FlatStyle := True;
    btnMsgOK.ControlIndex := 0;
    TMSFNCCloudImage4.SetParentComponent(pMsg);
    TMSFNCCloudImage4.Name := 'TMSFNCCloudImage4';
    TMSFNCCloudImage4.Left := 17;
    TMSFNCCloudImage4.Top := 14;
    TMSFNCCloudImage4.Width := 73;
    TMSFNCCloudImage4.Height := 68;
    TMSFNCCloudImage4.Margins.Left := 3;
    TMSFNCCloudImage4.Margins.Top := 3;
    TMSFNCCloudImage4.Margins.Right := 3;
    TMSFNCCloudImage4.Margins.Bottom := 3;
    TMSFNCCloudImage4.ParentDoubleBuffered := False;
    TMSFNCCloudImage4.Color := clBtnFace;
    TMSFNCCloudImage4.DoubleBuffered := True;
    TMSFNCCloudImage4.TabOrder := 1;
    TMSFNCCloudImage4.Bitmap.LoadFromFile('x.login.WebForm.pMsg.TMSFNCCloudImage4.Bitmap.svg');
    TMSFNCCloudImage4.Bitmaps.Clear;
    with TMSFNCCloudImage4.Bitmaps.Add do
    begin
      BitmapName := 'blue';
    end;
    TMSFNCCloudImage4.Stretch := True;
    TMSFNCCloudImage4.Fill.Kind := gfkNone;
    TMSFNCCloudImage4.Stroke.Kind := gskNone;
    TMSFNCCloudImage4.URL := 'https://x.am1.com/icons/cubes.com/green.png';
    TMSFNCBitmapContainer1.SetParentComponent(Self);
    TMSFNCBitmapContainer1.Name := 'TMSFNCBitmapContainer1';
    TMSFNCBitmapContainer1.Left := 297;
    TMSFNCBitmapContainer1.Top := 571;
    TMSFNCBitmapContainer1.Width := 13;
    TMSFNCBitmapContainer1.Height := 13;
    TMSFNCBitmapContainer1.Margins.Left := 3;
    TMSFNCBitmapContainer1.Margins.Top := 3;
    TMSFNCBitmapContainer1.Margins.Right := 3;
    TMSFNCBitmapContainer1.Margins.Bottom := 3;
    TMSFNCBitmapContainer1.Visible := True;
    TMSFNCBitmapContainer1.Items.Clear;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap.svg');
      Name := 'close';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_1.svg');
      Name := 'close2';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap.png');
      Name := 'Item3';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_1.png');
      Name := 'green';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_2.png');
      Name := 'blue';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_3.png');
      Name := 'black';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_4.png');
      Name := 'Item7';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_5.png');
      Name := 'Item8';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_2.svg');
      Name := 'Item9';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_6.png');
      Name := 'Item10';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.TMSFNCBitmapContainer1.Items.Bitmap_7.png');
      Name := 'greenPNG';
      Tag := 0;
    end;
    bClose.SetParentComponent(Self);
    bClose.Name := 'bClose';
    bClose.AlignWithMargins := True;
    bClose.Left := 497;
    bClose.Top := 19;
    bClose.Width := 12;
    bClose.Height := 12;
    bClose.Margins.Left := 3;
    bClose.Margins.Top := 3;
    bClose.Margins.Right := 3;
    bClose.Margins.Bottom := 3;
    bClose.ParentDoubleBuffered := False;
    bClose.DoubleBuffered := True;
    bClose.Font.Charset := DEFAULT_CHARSET;
    bClose.Font.Color := clWindowText;
    bClose.Font.Height := -22;
    bClose.Font.Name := 'Microsoft YaHei UI';
    bClose.Font.Style := [];
    bClose.ParentColor := True;
    bClose.TabOrder := 5;
    SetEvent(bClose, Self, 'OnClick', 'bCloseClick');
    bClose.DropDownHeight := 268.593750000000000000;
    bClose.DropDownWidth := 268.593750000000000000;
    bClose.Text := 'X';
    bClose.TextVisible := False;
    bClose.Bitmaps.Clear;
    with bClose.Bitmaps.Add do
    begin
      BitmapName := 'close';
    end;
    bClose.Layout := bblBitmap;
    bClose.BitmapVisible := True;
    bClose.BitmapContainer := TMSFNCBitmapContainer1;
    bClose.BitmapSize := 18.000000000000000000;
    bClose.LargeLayoutBitmapSize := 63.666667938232420000;
    bClose.Appearance.Rounding := 180.000000000000000000;
    bClose.Appearance.NormalFill.Kind := gfkSolid;
    bClose.Appearance.NormalFill.Color := clWhite;
    bClose.Appearance.NormalStroke.Kind := gskNone;
    bClose.Appearance.HoverFill.Kind := gfkSolid;
    bClose.Appearance.HoverFill.Color := clLightgray;
    bClose.Appearance.HoverStroke.Kind := gskNone;
    bClose.Appearance.DownFill.Kind := gfkSolid;
    bClose.Appearance.DownFill.Color := clDarkgray;
    bClose.Appearance.DownStroke.Kind := gskNone;
    bClose.Appearance.FlatStyle := True;
    bClose.ControlIndex := 0;
    bc.SetParentComponent(Self);
    bc.Name := 'bc';
    bc.Left := 434;
    bc.Top := 346;
    bc.Width := 13;
    bc.Height := 13;
    bc.Margins.Left := 3;
    bc.Margins.Top := 3;
    bc.Margins.Right := 3;
    bc.Margins.Bottom := 3;
    bc.Visible := True;
    bc.Items.Clear;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.bc.Items.Bitmap.svg');
      Name := 'black';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.bc.Items.Bitmap_1.svg');
      Name := 'blue';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.bc.Items.Bitmap_2.svg');
      Name := 'green';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.bc.Items.Bitmap_3.svg');
      Name := 'navy';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.bc.Items.Bitmap_4.svg');
      Name := 'orange';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('x.login.WebForm.bc.Items.Bitmap_5.svg');
      Name := 'purple';
      Tag := 0;
    end;
    ShowTimer.SetParentComponent(Self);
    ShowTimer.Name := 'ShowTimer';
    ShowTimer.Enabled := False;
    ShowTimer.Interval := 100;
    SetEvent(ShowTimer, Self, 'OnTimer', 'ShowTimerTimer');
    ShowTimer.Left := 458;
    ShowTimer.Top := 563;
    ts.SetParentComponent(Self);
    ts.Name := 'ts';
    ts.Enabled := False;
    ts.Interval := 1;
    SetEvent(ts, Self, 'OnTimer', 'tsTimer');
    ts.Left := 426;
    ts.Top := 290;
  finally
    pUser.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    bNext.AfterLoadDFMValues;
    eUser.AfterLoadDFMValues;
    bigcube.AfterLoadDFMValues;
    pPW.AfterLoadDFMValues;
    ePIN.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    bLogin.AfterLoadDFMValues;
    bAdd.AfterLoadDFMValues;
    pKeypad.AfterLoadDFMValues;
    keypad.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    eFirstName.AfterLoadDFMValues;
    eLastName.AfterLoadDFMValues;
    pSignedIn.AfterLoadDFMValues;
    lbHello.AfterLoadDFMValues;
    lbUser.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    IMGUser.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    TMSFNCToolBarButton4.AfterLoadDFMValues;
    TMSFNCToolBarButton3.AfterLoadDFMValues;
    TMSFNCToolBarButton6.AfterLoadDFMValues;
    TMSFNCToolBarButton1.AfterLoadDFMValues;
    pMsg.AfterLoadDFMValues;
    lbMsgHeading.AfterLoadDFMValues;
    lbMsg.AfterLoadDFMValues;
    WebPanel6.AfterLoadDFMValues;
    btnMsgBack.AfterLoadDFMValues;
    btnMsgOK.AfterLoadDFMValues;
    TMSFNCCloudImage4.AfterLoadDFMValues;
    TMSFNCBitmapContainer1.AfterLoadDFMValues;
    bClose.AfterLoadDFMValues;
    bc.AfterLoadDFMValues;
    ShowTimer.AfterLoadDFMValues;
    ts.AfterLoadDFMValues;
  end;
end;

end.

