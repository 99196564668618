unit x.icons.all;

interface
//  {$INCLUDE project.inc}
uses
  classes, system.Generics.Collections, ioUtils,
  {$IFDEF fwfmx}
  FMX.TMSFNCBitmapContainer, FMX.tmsfnctypes, FMX.TMSFNCGraphics, FMX.Graphics, FMX.ImgList, FMX.MultiResBitmap, FMX.Forms, system.Types,
  system.net.URLClient, quick.commons, libdl, FMX.TMSFNCUtils,
  {$ENDIF}
  {$IFDEF fwvcl}
  vcl.TMSFNCBitmapContainer, vcl.tmsfnctypes, vcl.TMSFNCGraphics, vcl.Graphics, vcl.forms, system.net.URLClient, quick.commons, libdl, vcl.TMSFNCUtils,
  cxGraphics, dxGDIPlusClasses,
  {$ENDIF}
  {$IFDEF fwWeb}
  vcl.TMSFNCTypes, vcl.TMSFNCUtils, vcl.TMSFNCGraphics, vcl.graphics,
  vcl.TMSFNCGraphicsTypes, vcl.TMSFNCCustomControl, vcl.TMSFNCBitmapContainer, js, web,
  {$ENDIF}
  x.libx, sysUtils, x.fs.types, x.json, x.json.types, x.fs.client, liblogtest;

type
  tLotties = tDictionary<string, tBytes>;
  txIcons = class;
  TBitmapLoadedProc = reference to procedure;
  txIcon = class
  private
    Fbn: string;
    Furl: string;
    Fname: string;
    FbsFile: tbsFile;
    Ficons: txIcons;
    FisLottie: boolean;
    FcxMedium: integer;
    Fwidth: integer;
    FcxSmall: integer;
    FDidDownload: boolean;
    FimgList: Integer;
    FLottie: tBytes;
    FcxLarge: integer;
    Fheight: integer;
    FDataString: string;
    FDataObject: tobject;
    FSVG: string;
    Ffn: string;
    FServerFN: string;
    function getBN: string;
  public

  published
    property bn: string read getBN write Fbn;
    property url: string read Furl write Furl;
    property name: string read Fname write Fname;
    property bsFile: tbsFile read FbsFile write FbsFile;
    property icons: txIcons read Ficons write Ficons;
    property imgList: Integer read FimgList write FimgList;
    property DidDownload: boolean read FDidDownload write FDidDownload;
    property DataObject: tobject read FDataObject write FDataObject;
    property DataString: string read FDataString write FDataString;
    property cxSmall: integer read FcxSmall write FcxSmall;
    property cxMedium: integer read FcxMedium write FcxMedium;
    property cxLarge: integer read FcxLarge write FcxLarge;
    property Lottie: tBytes read FLottie write FLottie;
    property isLottie: boolean read FisLottie write FisLottie;
    property SVG: string read FSVG write FSVG;
    property width: integer read Fwidth write Fwidth;
    property height: integer read Fheight write Fheight;
    property fn: string read Ffn write Ffn;
    property ServerFN: string read FServerFN write FServerFN;

  end;

  txIconsList = tDictionary<string, txIcon>;

  txIcons = class(txFS)
  private
    fRootURL: string;
    FRootPath: string;
    //  FRootHost: string;
    FIcons: txIconsList;
    Fbc: TTMSFNCBitmapContainer;
    FEnableBC: boolean;
    FRootDir: string;
    FFIles: tbsFileList;
    FEnableLottie: boolean;
    FEnableCX: boolean;
    FEnableFMXImgList: boolean;
    FuseFS: boolean;
    FEnableDL: boolean;
    FlocalFolder: string;
    FLotties: tLotties;
    {$IFDEF fwfmx}fImgList: tImageList;
    {$ENDIF}
    FRootHost: string;
    {$IFDEF fwVCL}
    FcxMedium: tcxImageList;
    FcxSmallSize: integer;
    FcxSmall: tcxImageList;
    FcxLargeSize: integer;
    FcxMediumSize: integer;
    FcxLarge: tcxImageList;
    {$ENDIF}

    FEnableSVG: boolean;
    function GetRootDir: string;
    procedure SetRootPath(const Value: string);
  public
    doLoadAll: boolean;
    {$IFNDEF fwweb}
    dl: tdl;
    procedure LoadBytesFromFile(var b: tBytes; afn: string);

    {$ENDIF}
    [ASync] procedure GetList; async;

    function PosLast(s, subS: string): integer;
    function GetFolderLastBit(p: string): string;
    function delR(p: string): string;
    function Nametobn(aurl: string): string;
    function URLtoServerFN(aurl: string): string;
    function URLtoFN(aURL: string): string;
    function NametoURL(aname: string): string;
    function bntoURL(abn: string): string;
    function fntobn(afn: string; isLocal: boolean = false): string;

    function isURL(s: string): boolean;
    function ext(afn: string): string;

    function isBitmap(afn: string): boolean;
    procedure doBC(aIcon: txIcon; aLoaded: TBitmapLoadedProc = nil);

    procedure doSVG(aIcon: txIcon);
    {$IFDEF fwVCL}
    procedure doCX(aicon: txIcon);
    procedure CreateCXLists;
    {$ENDIF}
    procedure doLottie(aicon: txicon);
    {$IFDEF fwfmx}
    procedure doImgList(aIcon: txIcon);
    function ImgListAdd(afn: string): integer;
    procedure CreateFMXImgList;
    {$ENDIF}
    {$IFNDEF fwweb}
    procedure DoDownload(aIcon: txicon);
    function DownloadIcon(aIcon: txIcon): string;
    function DownloadIconFS(aIcon: txIcon): boolean;
    {$ENDIF}
    function ServerFNtoName(afn: string): string;
    // constructor Create(aRoot: string = '');
    procedure SetRoot(isX: boolean; aroot: string);
    constructor create(aURI: string = ''); overload; override;
    constructor create(aLoadAM1Icons: boolean); overload;
    constructor CreateAndLoadAll(aURI: string; aRootPath: string);
    [Async] procedure Load(aURI: string; aRootPath: string; ALoadAll: boolean = False); async;
    [Async] procedure init; async; override;
    function GetIcon(aname: string; aLoaded: TBitmapLoadedProc = nil): txIcon;

    //   {$IFDEF fwweb}
   //    [Async] function GetIconA(aname: string): txIcon;
    //   [Async] procedure doBCA(aIcon: txIcon);
    //   {$ENDIF}

    [Async] function GetAllIcons: integer; async;
    class function GetXlibIcons: txIcons;
  published
    {$IFDEF fwvcl}
    property cxSmall: tcxImageList read FcxSmall write FcxSmall;
    property cxMedium: tcxImageList read FcxMedium write FcxMedium;
    property cxLarge: tcxImageList read FcxLarge write FcxLarge;
    property cxLargeSize: integer read FcxLargeSize write FcxLargeSize;
    property cxSmallSize: integer read FcxSmallSize write FcxSmallSize;
    property cxMediumSize: integer read FcxMediumSize write FcxMediumSize;

    {$ENDIF}
    property RootURL: string read fRootURL write fRootURL;
    property RootPath: string read FRootPath write SetRootPath; //The folder eg icons/app
    //property RootHost: string read FRootHost write FRootHost;     //is URI
    property RootDir: string read GetRootDir write FRootDir; // the Server folder. Set by setRoootPath
    property RootHost: string read FRootHost;
    property Icons: txIconsList read FIcons write FIcons;
    property bc: TTMSFNCBitmapContainer read Fbc write Fbc;
    property EnableBC: boolean read FEnableBC write FEnableBC;
    property EnableLottie: boolean read FEnableLottie write FEnableLottie;
    property EnableCX: boolean read FEnableCX write FEnableCX;
    property EnableDL: boolean read FEnableDL write FEnableDL;
    property EnableFMXImgList: boolean read FEnableFMXImgList write FEnableFMXImgList;
    property EnableSVG: boolean read FEnableSVG write FEnableSVG;
    property useFS: boolean read FuseFS write FuseFS;
    property FIles: tbsFileList read FFIles write FFIles;
    property localFolder: string read FlocalFolder write FlocalFolder;
    property Lotties: tLotties read FLotties write FLotties;
    {$IFDEF fwFMX}
    property ImgList: tImageList read fImgList write fImgList;
    {$ENDIF}
  end;

type
  txIconsDL = txIcons;
var
  fxlIcons: txIcons;

function xlIcons: txIcons;

implementation

function xlIcons: txIcons;
begin
  if not assigned(fxlIcons) then fxlIcons := txIcons.GetXlibIcons;
  result := fxlIcons;

end;

constructor txIcons.create(aLoadAM1Icons: boolean);
begin
  create('');
  Options.AutoCreateLookup := true;

end;

constructor txIcons.CreateAndLoadAll(aURI, aRootPath: string);
begin
  Create('');
  doLoadAll := true;
  rootpath := aRootPath;
  URI := aURI;

end;

procedure txIcons.SetRoot(isX: boolean; aroot: string);

begin
  {if isX then RootPath:=xpi(aroot) else fRootURL:=aroot;

  uri := turi.create(fRootURL);
  fRootHost := lowercase(uri.Host);
  frootPath:=uri.Path;
  frootpath := stringreplace(frootpath, '/', '\', [rfIgnoreCase, rfReplaceAll]);
  //frootpath:= RemoveLastPathSegment(frootpath);   }
  RootPath := aroot;

end;

{$IFNDEF fwweb}

procedure txIcons.LoadBytesFromFile(var b: tBytes; afn: string);
var
  ms: tmemoryStream;
begin
  try
    ms := tmemoryStream.create;
    ms.LoadFromFile(afn);
    SetLength(b, ms.Size);
    ms.Position := 0;
    ms.ReadBuffer(Pointer(b)^, ms.Size);
  finally
    ms.Free;

  end;

end;

procedure txIcons.DoDownload(aIcon: txicon);
begin
  alog.send('do dnwoload', enableDL);
  if EnableDL then
  begin
    if useFS then
      DownloadIconFS(aicon)
    else
      DownloadIcon(aIcon);

  end;

end;

function txIcons.DownloadIcon(aIcon: txIcon): string;
var
  fn, aurl: string;
begin
  try
    aurl := aicon.url;
    fn := urltofn(aurl);
    result := fn;
    aicon.fn := fn;

    // if fileexists(fn) then exit;
    if isURL(aurl) = false then
    begin
      aurl := bntourl(aurl);
    end;

    if dl.dl(aurl, fn) < 0 then
    begin
      alog.error('DownloadIcon', aurl);
      result := '';
    end;
    alog.send('Downloaded icon', result);
  except
    on e: exception do
    begin
      alog.error('DownloadIcon Exception', e.message);
      result := '';
    end;
  end;
end;

function txIcons.DownloadIconFS(aIcon: txIcon): boolean;
begin
end;

{$ENDIF}

function txIcons.bntoURL(abn: string): string;
var
  s: string;
  s2: string;
  p: integer;
begin
  s := RootURL;
  s2 := abn;
  p := posLast(s2, '-');

  if p <> 0 then s2[p] := '.';

  s2 := stringreplace(s2, '-', '/', [rfIgnoreCase, rfReplaceAll] );
  if s[length(s)] <> '/' then s := s + '/';
  result := s + s2;

end;

function NormalizePathDelim(const cPath: string; const Delim: Char): string;
begin
  if Delim = '\' then
    Result := StringReplace(cPath, '/', Delim, [rfReplaceAll] )
  else
    Result := StringReplace(cPath, '\', Delim, [rfReplaceAll] );
end;

function CombinePaths(const aFirstPath, aSecondPath: string; aDelim: Char): string;
var
  path1, path2: string;
begin
  path1 := NormalizePathDelim(aFirstPath, aDelim);
  path2 := NormalizePathDelim(aSecondPath, aDelim);
  if path1.EndsWith(aDelim) then
  begin
    if path2.StartsWith(aDelim) then
      Result := path1 + path2.Substring(1)
    else
      Result := path1 + path2;
  end
  else
  begin
    if path2.StartsWith(aDelim) then
      Result := path1 + path2
    else
      result := path1 + aDelim + path2;
  end;
end;

{ txIcons }

function txIcons.fntobn(afn: string; isLocal: boolean = false): string;
var
  s: string;
  adir, adir2: string;
begin
  if isLocal = false then
  begin
    s := afn;
    s := stringreplace(s, localFolder, '', [rfIgnoreCase] );

    s := stringreplace(s, '\', '-', [rfIgnoreCase, rfReplaceAll] );
    s := stringreplace(s, '.', '-', [rfIgnoreCase, rfReplaceAll] );
    result := s;
  end
  else
  begin
    s := afn;
    adir := extractfilepath(s);
    adir2 := GetFolderLastBit(adir);
    s := stringreplace(s, adir, '', [rfIgnoreCase] );
    s := adir2 + '-' + s;

    s := stringreplace(s, '\', '-', [rfIgnoreCase, rfReplaceAll] );
    s := stringreplace(s, '.', '-', [rfIgnoreCase, rfReplaceAll] );
    result := s;
  end;

end;

function txIcons.PosLast(s, subS: string): integer;
var
  p: integer;
  o, fp: integer;
begin
  o := 1;
  fp := 0;

  while o <> 0 do
  begin

    p := pos(subs, s, o);
    if p <> 0 then
    begin
      o := p + 1;
      fp := p;
    end
    else
      o := p;
  end;
  Result := fp;

end;

function txIcons.GetFolderLastBit(p: string): string;
var
  I: integer;
  rp: integer;
  LastS: integer;
begin

  if length(p) = 0 then
    exit;
  result := p;
  LastS := -1;
  p := delR(p);
  rp := length(p);
  for I := 1 to length(p) do
  begin
    if p[rp] = '\' then
    begin
      LastS := rp;
      break;
    end;
    rp := rp - 1;
  end;
  if LastS = -1 then
    exit;
  result := Copy(p, LastS + 1, length(p));
end;

constructor txIcons.create(aURI: string);
begin
  inherited;
  {$IFDEF IOS}
  localfolder := system.IOUtils.TPath.Combine(system.IOUtils.TPath.getdocumentspath, 'xicons');
  ForceDirectories(localfolder);
  {$ENDIF}
  {$IFDEF fwVCL}
  localFolder := combinepaths(path.exepath, 'xicons', pathdelim);
  {$ENDIF}
  {$IFDEF fwfmx}
  localFolder := combinepaths(path.exepath, 'xicons', pathdelim);
  ForceDirectories(localfolder);
  alog.send(LocalFolder);
  {$ENDIF}
  fFiles := tbsFileList.create;
  Icons := txIconsList.create;
  EnableBC := true;
  bc := ttmsfncBitmapContainer.create(nil);
  furi := 'https://x.am1.com/fs2';
  UseFS := false;
  EnableLottie := false;

  {$IFNDEF fwweb}
  EnableDL := true;
  EnableSVG := true;
  UseFS := false;
  EnableLottie := true;

  dl := tdl.create;
  Lotties := tLotties.create;

  //bc.OnBitmapChanged := bcCHanged;

  {$ENDIF}
end;

function txIcons.delR(p: string): string;
begin
  result := p;
  if length(p) = 0 then
    exit;
  if p[length(p)] = '\' then
    p := Copy(p, 1, length(p) - 1);
  result := p;
end;

function txIcons.ServerFNtoName(afn: string): string;
var
  s, adir, adir2: string;
  rdir: string;
begin
  s := afn;

  s := StringReplace(s, rootdir + '\', '', [rfIgnoreCase] );
  //s:=delr(s);
  s := stringreplace(s, '\', '-', [rfIgnoreCase, rfReplaceAll] );
  s := stringreplace(s, '.', '-', [rfIgnoreCase, rfReplaceAll] );
  result := s;
  {
  adir := extractfilepath(s);
  adir2 := GetFolderLastBit(adir);
  s := stringreplace(s, adir, '', [rfIgnoreCase]);
  s := adir2 + '-' + s;

  s := stringreplace(s, '\', '-', [rfIgnoreCase, rfReplaceAll]);
  s := stringreplace(s, '.', '-', [rfIgnoreCase, rfReplaceAll]);
  result := s;  }
end;

procedure txIcons.SetRootPath(const Value: string);
var
  // uuri: turi;
  aHost, aPath, aquery: string;
  aPort: integer;
begin
  FRootPath := Value;
  frootURL := xpi(value);
  // alog.send('setting URI', fURI);

 //  if isX then fRootURL:=xpi(aroot) else fRootURL:=aroot;

  TTMSFNCUtils.SplitURL(fRootURL, ahost, apath, aquery, aport);

  // uuri := turi.create(fRootURL);
  fRootHost := lowercase(aHost);
  fRootHost := stringreplace(fRootHost, 'http://', '', [rfIgnoreCase] );
  fRootHost := stringreplace(fRootHost, 'https://', '', [rfIgnoreCase] );

  //alog.send('fRootHost', fRootHost);

 // uuri := turi.create(fRootURL);
  //fRootHost := lowercase(uuri.Host);
  //frootPath:=uuri.Path;
 // frootpath := stringreplace(frootpath, '/', '\', [rfIgnoreCase, rfReplaceAll]);
  alog.send(URI);
  alog.send(fRootPath);
  alog.send(fRootURL);
  if fURI <> '' then URI := fURI;
end;

function txIcons.URLtoFN(aURL: string): string;
var
  s: string;
  // uri: turi;
  ahost, adir: string;
  p: integer;
begin
  if isURL(aURL) then
  begin
    // uri := turi.create(aurl);
    // ahost := lowercase(uri.Host);
    ahost := fRootHost;
    s := aurl;
    s := stringreplace(s, 'http://', '', [rfIgnoreCase] );
    s := stringreplace(s, 'https://', '', [rfIgnoreCase] );
    s := stringreplace(s, '/', pathdelim, [rfIgnoreCase, rfReplaceAll] );

    s := stringreplace(s, ahost, '', [rfIgnoreCase] );

    adir := combinepaths(localFolder, ahost, pathdelim);
    s := combinepaths(adir, s, pathdelim);

    result := s;
  end
  else
  begin
    s := aurl;
    p := posLast(s, '-');
    //lf.Log('Postlast ' + inttostr(P));
    if p <> 0 then s[p] := '.';
    s := stringreplace(s, '/', pathdelim, [rfIgnoreCase, rfReplaceAll] );
    s := stringreplace(s, '-', pathdelim, [rfIgnoreCase, rfReplaceAll] );
    adir := combinepaths(localFolder, roothost, pathdelim);
    adir := combinepaths(adir, rootPath, pathdelim);
    s := combinepaths(adir, s, pathdelim);

    result := s;
  end;

end;

function txIcons.isURL(s: string): boolean;
var
  sc: string;
begin
  result := false;
  sc := lowercase(s);
  if pos('http://', sc) <> 0 then result := true;
  if pos('https://', sc) <> 0 then result := true;
end;

procedure txIcons.Load(aURI, aRootPath: string; ALoadAll: boolean = False);
begin
  doLoadAll := aLoadAll;
  rootpath := aRootPath;
  URI := aURI;
end;

function txIcons.URLtoServerFN(aurl: string): string;
var
  s, adir: string;
  p: integer;
begin
  s := aurl;
  s := stringreplace(s, 'http://', '', [rfIgnoreCase] );
  s := stringreplace(s, 'https://', '', [rfIgnoreCase] );

  p := posLast(s, '-');
  //lf.Log('Postlast ' + inttostr(P));
  if p <> 0 then s[p] := '.';
  s := stringreplace(s, '/', '\', [rfIgnoreCase, rfReplaceAll] );
  s := stringreplace(s, '-', '\', [rfIgnoreCase, rfReplaceAll] );
  adir := combinepaths(rootdir, s, '\');
  //adir := combinepaths(adir, rootPath, pathdelim);
  //s := combinepaths(adir, s, pathdelim);
  result := adir;
  //result := s;
end;

function txIcons.Nametobn(aurl: string): string;
var
  s: string;
begin
  s := aurl;
  s := stringreplace(s, 'http://', '', [rfIgnoreCase] );
  s := stringreplace(s, 'https://', '', [rfIgnoreCase] );
  s := stringreplace(s, '/', '-', [rfIgnoreCase, rfReplaceAll] );
  s := stringreplace(s, '.', '-', [rfIgnoreCase, rfReplaceAll] );
  result := s;

end;

function txIcons.NametoURL(aname: string): string;
var
  s: string;
  s2: string;
  p: integer;
begin
  s := RootURL;
  s2 := aname;
  p := posLast(s2, '-');

  if p <> 0 then s2[p] := '.';

  s2 := stringreplace(s2, '-', '/', [rfIgnoreCase, rfReplaceAll] );
  if s[length(s)] <> '/' then s := s + '/';
  result := s + s2;

end;

function txIcons.ext(afn: string): string;
begin
  result := lowercase(extractFileext(afn));
end;

function txIcons.isBitmap(afn: string): boolean;
var
  s: string;
begin
  Result := false;
  s := ext(afn);
  if s = '.bmp' then result := true;
  if s = '.png' then result := true;
  if s = '.jpg' then result := true;

end;

{constructor txIcons.Create(aRoot: string);
var
  URLHost, URLPath, URLQuery: string;
  URLPort: integer;
begin
  fRootPath := aRoot;
  frootURL := xpi(aroot);
  fFiles := tbsFileList.create;
  Icons := txIcons.create;
  EnableBC := true;
  bc := ttmsfncBitmapContainer.create(nil);
  xd := tWXDataBase.create;
  xd.Connection.url := 'https://x.am1.com/fs2';
  xd.connected := true;

  //TTMSFNCUtils.SplitURL(fRootURL, URLHost,URLPath,URLQuery, URLPort);
  //fRootHost:=URLHost;
  //frootpath := stringreplace(URLPath, '/', '\', [rfIgnoreCase, rfReplaceAll]);
end; }

procedure txIcons.doBC(aIcon: txIcon; aLoaded: TBitmapLoadedProc = nil);
  procedure GotFuckingBitmap;
  begin
    if assigned(aloaded) then aloaded;

  end;
var
  aitem: ttmsfncBitmapItem;
begin
  //alog.send('bc', aicon.url);
  {$IFDEF fwweb}
  aicon.bn := aicon.name;
  aitem := bc.items.add;
  aitem.name := aicon.name;
  // aitem.bitmap.setsize(32,32);
  aitem.bitmap.loadfromurl(aicon.url, @GotFuckingBitmap);
  //  bc.addfromURL(aicon.url, aicon.name);

  {$ELSE}
  aitem := bc.Items.Add;
  if fileexists(aicon.fn) then

    aitem.bitmap.LoadFromFile(aicon.fn)
  else
    aitem.bitmap.LoadFromURL(aicon.url);
  aitem.name := aicon.name;
  {$ENDIF}
  aicon.bn := aicon.name;
  //   alog.send(aicon.bn,bc.ItemCount);
end;

{$IFDEF fwFMX}

procedure txIcons.CreateFMXImgList;
begin
  imgList := tImagelist.Create(application.mainform);

end;

function txIcons.ImgListAdd(afn: string): integer;
const
  SCALE = 1;
var
  vSource: TCustomSourceItem;
  vBitmapItem: TCustomBitmapItem;
  vDest: TCustomDestinationItem;
  vLayer: TLayer;
  aBitmap: tbitmap;
begin
  try
    Result := -1;
    if ImgList = nil then CreateFMXImgList;

    abitmap := tBitmap.Create;
    abitmap.LoadFromFile(afn);
    if (aBitmap.Width = 0) or (aBitmap.Height = 0) then exit;

    // add source bitmap
    vSource := imgList.Source.Add;
    // vSource.MultiResBitmap.TransparentColor := TColorRec.Fuchsia;
    vSource.MultiResBitmap.SizeKind := TSizeKind.source;
    vSource.MultiResBitmap.Width := Round(aBitmap.Width / SCALE);
    vSource.MultiResBitmap.Height := Round(aBitmap.Height / SCALE);
    //    vSource.MultiResBitmap.Width := 64;
     //vSource.MultiResBitmap.Height :=64;
    vBitmapItem := vSource.MultiResBitmap.ItemByScale(SCALE, True, True);
    if vBitmapItem = nil then
    begin
      vBitmapItem := vSource.MultiResBitmap.Add;
      vBitmapItem.Scale := Scale;
    end;
    vBitmapItem.Bitmap.Assign(aBitmap);

    vDest := imgList.Destination.Add;
    vLayer := vDest.Layers.Add;
    vLayer.SourceRect.Rect := TRectF.Create(TPoint.Zero, vSource.MultiResBitmap.Width,
      vSource.MultiResBitmap.Height);
    vLayer.Name := vSource.Name;
    Result := vDest.Index;
  except
    on e: exception do
    begin
      alog.error('txIconsDL.ImgListAdd(', e.message);
    end;

  end;
end;

procedure txIcons.doImgList(aIcon: txIcon);
var
  afn: string;
begin
  afn := aIcon.fn;

  if isBitmap(afn) then
  begin
    aicon.imgList := ImgListAdd(afn);
    aicon.width := imglist.Destination.Items[aicon.imgList] .Layers[0] .MultiResBitmap.Width;
    aicon.height := imglist.Destination.Items[aicon.imgList] .Layers[0] .MultiResBitmap.height;

  end;

end;
{$ENDIF}

procedure txIcons.doLottie(aicon: txicon);
var
  isLottie: boolean;
begin
try
  isLottie := false;
  if EnableLottie then
    if ext(aicon.bsfile.FullFN) = '.json' then      //bsfile?
      isLottie := true;

  if isLottie then
  begin
    {$IFNDEF fwweb}
    LoadBytesFromFile(aIcon.fLottie, aicon.fn);
    {$ENDIF}

  end;
  aIcon.isLottie := isLottie;
except
 on e: exception do
 begin

 end;

end;
end;

procedure txIcons.doSVG(aIcon: txIcon);
begin
  {$IFNDEF fwweb}

  if EnableSVG then
  begin
    if FileExists(aIcon.fn) then

      if ext(aicon.fn) = '.svg' then aicon.svg := tfile.ReadAllText(aicon.fn);
  end;
  {$ENDIF}
end;

{$IFDEF fwvcl}

procedure txIcons.CreateCXLists;
begin
  if cxSmallSize = 0 then
    cxSmallSize := 16;
  if cxMediumSize = 0 then
    //  cxMediumSize := 32;
    cxMediumSize := 16;
  if cxLargeSize = 0 then
    cxLargeSize := 128;

  if cxSmall = nil then
  begin
    cxSmall := tcxImageList.create(application.mainform);
    cxSmall.Width := cxSmallSize;
    cxSmall.height := cxSmallSize;
    alog.Send('Created cxSmall', cxSmall);
  end;
  if cxMedium = nil then
  begin
    cxMedium := tcxImageList.create(application.mainform);
    cxMedium.Width := cxMediumSize;
    cxMedium.height := cxMediumSize;
    alog.Send('Created cxMedium', cxMedium);
  end;
  if cxLarge = nil then
  begin
    cxLarge := tcxImageList.create(application.mainform);
    cxLarge.Width := cxLargeSize;
    cxLarge.height := cxLargeSize;
    alog.Send('Created cxLarge', cxLarge);
  end;
end;

procedure txIcons.docx(aicon: txicon);
var
  afn: string;
  simg: tdxSmartImage;
  idx: integer;
begin
  afn := aicon.fn;

  if EnableCX then
  begin
    alog.Send('doing cx', afn);
    CreateCXLists;

    try
      simg := tdxSmartImage.create;
      simg.LoadFromFile(afn);
      simg.Resize(cxSmall.Width, cxSmall.height);
      idx := cxSmall.Add(simg);
      //
      aIcon.cxSmall := idx;

      simg := tdxSmartImage.create;
      simg.LoadFromFile(afn);
      simg.Resize(cxMedium.Width, cxMedium.height);
      idx := cxMedium.Add(simg);
      //
      aIcon.cxMedium := idx;

      simg := tdxSmartImage.create;
      simg.LoadFromFile(afn);
      simg.Resize(cxLarge.Width, cxLarge.height);
      idx := cxLarge.Add(simg);

      //
      aIcon.cxLarge := idx;
    except
      on e: exception do
      begin

      end;
    end;
  end;
end;

{$ENDIF}

function txIcons.GetAllIcons: integer;
var
  afile: tbsFile;
  fp: string;
  aname: string;
  aIcon: txIcon;
  doIcon: boolean;
begin
  result := 0;
  for afile in files.files do
  begin
    fp := afile.FullFN;
    doIcon := false;

    if lowercase(ExtractFileExt(fp)) = '.png' then doIcon := true;
    if lowercase(ExtractFileExt(fp)) = '.svg' then doIcon := true;

    if doIcon then

    begin
      aname := ServerFNtoname(fp);
      // console.log('getting', aname);
      aIcon := getIcon(aname);
      if assigned(aicon) then
        result := result + 1;
      // else
      //   console.log('error', aname);
    end;

  end;
  // console.log('GotIcons', result);
end;

function txIcons.GetIcon(aname: string; aLoaded: TBitmapLoadedProc = nil): txIcon;
var
  aIcon: txIcon;
  fn: string;
  bn: string;
  afile: tbsfile;
  isLottie: boolean;
begin

  // bn := NametoBN(aname);
   // alog.Send('IconBN', bn);
  if icons.TryGetValue(aname, aIcon) then
  begin
    result := aIcon;
    exit;
  end;

  result := txIcon.create;
  result.url := nametourl(aname);
  result.icons := self;
  result.fn := urltofn(aname);
  // alog.send('fn', result.fn);
  result.name := fntobn(result.fn);

  Result.ServerFN := urltoServerFN(aname);
  afile := fFiles.lookup(result.serverFN);
  //if not assigned(afile) then alog.error('NoFile', result.ServerFN);

  result.bsfile := afile;
  icons.add(aname, result);

  //Download
  {$IFNDEF fwweb}
  if EnableDL then DoDOwnload(result);
  {$ENDIF}

  //Bitmap Container
  if EnableBC then doBC(result, aloaded);

  //SVG
  if EnableSVG then doSVG(result);

  // Lottie
  if EnableLottie then doLottie(result);

  //CX
  {$IFDEF fwVCL}
  if EnableCX then doCX(result);
  {$ENDIF}
  //FMXImgList
  {$IFDEF fwFMX}
  if EnableFMXImgList then doImgList(result);
  {$ENDIF}

end;

procedure txIcons.GetList;
begin
  try
    AddClientInfo('xIcons - ' + rootPath);
    {$IFDEF fwweb}
    fFiles := await(getitems(Rootdir, '*.*', true, true, true, false));

    {$ELSE}
    // alog.send('Getting list');
    fFiles := getitems(Rootdir, '*.*', true, true, true, false);
    //alog.send('Files', fFiles.files.count);
    {$ENDIF}

    if doLoadAll then
    begin
      {$IFDEF fwweb}
      GetAllIcons;

      {$ELSE}
      GetAllIcons;
      {$ENDIF}
    end;
    doLoadAll := false;
    // fFiles.log;
     // console.log('Got Icon Files', fFiles.files.count);
  except
    on e: exception do
    begin
      alog.error('txIcons.getlist', e.message);
    end;
  end;
end;

{

procedure txIcons.GetList;
var
  r: TXDataClientResponse;
  s: string;
  afile: tbsFile;
  jsList: tjsObject;
  ops: txjOps;
begin
  //CHECK THIS WORKS WITHOUT PASSING THROUGH JSOBJECT. IT MESSES WITH THE PUB PRO NAMES fEXISTS..
  //THE PROB WAS JUST THE SVDATES FIX XJSON OR EXC THE FIELDS

  RegisterClass(tbsFile);
  RegisterCLass(tbsFileList);
  aFile := tbsFile.create;

  r := await(xd.Client.RawInvokeAsync('IFileService.GetFiles', [RootDir, '', true] ));
  //console.log('List', r.ResponseText);

  jsList := TJSObject(r.Result);
  console.log('jslist', jslist);

  s := tjsJson.stringify(jslist);

  s := stringReplace(s, 'fexists', 'Exists', [rfIgNoreCase, rfReplaceAll] );
  s := stringReplace(s, 'fonclient', 'OnClient', [rfIgNoreCase, rfReplaceAll] );
  s := stringReplace(s, 'fdownloaded', 'downloaded', [rfIgNoreCase, rfReplaceAll] );
  //
 // s:=string(r.Result);       }
 { s := tjsJson.stringify(r.Result);
  ops := xjson.GetOps;
  ops.mode := txjMode.xjEx;
  ops.ap('files.svWriteTime');
  ops.ap('files.svAccessTime');
  ops.ap('files.svCreateTime');
  ops.ap('files.svCheckTime');
  ops.NoClassType := true;
  ops.LoadFromJson(ffiles, s);

  // console.log('s', s);
   //xjtoObject(s, ffiles);
  console.log('Files', ffiles);
  console.log('Got Files', ffiles.files.Count);
  console.log('file 1', ffiles.files[1] );
end;                       }

function txIcons.GetRootDir: string;
begin
  if fRootPath <> '' then

    result := xIconsDir + '\' + frootpath
  else
    Result := xIconsDir;
end;

procedure txIcons.init;
begin
  {$IFDEF fwweb}
  await(inherited);
  {$ELSE}
  inherited;
  {$ENDIF}

  if error<>'' then
  begin
    alog.error('Icons cannot connect to server');
    exit;
  end;

  {$IFDEF fwweb}
  await(GetList);
  {$ELSE}
  GetList;
  {$ENDIF}

end;

{ txIcon }

function txIcon.getBN: string;
begin
  Result := Fbn;
end;

class function txIcons.GetXlibIcons: txIcons;
begin
  try
    if not assigned(fxlIcons) then
    begin
      fxlIcons := txIcons.create;
      fxLicons.RootPath := 'xlib';
      fxlicons.uri := LibXFS;
      // xlicons:=txicons.CreateAndLoadAll(LibXFS, 'xlib');
    end;
    result := fxlIcons;
  except
    on e: exception do
    begin
      //  flog.error('txIcons.GetXlibIcons', e.message);
    end;

  end;
end;

end.

