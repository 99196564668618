unit x.css.slide;

interface
uses
  web, weblib.forms, js, classes, x.css, vcl.controls, liblogtest, sysutils;

   type
    txCSSSlideDirection=(fromLeft, FromRight, FromTop, FromBottom);

type
  txCSSSlide = class(txCSSBase)
  private

  public
    fcs: TJSCSSStyleDeclaration;
    SlideCOunt: integer;
    LastName: string;
    function NewSlide: TJSCSSStyleDeclaration;
    function NextName: string;
    constructor create;
    procedure loadcss; override;
    procedure AddDefaults;
    procedure SlideFromLeft(ac: tcontrol); overload;
    procedure SlideFromLeft(EH: TJSHTMLElement); overload;
    procedure SlideFromRight(ac: tcontrol); overload;
    procedure SlideFromRight(EH: TJSHTMLElement); overload;
   [ASync] function Slide(ac: tControl; Direction: txCSSSlideDirection): boolean; async;
  published
    property cs: TJSCSSStyleDeclaration read Fcs write Fcs;

  end;

var
  xcSlide: txCSSSlide;

implementation

{ txCSSSlide }

procedure txCSSSlide.AddDefaults;
var
  acss: TJSCSSStyleDeclaration;
begin
  {acss:=xCss.NewClass('xslide-fromright');
  acss.setProperty('position', 'absolute');
  acss.setProperty('left', 'absolute');
  acss.setProperty('width', 'absolute');
  acss.setProperty('height', 'absolute');
  acss.setProperty('-webkit-animation', 'absolute');
  acss.setProperty('-webkit-animation-delay', 'absolute');
  acss.setProperty('animation', 'absolute');
  acss.setProperty('animation-delay', 'absolute'); }

end;

constructor txCSSSlide.create;
begin
  mytype := '.xslide';
  SlideCount := 0;
end;

procedure txCSSSlide.loadcss;
begin
  inherited;

end;

function txCSSSlide.NewSlide: TJSCSSStyleDeclaration;
begin
  result := nil;
  try

    if not assigned(fcs) then
    begin
     fcs := xcss.get('xslide');
     alog.send('xslide', fcs);
    end;
    SlideCount := SlideCount + 1;
    result := xcss.CopyClass('xslide', 'xslide' + inttostr(slideCOunt));
    Lastname:= 'xslide' + inttostr(slideCOunt);
  except
    on e: exception do
    begin
      result := nil;
      alog.error('Newslide', e.message);
    end;

  end;

end;

function txCSSSlide.NextName: string;
begin
   SlideCount := SlideCount + 1;
   result:='xslide'+Inttostr(slideCOunt);
end;

procedure txCSSSlide.SlideFromLeft(ac: tcontrol);

begin

end;

function txCSSSlide.Slide(ac: tControl; Direction: txCSSSlideDirection): boolean;
procedure RemoveCSS;
begin
  // tjsHTMLElement(ac.elementHandle)..classList.remove(aclass);
  xcss.xr(ac, 'xslide');
end;
var
 sc: TJSCSSStyleDeclaration;
 sp: integer;
 OldAlign: tAlign;
 eh: tjsHtmlElement;
 s: string;
 cid: string;
begin
result:=false;
 //sc:=NewSlide;
        s:='position: absolute; left: %left%px; width: %width%px; height: %height%px; animation: slide 4s forwards;';

 eh:=tjsHTMLElement(ac.elementHandle);
 {if not assigned(sc) then
 begin
  alog.send('no css');
  exit;
 end;}
   sp:=0;
sp:=ac.width;
  s:=stringreplace(s, '%width%',  IntToStr(ac.width), [rfIgnorecase]);
  s:=stringreplace(s, '%height%',  IntToStr(ac.height), [rfIgnorecase]);
  s:=stringreplace(s, '%left%',  IntToStr(sp), [rfIgnorecase]);
 { s:=stringreplace(s, '%width%',  IntToStr(ac.width), [rfIgnorecase]);
  s:=stringreplace(s, '%width%',  IntToStr(ac.width), [rfIgnorecase]);
  s:=stringreplace(s, '%width%',  IntToStr(ac.width), [rfIgnorecase]);}


 {sc.setProperty('width', IntToStr(ac.width));
 sc.setProperty('height', IntToStr(ac.height));

 sc.setProperty('left', IntToStr(sp));

 sc.setProperty('-webkit-animation', 'slide 0.5s forwards');
  sc.setProperty('-webkit-animation-delay', '2s');
  sc.setProperty('animation', '0.5s');
  sc.setProperty('animation-delay', '2s');   }

  //oldAlign:=ac.Align;
 // ac.align:=alNone;
 // console.log('Setting slide', sc);
 cid:='xslide';
  console.log('Applying', s);
  await( xcss.addInstanceStyle(cid,s));
  eh.classlist.add(cid);
  // window.setTimeout(@RemoveCSS,3000);


end;

procedure txCSSSlide.SlideFromLeft(EH: TJSHTMLElement);
begin

end;

procedure txCSSSlide.SlideFromRight(ac: tcontrol);
begin

end;

procedure txCSSSlide.SlideFromRight(EH: TJSHTMLElement);
begin

end;

initialization
  xcSlide := txCSSSlide.create;

end.

