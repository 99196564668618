{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016                                        }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCloudImage;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCBitmapContainer
  {$IFNDEF WEBLIB}
  ,WEBLib.TMSFNCCloudBase
  {$ENDIF}
  ,WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  , UITypes, Types
  {$ENDIF}
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  // version history
  // v1.0.0.0 : First Release

type
  TTMSFNCCustomCloudImage = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    {$IFNDEF WEBLIB}
    FImageDownloader: TTMSFNCCloudBase;
    {$ENDIF}
    FBitmap: TTMSFNCBitmap;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FStretch: Boolean;
    FBitmaps: TTMSFNCScaledBitmaps;
    FCropping: Boolean;
    FCenter: Boolean;
    FAutoSize: Boolean;
    FAspectRatio: Boolean;
    FOnBitmapChanged: TNotifyEvent;
    FURL: string;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetStretch(const Value: Boolean);
    procedure SetBitmaps(const Value: TTMSFNCScaledBitmaps);
    procedure SetCropping(const Value: Boolean);
    procedure SetCenter(const Value: Boolean);
    procedure SetAS(const Value: Boolean);
    procedure SetAspectRatio(const Value: Boolean);
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetURL(const Value: string);
  protected
    function GetVersion: string; override;
    procedure ApplyAutoSize;
    {$IFNDEF WEBLIB}
    procedure DoDownloadImage(const ARequestResult: TTMSFNCCloudBaseRequestResult);
    {$ENDIF}
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure BitmapChanged(Sender: TObject);
    property AspectRatio: Boolean read FAspectRatio write SetAspectRatio default True;
    property Version: String read GetVersion;
    property Bitmaps: TTMSFNCScaledBitmaps read FBitmaps write SetBitmaps;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Stretch: Boolean read FStretch write SetStretch default False;
    property Cropping: Boolean read FCropping write SetCropping default False;
    property Center: Boolean read FCenter write SetCenter default True;
    property AutoSize: Boolean read FAutoSize write SetAS default False;
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property URL: string read FURL write SetURL;
  public
    procedure Clear;
    function GetPaintRectangle: TRectF;
    function GetBitmap: TTMSFNCBitmap;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure LoadFromURL(const AURL: string);
    property OnBitmapChanged: TNotifyEvent read FOnBitmapChanged write FOnBitmapChanged;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCloudImage = class(TTMSFNCCustomCloudImage)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property OnBitmapChanged;
    property AspectRatio;
    property Bitmap;
    property Version;
    property Bitmaps;
    property BitmapContainer;
    property Stretch;
    property Cropping;
    property Center;
    property AutoSize;
    property Fill;
    property Stroke;
    property URL;
  end;

implementation

uses
  SysUtils, WEBLib.Controls, WEBLib.Graphics, WEBLib.TMSFNCUtils;

procedure TTMSFNCCustomCloudImage.ApplyAutoSize;
var
  bmp: TTMSFNCBitmap;
begin
  bmp := GetBitmap;
  if Assigned(bmp) then
  begin
    if FAutoSize and not IsBitmapEmpty(bmp) then
    begin
      Self.Width := bmp.Width;
      Self.Height := bmp.Height;
    end;
  end;
end;

procedure TTMSFNCCustomCloudImage.BitmapChanged(Sender: TObject);
begin
  if Assigned(OnBitmapChanged) then
    OnBitmapChanged(Self);

  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.Clear;
begin
  Bitmaps.Clear;
  Bitmap.Assign(nil);
end;

constructor TTMSFNCCustomCloudImage.Create(AOwner: TComponent);
begin
  inherited;
  if IsDesignTime then
    DisableBackground;

  FCenter := True;
  FStretch := False;
  FAspectRatio := True;
  FCropping := False;
  FBitmaps := TTMSFNCScaledBitmaps.Create(Self);
  FBitmaps.OnChange := @BitmapChanged;
  FBitmap := TTMSFNCBitmap.Create;
  FBitmap.OnChange := @BitmapChanged;
  Width := 100;
  Height := 100;
end;

destructor TTMSFNCCustomCloudImage.Destroy;
begin
  FBitmap.Free;
  FBitmaps.Free;
  {$IFNDEF WEBLIB}
  FreeAndNil(FImageDownloader);
  {$ENDIF}
  inherited;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomCloudImage.DoDownloadImage(
  const ARequestResult: TTMSFNCCloudBaseRequestResult);
begin
  if Assigned(ARequestResult.ResultStream) then
    Bitmap.LoadFromStream(ARequestResult.ResultStream);
end;
{$ENDIF}

function TTMSFNCCustomCloudImage.GetBitmap: TTMSFNCBitmap;
begin
  if not IsBitmapEmpty(FBitmap) then
    Result := FBitmap
  else
    Result := TTMSFNCGraphics.GetScaledBitmap(Bitmaps, 0, FBitmapContainer);
end;

function TTMSFNCCustomCloudImage.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomCloudImage.GetPaintRectangle: TRectF;
var
  bmp: TTMSFNCBitmap;
  rdest: TRectF;
  w, h: Single;
  x, y: Single;
begin
  Result := RectF(0, 0, 0, 0);
  bmp := GetBitmap;
  if Assigned(bmp) then
  begin
    x := 0;
    y := 0;
    w := 0;
    h := 0;
    TTMSFNCGraphics.GetAspectSize(w, h, bmp.Width, bmp.Height, Width, Height, AspectRatio, Stretch, Cropping);
    if Center or Cropping then
    begin
      x := (width - w) / 2;
      y := (height - h) / 2;
    end;

    rdest := RectF(x, y, x + w, y + h);
    Result := rdest;
  end;
end;

function TTMSFNCCustomCloudImage.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomCloudImage.LoadFromURL(const AURL: string);
begin
  if Pos('HTTP', Uppercase(AURL)) <> 1 then
  begin
    Bitmap.LoadFromFile(AURL);
    Exit;
  end;

  {$IFNDEF WEBLIB}
  if not Assigned(FImageDownloader) then
    FImageDownloader := TTMSFNCCloudBase.Create(Self);

  FImageDownloader.Request.Clear;
  FImageDownloader.Request.Host := AURL;
  FImageDownloader.Request.ResultType := rrtStream;
  FImageDownloader.Request.Method := rmGET;
  FImageDownloader.Request.Name := 'DOWNLOAD IMAGE';
  FImageDownloader.ExecuteRequest({$IFDEF LCLWEBLIB}@{$ENDIF}DoDownloadImage);
  {$ENDIF}
  {$IFDEF WEBLIB}
  Bitmap.LoadFromURL(AURL);
  {$ENDIF}
end;

procedure TTMSFNCCustomCloudImage.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomCloudImage.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  bmp: TTMSFNCBitmap;
   rdest: TRectF;
  w, h: Single;
  x, y: Single;
begin
  inherited;
  if csDesigning in ComponentState then
  begin
    AGraphics.Stroke.Kind := gskDot;
    AGraphics.DrawRectangle(ARect);
  end;

  bmp := GetBitmap;
  if Assigned(bmp) then
  begin
    x := 0;
    y := 0;
    w := 0;
    h := 0;
    TTMSFNCGraphics.GetAspectSize(w, h, bmp.Width, bmp.Height, Width, Height, AspectRatio, Stretch, Cropping);
    if Center or Cropping then
    begin
      x := (width - w) / 2;
      y := (height - h) / 2;
    end;

    x := Round(x);
    y := Round(y);

    rdest := RectF(x, y, x + w, y + h);
    AGraphics.DrawBitmap(rdest, bmp);
  end;
end;

procedure TTMSFNCCustomCloudImage.SetAspectRatio(const Value: Boolean);
begin
  FAspectRatio := Value;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetAS(const Value: Boolean);
begin
  FAutoSize := Value;
  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetBitmaps(const Value: TTMSFNCScaledBitmaps);
begin
  FBitmaps.Assign(Value);
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetBitmap(const Value: TTMSFNCBitmap);
begin
  FBitmap.Assign(Value);
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  ApplyAutoSize;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetCenter(const Value: Boolean);
begin
  FCenter := Value;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetCropping(const Value: Boolean);
begin
  FCropping := Value;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetStretch(const Value: Boolean);
begin
  FStretch := Value;
  Invalidate;
end;

procedure TTMSFNCCustomCloudImage.SetURL(const Value: string);
begin
  FURL := Value;
  if FURL = '' then
    Clear
  else
    LoadFromURL(FURL);
end;

{ TTMSFNCCloudImage }

procedure TTMSFNCCloudImage.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCloudImage);
end;

end.
