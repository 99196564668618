{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCLabelEdit;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes,
  {$IFNDEF LCLLIB}
  Types,
  {$ENDIF}
  {$IFDEF WEBLIB}
  WEBLib.Controls,
  {$ENDIF}
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  TypInfo, WEBLib.ExtCtrls,
  WEBLib.TMSFNCHTMLText, WEBLib.TMSFNCEdit, WEBLib.TMSFNCImage,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 1; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Fixed : Issue with HorizontalTextAlign
  //v1.0.0.2 : Fixed : Issue with OnCancel called too early
  //v1.0.1.0 : New : Support for high dpi
  //v1.0.2.0 : New : Added Editable property
  //v1.0.2.1 : Fixed : Issue with OnAccept triggering before OnCancel

type
  TTMSFNCCustomLabelEditEditKeyDownEvent = procedure(Sender: TObject; var Key: Word; {$IFDEF FMXLIB}var KeyChar: Char;{$ENDIF} Shift: TShiftState) of object;
  TTMSFNCCustomLabelEditBeforeDrawButton = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomLabelEditAfterDrawButton = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;

  TTMSFNCCustomLabelEdit = class(TTMSFNCHTMLText)
  private
    FExitTimer: TTimer;
    FEditExit: Boolean;
    {$IFDEF WEBLIB}
    FMouseExit: Boolean;
    {$ENDIF}
    FCancelButton: TTMSFNCImage;
    FAcceptButton: TTMSFNCImage;
    FEdit: TTMSFNCEdit;
    FEditMode: Boolean;
    FMouseDown: Boolean;
    FOnAccept: TNotifyEvent;
    FOnCancel: TNotifyEvent;
    FPrevText: string;
    FOnEditKeyDown: TTMSFNCCustomLabelEditEditKeyDownEvent;
    FOnEditExit: TNotifyEvent;
    FAcceptButtonStroke: TTMSFNCGraphicsStroke;
    FCancelButtonStroke: TTMSFNCGraphicsStroke;
    FOnEditStart: TNotifyEvent;
    FOnEditEnd: TNotifyEvent;
    FEditable: Boolean;
    procedure SetAcceptButtonStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCancelButtonStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetEditMode(const Value: Boolean);
    function GetAcceptButton: TTMSFNCImage;
    function GetCancelButton: TTMSFNCImage;
    function GetEdit: TTMSFNCEdit;
    procedure SetEditable(const Value: Boolean);
  protected
    procedure ExitTimerChanged(Sender: TObject);
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    {$IFDEF FMXLIB}
    procedure LabelEditKeyDown(Sender: TObject; var Key: Word; var KeyChar: Char; Shift: TShiftState);
    procedure AcceptButtonMouseDown(Sender: TObject; Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
    procedure CancelButtonMouseDown(Sender: TObject; Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure LabelEditKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure AcceptButtonMouseDown(Sender: TObject; {%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Integer);
    procedure CancelButtonMouseDown(Sender: TObject; {%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Integer);
    {$ENDIF}
    procedure LabelEditExit(Sender: TObject);
    procedure LabelEditStrokeChanged(Sender: TObject);
    procedure DoBeforeDrawAcceptButton(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
    procedure DoBeforeDrawCancelButton(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
    procedure DoAccept;
    procedure DoCancel;
    function IsAppearanceProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; override;
    function GetVersion: string; override;
    property AcceptButtonStroke: TTMSFNCGraphicsStroke read FAcceptButtonStroke write SetAcceptButtonStroke;
    property CancelButtonStroke: TTMSFNCGraphicsStroke read FCancelButtonStroke write SetCancelButtonStroke;
    property Editable: Boolean read FEditable write SetEditable default True;
    property OnAccept: TNotifyEvent read FOnAccept write FOnAccept;
    property OnCancel: TNotifyEvent read FOnCancel write FOnCancel;
    property OnEditExit: TNotifyEvent read FOnEditExit write FOnEditExit;
    property OnEditKeyDown: TTMSFNCCustomLabelEditEditKeyDownEvent read FOnEditKeyDown write FOnEditKeyDown;
    property OnEditStart: TNotifyEvent read FOnEditStart write FOnEditStart;
    property OnEditEnd: TNotifyEvent read FOnEditEnd write FOnEditEnd;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property Edit: TTMSFNCEdit read GetEdit write FEdit;
    property CancelButton: TTMSFNCImage read GetCancelButton write FCancelButton;
    property AcceptButton: TTMSFNCImage read GetAcceptButton write FAcceptButton;
    property EditMode: Boolean read FEditMode write SetEditMode;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCLabelEdit = class(TTMSFNCCustomLabelEdit)
  published
    property AcceptButtonStroke;
    property CancelButtonStroke;
    property HorizontalTextAlign default gtaLeading;
    property Editable;
    property OnAccept;
    property OnCancel;
    property OnEditExit;
    property OnEditKeyDown;
    property OnEditStart;
    property OnEditEnd;
  end;

const
  BTNWIDTH = 25;

implementation

uses
  {$IFDEF CMNLIB}
  Controls,
  {$ENDIF}
  WEBLib.TMSFNCStyles, WEBLib.TMSFNCUtils;

type
  TCustomImageProtected = class(TTMSFNCImage);

{ TTMSFNCCustomLabelEdit }

{$IFDEF FMXLIB}
procedure TTMSFNCCustomLabelEdit.AcceptButtonMouseDown(Sender: TObject; Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomLabelEdit.AcceptButtonMouseDown(Sender: TObject; Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Integer);
{$ENDIF}
begin
  {$IFDEF WEBLIB}
  FMouseExit := True;
  {$ENDIF}
  DoAccept;
end;

procedure TTMSFNCCustomLabelEdit.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
  begin
    CancelButton.Fill.Color := c;
    AcceptButton.Fill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    CancelButtonStroke.Color := c;
    AcceptButtonStroke.Color := c;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomLabelEdit.CancelButtonMouseDown(Sender: TObject; Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomLabelEdit.CancelButtonMouseDown(Sender: TObject; Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Integer);
{$ENDIF}
begin
  {$IFDEF WEBLIB}
  FMouseExit := True;
  {$ENDIF}
  DoCancel;
end;

procedure TTMSFNCCustomLabelEdit.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  if not (csLoading in ComponentState) and not (csDesigning in ComponentState) and not FEditMode then
  begin
    {$IFDEF CMNLIB}
    BeginUpdate;
    if Assigned(FAcceptButton) then
    begin
      if (AcceptButton.Width <> TTMSFNCUtils.MulDivInt(AcceptButton.Width, M, D)) then
        AcceptButton.Width := TTMSFNCUtils.MulDivInt(AcceptButton.Width, M, D);
    end;

    if Assigned(FCancelButton) then
    begin
    if (CancelButton.Width <> TTMSFNCUtils.MulDivInt(CancelButton.Width, M, D)) then
      CancelButton.Width := TTMSFNCUtils.MulDivInt(CancelButton.Width, M, D);
    end;
    EndUpdate;
    {$ENDIF}
  end;
end;

constructor TTMSFNCCustomLabelEdit.Create(AOwner: TComponent);
begin
  inherited;
  FEditExit := False;
  FEditMode := False;
  {$IFDEF WEBLIB}
  FMouseExit := False;
  {$ENDIF}
  FEditable := True;
  FMouseDown := False;
  HorizontalTextAlign := gtaLeading;
  FPrevText := '';
  {$IFDEF FMXMOBILE}
  Height := 30;
  {$ENDIF}
  {$IFNDEF FMXMOBILE}
  Height := 25;
  {$ENDIF}
  Width := 120;
  FAcceptButtonStroke := TTMSFNCGraphicsStroke.Create;
  FAcceptButtonStroke.Width := 2;
  FAcceptButtonStroke.OnChanged := @LabelEditStrokeChanged;
  FCancelButtonStroke := TTMSFNCGraphicsStroke.Create;
  FCancelButtonStroke.Width := 2;
  FCancelButtonStroke.OnChanged := @LabelEditStrokeChanged;

  FExitTimer := TTimer.Create(Self);
  FExitTimer.Enabled := False;
  FExitTimer.Interval := 1;
  FExitTimer.OnTimer := ExitTimerChanged;

  if IsDesignTime then
    Text := 'TTMSFNCLabelEdit';
end;

destructor TTMSFNCCustomLabelEdit.Destroy;
begin
  if Assigned(FEdit) then
    FEdit.Free;

  if Assigned(FCancelButton) then
    FCancelButton.Free;

  if Assigned(FAcceptButton) then
    FAcceptButton.Free;

  FExitTimer.Free;
  FAcceptButtonStroke.Free;
  FCancelButtonStroke.Free;
  inherited;
end;

procedure TTMSFNCCustomLabelEdit.DoAccept;
begin
  FEditExit := False;
  EditMode := False;
  if Assigned(OnAccept) then
    OnAccept(Self);
end;

procedure TTMSFNCCustomLabelEdit.DoBeforeDrawAcceptButton(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
var
  br: TRectF;
  bmp: TTMSFNCBitmap;
begin
  bmp := FAcceptButton.GetBitmap;
  if not Assigned(bmp) or IsBitmapEmpty(bmp) then
  begin
    ADefaultDraw := False;
    br := RectF(ARect.Left, ARect.Top + (Height - FAcceptButton.Width) / 2, ARect.Right, ARect.Top + (Height + FAcceptButton.Width) / 2);
    InflateRectEx(br, -(FAcceptButton.Width / 4), -(FAcceptButton.Width / 4));
    AGraphics.Stroke.Assign(FAcceptButtonStroke);
    AGraphics.DrawLine(PointF(br.Left, br.Bottom - (br.Bottom - br.Top) / 3), PointF(br.Left + (br.Right - br.Left) / 3, br.Bottom));
    AGraphics.DrawLine(PointF(br.Right, br.Top), PointF(br.Left + (br.Right - br.Left) / 3, br.Bottom));
  end;
end;

procedure TTMSFNCCustomLabelEdit.DoBeforeDrawCancelButton(Sender: TObject;
  AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
var
  br: TRectF;
  bmp: TTMSFNCBitmap;
begin
  bmp := FCancelButton.GetBitmap;
  if not Assigned(bmp) or IsBitmapEmpty(bmp) then
  begin
    ADefaultDraw := False;
    br := RectF(ARect.Left, ARect.Top + (Height - FCancelButton.Width) / 2, ARect.Right, ARect.Top + (Height + FCancelButton.Width) / 2);
    InflateRectEx(br, -(FCancelButton.Width / 4), -(FCancelButton.Width / 4));
    AGraphics.Stroke.Assign(FCancelButtonStroke);
    AGraphics.DrawLine(PointF(br.Left, br.Top), PointF(br.Right, br.Bottom));
    AGraphics.DrawLine(PointF(br.Right, br.Top), PointF(br.Left, br.Bottom));
  end;
end;

procedure TTMSFNCCustomLabelEdit.DoCancel;
begin
  FEditExit := False;
  EditMode := False;
  Self.Text := FPrevText;
  if Assigned(OnCancel) then
    OnCancel(Self);
end;

procedure TTMSFNCCustomLabelEdit.ExitTimerChanged(Sender: TObject);
begin
  FExitTimer.Enabled := False;
  if FEditExit then
    DoAccept;
end;

function TTMSFNCCustomLabelEdit.GetAcceptButton: TTMSFNCImage;
begin
  if not Assigned(FAcceptButton) then
  begin
    FAcceptButton := TTMSFNCImage.Create(Self);
    FAcceptButton.Width := BTNWIDTH;
    FAcceptButton.AutoSize := True;
    FAcceptButton.Stroke.Kind := gskNone;
    FAcceptButton.Fill.Kind := gfkNone;
    {$IFDEF FMXLIB}
    FAcceptButton.Align := TAlignLayout.Right;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FAcceptButton.Align := alRight;
    {$ENDIF}
    {$IFDEF CMNLIB}
    {$IFDEF MSWINDOWS}
    TCustomImageProtected(FAcceptButton).NativeCanvas := True;
    {$ENDIF}
    {$ENDIF}
    FAcceptButton.OnMouseDown := AcceptButtonMouseDown;
    FAcceptButton.OnBeforeDraw := DoBeforeDrawAcceptButton;
  end;
  FAcceptButton.BitmapContainer := BitmapContainer;
  Result := FAcceptButton;
end;

function TTMSFNCCustomLabelEdit.GetCancelButton: TTMSFNCImage;
begin
  if not Assigned(FCancelButton) then
  begin
    FCancelButton := TTMSFNCImage.Create(Self);
    FCancelButton.Width := BTNWIDTH;
    FCancelButton.AutoSize := True;
    FCancelButton.Stroke.Kind := gskNone;
    FCancelButton.Fill.Kind := gfkNone;
    {$IFDEF FMXLIB}
    FCancelButton.Align := TAlignLayout.Right;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FCancelButton.Align := alRight;
    {$ENDIF}
    {$IFDEF CMNLIB}
    {$IFDEF MSWINDOWS}
    TCustomImageProtected(FCancelButton).NativeCanvas := True;
    {$ENDIF}
    {$ENDIF}
    FCancelButton.OnMouseDown := CancelButtonMouseDown;
    FCancelButton.OnBeforeDraw := DoBeforeDrawCancelButton;
  end;
  FCancelButton.BitmapContainer := BitmapContainer;
  Result := FCancelButton;
end;

function TTMSFNCCustomLabelEdit.GetEdit: TTMSFNCEdit;
begin
  if not Assigned(FEdit) then
  begin
    FEdit := TTMSFNCEdit.Create(Self);
    {$IFDEF FMXLIB}
    FEdit.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    FEdit.Align := alClient;
    {$ENDIF}
    FEdit.OnKeyDown := @LabelEditKeyDown;
    FEdit.OnExit := @LabelEditExit;
  end;
  Result := FEdit;
end;

function TTMSFNCCustomLabelEdit.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomLabelEdit.HandleKeyDown(var Key: Word;
  Shift: TShiftState);
begin
  inherited;
  if Key = KEY_RETURN then
    EditMode := True;
end;

procedure TTMSFNCCustomLabelEdit.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  CaptureEx;
  FMouseDown := True;
end;

procedure TTMSFNCCustomLabelEdit.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  if FMouseDown and PtInRectEx(GetControlRect, PointF(X, Y)) then
    EditMode := True;

  FMouseDown := False;
  ReleaseCaptureEx;
end;

function TTMSFNCCustomLabelEdit.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'AcceptButtonStroke') or (APropertyName = 'CancelButtonStroke')
    or (APropertyName = 'ShadowColor') or (APropertyName = 'URLColor');
end;

procedure TTMSFNCCustomLabelEdit.LabelEditExit(Sender: TObject);
begin
  {$IFDEF WEBLIB}
  if FMouseExit then
    FMouseExit := False
  else
  {$ENDIF}
  begin
    FEditExit := True;
    FExitTimer.Enabled := True;
  end;

  if Assigned(OnEditExit) then
    OnEditExit(Self);
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomLabelEdit.LabelEditKeyDown(Sender: TObject; var Key: Word; var KeyChar: Char; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomLabelEdit.LabelEditKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
{$ENDIF}
begin
  case Key of
    KEY_RETURN: DoAccept;
    KEY_ESCAPE: DoCancel;
  end;

  if Assigned(OnEditKeyDown) then
  begin
    {$IFDEF FMXLIB}
    OnEditKeyDown(Self, Key, KeyChar, Shift);
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    OnEditKeyDown(Self, Key, Shift);
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomLabelEdit.LabelEditStrokeChanged(Sender: TObject);
begin
  Invalidate;
end;

procedure TTMSFNCCustomLabelEdit.ResetToDefaultStyle;
begin
  inherited;
  CancelButton.Fill.Color := Fill.Color;
  AcceptButton.Fill.Color := Fill.Color;
  CancelButtonStroke.Color := gcSilver;
  AcceptButtonStroke.Color := gcSilver;
end;

procedure TTMSFNCCustomLabelEdit.SetAcceptButtonStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FAcceptButtonStroke.Assign(Value);
end;

procedure TTMSFNCCustomLabelEdit.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  AcceptButton.AdaptToStyle := Value;
  CancelButton.AdaptToStyle := Value;
end;

procedure TTMSFNCCustomLabelEdit.SetCancelButtonStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FCancelButtonStroke.Assign(Value);
end;

procedure TTMSFNCCustomLabelEdit.SetEditable(const Value: Boolean);
begin
  if FEditable <> Value then
  begin
    if not Value and EditMode then
      EditMode := False;

    FEditable := Value;
  end;
end;

procedure TTMSFNCCustomLabelEdit.SetEditMode(const Value: Boolean);
begin
  if FEditMode <> Value and FEditable then
  begin
    FEditMode := Value;
    if FEditMode then
    begin
      FPrevText := Self.Text;
      Self.Text := '';
      Edit.Text := FPrevText;

      CancelButton.Fill.Color := Fill.Color;
      AcceptButton.Fill.Color := Fill.Color;

      Edit.Parent := Self;
      CancelButton.Parent := Self;
      AcceptButton.Parent := Self;
      Edit.SetFocus;

      if Assigned(OnEditStart) then
        OnEditStart(Self);
    end
    else
    begin
      Edit.Parent := nil;
      AcceptButton.Parent := nil;
      CancelButton.Parent := nil;

      Self.Text := Edit.Text;

      if Assigned(OnEditEnd) then
        OnEditEnd(Self);
    end;
  end;
  Invalidate;
end;

end.
