{$mode objfpc}
unit liblogweb;


interface



  uses js,web;

  const a='🫶';

    type tLogTest=class
      private
      public
       constructor create;
       procedure send(a1: jsvalue);overload;
       procedure send(a1,a2: jsvalue); overload;
       procedure send(a1,a2,a3: jsvalue); overload;
       procedure send(a1,a2,a3,a4: jsvalue); overload;
       procedure send(a1,a2,a3,a4,a5: jsvalue); overload;

       procedure log(a1: jsvalue);overload;
       procedure log(a1,a2: jsvalue); overload;
       procedure log(a1,a2,a3: jsvalue); overload;
       procedure log(a1,a2,a3,a4: jsvalue); overload;
       procedure log(a1,a2,a3,a4,a5: jsvalue); overload;

       procedure error(a1: jsvalue);overload;
       procedure error(a1,a2: jsvalue); overload;
       procedure error(a1,a2,a3: jsvalue); overload;
       procedure error(a1,a2,a3,a4: jsvalue); overload;
       procedure error(a1,a2,a3,a4,a5: jsvalue); overload;

       procedure warn(a1: jsvalue);overload;
       procedure warn(a1,a2: jsvalue); overload;
       procedure warn(a1,a2,a3: jsvalue); overload;
       procedure warn(a1,a2,a3,a4: jsvalue); overload;
       procedure warn(a1,a2,a3,a4,a5: jsvalue); overload;


      published

    end;
   var
     alog: tlogTest;
     console: tLogTest;
implementation


{ tLogTest }

constructor tLogTest.create;
begin

end;

procedure tLogTest.send(a1: jsvalue);
begin
  web.console.log(a1,a);
end;

procedure tLogTest.send(a1, a2: jsvalue);
begin
  web.console.log(a1,a2,a);
end;

procedure tLogTest.send(a1, a2, a3: jsvalue);
begin
  web.console.log(a1,a2,a3,a);
end;

procedure tLogTest.send(a1, a2, a3, a4: jsvalue);
begin
  web.console.log(a1,a2,a3,a4,a);
end;

procedure tLogTest.send(a1, a2, a3, a4, a5: jsvalue);
begin
   web.console.log(a1,a2,a3,a4,a5,a);
end;

procedure tLogTest.error(a1, a2, a3, a4, a5: jsvalue);
begin
    web.console.error(a1,a2,a3,a4,a5,a);
end;

procedure tLogTest.error(a1, a2, a3, a4: jsvalue);
begin
    web.console.error(a1,a2,a3,a4,a);
end;

procedure tLogTest.error(a1, a2, a3: jsvalue);
begin
    web.console.error(a1,a2,a3,a);
end;

procedure tLogTest.error(a1: jsvalue);
begin
    web.console.error(a1,a);
end;

procedure tLogTest.error(a1, a2: jsvalue);
begin
   web.console.error(a1,a2,a);
end;

procedure tLogTest.log(a1, a2: jsvalue);
begin
   web.console.log(a1,a2,a);
end;

procedure tLogTest.log(a1: jsvalue);
begin
  web.console.log(a1,a);
end;

procedure tLogTest.log(a1, a2, a3: jsvalue);
begin
  web.console.log(a1,a2,a3,a);
end;

procedure tLogTest.log(a1, a2, a3, a4, a5: jsvalue);
begin
   web.console.log(a1,a2,a3,a4,a5,a);
end;

procedure tLogTest.log(a1, a2, a3, a4: jsvalue);
begin
 web.console.log(a1,a2,a3,a4,a4,a);
end;





procedure tLogTest.warn(a1, a2, a3, a4: jsvalue);
begin

end;

procedure tLogTest.warn(a1, a2, a3, a4, a5: jsvalue);
begin

end;

procedure tLogTest.warn(a1, a2, a3: jsvalue);
begin

end;

procedure tLogTest.warn(a1, a2: jsvalue);
begin

end;

procedure tLogTest.warn(a1: jsvalue);
begin

end;

initialization
 alog:=tlogtest.create;
 console:=tLogTest.create;

end.
