unit x.controls.extra;

interface

uses
  classes, rtti, system.Generics.Collections, uiTypes,  x.cfg.classes, sysutils,   x.controls, x.appstyler,

{$IFDEF fwfmx}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts, FMX.skia, FMX.TMSFNCGraphicstypes,
  FMX.TMSFNCSplitter,
  FMX.TMSFNCTreeViewBase,
  FMX.TMSFNCTreeViewData, FMX.TMSFNCCustomTreeView, FMX.TMSFNCTreeView,
  FMX.TMSFNCObjectInspector, x.icons.all,  liblogtest;
{$ENDIF}
{$IFDEF fwVCL}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, windows, messages, scGPControls, scgpExtControls, vcl.skia, scControls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, x.icons.all,

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
  VCL.TMSFNCObjectInspector, liblogtest;
{$ENDIF}
{$IFDEF fwweb}
VCL.Controls, VCL.Forms, VCL.Graphics, VCL.Dialogs, VCL.StdCtrls, VCL.ExtCtrls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage,

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,  system.types,
  VCL.TMSFNCObjectInspector, x.icons.all, liblogtest;
{$ENDIF}


type

txHeader=class(txrectangle)
private
    Ficon: txicon;
    Ftext: string;
    FReqHeight: Integer;
    FImageMargin: integer;
    FIconSize: integer;
    fTextSize: integer;
    FTextFont: txFont;
    FIconPoint: tPoint;
    FBottomLine: boolean;
    FIconS: string;

    procedure setIcon(const Value: txicon);
    procedure SetText(const Value: string);
    function getREqHeight: Integer;
    procedure setIconSize(const Value: integer);
    procedure SetTextSize(const Value: integer);
    procedure SetIconPoint(const Value: tPoint);
    procedure SetTextFont(const Value: txFont);
    procedure SetBottomLine(const Value: boolean);
    procedure SetIconS(const Value: string);
public
 fImage: txImage;
 fLabel: txLabel;
 procedure doLayout;
 procedure CreateLabel;
 procedure CreateImage;
 constructor create(aOwner: tcomponent); overload; override;
 constructor Create(aparent: txWinControlClass; aSize: integer; atext: string; aIcon: txIcon=nil);    overload;
published
 property icon: txicon read Ficon write setIcon;
 property IconS: string read FIconS write SetIconS;
 property text: string read Ftext write SetText;
 property ImageMargin: integer read FImageMargin write FImageMargin;
 property IconSize: integer read FIconSize write setIconSize;
 property TextSize: integer read fTextSize write SetTextSize;
 property TextFont: txFont read FTextFont write SetTextFont;
 property IconPoint: tPoint read FIconPoint write SetIconPoint;
 property ReqHeight: Integer read getREqHeight;
 property BottomLine: boolean read FBottomLine write SetBottomLine;
end;


implementation

{ txHeader }

constructor txHeader.create(aOwner: tcomponent);
begin
  inherited;
  {$IFNDEF fwweb}   pad(3);{$ENDIF}


  ImageMargin:=4;
   textSize:=1;
 IconSize:=1;
 BottomLine:=false;
  Align:=AlignTop;
  margins.top:=6;

end;

constructor txHeader.create(aparent: txWinControlClass; aSize: integer;
  atext: string; aIcon: txIcon);
begin
 inherited create(aparent);
 parent:=aParent;
 IconSize:=aSize;
 TextSize:=aSize;
 if assigned(aicon) then icon:=aIcon;
 text:=aText;
 Align:=AlignTop;
   margins.top:=6;
end;

procedure txHeader.CreateImage;
begin
     if not assigned(fimage) then
     begin
     fimage:=txImage.create(self);
     fimage.parent:=self;
     fImage.width:=IconPoint.x;
     fimage.height:=IconPoint.y;
     {$IFDEF fwweb}
         fimage.fill.kind:=gfkNone;
         fimage.stroke.kind:=gskNone;
         {$ENDIF}
     end;

end;

procedure txHeader.CreateLabel;
begin
   if not assigned(fLabel) then
   begin
   fLabel:=txLabel.create(self);
  fLabel.parent:=self;
  {$IFNDEF fwweb} flabel.StyleElements:=[];  {$ENDIF}


  flabel.Font.name:=textFont.name;
    flabel.Font.size:=textFont.size;
    if textfont.style=[tFontStyle.fsbold] then flabel.bold:=true;

    //flabel.TextSettings.FontColor:=xStyler.xAppStyle.h1.Color;

      //flabel.TextSettings.font.Assign(AppStyle.h1);     //?
     flabel.AutoSize:=true;

   end;

end;

procedure txHeader.doLayout;
var
 x1: integer;
begin
 height:=reqHeight;
 x1:=Padding.left;
 if assigned(fimage) then
 begin
   SetPosX(fimage, Padding.left);
   SetPosy(fimage, trunc((height-fimage.height)/2));
   x1:=padding.left+fimage.width+ImageMargin;
 end;
 if assigned(fLabel) then
 begin
   setposx(flabel, x1);
    SetPosy(fLabel, trunc((height-flabel.height)/2));

 end;
end;

function txHeader.getREqHeight: Integer;
var
 y1,y2: integer;
begin
 if assigned(fLabel) then y1:=fLabel.Height else y1:=0;
 if assigned(fImage) then y2:=fImage.height else y2:=0;
 result:=y1+y2+padding.top+padding.bottom;
  alog.send('ReqHiehgt', result);

end;

procedure txHeader.SetBottomLine(const Value: boolean);
begin
  FBottomLine := Value;
  if value=true then
  begin
     xborder.sides:=[txside.Bottom];
  stroke.Thickness:=1;
  Stroke.Color:=gcgray;
  stroke.kind:=txBrushKind.Solid;
  end else
  begin
     xborder.sides:=[];
  end;
end;

procedure txHeader.setIcon(const Value: txicon);
begin
  CreateImage;
  fImage.icon:=value;
  Ficon := Value;
  doLayout;
end;

procedure txHeader.SetIconPoint(const Value: tPoint);
begin
  FIconPoint := Value;
end;

procedure txHeader.SetIconS(const Value: string);
begin
  FIconS := Value;
  Icon:=xlIcons.geticon(value);
  alog.send('Icon', value);
 // alog.send(fIcon);
end;

procedure txHeader.setIconSize(const Value: integer);
begin
  FIconSize := Value;
  if value=1 then IconPoint:=AppStyle.I1;
  if value=2 then IconPoint:=AppStyle.I2;
  if value=3 then IconPoint:=AppStyle.I3;
  if value=4 then IconPoint:=AppStyle.I4;



end;

procedure txHeader.SetText(const Value: string);
begin
  CreateLabel;
  fLabel.Caption:=value;
  Ftext := Value;
  doLayout;

end;

procedure txHeader.SetTextFont(const Value: txFont);
begin
  FTextFont := Value;
   Font.Assign(Value);  ///??

end;

procedure txHeader.SetTextSize(const Value: integer);
begin
  fTextSize := Value;
   if value=1 then TextFont:=AppStyle.h1;
  if value=2 then TextFont:=AppStyle.h2;
  if value=3 then TextFont:=AppStyle.h3;
  if value=4 then TextFont:=AppStyle.h4;

end;

end.
