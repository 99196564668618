unit x.css;

interface

uses web, js, classes, vcl.TMSFNCUtils, weblib.controls, vcl.controls,
  system.Generics.Collections, weblib.CSS, weblib.WebCtrls;


{$REGION 'CSS Props'}
//Aligns items in a flex container along flex lines.
const calign_content='align-content';
//Aligns evenly spaced items in a flex container.
const calign_items='align-items';
//Aligns an item inside a flex container.
const calign_self='align-self';
//Resets all element properties to its default or inherited values.
const call='all';
//Creates an animating element.
const canimation='animation';
//Sets a delay before an animation begins.
const canimation_delay='animation-delay';
//Sets how, in which direction, an animation is played.
const canimation_direction='animation-direction';
//Defines the duration of an animation cycle.
const canimation_duration='animation-duration';
//Defines how styles are applied before and after animation.
const canimation_fill_mode='animation-fill-mode';
//Sets the number of times an animation is played.
const canimation_iteration_count='animation-iteration-count';
//Defines a name for the animation.
const canimation_name='animation-name';
//Sets the animation play state to running or paused.
const canimation_play_state='animation-play-state';
//Specifies the animation speed curve.
const canimation_timing_function='animation-timing-function';
//Shows or hides the backface visibility of an element.
const cbackface_visibility='backface-visibility';
//Sets the background of an element.
const cbackground='background';
//Defines how the background is attached to an element.
const cbackground_attachment='background-attachment';
//Defines the background layer blending mode.
const cbackground_blend_mode='background-blend-mode';
//Defines how background extends beyond the element.
const cbackground_clip='background-clip';
//Sets the background color of the element.
const cbackground_color='background-color';
//Specifies a background image for an element.
const cbackground_image='background-image';
//Specifies the background image origin position.
const cbackground_origin='background-origin';
//Sets the position of a background image.
const cbackground_position='background-position';
//Specifies how the background image is repeated.
const cbackground_repeat='background-repeat';
//Sets the size of the background image.
const cbackground_size='background-size';
//Specifies a border for an element
const cborder='border';
//Specifies a bottom border for an element.
const cborder_bottom='border-bottom';
//Sets the color of a bottom border .
const cborder_bottom_color='border-bottom-color';
//Sets the border radius of the bottom left corner.
const cborder_bottom_left_radius='border-bottom-left-radius';
//Sets the border radius of the bottom right corner
const cborder_bottom_right_radius='border-bottom-right-radius';
//Sets the style of the bottom border.
const cborder_bottom_style='border-bottom-style';
//Sets the width of the bottom border
const cborder_bottom_width='border-bottom-width';
//Sets table borders to single collapsed line or separated.
const cborder_collapse='border-collapse';
//Sets the color of the border.
const cborder_color='border-color';
//Defines an image as border, instead of a color.
const cborder_image='border-image';
//Sets how far a border image extends beyond the border.
const cborder_image_outset='border-image-outset';
//Defines if and how the border image is repeated.
const cborder_image_repeat='border-image-repeat';
//Defines how the border image will be sliced.
const cborder_image_slice='border-image-slice';
//Specifies the url of the border image file.
const cborder_image_source='border-image-source';
//Sets the width of the image border.
const cborder_image_width='border-image-width';
//Sets the left border of the element.
const cborder_left='border-left';
//Sets the color of the left border.
const cborder_left_color='border-left-color';
//Sets the style of the left border.
const cborder_left_style='border-left-style';
//Sets the width of the left border.
const cborder_left_width='border-left-width';
//Sets the radius of the border.
const cborder_radius='border-radius';
//Sets the right border of the element.
const cborder_right='border-right';
//Sets the color of the right border.
const cborder_right_color='border-right-color';
//Sets the style of the right border.
const cborder_right_style='border-right-style';
//Sets the width of the right border.
const cborder_right_width='border-right-width';
//Sets the adjacent table cell distance.
const cborder_spacing='border-spacing';
//Defines the style of the border
const cborder_style='border-style';
//Sets the top border of the element.
const cborder_top='border-top';
//Sets the color of the top border.
const cborder_top_color='border-top-color';
//Sets the border radius of the top left corner.
const cborder_top_left_radius='border-top-left-radius';
//Sets the border radius of the top right corner.
const cborder_top_right_radius='border-top-right-radius';
//Sets the style of the top border.
const cborder_top_style='border-top-style';
//Sets the width of the top border.
const cborder_top_width='border-top-width';
//Sets the border width of the element.
const cborder_width='border-width';
//Positions the element from the bottom of the relative container.
const cbottom='bottom';
//Adds a shadow effect to an element.
const cbox_shadow='box-shadow';
//Sets how element height and width are calculated.
const cbox_sizing='box-sizing';
//Defines on which side of the table a caption is placed.
const ccaption_side='caption-side';
//Sets the color of the blinking mouse caret.
const ccaret_color='caret-color';
//Specifies the character encoding of the stylesheet.
const ccharset='@charset';
//Sets the element side that does not allow floating elements.
const cclear='clear';
//Sets how an image is cropped or clipped inside a container.
const cclip='clip';
//Clips an element inside a specific shape or SVG.
const cclip_path='clip-path';
//Specifies the color of text in an element.
const ccolor='color';
//Divides an element into the specified number of columns.
const ccolumn_count='column-count';
//Specifies how divided columns are filled.
const ccolumn_fill='column-fill';
//Specifies the space between divided columns.
const ccolumn_gap='column-gap';
//Sets the style, width, and color of a column divider.
const ccolumn_rule='column-rule';
//Sets the color of a column divider.
const ccolumn_rule_color='column-rule-color';
//Sets the style of a column divider.
const ccolumn_rule_style='column-rule-style';
//Sets the width of a column divider.
const ccolumn_rule_width='column-rule-width';
//Sets number of divided columns an element should span.
const ccolumn_span='column-span';
//Specifies the width of a divided column.
const ccolumn_width='column-width';
//Divide an element into columns of a certain width.
const ccolumns='columns';
//Used to insert content before or after an element.
const ccontent='content';
//Increase or decrease a CSS counter.
const ccounter_increment='counter-increment';
//Initialize or reset CSS counter.
const ccounter_reset='counter-reset';
//Specifies the shape of the mouse cursor.
const ccursor='cursor';
//Specifies the text writing direction of a block-level element.
const cdirection='direction';
//Specify an element's display behavior.
const cdisplay='display';
//Specifies whether empty table cell borders will be displayed.
const cempty_cells='empty-cells';
//Adds an image enhancing effect to an image.
const cfilter='filter';
//Specifies the width of the flexible items.
const cflex='flex';
//Specifies the initial width of a flex item.
const cflex_basis='flex-basis';
//Specifies the direction for the flex item to align.
const cflex_direction='flex-direction';
//Controls the direction and wrapping of flexible items.
const cflex_flow='flex-flow';
//Specifies how a flex item can grow inside the container.
const cflex_grow='flex-grow';
//Specifies how a flex item can shrink inside the container.
const cflex_shrink='flex-shrink';
//Specifies how flexible items wrap inside the container.
const cflex_wrap='flex-wrap';
//Sets how an element is positioned relative to other elements.
const cfloat='float';
//Sets font family, variant, weight, height, and size for an element.
const cfont='font';
//Embeds a custom font inside a web page
const cfont_face='@font-face';
//Sets the font family for an element.
const cfont_family='font-family';
//Sets the spacing between the font's characters.
const cfont_kerning='font-kerning';
//Sets the size of the font for an element.
const cfont_size='font-size';
//Specifies a fall-back font size.
const cfont_size_adjust='font-size-adjust';
//Sets the text characters to a wider or narrower variant.
const cfont_stretch='font-stretch';
//Set the font style to normal, italic, or oblique.
const cfont_style='font-style';
//Specifies that text is displayed in a small-caps font.
const cfont_variant='font-variant';
//Sets the weight or thickness of the font.
const cfont_weight='font-weight';
//Defines a grid layout with responsive rows and columns.
const cgrid='grid';
//Sets the size and location of grid items in a grid container.
const cgrid_area='grid-area';
//Specifies the size of the columns in a grid container.
const cgrid_auto_columns='grid-auto-columns';
//Specifies the initial placement of items in a grid container.
const cgrid_auto_flow='grid-auto-flow';
//Specifies the initial size of the items in a grid container.
const cgrid_auto_rows='grid-auto-rows';
//Specifies the size and location of a grid item in a grid container.
const cgrid_column='grid-column';
//Specifies in which column-line the grid item will end.
const cgrid_column_end='grid-column-end';
//Specifies the gap size between columns in a grid container.
const cgrid_column_gap='grid-column-gap';
//Specifies in which column line the grid item will start.
const cgrid_column_start='grid-column-start';
//Specifies the gap size between grid rows and columns.
const cgrid_gap='grid-gap';
//Specifies the grid item size and location in a grid container.
const cgrid_row='grid-row';
//Specifies in which row-line the grid item will end.
const cgrid_row_end='grid-row-end';
//Specifies the gap size between rows in a grid container.
const cgrid_row_gap='grid-row-gap';
//Specifies in which row line the grid item will start
const cgrid_row_start='grid-row-start';
//Divides a page into sections with a size, position, and layer.
const cgrid_template='grid-template';
//Specifies area in a grid container.
const cgrid_template_areas='grid-template-areas';
//Sets the number and width of columns in a grid container.
const cgrid_template_columns='grid-template-columns';
//Sets the number and height of rows in a grid container.
const cgrid_template_rows='grid-template-rows';
//Sets the height of an element.
const cheight='height';
//Specifies hyphenation with wrap opportunities in a line of text.
const chyphens='hyphens';
//Imports a style sheet inside another style sheet.
const cimport='@import';
//Defines the alignment of items in a flex container.
const cjustify_content='justify-content';
//Defines the CSS style to animate.
const ckeyframes='@keyframes';
//Positions the element from the left of the relative container.
const cleft='left';
//Sets the spacing between characters.
const cletter_spacing='letter-spacing';
//Sets the vertical spacing between lines of text.
const cline_height='line-height';
//Defines the markers (bullet points) for items in a list.
const clist_style='list-style';
//Defines an image markers (bullet points) for items in a list.
const clist_style_image='list-style-image';
//Sets the marker (bullet point) positions for items in a list
const clist_style_position='list-style-position';
//Defines the marker types (bullet points) for items in a list
const clist_style_type='list-style-type';
//Sets the margin (outside spacing) for an element.
const cmargin='margin';
//Sets the bottom margin (outside spacing) for an element.
const cmargin_bottom='margin-bottom';
//Sets the left margin (outside spacing) for an element.
const cmargin_left='margin-left';
//Sets the right margin (outside spacing) for an element.
const cmargin_right='margin-right';
//Sets the top margin (outside spacing) for an element.
const cmargin_top='margin-top';
//Sets the maximumn height for an element.
const cmax_height='max-height';
//Sets the maximum width for an element.
const cmax_width='max-width';
//Applies media queries to a page.
const cmedia='@media';
//Sets the minimum height for an element.
const cmin_height='min-height';
//Sets the minimum width for an element.
const cmin_width='min-width';
//Specifies how an image or video fits inside a container.
const cobject_fit='object-fit';
//Specifies the image or video position inside a container.
const cobject_position='object-position';
//Sets the opacity (transparency) of the element.
const copacity='opacity';
//Specifies the order of an item in a flex container.
const corder='order';
//Adds an outline (highlighted border) to an element.
const coutline='outline';
//Sets the color of an outline.
const coutline_color='outline-color';
//Sets the space between the outline and border.
const coutline_offset='outline-offset';
//Sets the style of an outline.
const coutline_style='outline-style';
//Sets the width of an outline.
const coutline_width='outline-width';
//Specifies the flow of content that exceeds the container.
const coverflow='overflow';
//Specifies the flow of content that exceeds the container width.
const coverflow_x='overflow-x';
//Specifies the flow of content that exceeds the container height.
const coverflow_y='overflow-y';
//Sets the spacing between content and element border.
const cpadding='padding';
//Sets the spacing between content and bottom element border.
const cpadding_bottom='padding-bottom';
//Sets the spacing between content and left element border.
const cpadding_left='padding-left';
//Sets the spacing between content and right element border.
const cpadding_right='padding-right';
//Sets the spacing between content and top element border.
const cpadding_top='padding-top';
//Adds a print page-break after an element.
const cpage_break_after='page-break-after';
//Adds a print page-break before an element.
const cpage_break_before='page-break-before';
//Specifies if print page-break is allowed inside an element.
const cpage_break_inside='page-break-inside';
//Adds perspective to a 3D-positioned element.
const cperspective='perspective';
//Sets the origin of the perspective for a 3D-positioned element.
const cperspective_origin='perspective-origin';
//Specifies whether element reacts to pointer events or not.
const cpointer_events='pointer-events';
//Sets the element's positioning method.
const cposition='position';
//Defines the quotation marks to be used on text.
const cquotes='quotes';
//Positions the element from the right of the relative container.
const cright='right';
//Specifies the scrolling behavior of an element
const cscroll_behavior='scroll-behavior';
//Aligns elements according to a table with rows and columns.
const ctable_layout='table-layout';
//Sets the alignment of text inside an element.
const ctext_align='text-align';
//Sets the alignment for the last line of text.
const ctext_align_last='text-align-last';
//Defines the style and color of underlined text.
const ctext_decoration='text-decoration';
//Defines the color of underlined text.
const ctext_decoration_color='text-decoration-color';
//Defines the kind of line to use with text.
const ctext_decoration_line='text-decoration-line';
//Defines the style of underlined text.
const ctext_decoration_style='text-decoration-style';
//Sets the indentation to the beginning of text.
const ctext_indent='text-indent';
//Defines the text justification inside a container.
const ctext_justify='text-justify';
//Sets the display behavior of text that overflows a container.
const ctext_overflow='text-overflow';
//Adds a shadow effect to text.
const ctext_shadow='text-shadow';
//Defines text capitalization or casing.
const ctext_transform='text-transform';
//Positions the element from the top of the relative container
const ctop='top';
//Applies a 2D or 3D transformation to an element.
const ctransform='transform';
//Sets the origin for the transformation of the element.
const ctransform_origin='transform-origin';
//Specifies the display behavior of 3D space nested elements.
const ctransform_style='transform-style';
//Creates transitions from one property value to another.
const ctransition='transition';
//Creates a delay before the transition effect starts.
const ctransition_delay='transition-delay';
//Specifies the time the transition will take.
const ctransition_duration='transition-duration';
//Specifies the CSS property that will transition.
const ctransition_property='transition-property';
//Defines the speed curve function of the transition.
const ctransition_timing_function='transition-timing-function';
//Specifies how text can be selected (highlighted)
const cuser_select='user-select';
//Specifies vertical alignment of an element.
const cvertical_align='vertical-align';
//Specifies the visibility of an element.
const cvisibility='visibility';
//Specifies how white-space is handled inside an element.
const cwhite_space='white-space';
//Sets the width of an element.
const cwidth='width';
//Specifies how line breaks take place.
const cword_break='word-break';
//Sets the spacing between words.
const cword_spacing='word-spacing';
//Specifies how long words can be wrapped.
const cword_wrap='word-wrap';
//Sets the text reading orientation: top to bottom, etc.
const cwriting_mode='writing-mode';
//Sets the vertical stacking order relative to other elements.
const cz_index='z-index';
{$ENDREGION}


type
   txCSSBase = class
  private
    FmyType: string;
    FmyCSS: tStringList;
    FLoaded: boolean;
    function GetMyCSS: tStringList;
  public

    function get(aclass: string): TJSCSSStyleDeclaration;
    function ln(aclass: string): string;
    function fn(aln: string): string;
    property myType: string read FmyType write FmyType;
    property myCSS: tStringList read GetMyCSS write FmyCSS;
    property Loaded: boolean read FLoaded write FLoaded;
    procedure LoadCSS; virtual;
    procedure logCSS;



  end;

type
  txCSS = class
  private
    Fstyles: tStringList;
  public
   tempDiv: Thtmldiv;
    procedure ecn(ac: tcontrol);

    //Adds /removes classes to controls
    procedure xa(ac: tcontrol; css: array of string; Append: boolean = true); overload;
    procedure xa(ac: tcontrol; css: String; Append: boolean = true); overload;

    procedure xar(ac: tcontrol; css: array of string; StartsWith: string; Append: boolean = true); overload;
    procedure xar(ac: tcontrol; css: String; StartsWith: string; Append: boolean = true); overload;

    procedure xr(ac: tcontrol; StartsWith: string); overload;
     //Adds /removes classes to element handles
     procedure xa(eh: TJSHTMLElement; css: array of string; Append: boolean = true); overload;
    procedure xa(eh: TJSHTMLElement; css: String; Append: boolean = true); overload;

    procedure xar(eh: TJSHTMLElement; css: array of string; StartsWith: string; Append: boolean = true); overload;
    procedure xar(eh: TJSHTMLElement; css: String; StartsWith: string; Append: boolean = true); overload;

    procedure xr(eh: TJSHTMLElement; StartsWith: string); overload;
    //Utils for adding commonly parented controls
    procedure SetClasses(aroot: tcontrol; PreName: string; ControlClass: string;
      css: array of string; Append: boolean = true); overload;
    procedure SetClasses(aroot: tcontrol; PreName: string; css: array of string;
      Append: boolean = true); overload;

    procedure SetClasses(aroot: tcontrol; PreName: string; ControlClass: string;
      css: string; Append: boolean = true); overload;
    procedure SetClasses(aroot: tcontrol; PreName: string; css: string;
      Append: boolean = true); overload;
    //Creates classes
    function NewClass(aname: string=''): TJSCSSStyleDeclaration;
    function CopyClass(acopyFrom: string; aname: string=''; append: boolean=false): TJSCSSStyleDeclaration;
     function NextClassName: string;
     function CheckName(s: string): string;
     //Gets a class
      function get(aclass: string): TJSCSSStyleDeclaration;

    //Utils
    [Async] procedure AddInstanceStyle(const id: string; const css: string); async;
    function sw(StartsWith, astyle: string): boolean;
    constructor create;
    procedure getClasses;
    function removeClass(e: tjsElement; StartsWith: string): integer;
    function RemoveAll(e: tjsElement): integer; overload;
    function RemoveAll(aclass: TJSCSSStyleDeclaration): integer; overload;
    function GetStyle(astyle: string): TJSCSSStyleDeclaration;
    function GetStyles(StartsWith: string): tStringList;
    procedure logCSS;

  published
    property styles: tStringList read Fstyles write Fstyles;
  end;

var
  xCSS: txCSS;

implementation

uses sysutils;
{ txCSS }


procedure txCSS.AddInstanceStyle(const id: string; const css: string);
begin
{$IFNDEF WIN64}
  {$IFDEF PAS2JS}
  asm
    function writeStylesOnce(styleName, cssText) {
        var styleElement = document.getElementById(styleName);
        if (styleElement) {
          styleElement.innerHTML = cssText;
          return;
          }
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = styleName;
        styleElement.innerHTML = cssText;
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    writeStylesOnce(id,css);
  end;
  {$ENDIF}
  {$ENDIF}
end;


function txCSS.CheckName(s: string): string;
begin
 if pos('.', s)=0 then result:='.' + s else result:=s;

end;

function txCSS.CopyClass(acopyFrom, aname: string; append: boolean=false): TJSCSSStyleDeclaration;
var
 cn, ccn: string;
 nClass, CopyClass: TJSCSSStyleDeclaration;
 nClassName, CopyClassName: string;
 valBefore: string;
 i: integer;
 val, Prop : string;
begin
 CopyClassName:=checkName(aCopyFrom);
 CopyClass:=GetStyle(CopyClassName);
 if not assigned(copyClass) then exit;

 nClassName:=CheckName(aName);
 nClass:=GetStyle(nClassName);
 if not assigned(nClass) then nclass:=NewClass(nClassName);



  if append=false then RemoveAll(nclass);

  for i:=1 to copyClass.length do
  begin
     prop:=copyClass.item(i-1);
     val:=copyClass.getPropertyValue(prop);
     nClass.setProperty(prop,val);
  end;
  Result:=nClass;
end;

constructor txCSS.create;
begin
  styles := tStringList.create;
  getClasses;
end;

procedure txCSS.ecn(ac: tcontrol);
begin
  if ac.elementclassname = '' then

    ac.elementclassname := 'xxx';
end;

function txCSS.get(aclass: string): TJSCSSStyleDeclaration;
var
  idx: integer;
  s: string;
begin
  result := nil;
  s := aclass;
  if pos('.', s) = 0 then s:='.' + s;


  idx := Styles.IndexOf(s);
  if idx <> -1 then

  begin
    result := TJSCSSStyleDeclaration(Styles.Objects[idx]);
  end;
end;

procedure txCSS.getClasses;
  procedure doRule(arule: TJSCSSRule);
  var
    cssSheet: TJSCSSStyleSheet;
    ni: integer;
  begin
    console.log('RULE', arule.cssText);
    cssSheet := TJSCSSStyleSheet(arule);
    for ni := 1 to cssSheet.cssRules.length do
    begin
      doRule(cssSheet.cssRules.item(ni - 1));
    end;
  end;
  procedure doitem(aItem: tjsobject);
  var

    ni: integer;
    rules: TJSCSSRuleList;
    rule: TJSCSSRule;
    sheet: TJSCSSStyleSheet;
    sd: TJSCSSStyleDeclaration;
    cssText, selectorText: string;
    idx: integer;
  begin
    // console.log('CSS ITEM');
    // console.log('Item', aitem);

    idx := -1;
    if isUndefined(aItem.properties['selectorText']) = false then
    begin
      selectorText := String(aItem.properties['selectorText']);
     //  console.log(aitem.properties['selectorText'], aitem);
     // if selectorText<>'' then

     // if pos('.x', selectorText) = 1 then

        idx := styles.Add(selectorText);
    end;


      if isUndefined(aItem.properties['cssText']) = false then
      begin
        cssText := String(aItem.properties['cssText']);
        // console.log(aitem.properties['cssText'], aitem);
      end;

      if isUndefined(aItem.properties['style']) = false then
      begin

        sd := TJSCSSStyleDeclaration(aItem.properties['style']);
        if idx <> -1 then
          styles.Objects[idx] := tobject(sd);

      end;


       try
      if isUndefined(aItem.properties['cssRules']) = false then
      begin
        // console.log('HAS cssRules', aitem.properties['cssRules']);

        rules := TJSCSSRuleList(aItem.properties['cssRules']);
        for ni := 1 to rules.length do
        begin
          doitem(rules.item(ni - 1));
        end;
      end;
       except
        on e: exception do
        begin

        end else begin end;

       end;


   try
    if isUndefined(aItem.properties['styleSheet']) = false then
    begin
      // console.log('HAS styleSheet');
      sheet := TJSCSSStyleSheet(aItem.properties['styleSheet']);
     if sheet.cssRules<>nil then
     begin
      for ni := 1 to sheet.cssRules.length do
      begin
        doitem(sheet.cssRules.item(ni - 1));
      end;
     end;
    end;
   except
    on e: exception do
    begin

    end   else begin end;

   end;

  end;

var
  // aSheet, asheet2: TJSCSSStyleSheet;

  i, ni: integer;
  item: tjsobject;
begin

  // alist := tstringlist.create;
  for i := 1 to document.styleSheets.length do
  begin
    item := document.styleSheets[i - 1];

    doitem(item);
         console.log(item);
    { asheet:=TJSCSSStyleSheet(document.styleSheets[i-1]);
      console.log(asheet.title, asheet);
      for ni:=1 to asheet.cssrules.length do
      begin
      arule:=asheet.cssrules[ni-1];
      asheet.cssRules.
      asheet2:=TJSCSSStyleSheet(arule);
      console.log(asheet2);
      end;
      end; }
  end;

end;

function txCSS.GetStyle(astyle: string): TJSCSSStyleDeclaration;
var
  idx: integer;
begin
  idx := styles.IndexOf(astyle);
  if idx <> -1 then
  begin
    result := TJSCSSStyleDeclaration(styles.Objects[idx]);
  end
  else
    result := nil;

end;

function txCSS.GetStyles(StartsWith: string): tStringList;
var
  i: integer;
begin
  result := tStringList.create;
  result.CaseSensitive := false;
  for i := 1 to styles.Count do
  begin
    if sw(StartsWith, styles[i - 1]) = true then
    begin
      result.AddObject(styles[i - 1], styles.Objects[i - 1]);
    end;
  end;
end;

procedure txCSS.logCSS;
var
  i: integer;
begin
  for i := 1 to styles.Count do
    begin
    //if pos('xslide', lowercase(styles[i-1]))<>0 then

    console.log(styles[i - 1], styles.Objects[i - 1]);
    end;
end;

function txCSS.NewClass(aname: string): TJSCSSStyleDeclaration;
var
 nc: TCSSclass;
 n: string;
begin
 nc:=TcssClass.Create(nil);
 n:=aname;
 if n='' then n:=NextClassName;
   nc.cssClassName:=n;
   nc.UpdateCSS;
   removeall(tempDiv.elementHandle);
   tempdiv.ElementClassName:=n;
   result:=tempdiv.ElementHandle.style;

   Styles.AddObject(n, tobject(tempdiv.ElementHandle.style));
end;

function txCSS.NextClassName: string;
begin
  result:='GeneratedClass' + Inttostr(styles.Count);
end;

function txCSS.RemoveAll(aclass: TJSCSSStyleDeclaration): integer;
begin
  while aclass.length <> 0 do
    aclass.removeProperty(aclass.item(0));
end;

function txCSS.RemoveAll(e: tjsElement): integer;
begin
  while e.classList.length <> 0 do
    e.classList.remove(e.classList.item(0));
end;

function txCSS.removeClass(e: tjsElement; StartsWith: string): integer;
  function FindFirstClass: integer;
  var
    i: integer;
    s: string;
  begin
    result := -1;
    for i := 1 to e.classList.length do
    begin
      s := lowercase(e.classList.item(i - 1));
      if pos(lowercase(StartsWith), s) = 1 then
      begin
        result := i - 1;
        exit;
      end;
    end;

  end;

var
  found: integer;
begin
  result := 0;
  if StartsWith='' then exit;

  found := FindFirstClass;

  while found <> -1 do
  begin
    e.classList.remove(e.classList.item(found));
    result := result + 1;
    found := FindFirstClass;
  end;

end;

function txCSS.sw(StartsWith, astyle: string): boolean;
begin
  result := pos(lowercase(StartsWith), lowercase(astyle)) = 1;
end;

procedure txCSS.xa(ac: tcontrol; css: array of string; Append: boolean);
var
  i: integer;
  s: string;
begin
  ecn(ac);
  if Append = false then
    RemoveAll(ac.elementHandle);
  for i := 1 to length(css) do
  begin
    s := css[i - 1];
    // if ac.eh.classList.contains(s)=false then
    ac.elementHandle.classList.Add(s);

  end;

end;

procedure txCSS.xa(ac: tcontrol; css: String; Append: boolean);
var
  a: array of string;
begin
  setLength(a, 1);
  a[0] := css;
  xa(ac, a, Append);

end;

procedure txCSS.xar(ac: tcontrol; css: String; StartsWith: string;
  Append: boolean);
begin
  xr(ac, StartsWith);
  xa(ac, css, Append);
end;

procedure txCSS.xar(ac: tcontrol; css: array of string; StartsWith: string;
  Append: boolean);
begin
  xr(ac, StartsWith);
  xa(ac, css, Append);
end;

procedure txCSS.xr(ac: tcontrol; StartsWith: string);
begin
  removeClass(ac.elementHandle, StartsWith);
end;

procedure txCSS.xa(eh: TJSHTMLElement; css: array of string; Append: boolean);
var
  i: integer;
  s: string;
begin
 // ecn(ac);
  if Append = false then
    RemoveAll(eh);
  for i := 1 to length(css) do
  begin
    s := css[i - 1];
    // if ac.eh.classList.contains(s)=false then
    eh.classList.Add(s);

  end;

end;

procedure txCSS.xa(eh: TJSHTMLElement; css: String; Append: boolean);
var
  a: array of string;
begin
  setLength(a, 1);
  a[0] := css;
  xa(eh, a, Append);

end;

procedure txCSS.xar(eh: TJSHTMLElement; css: array of string; StartsWith: string; Append: boolean);
begin
  xr(eh, StartsWith);
  xa(eh, css, Append);
end;

procedure txCSS.xar(eh: TJSHTMLElement; css, StartsWith: string; Append: boolean);
begin
  xr(eh, StartsWith);
  xa(eh, css, Append);
end;

procedure txCSS.xr(eh: TJSHTMLElement; StartsWith: string);
begin
  removeClass(eh, StartsWith);
end;

{ txCSSBase }

function txCSSBase.fn(aln: string): string;
begin
  if pos('.', aln) = 1 then
  begin
    result := aln;
    exit;
  end;
  if pos(myType + '-', aln) <> 1 then

    result := myType + '-' + aln
  else
    result := aln;
end;

function txCSSBase.get(aclass: string): TJSCSSStyleDeclaration;
var
  idx: integer;
  s: string;
begin
  result := nil;
  s := aclass;
  if pos('.', s) = 0 then
    s := myType + '-' + s;

  idx := myCSS.IndexOf(s);
  if idx <> -1 then

  begin
    result := TJSCSSStyleDeclaration(myCSS.Objects[idx]);
  end;
end;

function txCSSBase.GetMyCSS: tStringList;
begin
  if assigned(FmyCSS) then
    result := FmyCSS
  else
  begin
    LoadCSS;
    result := FmyCSS;
  end;
end;

function txCSSBase.ln(aclass: string): string;
begin
  result := stringreplace(aclass, myType + '-', '', [rfIgnoreCase]);
end;

procedure txCSSBase.LoadCSS;
begin
  myCSS := xCSS.GetStyles(myType);
  Loaded := true;
end;

procedure txCSSBase.logCSS;
var
  i: integer;
begin
  console.log('xCSS classes: ' + myType + ' (' + inttostr(myCSS.Count) + ')');
  for i := 1 to myCSS.Count do

    console.log(myCSS[i - 1], myCSS.Objects[i - 1]);
end;

procedure txCSS.SetClasses(aroot: tcontrol; PreName, css: string;
  Append: boolean);

begin

  SetClasses(aroot, PreName, '', css, Append);
end;

procedure txCSS.SetClasses(aroot: tcontrol; PreName, ControlClass, css: string;
  Append: boolean);
var
  a: array of string;
begin
  setLength(a, 1);
  a[0] := css;
  SetClasses(aroot, PreName, ControlClass, a, Append);
end;

procedure txCSS.SetClasses(aroot: tcontrol; PreName: string;
  css: array of string; Append: boolean);
begin
  SetClasses(aroot, PreName, '', css, Append);
end;

procedure txCSS.SetClasses(aroot: tcontrol; PreName, ControlClass: string;
  css: array of string; Append: boolean);
  procedure SetControl(ac: tcontrol);
  var
    e: string;
    i: integer;
    cn: string;
  begin
    if Append then
      e := ac.elementclassname
    else
      e := '';

    for i := 1 to length(css) do
    begin
      cn := css[i - 1];
      if e <> '' then
        e := e + ' ';
      e := e + cn;
    end;
    ac.elementclassname := e;
    // console.log('SET ' + ac.Name, e);
  end;
  function doesMatch(ac: tcontrol): boolean;
  var
    sname, sClass: string;
    r: boolean;

  begin
    result := false;
    sname := lowercase(ac.Name);
    sClass := lowercase(ac.ClassName);
    if PreName <> '' then
    begin
      if pos(lowercase(PreName), sname) <> 1 then
        exit;
    end;
    if ControlClass <> '' then
    begin
      // console.log(ControlClass, sclass);
      if lowercase(ControlClass) <> lowercase(sClass) then
        exit;
    end;
    result := true;

  end;
  procedure DoControl(ac: tcontrol);
  var
    i: integer;
    aac: tcontrol;
    e: string;
  begin
    if doesMatch(ac) then
      SetControl(ac);
    for i := 1 to ac.controlCount do
    begin
      aac := ac.controls[i - 1];
      if aac <> nil then
      begin
        DoControl(aac);
      end;
    end;
  end;

begin
  DoControl(aroot);
end;

initialization

xCSS := txCSS.create;

end.
