unit x.fs;

interface

uses
{$IFNDEF fwweb}XData.Service.Common, {$ELSE} JS, {$ENDIF} x.fs.types, sysutils, classes;

type

  [ServiceContract]
  iFS = interface(IInvokable)
    ['{22C97C55-A79D-444B-9DC0-4A7C349EC5B8}']
    // [HttpPost] function GetFile( afile : string ) : tbsfile;
    [ASync]
    [HttpPost]
    function GetFiles(apath: string; amask: string; recurse: boolean): tbsfilelist; async;
    // [HttpPost] function RenameFile( afile, aNewFile : string ) : tbsfile;
    // [HttpPost] function DeleteFile( afile : string ) : boolean;
    [ASync]
    [HttpPost]
    function CreateTextFile(afile: string; acontent: string): tbsfile; async;




    // [HttpPost] function CreateDir( apath : string ) : tbsfile;
    // [HttpPost] function DownloadFile( afile : string ) : tstream;

{$IFNDEF fwweb}
    [ASync]
    [HttpPost]
    function UploadfileAUtoName(astream: tStream; [FromQuery] aExt: string): tbsFilePath;
{$ENDIF}
    [ASync]
    [HttpPost]
    function GetFolders(apath: string; recurse: boolean): tbsfilelist; async;
    [ASync]
    [HttpGet]
    [ASync]
    function GetServerDir: string; async;


    // new
    // Server General

    [ASync]
    function GetPathMap: string; async;
    [ASync]
    function ToURL(apath: string): string; async;
    [ASync]
    function ToPath(aURL: string): string; async;

    // url
    [ASync]
    [HttpPost]
    function UploadURLText(aURL: string; acontent: string): boolean; async;
{$IFNDEF fwweb}
    [ASync]
    [HttpPost]
    function UploadURL(astream: tStream; [FromQuery] aURL: string): boolean;
{$ENDIF}
    [ASync]
    [HttpPost]
    function DownloadURL(aURL: string): tStream; async;
    [ASync]
    [HttpPost]
    function DownloadURLText(aURL: string): string; async;
    [ASync]
    [HttpPost]
    function renameURL(aURL, aNewURL: string): tbsfile; async;
    [ASync]
    [HttpPost]
    function DeleteURL(aURL: string): boolean; async;
    [ASync]
    [HttpPost]
    function GetURL(aURL: string): tbsfile; async;
    [ASync]
    [HttpPost]
    function getURLDir(aURL: string; amask: string; recurse: boolean): tbsfilelist; async;
    [ASync]
    [HttpPost]
    function Createurl(aURL: string): tbsfile; async;

    // file
    [ASync]
    [HttpPost]
    function UploadFileText(apath: string; acontent: string): boolean; async;
{$IFNDEF fwweb}
    [ASync]
    [HttpPost]
    function Uploadfile(astream: tStream; [FromQuery] afile: string): boolean;
{$ELSE}

    [ASync]
    [HttpPost]
    function Uploadfile(astream: tStream;  afile: string): boolean; async;
{$ENDIF}

    [ASync]
    [HttpPost]
    function DownloadFile(apath: string): tStream; async;
    [ASync]
    [HttpPost]
    function DownloadFileText(apath: string): string; async;
    [ASync]
    [HttpPost]
    function renameFile(apath, aNewPath: string): tbsfile; async;
    [ASync]
    [HttpPost]
    function DeleteFile(apath: string): boolean; async;
    [ASync]
    [HttpPost]
    function GetFile(apath: string): tbsfile; async;
    [ASync]
    [HttpPost]
    function getFileDir(apath: string; amask: string; recurse: boolean): tbsfilelist; async;
    [ASync]
    [HttpPost]
    function CreateDir(apath: string): tbsfile; async;
    [ASync]
    [HttpPost]
    function GetBoolean: boolean; async;
    [ASync]
    [HttpPost]
    function GetInteger: Integer; async;
    [ASync]
    [HttpPost]
    function GetDate: tDateTime; async;
    [ASync]
    [HttpPost]

    function GetItems(apath: string; amask: string; doDirs, doFiles, doRecurse: boolean): tbsfilelist; async;
    [ASync]
    [HttpPost]

     function GetAll(aPath: string; aNoInfo: boolean): tbsFileList; async;

       [ASync]
    [HttpPost]
    function GetNames(apath: string;aMask:string;doDirs, doFiles, doRecurse: boolean): String; async;

     [ASync]
    [HttpPost]
    function MyIP: String; async;
  end;

var
  FileServiceDataDir: string;
  FileServiceURL: string;

implementation



end.
