unit x.Login;

interface

uses
  {$IFNDEF fwweb}XData.Service.Common, {$ELSE} JS, {$ENDIF}  sysutils, classes, x.login.types;

type
  [ServiceContract]
  ILoginService = interface(IInvokable)
    ['{3ABD086F-A755-41B2-BC6B-136E47CB5383}']
  [Async] function Login(const UserName, Password: string): txUser; async;
  [Async] function GetRemoteIP: string; async;
  [Async] function AddUser(email, firstname, lastname,pw: string): txUser; async;
  [Async] function GetUser: txuser; async;
  end;

implementation

initialization
{$IFNDEF fwweb}

  RegisterServiceType(TypeInfo(ILoginService));
{$ENDIF}
end.
