unit x.xdata.connect;

interface
 // {$INCLUDE project.inc}
uses
  classes, x.ws.shared, sysutils,   vcl.tmsfncutils,

  {$IFDEF fwweb}
  xdata.web.client, xweb.xdata.client, xdata.Web.Connection, JS, Web,
  {$ELSE}
  xdata.client, sparkle.Http.client,
  {$ENDIF}
  liblogtest;

type
  txDataStatus=class;
  txDataEvent = procedure(acode: integer; aMsg: string; aobject: tobject) of object;
  tXDStatusEvent=procedure(aStatus: txDataStatus) of object;
  txDataState = (xdsIdle, xdsConnecting, xdsLive, xdsError);

  txDataStatus = class
  private
    FState: txDataState;
    FStatus: string;
    FonChange: tXDStatusEvent;
    FServiceStatus: string;
    procedure SetState(const Value: txDataState);
    procedure SetStatus(const Value: string);
    procedure SetServiceStatus(const Value: string);
    function GetStateString: string;
  public
   InternalStatus: txdStatusEvent;
   procedure doChanged;
  published
   property State: txDataState read FState write SetState;
   property StateString: string read GetStateString;
   property Status: string read FStatus write SetStatus;
   property ServiceStatus: string read FServiceStatus write SetServiceStatus;
   property onChange: tXDStatusEvent read FonChange write FonChange;
  end;





  txDataConnection = class
  private

    FUseWS: boolean;
    FonConnected: tNotifyEvent;
    FonError: txDataEvent;
    FURI: string;
    Ftimeout: integer;
    FError: string;
    FReInitAfterError: boolean;
    FLastRecon: tDateTime;
    FNoAutoInit: boolean;
    FStatus: txDataStatus;
    FonStatus: txdStatusEvent;
    fInternalStatus: txdStatusEvent;
    procedure SetURI(const Value: string);

    procedure setOnStatus(const Value: txdStatusEvent);
    procedure SetInternalStatus(const Value: txdStatusEvent);


  public
    InternalConnected: tNotifyEvent;

    InternalError: txDataEvent;
    {$IFDEF fwweb}
    procedure XDConnected(sender: tobject);
    procedure XDError(Error: TXDataWebConnectionError);
    procedure XDRequest(Request: TXDataClientRequest);
    procedure XDResponse(Args: TXDataWebConnectionResponse);
    {$ELSE}
    procedure XDRequest(Req: thttpREquest);
    procedure XDResponse(ARequest: THttpRequest; var AResponse: THttpResponse);
    {$ENDIF}
    constructor create(aURI: string = '');
    procedure Init;
    destructor destroy;
  published
    {$IFDEF fwweb}
    xdclient: txXDataWebClient;
    xdConnection: txdatawebConnection;
    {$ELSE}
    xdclient: txdataclient;
    {$ENDIF}
    property UseWS: boolean read FUseWS write FUseWS;
    property onConnected: tNotifyEvent read FonConnected write FonConnected;
    property onStatus: txdStatusEvent read FonStatus write setOnStatus;
    property onError: txDataEvent read FonError write FonError;
    property URI: string read FURI write SetURI;
    property timeout: integer read Ftimeout write Ftimeout;
    property Error: string read FError write FError;
    property ReInitAfterError: boolean read FReInitAfterError write FReInitAfterError;
    property LastRecon: tDateTime read FLastRecon write FLastRecon;
    property NoAutoInit: boolean read FNoAutoInit write FNoAutoInit;
    property Status: txDataStatus read FStatus write fStatus;
        property InternalStatus: txdStatusEvent read fInternalStatus write SetInternalStatus;
  end;


  txDataService = class
  private
    FConnection: txDataConnection;
    FonReady: tNotifyEvent;
    Fonerror: txDataEvent;
    Ftimeout: integer;
    FnoAutoInit: boolean;
    FStatus: txDataStatus;
    FonStatus: txdStatusEvent;
    fURIQuery: string;
    fURIPort: integer;
    FURIHost: string;
    FURIpath: string;
    FuriSSL: boolean;
    procedure setTimeout(const Value: integer);
    procedure setOnStatus(const Value: txdStatusEvent);
  public
    FURI: string;
    ferror: string;
    procedure SetError(const Value: string); virtual;
    procedure InternalError(acode: integer; aMsg: string; aobject: tobject);
    procedure InternalStatus(aStatus: txDataStatus); virtual;
    [Async] procedure InternalConnected(sender: tobject); async;
    procedure SetURI(const Value: string); virtual;
    procedure ParseURI(aURI: string);
    constructor create(aURI: string = ''); virtual;
    constructor createNoInit(aURI: string = '');
    destructor destroy;
    [Async] procedure init; async; virtual;
    procedure CheckURI(var aURI: string); virtual;
  published
    property Connection: txDataConnection read FConnection write FConnection;
    property URI: string read FURI write SetURI;
    property URIHost: string read FURIHost;
    property URIpath: string read FURIpath;
    property URIQuery: string read fURIQuery;
    property URIPort: integer read fURIPort;
    property uriSSL: boolean read FuriSSL ;


    property onReady: tNotifyEvent read FonReady write FonReady;
    property timeout: integer read Ftimeout write setTimeout;
    property noAutoInit: boolean read FnoAutoInit write FnoAutoInit;
    property error: string read Ferror write SetError;
    property onerror: txDataEvent read Fonerror write Fonerror;
    property Status: txDataStatus read FStatus write FStatus;
    property onStatus: txdStatusEvent read FonStatus write setOnStatus;
  end;

var
  JWTtoken: string;

implementation
uses
  DateUtils;

procedure txDataService.CheckURI(var aURI: string);
begin

end;

procedure txDataService.InternalError(acode: integer; aMsg: string; aobject: tobject);
begin
  if assigned(onError) then
    onError(acode, amsg, aObject);

end;

procedure txDataService.InternalStatus(aStatus: txDataStatus);
begin
  fStatus:=AStatus;
end;

procedure txDataService.ParseURI(aURI: string);
begin
try
  TTMSFNCUtils.SplitURL(AURI, fURIHost, fURIPath, fURIQuery, fURIPort);
  if pos('https://', lowercase(furiHost))<>0 then furiSSL:=true else fURISSL:=false;

  furihost:=stringreplace(furiHost, 'https://', '', [rfIgnoreCase]);
  furihost:=stringreplace(furiHost, 'http://', '', [rfIgnoreCase]);

except
 on e: exception do
 begin
   alog.error('parseURI', e.message);
 end;

end;
end;

procedure txDataService.SetError(const Value: string);
begin
  Ferror := Value;
end;

{$IFDEF fwweb}

constructor txDataConnection.create(aURI: string);
begin
  fstatus:=txDataStatus.create;
  fstatus.state := xdsIdle;
  xdConnection := txdatawebConnection.create(nil);
  xdConnection.OnConnect := XDConnected;
  xdConnection.onError := XDError;

  FURI := aURI;
  xdclient := txXDataWebClient.create(nil);

  xdclient.Connection := xdConnection;
  xdclient.OnRequest := XDrequest;
  xdConnection.OnResponse := XDResponse;
  Init;
end;

procedure txDataConnection.XDConnected(sender: tobject);
begin

  if assigned(InternalConnected) then
    InternalConnected(self);

  if assigned(onConnected) then
    onConnected(sender);

end;

procedure txDataConnection.XDError(Error: TXDataWebConnectionError);
begin
status.State :=xdsError;
  if assigned(internalError) then
    InternalError(0, '', error);

  if assigned(onError) then
    onError(0, '', error);
end;

procedure txDataConnection.XDRequest(Request: TXDataClientRequest);
begin
  alog.send('xRequest ' + jwtToken);

  if JWTtoken <> ''  then
  begin
    alog.send('Adding header');
    //request.request.Headers.Create;
    request.request.Headers.SetValue('authorization', 'Bearer ' + jwtToken);

  end else alog.send('No JWT header');
 // console.log('Headers', request.request.Headers.get('authorization'));
end;

{procedure txDataConnection.xRequest(Args: TXDataWebConnectionRequest);
begin
  alog.send('xRequest ' + jwtToken);
  args.request.timeout := 30000;
  if JWTtoken <> '' then
  begin
    alog.send('Adding header');
    Args.request.Headers.SetValue('authorization', 'Bearer ' + jwtToken);
  end;
  console.log(args.Request.headers);
end;      }

procedure txDataConnection.XDResponse(Args: TXDataWebConnectionResponse);
begin
  if args.Response.StatusCode = 200 then
  begin
     status.State := xdsLive;
      error := '';
  end
  else
  begin
    status.State  := xdsError;

  end;
end;

{$ELSE}

constructor txDataConnection.create(aURI: string);
begin
  reInitAfterError := false;
    fstatus:=txDataStatus.create;
  fstatus.state := xdsIdle;

  xdclient := txdataclient.create;
  xdclient.IgnoreUnknownProperties := true;
  //xdclient.RawSerialization:=true;

  xdclient.HttpClient.OnSendingRequest := XDRequest;
  xdclient.HttpClient.OnResponseReceived := XDResponse;
  timeout := 0;
  FURI := aURI;
  if noAutoInit = false then Init;
end;

procedure txDataConnection.XDRequest(Req: thttpREquest);
begin

  if timeout <> 0 then req.Timeout := timeout;

  if UseWS then
    Req.Headers.SetValue('wsg', wsCurGuid);
  // req.Headers.SetValue('ip', alog.IPs.text);
  Req.Headers.SetValue('CPU', alog.cpu);
  if JWTtoken <> '' then
  begin
    req.Headers.SetValue('Authorization', 'Bearer ' + jwtToken);
  end;
end;

procedure txDataConnection.XDResponse(ARequest: THttpRequest;
  var AResponse: THttpResponse);
var
  s: string;
begin
  if aResponse.StatusCode <> 200 then
  begin
    error := inttostr(aResponse.StatusCode) + ':' + aResponse.StatusReason;
    error := stringreplace(error, #13#10, ',', [rfIgnoreCase, rfReplaceAll] );
    alog.send('XDREc', error);
    if assigned(InternalError) then InternalError(aResponse.statusCode, error, nil);
    if assigned(onError) then onError(aResponse.statusCode, error, nil);
   status.State :=xdsError;
  end
  else
  begin
  status.State  := xdsLive;
          error := '';
  end;

  //if timeout<>0 then aRequest.Timeout:=timeout;
       //alog.send(aresponse.StatusReason, aREsponse.StatusCode);
   //s:=TEncoding.Unicode.GetString( aResponse.ContentAsBytes );
   //alog.send('In',StringOf(aResponse.ContentAsBytes));
end;



{$ENDIF}

destructor txDataConnection.destroy;
begin
  {$IFNDEF fwweb}
  xdclient.free;
  {$ENDIF}

end;

procedure txDataConnection.Init;
begin
  UseWS := true;
  if FURI <> '' then
    URI := FURI;

end;

procedure txDataConnection.SetInternalStatus(const Value: txdStatusEvent);
begin
  fInternalStatus := Value;
  Status.InternalStatus:=value;
end;

procedure txDataConnection.setOnStatus(const Value: txdStatusEvent);
begin
  FonStatus := Value;
  Status.onchange:=value;
end;



procedure txDataConnection.SetURI(const Value: string);
begin
  if value = '' then
    exit;
  status.State := xdsConnecting;
  {$IFDEF fwweb}
  if xdConnection.URL = Value then
    if xdConnection.connected then
      exit;

  if xdConnection.connected then
    xdConnection.connected := false;

  xdConnection.URL := Value;
  xdConnection.connected := true;

  {$ELSE}
  xdclient.URI := Value;
  if assigned(InternalConnected) then
    InternalConnected(self);
  {$ENDIF}
  FURI := Value;

end;

{ txDataService }

constructor txDataService.create(aURI: string);
begin

  Connection := txDataConnection.create;
  Connection.InternalConnected := InternalConnected;
  COnnection.InternalError := InternalError;
  Connection.InternalStatus:=InternalStatus;
  fStatus:=Connection.Status;
  {$IFDEF fwweb}Connection.internalError := InternalError;
  {$ENDIF}
  if auri <> '' then
    uri := auri;

end;

constructor txDataService.createNoInit(aURI: string);
begin
  NoAutoInit := true;
  Create(aURI);

end;

destructor txDataService.destroy;
begin
  {$IFNDEF fwweb}
  if assigned(connection) then connection.free;
  {$ENDIF}
end;

procedure txDataService.init;
begin

end;

procedure txDataService.InternalConnected(sender: tobject);
begin
  if noAutoInit = false then
  begin

    {$IFDEF fwweb}await({$ENDIF}init{$IFDEF fwweb}){$ENDIF};

    if error = '' then
    begin
      if Assigned(onReady) then
        onReady(self);
    end
    else
    begin
      //Should not be needed as now checking errorsin response
    //    {$IFNDEF fwweb}if assigned(onerror) then
    //      onerror(self);
    //    {$ENDIF}

    end;
  end;

end;

procedure txDataService.setOnStatus(const Value: txdStatusEvent);
begin
  FonStatus := Value;
  fStatus.onChange:=value;
end;

procedure txDataService.setTimeout(const Value: integer);
begin
  Ftimeout := Value;
  Connection.timeout := value;
end;

procedure txDataService.SetURI(const Value: string);
var
  auri: string;
begin
  aURI := Value;
  CheckURI(aURI);
  ParseURI(aURI);
  furi := auri;
  Connection.noAutoInit := noAutoInit;
  if assigned(Connection) then
    Connection.URI := auri;

end;

{ txDataStatus }

procedure txDataStatus.doChanged;
begin
 if assigned(InternalStatus) then InternalStatus(self);

 if assigned(onChange) then onChange(self);

end;

function txDataStatus.GetStateString: string;
begin

//  txDataState = (xdsIdle, xdsConnecting, xdsLive, xdsError);
 if fState=xdsIdle then result:='Idle' else
 if fState=xdsConnecting then result:='Connecting' else
 if fState=xdsLive then result:='Connected' else
 if fState=xdsError then result:='Error';


end;

procedure txDataStatus.SetServiceStatus(const Value: string);
begin
  FServiceStatus := Value;
   DoChanged;
end;

procedure txDataStatus.SetState(const Value: txDataState);
begin
  FState := Value;
    DoChanged;
end;

procedure txDataStatus.SetStatus(const Value: string);
begin
  FStatus := Value;
  DoChanged;
end;

end.

