unit x.Login.Types;

interface
uses
  x.ao, classes,
  {$IFDEF MSWINDOWS}
  Bcl.Json.Attributes,
  {$ENDIF}
  liblogtest;

type
  txUser = class(tao)
  private
    FUser: string;
    FFirstName: string;
    FLastName: string;
    Fpw: string;
    FID: integer;
    FAvatar: string;
    FHomeFolder: string;
    FHomeFS: string;
    fNotes: string;
    FJWT: string;

  public
    [JsonIgnore] DataObject: tobject;
    [JsonIgnore] UIObject: tobject;
    [JsonIgnore] DataString: string;
    [JsonIgnore] DataInteger: integer;
    procedure SetFirstName(const Value: string); virtual;
    procedure SetLastName(const Value: string); virtual;
    procedure SetPw(const Value: string); virtual;
    constructor create; override;
  published
    property ID: integer read FID write FID;
    property User: string read FUser write FUser;
    property FirstName: string read FFirstName write SetFirstName;
    property LastName: string read FLastName write SetLastName;
    property pw: string read Fpw write SetPw;
    property Avatar: string read FAvatar write FAvatar;
    property HomeFolder: string read FHomeFolder write FHomeFolder;
    property HomeFS: string read FHomeFS write FHomeFS;
    property Notes: string read fNotes write fNotes;
    property JWT: string read FJWT write FJWT;
  end;

implementation

{ txUser }

constructor txUser.create;
begin
  inherited;

end;

procedure txUser.SetFirstName(const Value: string);
begin
  fFirstName := value;
end;

procedure txUser.SetLastName(const Value: string);
begin
  fLastName := value;
end;

procedure txUser.SetPw(const Value: string);
begin
  fpw := value;
end;

initialization
RegisterClass(txUser);

end.

