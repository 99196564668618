unit x.login.userbox;

interface

uses
  classes, rtti, system.Generics.Collections, uiTypes,  x.cfg.classes, sysutils,   x.controls, x.appstyler, x.login.client,

{$IFDEF fwfmx}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts, FMX.skia, FMX.TMSFNCGraphicstypes,
  FMX.TMSFNCSplitter,
  FMX.TMSFNCTreeViewBase,
  FMX.TMSFNCTreeViewData, FMX.TMSFNCCustomTreeView, FMX.TMSFNCTreeView,
  FMX.TMSFNCObjectInspector, x.icons.all,  liblogtest;
{$ENDIF}
{$IFDEF fwVCL}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, windows, messages, scGPControls, scgpExtControls, vcl.skia, scControls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, x.icons.all, liblogtest;

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
  VCL.TMSFNCObjectInspector,   liblogtest;
{$ENDIF}
{$IFDEF fwweb}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage, web,js,

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,  system.types,
  VCL.TMSFNCObjectInspector, x.icons.all, x.login.WebForm, xweb.forms, liblogtest;
{$ENDIF}


type

txUserbox=class(txRectangle)
  private
    FImageSize: integer;
    procedure SetImageSize(const Value: integer);
  public
   fLabel: txLabel;
   fImage: txImage;
   constructor create(aOwner: tcomponent); override;
   procedure UpdateDisplay;
   procedure DoAutoSize;
   procedure IntMouseUp; override;
   procedure DoClick(sender: tobject);
   [Async] procedure ShowLogin; async;
  published
   property ImageSize: integer read FImageSize write SetImageSize;
end;


implementation

{ txUserbox }

constructor txUserbox.create(aOwner: tcomponent);
begin
  inherited;
  fImage:=txImage.create (self);
  fImage.parent:=self;

  fLabel:=txLabel.create(self);
  fLabel.parent:=self;
  flabel.HitTest:=true;
  flabel.Layout:=tlCenter;
  fImage.HitTest:=false;
  //fImage.align:=AlignLeft;
  //fLabel.align:=AlignLeft;
  fimage.margins.left:=6;
  fimage.margins.right:=5;
  fimage.margins.top:=6;
  fimage.margins.bottom:=6;
  fImage.AlignWithMargins:=true;
  flabel.font.Color:=clBlack;
  flabel.FontColor:=clBlack;
  flabel.font.size:=12;
  imagesize:=24;
     //elementhandle.style.setProperty('background-color', 'transparant');
     flabel.OnClick:=DoClick;
     fImage.OnClick:=doClick;
     flabel.xText:='Sign in';
   fimage.URL:='https://x.am1.com/users/nouser.svg';
end;

procedure txUserbox.DoAutoSize;
begin
 setposx(fimage,4);
  setposy(fimage,4);
 setposx(flabel, fimage.width+posx(fimage)+4);

 Width:=flabel.width+posX(flabel)+4;
 height:=fImage.height+8;

 //if flabel.height>fImage.height then height:=flabel.height+4 else height:=fimage.height+4;
 setposy(flabel, trunc((height-flabel.height)/2));

end;

procedure txUserbox.DoClick(sender: tobject);
begin
ShowLogin;
end;

procedure txUserbox.IntMouseUp;
begin
  inherited;

 //showLogin;



end;

procedure txUserbox.SetImageSize(const Value: integer);
begin
  FImageSize := Value;
  fImage.width:=value;
  fImage.height:=value;
  DoAutoSize;
end;

procedure txUserbox.ShowLogin;
begin

{$IFDEF fwfmx}

{$ENDIF}
{$IFDEF fwvcl}

{$ENDIF}
{$IFDEF fwweb}
 if LoginForm=nil then  LoginForm:=tLoginForm.create(Application.MainForm) else loginForm.reset;

   await(LoginForm.xShow(true, true));
{$ENDIF}



end;

procedure txUserbox.UpdateDisplay;
begin
try
 if xlogin.HasUser then
 begin
  if assigned(xlogin.user) then
  begin
 console.log('user', xlogin.fuser.user);
   flabel.xText:=xlogin.fuser.Firstname;
   flabel.hint:=xlogin.fuser.user;//xlogin.fuser.FirstName + ' ' + xlogin.user.LastName;
   flabel.ShowHint:=true;
  alog.send('Avatar', xlogin.fuser.Avatar);
   fimage.url:=xlogin.fuser.Avatar;
  end else alog.error('Hasuser=true but no user');

 end else
 begin
   flabel.xText:='Sign in';
   fimage.URL:='https://x.am1.com/users/nouser.svg';
 end;
except
 on e: exception do
 begin
   alog.error('UpdateDisplay', e.message);
 end;

end;
    DoAutoSize;
end;

end.
