unit x.ao;

// {$DEFINE WEBLIB}
{

  Constructor should be declared as override
  The default constructor (create()) called by Json Serializer
  No need to inherite the create here (should be left blank)
  Not overriding create will mean the serializer and anything else that does not call
  TYourInheritedAOObject.create directly will get the virtual (blank) create defined here
  which may be desirable behaviour in some cases
  todo: AfterConstructor registers classtype for serializer, should be global option
  web version should have own unit?
  alog stub for console, web unit should not be used here
  Own class registry
  After Create, After serialize methods

  Web load object:
  await(alist.loadfromserver('locations'));

 GUIDS
 todo: for web..if it needs to generate find out best way to do in JS. This wont compile in web atm need to make the setting of aog in afterconstruction condfitional
 for FMX can work if on windows probably just need to make it conditional on windows not fwvcl
 Implement txjOps in the xlist
}
interface

uses system.Generics.Collections,
{$IFDEF WEBLIB}
  xdata.Web.Client, js, Web, weblib.TMSFNCTypes, weblib.JSON, system.Types,
{$ELSE}
    rest.Json, json,
{$ENDIF}
{$IFDEF fwvcl}
windows,
{$ENDIF}
  sysutils,contnrs,classes;

type
  TAO = class(tpersistent)
  private
    fAOName: string;
    FAOIcon: string;
    Ffn: string;

    FAOG: String;
    FAOGS: string;
    LastJson: string;

    function GetInfo: string; virtual;
    function GetAOName: string; virtual;
    function getfn: string;
    function GetAOGS: string;
    procedure SetAOGS(const Value: string);

  public


     Finfo: string;
{$IFDEF fwWeb}
    [Async]
    procedure LoadFromServer(aName: string = ''); async;
    [Async]
    procedure LoadFromJSO(ajo: jsvalue); async;
    procedure LoadfromJson(s: string);
{$ELSE}
    function Save: boolean;
    function Load: boolean;
{$ENDIF}

    constructor create;  virtual;
    procedure AfterCreate; virtual;
    procedure AfterConstruction; override;
{$IFDEF WEBLIB}
    procedure xdbResult(R: tXDataClientResponse; rv: tJSObject; rp: tobject; rs: string; rvs: string; sc: integer);
{$ENDIF}
    property fn: string read getfn write Ffn;
        // was published. Might break the web IX thing.
    property info: string read GetInfo write Finfo;
    property AOName: string read GetAOName write fAOName;
    property AOIcon: string read FAOIcon write FAOIcon;
    //Publish this in inherited class if serialisation of guid is wanted
    //Note this will cause objects to retain their original guid after deserial. If just want to use the guid for other purpose make a new property

    property AOGS: string read getAOGS write SetAOGS;
  published
    // a Guid
    property AOG: String read FAOG ;

  end;

type
  TAOW = class(TAO)
  private
  public
  published
  end;

type
taObjectList < T: class >= class(TObjectList<T>)
private
{$IFDEF WEBLIB}
procedure xdbResult(R: tXDataClientResponse; rv: tJSObject; rp: tobject; rs: string; rvs: string; sc: integer);
{$ENDIF}
public

{$IFDEF WEBLIB}
  [Async]
  procedure LoadFromServer(aName: string = ''); async;
  [Async]
  procedure LoadFromJSO(ajo: jsvalue); async;
{$ELSE}
    function LoadFromServer(aName: string = ''): string;
  function LoadFromServerAJO(aName: string = ''): string;
{$ENDIF}
  end;

  type txListMode=(xlUpdate, xlIgnore, xlOverwrite, xlDefault);
  //xlUpdate - Objects with same guid and not recreated but are updated by xJson
  //xlIgnore = Objects with same guid are not touched
  //xlOverwrite - the normal behaviour the whole list is redone
  //xlDefault - used only on a call to FromJ to set the chosen val above from mode property

  //TXList
        type txlistBeforeEvent=procedure(aitem: tAO; var canDo: boolean) of object;
        type txlistAfterEvent=procedure(aitem: tAO) of object;


  {$IFDEF WEBLIB}
  type txList<T: class>=class(tObjectList<T>)
  {$ELSE}
  type txList<T: class, constructor>=class(tObjectList<T>)
  {$ENDIF}
  private
    Fmode: txListMode;
   // Fops: txjOps;
    FSetAOG: boolean;
    FEnableLog: boolean;
    fLastJ: string;
    FFreeOnRemove: boolean;
    FEnableRemove: boolean;
      FonBeforeRemove: txListBeforeEvent;
    FonAfterRemove: txListAfterEvent;
    FonBeforeAdd: txListBeforeEvent;
    FonAfterAdd: txListAfterEvent;
    FonBeforeUpdate: txListBeforeEvent;
    FonAfterUpdate: txListAfterEvent;
    Ffn: string;
    FAOName: string;

    procedure setmode(const Value: txListMode);
   // {$IFDEF WEBLIB}

   //  {$ELSE}
    function GetJA(S: String): tJsonArray;
   // {$ENDIF}
    procedure setEnableLog(const Value: boolean);
    function GetEnableLog: boolean;
     function DoRemove(alist: tstringlist): integer;
    function getfn: string;
  public
    {$IFDEF WEBLIB}

{$ELSE}
    function Save: boolean;
    function Load: boolean;
    {$ENDIF}
    function FromJ(s: string; amode: txListMode=xlDefault): integer;
    //   function FromList(FL:  txList<T>; FreeAfter: boolean=false): integer;


   constructor create;
   procedure LogList;
   Function Item(aG: string):T;
   function ToJ: string;


   property mode: txListMode read Fmode write setmode;
  // property ops: txjOps read Fops write Fops;
   //Turn off if persisting, default true
   property SetAOG: boolean read FSetAOG write FSetAOG;
   //Note this will be for all instances of type<T> and if disabled LogList will use flog instead
   property EnableLog: boolean read GetEnableLog write setEnableLog;
   property FreeOnRemove: boolean read FFreeOnRemove write FFreeOnRemove;
   property EnableRemove: boolean read FEnableRemove write FEnableRemove;

   property onBeforeRemove: txListBeforeEvent read FonBeforeRemove write FonBeforeRemove;
   property onBeforeAdd: txListBeforeEvent read FonBeforeAdd write FonBeforeAdd;
   property onBeforeUpdate: txListBeforeEvent read FonBeforeUpdate write FonBeforeUpdate;
   property onAfterUpdate: txListAfterEvent read FonAfterUpdate write FonAfterUpdate;
   property onAfterRemove: txListAfterEvent read FonAfterRemove write FonAfterRemove;
   property onAfterAdd: txListAfterEvent read FonAfterAdd write FonAfterAdd;

   property fn: string read getfn write Ffn;
   property AOName: string read FAOName write FAOName;
  published


  end;

type
  TAOClass = Class of TAO;

  { taObjectList<T> }

  { Utils }
function GetGenericListType(s: String): String;

 {$IFDEF fwvcl}

  function RDTSC : Int64;
  function CPUSpeed : Longword;
 {$ENDIF}
 function amCleanClass(S: String): String;

type
  TAOList = TObjectList<TAO>;

var
  DisableList: boolean;
  AODisableAUtoREg: boolean;
  ao: TAOList;

implementation

uses {$IFDEF WEBLIB}
  xweb.xdclient, liblogtest,
{$ELSE}
  x.xdata.dbservice, quick.commons, liblogtest,
{$ENDIF}
  strUtils, ioUtils,x.json;

  {$IFDEF fwvcl}

  //https://groups.google.com/g/borland.public.delphi.objectpascal/c/u2wTIa-Cydg

  function RDTSC : Int64;
asm
dw $310F // opcode for RDTSC
end;

function CPUSpeed : Longword;
var
f,tsc,pc,pc2 : Int64;
begin
if QueryPerformanceFrequency(f) then
begin
QueryPerformanceCounter(pc);
tsc := RDTSC;
Sleep(0);
QueryPerformanceCounter(pc);
tsc := RDTSC;
Sleep(100);
QueryPerformanceCounter(pc2);
tsc := RDTSC-tsc;
pc := pc2-pc;
result := round(tsc*f/(pc*1e6));
end
else
result := 0;
end;
{$ENDIF}

{ Utils }
function GetGenericListType(s: String): String;
var

  openBracket, closebracket: integer;
begin
  openBracket := Pos('<', s);
  closebracket := PosEx('>', s, openBracket + 1);
  s := Copy(s, openBracket + 1, closebracket - openBracket - 1);
  if Pos('.', s) <> 0 then
    s := Copy(s, Pos('.', s) + 1, length(s));
  Result := s;
  // console.log(Result);
end;

{ TAO }

procedure TAO.AfterConstruction;
begin
  inherited;
//  AOG := FloattoStrF(now, tfloatFormat.ffGeneral,15,15);
     //alog.send('AO after cons');
  {$IFDEF fwvcl}
 faog:=Inttostr(RDTSC);
    faogs:=faog;
    {$ENDIF}
  if DisableList = false then
  begin
    if ao = nil then
      ao := TAOList.create;
    ao.Add(self);
  end;
  if AODisableAUtoREg=false then
  begin
      {$IFDEF WEBLIB}
       RegisterClass(tPersistentClass(self.ClassType));
       //console.log('aocreate');
     {$ELSE}
  System.classes.RegisterClass(tPersistentClass(self.ClassType));
  {$ENDIF}
  end;
  AfterCreate;
  //  alog.send('AO after cons ends');
end;

procedure TAO.AfterCreate;
begin

end;

constructor TAO.create;
begin
  inherited;
 //  alog.Send('AO Create');
  // console.log('TAO Object created', self.ClassName);
  // RegisterClass(tPersistentClass(self.ClassType));
end;

function TAO.GetAOGS: string;
begin
 result:=fAOG;
end;

function TAO.GetAOName: string;
begin
  Result := fAOName;
  if Result = '' then
    Result := '(' + classname + ')';

end;

function TAO.getfn: string;
var
  LocalFolder, aName: string;
begin
  if Ffn <> '' then
  begin
    Result := Ffn;
    exit;
  end;
{$IFDEF IOS}
  LocalFolder := system.ioUtils.TPath.Combine(system.ioUtils.TPath.getdocumentspath, 'CFG');
  ForceDirectories(LocalFolder);
  if AOName <> '' then
    aName := AOName + '.json'
  else
    aName := classname + '.json';
  Result := combinepaths(LocalFolder, aName, pathdelim);
{$ENDIF}
{$IFDEF fwvcl}
  LocalFolder := combinepaths(path.exepath, 'CFG', pathdelim);

  ForceDirectories(LocalFolder);
  if AOName <> '' then
    aName := AOName + '.json'
  else
    aName := classname + '.json';
  Result := combinepaths(LocalFolder, aName, pathdelim);
{$ENDIF}
{$IFDEF fwfmx}
  LocalFolder := combinepaths(path.exepath, 'CFG', pathdelim);

  ForceDirectories(LocalFolder);
  if AOName <> '' then
    aName := AOName + '.json'
  else
    aName := classname + '.json';
  Result := combinepaths(LocalFolder, aName, pathdelim);
{$ENDIF}
end;

function TAO.GetInfo: string;
begin
  Result := Finfo;
  if Result = '' then
    Result := '()';

end;

{$IFDEF WEBLIB}

procedure TAO.LoadFromServer(aName: string = '');
begin
  await(XDDB.GetHotRow(aName, self));
end;

procedure TAO.xdbResult(R: tXDataClientResponse; rv: tJSObject; rp: tobject; rs, rvs: string; sc: integer);
begin

end;

procedure TAO.LoadFromJSO(ajo: jsvalue);
var
  J: string;
begin
  try
    J := tjsJson.stringify(ajo);
    self.json := J;
  except
    on e: tJSObject do
    begin
      console.error('Exception');
      if e is tJSError then
        console.error('its a tjserror', e)
      else
        console.error('Not JS Error');
    end
    else
    begin
      console.error('Exception not caught by JSObject');
    end;
  end;

end;

procedure TAO.LoadfromJson(s: string);
begin
  try

    self.json := s;
  except
    on e: tJSObject do
    begin
      console.error('Exception');
      if e is tJSError then
        console.error('its a tjserror', e)
      else
        console.error('Not JS Error');
    end
    else
    begin
      console.error('Exception not caught by JSObject');
    end;
  end;

end;

{ taObjectList<T> }

procedure taObjectList<T>.LoadFromServer(aName: string = '');
var
  o: tobject;

begin
  if aName = '' then
    aName := GetGenericListType(self.classname);
  // if web...
  await(XDDB.GetHotTable(aName, 1, self));

end;

procedure taObjectList<T>.LoadFromJSO(ajo: jsvalue);
var
  J: string;
begin
  try
    J := tjsJson.stringify(ajo);
    self.json := J;
  except
    on e: tJSObject do
    begin
      console.error('Exception');
      if e is tJSError then
        console.error('its a tjserror', e)
      else
        console.error('Not JS Error');
    end
    else
    begin
      console.error('Exception not caught by JSObject');
    end;
  end;

end;

procedure taObjectList<T>.xdbResult(R: tXDataClientResponse; rv: tJSObject; rp: tobject; rs, rvs: string; sc: integer);
begin

end;
{$ELSE}

function taObjectList<T>.LoadFromServer(aName: string = ''): string;
begin
  if aName = '' then
    aName := GetGenericListType(self.classname);
  XDDB.GetHotTable(aName, self);
end;

function taObjectList<T>.LoadFromServerAJO(aName: string = ''): string;
begin
  if aName = '' then
    aName := GetGenericListType(self.classname);
  XDDB.GetHotTable(aName, self, true);
end;

function TAO.Load: boolean;
var
  J: string;
begin
  try
    if fileexists(fn) then
    begin
      J := tfile.ReadAllText(fn);
      Result := xjtoObject(J, self);
    end;
  except
    on e: exception do
    begin
      Result := false;
      alog.error(e.message);
    end;

  end;
end;

function TAO.Save: boolean;
var
  J: string;
begin
  try
    J := xj(self);
    tfile.WriteAllText(fn, J);
    Result := fileexists(fn);
  except
    on e: exception do
    begin
      Result := false;
      alog.error(e.message);
    end;

  end;

end;
 {$ENDIF}
procedure TAO.SetAOGS(const Value: string);
begin
 fAOG:=value;
end;



{ txList<T> }



constructor txList<T>.create;
begin
  inherited;

  mode:=xlUpdate;
  SetAOG:=true;
  OwnsObjects:=false;
  FreeOnRemove:=true;
  EnableRemove:=true;
//  alog.Send('Xlist created', self.ClassName);
end;

//Result: 0, OK -1, item fails, -2 whole thing fails, -3 an item was not a tAO

function txList<T>.DoRemove(alist: tstringlist): integer;
var
 aG: string;
 i: integer;
 aItem: T;
 rList: tobjectlist;
 CountR: integer;
   aAO: tao;
   cando: boolean;
begin
  result:=0;
  if EnableRemove=false then exit;

 rlist:=tobjectlist.create;
 rlist.OwnsObjects:=FreeOnRemove;
 countR:=0;

 for i:=1 to count do
 begin
  aitem:=items[i-1];
   ag:=tao(aitem).AOG;
   if alist.IndexOf(ag) =-1 then
   begin
    if assigned(onBeforeRemove) then onBeforeRemove(tao(aitem), cando) else cando:=true;
    if cando then
    begin
    rlist.Add(aitem);
    {$IFNDEF WEBLIB}
   items[i-1]:=nil;
   {$ENDIF}
   inc(countR);
   if assigned(onAfterRemove) then onAfterRemove(tao(aitem));

    end;
   end;

 end;
  result:=CountR;
     {$IFDEF WEBLIB}
      for i:=1 to rlist.Count do
      begin
       aitem:=T(rlist[i-1]);
       Remove(aitem);
      end;

     {$ELSE}
 pack;
 {$ENDIF}

 {for i:=1 to rlist.Count do
 begin
   aao:=tao(rlist[i-1]);
   alog.Send('about to be free', aao);
   aao.free;
 end;}
  rList.Free;


end;
 // {$IFDEF WEBLIB}

//{$ELSE}

function txList<T>.FromJ(s: string; amode: txListMode): integer;
var
  JA: tJsonArray;
  aJO: tJsonObject;
  ajv: tJsonValue;
  I: integer;
  aG: String;
  J: string;
  aitem: t;
  CountCreated: integer;
  countUpdated: integer;
  countIgnored: integer;
  CountRemoved: integer;
  apair: tJsonPair;
  jv: tjsonvalue;
  AllAOG: TStringlist;
  cando: boolean;
  cc: string;
   tme: tpersistentclass;
   aoClass: taoClass;
begin
   try
     allAOG:=tstringlist.Create;
     allAOG.Sorted:=true;
     allAOG.Duplicates:=dupIgnore;

   try
   countCreated:=0;
   countUpdated:=0;
   countIgnored:=0;


   result:=0;
   if amode=xlDefault then amode:=mode;
   JA:=getJA(s);
   assert (JA<>nil);
   for i:=1 to JA.Count do
   begin
    try
    ajv:=Ja.Items[i-1];
    ajo:=tjsonobject(ajv);
    JV:=ajo.GetValue('AOG');

    //if not JV.TryGetValue<string>(ag) then
    {$IFDEF WEBLIB}
    ag:=jv.ToString;
    ag:=stringReplace(ag,'"','',[rfIgnoreCase, rfReplaceAll]);
    console.log('AG', 'Q'+ag+'Q');
    {$ELSE}
    ag:=jv.GetValue<string>;
    {$ENDIF}


    if ag='' then

    begin
      alog.error('Cannot get AGO for item');
      result:=-3;
      exit;
    end;
     AllAOG.Add(ag);

    aitem:=item(ag);
    if ((assigned(aitem)) and (amode=xlUpdate)) then
    begin
      if assigned (OnBeforeUpdate) then onBeforeUpdate(tao(aitem), cando) else cando:=true;
      if cando then
      begin
      inc(countUpdated);
      j:=ajo.ToJSON;
      xjtoObject(j, aitem);
      if assigned (onAfterUpdate) then onAfterUpdate(tao(aitem));

      end;
    end else
    if (not (assigned(aitem))) then
    begin
    if assigned(onBeforeAdd) then onBeforeAdd(tao(aitem), cando) else cando:=true;
    if cando then
    begin
     inc(countCreated);
      j:=ajo.ToJSON;

      {$IFDEF fwweb}
      cc:=amcleanclass(self.classname);
       //console.log('cc',cc);
       tme:= FindClass(cc);
       if tme<>nil then
       begin
       console.log('AM Magic starts');
         aoClass:= taoClass(tme);

         aitem:=t(aoclass.create);
         console.log('AM CREATED CLIENT!!!', aitem);
         console.log(cc);
        //c:=ap.ClassType;
     //  alog.send('Resolved classtype: ' + c.ClassName);
       end else console.log('TME WAS NIL');

      {$ELSE}
      aitem:=t.create;
      {$ENDIF}
      xjtoObject(j, aitem);
      if setAOG then tao(aitem).FAOG:=ag;
      add(aitem);
      if assigned(onAfterAdd) then onAfterAdd(tao(aitem));
       {$IFDEF fwweb}
       //console.log('Item0',items[0]);
       {$ENDIF}
    end;
    end else inc(countIgnored);
    except
     on e: exception do
     begin
       alog.error('Failed on item');
       result:=-1;
     end;

    end;
   end;
   CountRemoved:=doRemove(allAOG);

   {alog.Send('FROMJ');
   alog.Send('  Created', countCreated);
   alog.Send('  Updated', countUpdated);
   alog.Send('  Ignored', CountIgnored);
    alog.Send('  Removed', CountRemoved);
       alog.Send('me', self);}

    fLastJ:=s;

  {$IFDEF fwweb}
      // console.log('Item0',items[0]);
       {$ENDIF}
  except
   on e: exception do
   begin
     alog.error('FromJ', e.message);
     result:=-2;
   end;
   end;
     finally
      AllAOG.Free;
   end;
end;

{function txList<T>.FromList(FL: txList<T>; FreeAfter: boolean): integer;
begin

end;    }
//{$ENDIF}



function txList<T>.GetEnableLog: boolean;
begin
  {$IFDEF WEBLIB}

{$ELSE}
 result:= alogcfg(self.ClassName).myCFG.enable;
 {$ENDIF}
end;

function txList<T>.getfn: string;

var
  LocalFolder, aName: string;
begin
  if Ffn <> '' then
  begin
    Result := Ffn;
    exit;
  end;
{$IFDEF IOS}
  LocalFolder := system.ioUtils.TPath.Combine(system.ioUtils.TPath.getdocumentspath, 'CFG');
  ForceDirectories(LocalFolder);
  if AOName <> '' then
    aName := AOName + '.json'
  else
    aName := classname + '.json';
  Result := combinepaths(LocalFolder, aName, pathdelim);
{$ENDIF}
{$IFDEF fwvcl}
  LocalFolder := combinepaths(path.exepath, 'CFG', pathdelim);

  ForceDirectories(LocalFolder);
  if AOName <> '' then
    aName := AOName + '.json'
  else
    aName := classname + '.json';
  Result := combinepaths(LocalFolder, aName, pathdelim);
{$ENDIF}
{$IFDEF fwfmx}
  LocalFolder := combinepaths(path.exepath, 'CFG', pathdelim);

  ForceDirectories(LocalFolder);
  if AOName <> '' then
    aName := AOName + '.json'
  else
    aName := classname + '.json';
  Result := combinepaths(LocalFolder, aName, pathdelim);
{$ENDIF}
end;

 //{$IFDEF WEBLIB}
  
 //    {$ELSE}

function txList<T>.GetJA(S: String): tJsonArray;
begin
  result:=tjsonArray.create;
  result := tJsonArray(TJSONObject.ParseJSONValue(s));
end;
//{$ENDIF}

function txList<T>.Item(aG: string): T;
var
 aitem: t;
 i: integer;
begin
result:=nil;
  for I:=1 to Count do
  begin
   aitem:=items[i-1];
   if assigned(aitem) then
   begin
    if tao(aitem).AOG=AG then
    begin
      result:=aItem;
      exit;
    end;
   end;
  end;

end;



procedure txList<T>.LogList;
var
 aItem: t;
 i: integer;
 Logger: tLogTest;
begin
  {$IFDEF WEBLIB}

{$ELSE}
 if EnableLog then logger:=alog else logger:=flog;

 logger.Send('Xlist: ' + inttostr(count) + ' items');
 for i:=1 to count do
 begin
  logger.Send('Item ' + inttostr(i), items[i-1]);

 end;
 {$ENDIF}
end;

  {$IFDEF WEBLIB}

{$ELSE}

function txList<T>.Save: boolean;
var
  J: string;
begin
  try
    J := xj(self);
    tfile.WriteAllText(fn, J);
    Result := fileexists(fn);
  except
    on e: exception do
    begin
      Result := false;
      alog.error(e.message);
    end;

  end;

end;

function txList<T>.Load: boolean;

var
  J: string;
begin
  try
    if fileexists(fn) then
    begin
      J := tfile.ReadAllText(fn);
      Result := xjtoObject(J, self);
    end;
  except
    on e: exception do
    begin
      Result := false;
      alog.error(e.message);
    end;

  end;
end;
{$ENDIF}

procedure txList<T>.setEnableLog(const Value: boolean);
begin
  {$IFDEF WEBLIB}

{$ELSE}
  FEnableLog := Value;
  al(self.ClassName, value);
  {$ENDIF}
end;

procedure txList<T>.setmode(const Value: txListMode);
begin
 if value<>xlDefault then

  Fmode := Value else
  begin
    alog.Send('Mode cannot be default here');
  end;
end;

function txList<T>.ToJ: string;
begin
  result:=xj(self);
end;

function amCleanClass(S: String): String;
var

	openBracket, closebracket: integer;
begin
 openBracket := Pos( '<', s );
	closebracket := PosEx( '>', s, openBracket + 1 );
	s := Copy( s, openBracket + 1, closebracket - openBracket - 1 );

	while Pos( '.', s ) <> 0 do
		s := Copy( s, Pos( '.', s ) + 1, length( s ) );
	Result := s;
 //  console.log(Result);
 end;

initialization

ao := TAOList.create;

end.
