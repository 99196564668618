unit x.rtti;

interface

uses classes, x.json, x.json.types,  system.Generics.collections, rtti, typinfo,
  sysutils,

  {$IFDEF fwweb}
  {$DEFINE WEBLIB}
	WEBLib.controls, WEBLib.ExtCtrls, WEBLib.forms, jS, web, generics.Collections,
	liblogweb, WEBLib.tmsfnctypes, WEBLib.TMSFNCPersistence,
{$IFEND}
{$IFDEF fwfmx}
	fmx.StdCtrls, fmx.forms, fmx.controls, fmx.types,
	fmx.TMSFNCPersistence,FMX.graphics, FMX.TMSFNCTypes, FMX.TMSFNCGraphics, FMX.TMSFNCGraphicsTypes,
{$IFEND}
{$IFDEF fwVCL}
	vcl.controls, vcl.StdCtrls, vcl.ExtCtrls, forms,
vcl.TMSFNCPersistence,vcl.graphics, vcl.TMSFNCTypes, vcl.TMSFNCGraphics, vcl.TMSFNCGraphicsTypes,
{$IFEND}


  liblogtest;

type
  txProp = class
  private
    Fname: String;
    fProp: trttiProperty;
    fInstance: tObject;
    FTypename: string;
    fxType: string;
    feNumVals: tStringList;
    function GetValue: String;
    function getEnums: string;
  public
    constructor Create(aProp: TRttiProperty; aobject: tobject);
    property prop:  trttiProperty read fProp write fProp;
   property Instance: tObject read fInstance write fInstance;

    function IsColor: Boolean;
    function IsComponent: Boolean;
    function IsControl: Boolean;
    function IsDate: Boolean;
    function IsDateTime: Boolean;
    function IsDescendingClass(AClassParentList: array of string): Boolean;
    function IsFillKind: Boolean;
    function IsTime: Boolean;
    function isObject: boolean;
    function isEnum: boolean;
    function EnumToInt<T>(const enValue: T): Integer;
    function GetEnumNameEx(aInfo: ptypeInfo; AValue: Integer): string;
    procedure GetEnumValues(AValues: TStrings);
    function GetTypeInfoEx: PTypeInfo;
   // function GetPropInfo: pPropInfo;
    procedure StringToEnum<T>(strValue: String; var enValue: T);
    function GetPropInfoTypeName: string;
     function GetPropValue(max: integer=-1): string;
     function GetName: string;
      property eNumVals: tStringList read feNumVals write feNumVals;
  published
    property name: string read Fname write Fname;
    property TypeName: string read FTypename write FTypename;
    property isItColor: boolean read isColor;
     property isItDate: boolean read isDateTime;
     property isitEnum: boolean read iseNum;
     property isitObject: boolean read isObject;
     property value:String read GetValue;
     property xType: string read fxType write fxType;
     property enums: string read getEnums;

  end;
  type txProps=tobjectlist<txProp>;

  {$IFDEF fwweb}

// TRttiPropertyArray = specialize TArray<TRttiProperty>;
type
	tMonster = TArray<TRttiProperty>;
{$ELSE}

type
	TRttiPropertyArray = TArray<TRttiProperty>;
{$IFEND}

type
	txInstance = class(tpersistent)
	private
		finstance: tobject;
		fclasstype: tclass;
    function GetMethodList: tStringList;

	public
		fproplist: tstringlist;
    fMethodList: TStringList;
		fproperties: TRttiPropertyArray;
    fMethods:   tarray<TRttiMethod>;
		function GetPropList: tstringlist;
		function IsInstance: boolean;
		function isnil: boolean;
		function GetProperties: TRttiPropertyArray;
       function GetMethods: tarray<TRttiMethod>;
		function GetProperty(const AName: string): TRttiProperty;
{$IFDEF fwweb}
		function GetValue(const AName: string): JSVALUE;
		procedure SetValue(const AName: string; const AValue: JSVALUE); overload;

{$ELSE}
		function GetValue(const AName: string): variant;
{$IFEND}
		procedure SetValue(const AName: string; const AValue: TValue); overload;
		procedure SetChildValues(const AName: string; const AValue: TValue;
			aclass: tclass = nil);
		function GetTValue(const AName: string): TValue;
		constructor create(ainstance: tobject); overload;
    function HasProp(aname: string): boolean;
    function HasMethod(aname: string): boolean;
    function Method(aname: string): trttiMethod;
    function DoMethod(aname: string): tvalue;
		// constructor create(aclasstype: tclass); overload;
		destructor destroy;
	published
		property PropList: tstringlist read GetPropList;
    property MethodList: tStringList read GetMethodList;
		property properties: TRttiPropertyArray read GetProperties;

	end;

implementation
 {$IFNDEF WEBLIB}
{$IFNDEF FMXMOBILE}
{$IFNDEF LCLLIB}

  type
{$HINTS OFF}
{$IF COMPILERVERSION < 26}
  TSymbolNameBase = string[255];
  TSymbolName = type TSymbolNameBase;
{$IFEND}
{$HINTS ON}
  PSymbolName = ^TSymbolName;
{$ENDIF}
{$IFDEF LCLLIB}

type
  PSymbolName = ^ShortString;
{$ENDIF}

function GetShortStringString(const ShortStringPointer: PSymbolName): string;
begin
  Result := string(ShortStringPointer^);
end;
{$ENDIF}
{$IFDEF FMXMOBILE}

function GetShortStringString(const ShortStringPointer: PByte): string;
var
  ShortStringLength: Byte;
  FirstShortStringCharacter: MarshaledAString;
  ConvertedLength: Cardinal;
  UnicodeCharacters: array [Byte] of Char;
begin
  if not Assigned(ShortStringPointer) then
    Result := ''
  else
  begin
    ShortStringLength := ShortStringPointer^;
    if ShortStringLength = 0 then
      Result := ''
    else
    begin
      FirstShortStringCharacter := MarshaledAString(ShortStringPointer + 1);
      ConvertedLength := UTF8ToUnicode(UnicodeCharacters, Length(UnicodeCharacters), FirstShortStringCharacter, ShortStringLength);

      ConvertedLength := ConvertedLength - 1;
      SetString(Result, UnicodeCharacters, ConvertedLength);
    end;
  end;
end;
{$ENDIF}
{$ENDIF}


function txProp.GetPropInfoTypeName: string;
var
 {$IFNDEF fwweb} aPropInfo: pPropInfo;  {$ELSE}aPropInfo:TTypeMemberProperty; {$ENDIF}

begin
 APropInfo := GetPropInfo(Instance,GetName);
  {$IFNDEF WEBLIB}
  Result := GetShortStringString(@APropInfo{$IFDEF LCLLIB}^{$ENDIF}.PropType^.Name);
  {$ENDIF}
  {$IFDEF WEBLIB}
  Result := '';
  if Assigned(APropInfo.typeinfo) then
    Result := APropInfo.typeinfo.name;
  {$ENDIF}
end;


function txProp.GetTypeInfoEx: PTypeInfo;
var
 {$IFNDEF fwweb} aPropInfo: pPropInfo;  {$ELSE}aPropInfo:TTypeMemberProperty; {$ENDIF}
begin
  APropInfo := GetPropInfo(Instance,GetName);
{$IFNDEF WEBLIB}
  Result := APropInfo^.PropType{$IFNDEF LCLLIB}^{$ENDIF};
{$ENDIF}
{$IFDEF WEBLIB}
  Result := APropInfo.typeinfo;
{$ENDIF}
end;

function txProp.GetValue: String;
begin
 result:=GetPropValue;
end;

function txProp.GetEnumNameEx(aInfo: ptypeInfo; AValue: Integer): string;
begin
{$IFNDEF WEBLIB}
  Result := typinfo.GetEnumName(aInfo, AValue);
{$ENDIF}
{$IFDEF WEBLIB}
  Result := TTypeInfoEnum(ainfo).EnumType.IntToName[AValue];  //ATypeInfo
{$ENDIF}
end;

function txProp.getEnums: string;
begin
 result:=eNumVals.text;
end;

procedure txProp.GetEnumValues(AValues: TStrings);
var
  p: PTypeInfo;
{$IFNDEF WEBLIB}
  su: PTypeData;
{$IFNDEF LCLLIB}
  ct: PPTypeInfo;
{$ENDIF}
{$IFDEF LCLLIB}
  ct: PTypeInfo;
{$ENDIF}
{$ENDIF}
{$IFDEF WEBLIB}
  pi: TTypeInfoInteger;
  ps: PTypeInfo;
{$ENDIF}
  i: Integer;
  k: TTypeKind;
  {$IFNDEF fwweb} aPropInfo: pPropInfo;  {$ELSE}aPropInfo:TTypeMemberProperty; {$ENDIF}
begin
  APropInfo := GetPropInfo(Instance, getname);
  p := GetTypeInfoEx;
{$IFNDEF WEBLIB}
  su := GetTypeData(p);
  if Assigned(su) then
  begin
    if p{$IFDEF LCLLIB}^{$ENDIF}.Kind = tkSet then
    begin
      ct := su^.CompType;
      if Assigned(ct) then
      begin
        k := ct^.Kind;
        case k of
          tkEnumeration:
            begin
              su := GetTypeData(ct{$IFNDEF LCLLIB}^{$ENDIF});
              for i := su^.MinValue to su^.MaxValue do
                AValues.Add(GetEnumNameEx(ct{$IFNDEF LCLLIB}^{$ENDIF}, i) + '=' + Inttostr(i));
            end;
        end;
      end
    end
    else
    begin
      for i := su^.MinValue to su^.MaxValue do
        AValues.Add(GetEnumNameEx(p, i) + '=' + Inttostr(i));
    end;
  end;
{$ENDIF}
{$IFDEF WEBLIB}
  if Assigned(p) and (p is TTypeInfoSet) then
    p := TTypeInfoSet(p).CompType;

  if Assigned(p) and (p is TTypeInfoInteger) then
  begin
    pi := TTypeInfoInteger(p);
    for i := pi.MinValue to pi.MaxValue do
      AValues.Add(GetEnumNameEx(p, i) + '=' + Inttostr(i));
  end;
{$ENDIF}
end;

function txProp.GetName: string;
begin
 result:=prop.Name;
end;

procedure txProp.StringToEnum<T>(strValue: String; var enValue: T);
var
  Tipo: PTypeInfo;
  Temp: Integer;
  PTemp: pointer;
begin
{$IFNDEF fwweb}

  Tipo := typeinfo(T);
  Temp := GetEnumValue(Tipo, strValue);
  PTemp := @Temp;
  enValue := T(PTemp^);
  {$ENDIF}
end;

constructor txProp.Create(aProp: TRttiProperty; aobject: tobject);
begin
 prop:=aProp;
 Instance:=aObject;
 enumVals:=tStringList.create;
 try
 if iseNum then GetEnumValues(enumvals);
 except
  on e: exception do
  begin

  end;

 end;
 fname:=GetName;
 fTypeName:=GetPropInfoTypeName;

 if isDate then xType:='date' else
 if isColor then xType:='color' else
 if isDatetime then xType:='datetime' else
 if isTime then xType:='time' else
 if isEnum then xtype:='enum' else
 if isObject then xtype:='object' else


 begin
   xtype:=GetPropInfoTypeName;

 end;



end;

function txProp.EnumToInt<T>(const enValue: T): Integer;
begin
{$IFNDEF fwweb}
  Result := 0;
  Move(enValue, Result, sizeOf(enValue));
  {$ENDIF}
end;

function txProp.GetPropValue( max: integer=-1): string;
var
  pName: string;
  en: string;
  vls: string;
  k: TTypeKind;
  o: tobject;
   {$IFNDEF fwweb} p: pPropInfo;  {$ELSE}p:TTypeMemberProperty; {$ENDIF}
  n: String;
  f: Extended;
  s, sv: tstringlist;
  i, J: Integer;
  v: NativeInt;
begin
  o := Instance;
  p := GetPropInfo(Instance, getname);
  // aprop.PropInfo.
  k := prop.PropertyType.TypeKind;
  pName :=getname;
  vls := '';

  case k of
    tkInteger:
      vls := IntToStr(GetOrdProp(o, p));
    tkChar, tkString{$IFNDEF WEBLIB}, tkWChar, tkLString, tkUString{$ENDIF}{$IFDEF LCLLIB}, tkAString{$ENDIF}:
      vls := GetStrProp(o, p);
{$IFDEF LCLWEBLIB}
    tkBool:
      vls := BoolToStr(Boolean(GetOrdProp(o, p)), true);
{$ENDIF}
    tkEnumeration:
      vls := GetEnumNameEx(GetTypeInfoEx, GetOrdProp(o, p));
    tkFloat:
      begin
        f := GetFloatProp(o, p);
        n := GetPropInfoTypeName;
        if IsDate then
          vls := DateToStr(f)
        else if IsTime then
          vls := TimeToStr(f)
        else if IsDateTime then
          vls := DateTimeToStr(f)
        else
          vls := FloatToStr(f);
      end;
{$IFNDEF WEBLIB}
    tkInt64:
      vls := IntToStr(GetInt64Prop(o, p));
{$ENDIF}
    tkSet:
      begin
        v := GetOrdProp(o, p);
        s := tstringlist.create;
        sv := tstringlist.create;
        try
          sv.delimiter := ',';
          GetEnumValues(s);
          J := 1;
          for i := 0 to s.count - 1 do
          begin
            if v and J > 0 then
              sv.Add(s[i]);

            J := J * 2;
          end;

          vls := sv.delimitedtext;
        finally
          sv.Free;
          s.Free;
        end;
      end
  else
    begin
      en := GetEnumNameEx(typeinfo(TTypeKind), Integer(k));
      // raise Exception.CreateFmt('Cannot read property %s with type %s', [pName, en]);
    end;
  end;
  if max<>-1 then
  begin
    if length(vls)>max then vls:=copy(vls,1,max) + '...';

  end;
  Result := vls;
end;

function txProp.IsColor: Boolean;
begin
  Result := (GetPropInfoTypeName = 'TAlphaColor') or (GetPropInfoTypeName = 'TColor') or (GetPropInfoTypeName = 'TGraphicsColor');
end;

function txProp.IsDescendingClass(AClassParentList: array of string): Boolean;
var
  cn: string;
  i: Integer;
  aClass: tclass;
begin
 aclass:=instance.ClassType;
  if not Assigned(AClass) then
    Exit(false);
  repeat
    cn := AClass.ClassName;
    for i := 0 to Length(AClassParentList) - 1 do
    begin
      if (cn = AClassParentList[i]) then
        Exit(true);
    end;
    AClass := AClass.ClassParent;
  until not Assigned(AClass);
  Result := false;
end;

function txProp.isEnum: boolean;
begin
 result:=prop.PropertyType.TypeKind=tkEnumeration;
end;

function txProp.IsFillKind: Boolean;
begin
  Result := (GetPropInfoTypeName = 'TTMSFNCGraphicsFillKind');
end;

function txProp.isObject: boolean;
begin
  result:=prop.PropertyType.TypeKind=tkClass;
end;

function txProp.IsComponent: Boolean;

begin

  Result := IsDescendingClass(['TComponent', 'TTMSFNCCustomComponent']);
end;

function txProp.IsControl: Boolean;
begin
  Result := IsDescendingClass( ['TControl']);
end;

function txProp.IsDate: Boolean;
begin
  Result := (GetPropInfoTypeName = 'TDate');
end;

function txProp.IsDateTime: Boolean;
begin
  Result := (GetPropInfoTypeName = 'TDateTime');
end;

function txProp.IsTime: Boolean;
begin
  Result := (GetPropInfoTypeName = 'TTime');
end;

{ txInstance }

{ txInstance }

constructor txInstance.create(ainstance: tobject);
begin

	finstance := ainstance;
	fclasstype := nil;
	fproperties := nil;
  if assigned(fInstance) then
  begin
    GetProperties;
    GetPropList;
    GetMethods;
    GetMethodList;
  end;
end;

{ constructor txInstance.create(aclasstype: tclass);
	begin
	fclasstype := aclasstype;
	finstance := nil;
	end; }

destructor txInstance.destroy;
begin

end;

function txInstance.DoMethod(aname: string): tvalue;
var
 aMethod: trttiMethod;
begin
 aMethod:=Method(aname);
 if assigned(aMethod) then
 begin
   result:=aMethod.invoke(fInstance,[]);
 end;

end;

function txInstance.GetMethodList: tStringList;
var
 aMethod: trttiMethod;
 i: integer;
begin
  if assigned(fMethodList) then
  begin
    result:=fMethodList;
    exit;
  end;
  fMethodList:=TStringList.create;
  fMethodList.CaseSensitive:=false;
  for i := 1 to length(fMethods) do
    begin
      aMethod:=fMethods[i-1];
      fMethodList.AddObject(aMethod.name, amethod);
    end;
   result:=fMethodList;
end;

function txInstance.GetMethods: tarray<trttiMethod>;
var
	ctx: TRttiContext;
	rttiprop: TRttiProperty;
	rttitype: TRttiStructuredType;
begin
	try
		try
			ctx := TRttiContext.create;
			//alog.send('IsInstance');
			//alog.send(IsInstance);

			if IsInstance then
				rttitype := ctx.gettype(finstance.classtype) as TRttiStructuredType
			else
				rttitype := ctx.gettype(fclasstype) as TRttiStructuredType;

			result := rttitype.GetMethods;
			fMethods := result;
			//alog.send('GOT PROPERTIES');
			//alog.send(length(result));
		except
			on e: exception do
			begin
				alog.send('Exception txInstance.getproperties', e.message);
			end;
		end;

	finally
		ctx.free;
	end;
end;

function txInstance.GetProperties: TRttiPropertyArray;
var
	ctx: TRttiContext;
	rttiprop: TRttiProperty;
	rttitype: TRttiStructuredType;
begin
	try
		try
			ctx := TRttiContext.create;
			//alog.send('IsInstance');
			//alog.send(IsInstance);

			if IsInstance then
				rttitype := ctx.gettype(finstance.classtype) as TRttiStructuredType
			else
				rttitype := ctx.gettype(fclasstype) as TRttiStructuredType;

			result := rttitype.GetProperties;
			fproperties := result;
			//alog.send('GOT PROPERTIES');
			//alog.send(length(result));
		except
			on e: exception do
			begin
				alog.send('Exception txInstance.getproperties', e.message);
			end;
		end;

	finally
		ctx.free;
	end;
end;

function txInstance.GetProperty(const AName: string): TRttiProperty;
var
	ctx: TRttiContext;
	rttiprop: TRttiProperty;
	rttitype: trttitype;
begin
	try
		try
			ctx := TRttiContext.create;
			if IsInstance then
				rttitype := ctx.gettype(finstance.classtype) as TRttiStructuredType
			else
				rttitype := ctx.gettype(fclasstype) as TRttiStructuredType;

			rttiprop := rttitype.GetProperty(AName);
			result := rttiprop;
		except
			on e: exception do
			begin
				alog.send('Exception txInstance.getproperty', e.message);
			end;
		end;
	finally
		ctx.free;
	end;
end;

function txInstance.GetPropList: tstringlist;
var
	rttiprop: TRttiProperty;
	i: integer;
begin
	// if fproperties = nil then
	// GetProperties;
	// GetProperties;
  if assigned(fPropList) then
  begin
    result:=fPropList;
    exit;
  end;
  fPropList:=tStringlist.create;
  Result:=fPropList;
//	result := tstringlist.create;
	result.CaseSensitive := false;
  result.sorted:=true;
	//alog.send(fproperties);

	for i := 1 to length(fproperties) do
	begin
		try
			rttiprop := fproperties[i - 1];
			// if rttiprop.Visibility = mvpublished then
			// begin

			// alog.send(rttiprop);

			result.add(rttiprop.name);
			// end;
		except
			on EZeroDivide do
				alog.send('hello');
			on E2: EOverflow do
				alog.send('hello');
			else
				alog.send('nothing');
		end;
	end;

end;

function txInstance.HasMethod(aname: string): boolean;
begin
 result:=MethodList.indexof(aname)<>-1;
end;

function txInstance.HasProp(aname: string): boolean;
begin
 result:=false;
 if assigned(fPropList) then
  result:=fPropList.indexof(aname)<>-1;
end;


{$IFDEF fwweb}

function txInstance.GetValue(const AName: string): JSVALUE;
var
	ctx: TRttiContext;
	rttiprop: TRttiProperty;
	rttitype: trttitype;
begin
	try
		try

			ctx := TRttiContext.create;
			rttitype := ctx.gettype(finstance.classtype) as TRttiStructuredType;
			rttiprop := rttitype.GetProperty(AName);
			// console.log(rttiprop.GetValue(xcontrol));
			result := rttiprop.GetValue(finstance).asjsvalue;
		except
			on e: exception do
			begin
				alog.send('Exception txInstance.getvalue', e.message);
			end;
		end;
	finally
		ctx.free;
	end;
end;

{$ELSE}

function txInstance.GetValue(const AName: string): variant;
var
	ctx: TRttiContext;
	rttiprop: TRttiProperty;
	rttitype: trttitype;
begin
	result := GetTValue(AName).AsVariant;
end;



{$ENDIF}

function txInstance.GetTValue(const AName: string): TValue;
var
	ctx: TRttiContext;
	rttiprop: TRttiProperty;
	rttitype: trttitype;
begin
	try
		try
			// alog.send('getting TVALUE ' + aname);
			ctx := TRttiContext.create;
			rttitype := ctx.gettype(finstance.classtype);
			rttiprop := rttitype.GetProperty(AName);
			result := rttiprop.GetValue(finstance);
		except
			on e: exception do
			begin
				alog.send('Exception txInstance.gettvalue', e.message);
			end;
		end;
	finally
		ctx.free;
	end;
end;

function txInstance.IsInstance: boolean;
begin
	result := finstance <> nil;
end;

function txInstance.isnil: boolean;
begin
	result := finstance = nil;
end;

function txInstance.Method(aname: string): trttiMethod;
begin
  if MethodList.indexof(aname)<>-1 then
   result:=trttiMethod(MethodList.Objects[MethodList.indexof(aname)]);

end;

{$IFDEF fwweb}

procedure txInstance.SetValue(const AName: string; const AValue: JSVALUE); overload;
var
	ctx: TRttiContext;
	rttitype: trttitype;
	rttiprop: TRttiProperty;
  value: tvalue;
begin
	try
		try
			ctx := TRttiContext.create;
			rttitype := ctx.gettype(finstance.classtype);
			rttiprop := rttitype.GetProperty(AName);
      value:=tvalue.fromjsvalue(avalue);
			rttiprop.SetValue(finstance, value);   //AValue
		except
			on e: exception do
			begin
				alog.send('Exception txInstance.setvalue', e.message);
			end;
		end;

	finally
		ctx.free;
	end;
end;
{$ENDIF}


{$IFDEF fwVCL}
procedure txInstance.SetChildValues(const AName: string; const AValue: TValue;
			aclass: tclass = nil);
begin

end;
{$IFEND}

{$IFDEF fwWeb}
procedure txInstance.SetChildValues(const AName: string; const AValue: TValue;
			aclass: tclass = nil);
begin

end;
{$IFEND}

{$IFDEF fwfmx}

procedure txInstance.SetChildValues(const AName: string; const AValue: TValue;
	aclass: tclass);
procedure SetChildValue(ac: tfmxobject; aclass: tclass = nil);
	var
		rti: txInstance;
		prop: TRttiProperty;
		i: integer;
		ao: tfmxobject;
		passClass: boolean;
	begin

		for i := 1 to ac.childrencount do
		begin
			ao := ac.Children[i - 1];
			if ao is tcontrol then
			begin

					passClass := true;
					if aclass <> nil then
					begin
       //    alog.Send(ao.classname);
						if aclass <> ao.classtype then
							passClass := false;
					end;
					if passClass then
					begin
            try
						rti := txInstance.create(ao);

					 	prop := rti.GetProperty(AName);
						if prop <> nil then

							rti.SetValue(AName, AValue);
          	finally
					rti.free;
				end;
					end;


				SetChildValue(ao, aclass);
			end;
		end;
	end;

var
	fac: tfmxobject;
begin
	if finstance = nil then
		exit;
	if finstance is tfmxobject then
	begin
		fac := tfmxobject(finstance);
		SetChildValue(fac, aclass);
	end;
	{ ac:=tfmxobject(finstance);
		for i := 1 to ac.childrencount do
		begin
		ao:=ac.Children[i-1];
		if ao is tcontrol then
		begin
		try
		rti:=txInstance.create(ao);

		prop:=rti.GetProperty(aname);
		if prop<>nil then


		rti.SetValue(aname, avalue);
		finally
		rti.Free;
		end;

		SetChildValues(ao);
		end;
		end; }

end;
{$IFEND}

procedure txInstance.SetValue(const AName: string; const AValue: TValue);
var
	ctx: TRttiContext;
	rttitype: trttitype;
	rttiprop: TRttiProperty;
begin
	try
		try
			ctx := TRttiContext.create;
			rttitype := ctx.gettype(finstance.classtype);

			// if rttitype.HasName(aname) then
			// begin
      alog.Send('SEtting ' + aname);
			rttiprop := rttitype.GetProperty(AName);
			rttiprop.SetValue(finstance, AValue);

			// end;

		except
			on e: exception do
			begin
				alog.send('Exception txInstance.setvalue', e.message);
			end;
		end;

	finally
		ctx.free;

	end;
end;



end.
