unit x.login.client;

interface
uses

  x.xdata.connect,
  sysutils, classes, x.login, system.Generics.Collections, x.AppInfo, x.login.types,
  {$IFDEF fwweb}
  JS, Web, xweb.cookies,
  {$ELSE}
  xdata.client, sparkle.Http.client, ioUtils,
  {$ENDIF}
  liblogtest;

type
  txLoginEvent = procedure(auser: txUser) of object;
  txLogin = class(txDataService)
  private
    Fmyip: string;
    FJWT: string;

    FonLogin: txLoginEvent;
    function GetJWT: string;
    procedure SetJWT(const Value: string);
    procedure SetUser(const Value: txUser);
  public
    HasUser: boolean;
    FUser: txUser;
    {$IFDEF fwweb}
    cookies: txCookies;
    {$ENDIF}
    svc: ILoginService;
    function LoadToken(var aToken: string): boolean;
    function SaveToken(aToken: string): boolean;
    procedure SetError(const Value: string); override;
    procedure CheckURI(var aURI: string); override;
    [Async] procedure Init; async; override;
    constructor create(aURI: string = ''); override;
    [Async] function login(un, pw: string): txuser; async;
    [Async] function Checklogin(un, pw: string): txuser; async;


    [Async] function CreateUser(email, firstname, lastname, pw: string): txuser; async;
    [Async] function GetUser: txuser; async;
    procedure Logout;

  published
    property myip: string read Fmyip write Fmyip;
    property JWT: string read GetJWT write SetJWT;
    property User: txUser read FUser write SetUser;
    property onLogin: txLoginEvent read FonLogin write FonLogin;

  end;

var
  xLogin: txLogin;

implementation

{ txLogin }



procedure txLogin.CheckURI(var aURI: string);
begin
  inherited;

end;

constructor txLogin.create(aURI: string);
begin
  inherited;
  fuser := nil;
end;

function txLogin.CreateUser(email, firstname, lastname,
  pw: string): txuser;
begin
  result := {$IFDEF fwweb}await({$ENDIF}svc.AddUser(email, firstname, lastname, pw){$IFDEF fwweb}){$ENDIF};
end;

function txLogin.GetJWT: string;
begin
  result := jwtToken;
end;

function txLogin.GetUser: txuser;
begin
  try
    result := nil;

    result := {$IFDEF fwweb}await({$ENDIF}svc.GetUser{$IFDEF fwweb}){$ENDIF};
  except
    on e: exception do
    begin
      result := nil;
    end;

  end;
end;

procedure txLogin.SetJWT(const Value: string);
begin
  alog.send('SetJWT', value);
  if ((value = '') or (value = 'none')) then
  begin
    alog.send('Deleting token');
    cookies.cookies.delete('token');
    cookies.cookies.SetCookies;
    jwtToken := '';
  end
  else
  begin
    jwtToken := value;
    SaveToken(value);
  end;

end;

procedure txLogin.SetUser(const Value: txUser);
begin
  FUser := Value;
  if assigned(value) then
    hasUser := true
  else
    hasUser := false;

  if assigned(onLogin) then onLogin(value);

end;

procedure txLogin.Init;
var
  s: string;
begin
  inherited;
  try
    {$IFDEF fwweb}
    Cookies := txCookies.create;
    {$ENDIF}
    svc := Connection.xdclient.Service<iLoginService>;
    myIP := {$IFDEF fwweb}await({$ENDIF}svc.GetremoteIP{$IFDEF fwweb}){$ENDIF};
    Error := '';
    if LoadToken(s) then
    begin
      if s <> '' then
      begin
        alog.send('Got token, getting user');
        JWTToken:=s;
        user := await(GetUser);
        if assigned(user) then
        begin
          alog.send('got user, setting JWT');
          JWT := S;
       end
        else
          Logout;
        //User:={$IFDEF fwweb} await({$ENDIF}GetUser{$IFDEF fwweb}){$ENDIF};

      end
      else
        Logout;
    end
    else
      Logout;

  except
    on e: exception do
    begin
      fuser := nil;
      Error := e.message;
      alog.error('txLogin.init', e.message);
    end;

  end;

end;

{$IFDEF fwweb}
function txLogin.LoadToken(var aToken: string): boolean;
begin
  aToken := '';
  result := cookies.get('token', aToken);
  if Result then
    alog.send('Loaded JWT cookie')
  else
    alog.send('No JWT cookie');
end;

function txLogin.SaveToken(aToken: string): boolean;
begin
  cookies.save(atoken, 'token');

end;

{$ELSE}
function txLogin.SaveToken(aToken: string): boolean;
begin

end;

function txLogin.LoadToken(var aToken: string): boolean;
begin

end;

{$ENDIF}

function txLogin.login(un, pw: string): txuser;
begin
 try
 result:=nil;
  result := {$IFDEF fwweb}await({$ENDIF}svc.Login(un, pw){$IFDEF fwweb}){$ENDIF};
  if assigned(result) then
  begin

    JWT := result.jwt;
    User := result;
  end
  else
  begin
    user := nil;
    JWT := 'none';
  end;
 except
  on e: exception do
  begin
    user:=nil;
    result:=nil;
    jwt:='none';
  end;

 end;
end;

function txLogin.Checklogin(un, pw: string): txuser;
begin
 try
 result:=nil;
  result := {$IFDEF fwweb}await({$ENDIF}svc.Login(un, pw){$IFDEF fwweb}){$ENDIF};
  if assigned(result) then
  begin


  end
  else
  begin

  end;
 except
  on e: exception do
  begin

  end;

 end;
end;


procedure txLogin.Logout;
begin
  console.log('LOGOUT');
  JWT := 'none';
  user := nil;
end;

procedure txLogin.SetError(const Value: string);
begin
  inherited;

end;

end.

