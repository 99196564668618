unit x.appstyler;

interface

//  {$INCLUDE project.inc}
 uses
    classes, system.generics.collections, system.uitypes, system.types,x.controls, liblogtest,

 {$IFDEF fwfmx}
   FMX.graphics;
 {$ENDIF}
 {$IFDEF fwvcl}
    VCL.graphics;
 {$ENDIF}
 {$IFDEF fwweb}
     vcl.graphics;
 {$ENDIF}



   type

   txAppStyleFonts=tobjectlist<txFont>;



  txAppStyle = class
    private
    FHeaderFont: tFont;
    Fh1: txFont;
    Fh2: txFont;
    Fh3: txFont;
    Fb1: txFont;
    Fh4: txFont;
    Fi2: tPoint;
    fi1: tpoint;
    Fi3: tpoint;
    Fi4: tpoint;
    public
     Fonts: txAppStyleFonts;
     constructor create;
      procedure allFonts(afamily: string; asize: integer=-1);
      procedure SetDefaults;
    published
     property h1: txFont read Fh1 write Fh1;
     property h2: txFont read Fh2 write Fh2;
     property h3: txFont read Fh3 write Fh3;
     property h4: txFont read Fh4 write Fh4;
     property b1: txFont read Fb1 write Fb1;
     property I1: tpoint read fi1 write fi1;
     property i2: tPoint read Fi2 write Fi2;
     property i3: tpoint read Fi3 write Fi3;
     property i4: tpoint read Fi4 write Fi4;

  end;


  var
    AppStyle: txAppStyle;

implementation

{ txAppStyle }

procedure txAppStyle.allFonts(afamily: string; asize: integer);
var
 afont: txfont;
 I: integer;
begin

 for i:=1 to fonts.count do
   begin
     afont:=fonts[i-1];
   {$IFDEF fwfmx}
   if afamily<>'' then afont.family:=aFamily;
  {$ELSE}
     if afamily<>'' then afont.name:=aFamily;
   {$ENDIF}

     if asize<>-1 then afont.size:=aSize;

   end;

end;

constructor txAppStyle.create;
begin
 fonts:=txAppStyleFonts.create;

 h1:=txfont.create;
 h2:=txFont.create;
 h3:=txFont.create;
 h4:=txFont.Create;
 b1:=txfont.create;
 alog.send('h1', h1);
 Fonts.add(h1);
 fonts.add(h2);
 fonts.add(h3);
 fonts.add(b1);
 SetDefaults;

end;

procedure txAppStyle.SetDefaults;
begin
 AllFonts( 'Microsoft YaHei UI', 12);
 h1.size:=18;
 h2.size:=16;
 h3.size:=14;
 h4.size:=12;
 b1.size:=12;

 h4.style:=[ tfontstyle.fsBold];

 i1:=point(128,128);
 i2:=point(64,64);
 i3:=point(32,23);
 i4:=point(16,16);



end;

initialization
if not assigned(appstyle) then  AppStyle:=txAppStyle.create;


end.
