unit x.css.boxShadow;

interface
  uses web,weblib.forms, js, classes, x.css, vcl.controls;


    type txCSSBoxShadow=class(txCSSBase)
    private

    public
     constructor create;
     procedure loadcss; override;
     procedure AddDefaults;
    published


    end;

    var
     xcssBoxShadow: txCSSBoxShadow;
implementation

{ txCSSBoxShadow }

procedure txCSSBoxShadow.AddDefaults;
var
 acss:TJSCSSStyleDeclaration;
begin
  acss:=xCss.NewClass('xboxshadow-rainbow');
  acss.setProperty('box-shadow','rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px');
  acss.setProperty('z-index', Application.MaxZIndexStr);

  acss.setProperty('border', 'none');
 console.log('BOXSTYLE', acss);
end;

constructor txCSSBoxShadow.create;
begin
     mytype:='.xboxshadow';

end;

procedure txCSSBoxShadow.loadcss;
begin
  inherited;

end;

initialization
   xcssBoxShadow:=txCSSBoxShadow.create;

end.
