unit x.fs.client;

interface
uses

  x.xdata.connect,
  sysutils, classes, x.fs, x.fs.types, system.Generics.Collections, x.AppInfo,
  {$IFDEF fwweb}
  JS, Web;
{$ELSE}
  xdata.client, sparkle.Http.client, ioUtils, liblogtest;
{$ENDIF}

type
  txfsServerInfo = class(txInfo)
  private
    fServer: string;
    fConnections: integer;
    FErrors: string;
    FIP: string;
    el: tStringList;
    SL: tStringlist;
    FServices: string;
    FErrorCount: Integer;
    function GetErrors: string;
    function GetServices: string;
    function GetErrorCount: Integer;
    procedure SetServer(const Value: string);

  public
    UIObject: tobject;
    procedure AddError(aError: string);
    procedure AddService(s: string);
    constructor create; override;
  published
    property Server: string read fServer write SetServer;
    property Services: string read GetServices write FServices;
    property Connections: integer read fConnections;
    property Errors: string read GetErrors;
    property ErrorCount: Integer read GetErrorCount;
    property IP: string read FIP write FIP;
  end;

type
  txfsServers = tobjectList<txfsServerInfo>;

type
  txFSInfo = class
  private

    FServers: txfsServers;
    Fcount: integer;
    function getCount: integer;

  public
    UIObject: tobject;
    function AddConnection(aServer: string): txfsServerInfo;
    procedure AddError(aServer: string; aError: string);
    constructor create;
    function GetServer(aURI: string): txfsServerInfo;
  published
    property Servers: txfsServers read FServers write FServers;
    property count: integer read getCount;
  end;

type
  txFSOPtions = class
  private
    FAutoURI: boolean;
    FPreferHTTPS: boolean;
    FDefaultURL: string;
    FAutoCreateLookup: boolean;
    FAutoIncludeRoot: boolean;
    FURLStart: string;
    function getURLStart: string;
  public
    constructor create;
  published
    //URI
    property AutoURI: boolean read FAutoURI write FAutoURI;
    property PreferHTTPS: boolean read FPreferHTTPS write FPreferHTTPS;
    property DefaultURL: string read FDefaultURL write FDefaultURL;
    property URLStart: string read getURLStart;
    //bsFileList
    property AutoCreateLookup: boolean read FAutoCreateLookup write FAutoCreateLookup;
    property AutoIncludeRoot: boolean read FAutoIncludeRoot write FAutoIncludeRoot;
  end;
type

  txFS = class(txDataService)
  private
    Fserverdir: string;
    FServerURI: string;
    FRootDir: string;
    FPathMap: tbsPathMap;
    FActiveRoot: tbsPathMapItem;
    FOptions: txFSOptions;
    FReady: boolean;
  public
    svc: iFS;
    [ASync] function GetServerDir: string; async;
    procedure SetError(const Value: string); override;
    procedure CheckURI(var aURI: string); override;
    [Async] procedure Init; async; override;
    constructor create(aURI: string = ''); override;
    procedure mark(afile: tbsFile); overload;
    procedure mark(alist: tbsFileList); overload;
    function doroot(apath: string): string;
    [ASync] procedure AddInfo; async;
    [ASync] procedure AddInfoIP; async;
    procedure AddClientInfo(s: string);
    procedure AddError(aError: string);
    procedure CheckReady; //should be auto called before any call in xdconnect but need to deal with init
     procedure InternalStatus(aStatus: txDataStatus); override;
    [ASync] function GetIP: string; async;
    [ASync] function GetPathMap: tbsPathMap; async;
    [ASync] function GetItems(apath: string; amask: string; doDirs, doFiles, doRecurse: boolean; fromRoot: boolean): tbsfilelist; async;
    [ASync] function getall(apath: string; FromRoot: boolean; aNoInfo: boolean = false): tbsfilelist; async;
    [ASync] function GetFolders(apath: string; recurse: boolean; fromRoot: boolean): tbsfilelist; async;
    [ASync] function GetFiles(apath: string; recurse: boolean; fromRoot: boolean): tbsfilelist; async; overload;
    [ASync] function GetFiles: tbsfilelist; async; overload;
    [ASync] function GetFile(afile: string; fromRoot: boolean = true): tbsfile; async;
    {$IFNDEF fwWeb}
    [ASync] function Download(aserverfile, alocalfile: string; ifnewer: boolean; FromRoot: boolean = true): tbsfile; overload;
    [ASync] function Download(var aServerFile: tbsfile; alocalfile: string; ifnewer: boolean): tbsfile; overload;
    [ASync] function Download(aServerFile: string; ifNewer: boolean; FromRoot: boolean): tbsFile; overload;
    [ASync] function Download(var aServerFile: tbsFile; ifNewer: boolean): tbsFile; overload;
    function GetLocalFn(aServerFN: string; alocalroot: string = ''): string;
    [ASync] function GetLocal(afile: string): tbsfile;
    [ASync] function Upload(alocalfile: string; aserverfile: string; FromRoot: boolean = true): boolean; overload;
    {$ENDIF}
    [Async] function DownloadStream(afile: tbsFile; fromRoot: boolean = true): tStream; async;
    [ASync] function DownloadText(afile: string; FromRoot: boolean = true): string; async; overload;
    [ASync] function DownloadText(aFile: tbsFile): string; async; overload;

    [ASync] function Upload(var alocalfile: tMemoryStream; aserverfile: string; FromRoot: boolean = true): boolean; async; overload;
    [ASync] function UploadText(afn: string; aContent: string; FromRoot: boolean = true): boolean; async;

    [ASync] function IsNewer(aRemoteFile, aLocalFile: tbsfile): boolean; async;
    [Async] function NewFile(afn: string; atext: string): boolean; async;
    [Async] function DeleteFile(afn: string): boolean; async;
    [Async] function RenameFile(afn: string; aNewfn: string): tbsFile; async;
    [Async] function GetNames(apath: string; amask: string; doDirs, doFiles, doRecurse: boolean; fromRoot: boolean): string; async;
    [Async] function GetAllNames(apath: string; fromroot: boolean = true): string; async;
  published
    property Options: txFSOptions read FOptions write FOptions;
    property PathMap: tbsPathMap read FPathMap write FPathMap;
    //property serverdir: string read GetServerDir write Fserverdir;
    property ServerURI: string read FServerURI write FServerURI;
    property RootDir: string read FRootDir write FRootDir;
    property ActiveRoot: tbsPathMapItem read FActiveRoot write FActiveRoot;
    property Ready: boolean read FReady write FReady;
  end;

var
  xfsInfo: txFSInfo;

implementation
uses
  DateUtils;

{ txFS }

function txFS.doroot(apath: string): string;
begin
  // result:=combinepaths(RootDir, apath, '\');
  result := activeRoot.path;
  if apath = '' then
    exit;

  if Result[Length(result)] <> '\' then
    result := result + '\';
  result := result + aPath;
end;

{$IFNDEF fwWeb}

function txFS.GetLocal(afile: string): tbsfile;
begin

  var
  attr: tfileAttributes;
  begin
    result := tbsfile.create;
    result.Checktimes := DateTimeToStr(now);

    if tfile.Exists(afile, false) then
    begin
      attr := tfile.GetAttributes(afile);
      result.attr := tfile.FileAttributesToInteger(attr);
      // result.IsDir := system.ioutils.TFileAttribute.faDirectory in attr;
      result.IsDir := false;
      result.WriteTimes := DateTimeToStr(tfile.GetLastWriteTime(afile));
      result.AccessTimes := DateTimeToStr(tfile.GetLastAccessTime(afile));
      result.CreateTimes := DateTimeToStr(tfile.GetCreationTime(afile));
      result.size := tfile.GetSize(afile);
      result.Fullfn := afile;
      result.Exists := true;
      Mark(result);
    end
    else if tdirectory.Exists(afile, false) then
    begin
      result.IsDir := true;
      attr := tdirectory.GetAttributes(afile);
      result.attr := tfile.FileAttributesToInteger(attr);
      // result.IsDir := system.ioutils.TFileAttribute.faDirectory in attr;

      result.WriteTimes := DateTimeToStr(tdirectory.GetLastWriteTime(afile));
      result.AccessTimes := DateTimeToStr(tdirectory.GetLastAccessTime(afile));
      result.CreateTimes := DateTimeToStr(tdirectory.GetCreationTime(afile));
      //result.size := tdirectory.GetSize( afile ); ***

      result.Fullfn := afile;
      result.Exists := true;
      mark(result);
    end;

  end;

end;

function txFS.GetLocalFn(aServerFN: string; alocalroot: string = ''): string;
var
  sfn, lfn: string;
begin
  sfn := ActiveRoot.path;
  if alocalroot = '' then
    lfn := ActiveRoot.LocalPath
  else
    lfn := aLocalRoot;
  if sfn[length(sfn)] <> '\' then
    sfn := sfn + '\';
  if lfn[Length(lfn)] <> '\' then
    lfn := lfn + '\';

  result := StringReplace(lowercase(aServerFN), sfn, lfn, [rfIgnoreCase] );

end;

function txFS.Download(var aServerFile: tbsFile; ifNewer: boolean): tbsFile;
var
  dlfn, e: string;
begin
  if aserverfile.local.FullFN <> '' then
    dlfn := aserverfile.local.FullFN
  else
    dlfn := GetLocalFN(aServerFile.FullFN);
  if dlfn = '' then
  begin
    result := aserverfile;
    e := 'No filename specified and no local folder for pathmap';
    result.error := e;
    alog.error('Download', e);
    exit;
  end;
  //alog.send('Downloading ' + dlfn);
  result := Download(aServerFile, dlfn, ifNewer);
end;

function txFS.Download(aServerFile: string; ifNewer: boolean; FromRoot: boolean): tbsFile;
var
  lfn: string;
begin
  if fromroot then
    aServerFile := doroot(aServerFile);

  lfn := GetLocalFn(aServerFile);
  Result := Download(aServerFile, lfn, ifNewer, false);

end;

function txFS.Download(aserverfile, alocalfile: string; ifnewer, FromRoot: boolean): tbsfile;
var

  afile: tbsfile;
  sfn, sp: string;
begin
  try
    result := nil;
    if fromroot then
      sfn := doroot(aserverfile)
    else
      sfn := aserverfile;

    afile := getfile(sfn, fromroot);
    result := download(afile, alocalfile, ifnewer);
  except
    on e: exception do
    begin
      result := nil;
    end;

  end;
end;

function txFS.Download(var aServerFile: tbsfile; alocalfile: string; ifnewer: boolean): tbsfile;
var
  astream: tstream;
  sfn, sp: string;
  st: tdateTime;
  tt: integer;
begin
  try
    result := aServerFile;
    if not assigned(aserverfile) then exit;
    if aServerFile.exists = false then
    begin
     result.error:='ServerFile is marked as does not exist';
     exit; //?
    end;

    if ifnewer then
    begin
      if fileexists(alocalfile) then
      begin
        if aServerFile.svWriteTime <= tfile.GetLastWriteTime(alocalfile) then
        begin

          result.local.FullFN := alocalfile;
          result.local.CheckTime := now;
          result.local.skipped := true;
          result.local.error := '';
          result.downloaded := true;
          result.dlskipped := true;
          result.error := '';
          exit;
        end;

        {  result := getlocal(alocalfile);
          result.downloaded := true;
          result.Error := '';
          aServerFile.Error := '';
          aServerFile.downloaded := true;
          aServerFile.dlskipped := true;
          exit;}
      end;
    end;

    astream := tmemorystream.Create;
    st := now;
    astream := svc.DownloadFile(aServerFile.Fullfn);
    tt := MilliSEcondsBetween(now, st);

    astream.Seek(0, sofrombeginning);
    forcedirectories(extractfilepath(alocalfile));
    tmemorystream(astream).savetofile(alocalfile);

    astream.Free;

    tfile.SetLastWriteTime(alocalfile, aServerFile.svWriteTime);

    result.dlskipped := false;
    result.downloaded := true;
    result.error := '';
    result.local.FullFN := alocalfile;
    result.local.Downloaded := now;
    result.local.Skipped := false;
    result.local.CheckTime := now;
    { result := getlocal(alocalfile);
     result.dlskipped := false;
     result.downloaded := true;
     result.Error := '';
     aServerFile.Error := '';
     aServerFile.downloaded := true;}

  except
    on e: exception do
    begin
      alog.error('xfs.download ' + alocalfile, e.message);
      result.Error := 'xfs.download ' + alocalfile + ' ' + e.message;
      result.local.error := result.error;
     //Removed..?
   //   result := nil;
    end;

  end;

end;

function txFS.Upload(alocalfile, aserverfile: string;
  FromRoot: boolean): boolean;
var
  astream: tstream;
  sfn: string;
begin
  if fromroot then
    sfn := doroot(aserverfile)
  else
    sfn := aserverfile;
  try
    astream := tmemorystream.Create;

    tmemorystream(astream).loadfromfile(alocalfile);
    astream.Seek(0, sofrombeginning);
    result := svc.Uploadfile(astream, sfn);
    astream.Free;
  except
    on e: exception do
    begin
      alog.error('txdfiles.Upload ' + aserverfile, e.message);
    end;

  end;
end;

{$ENDIF}

function txFS.DownloadStream(afile: tbsFile; fromRoot: boolean): tStream;
begin
  result := tmemorystream.Create;
     Result :=   {$IFDEF fwweb} await({$ENDIF}svc.DownloadFile(afile.Fullfn){$IFDEF fwweb}){$ENDIF};


  //  result.Seek(0, 0);
end;


function txfs.Upload(var alocalfile: tMemoryStream; aserverfile: string;
  FromRoot: boolean = true): boolean;
var
  sfn: string;
begin
  if fromroot then
    sfn := doroot(aserverfile)
  else
    sfn := aserverfile;
  //try
  result := {$IFDEF fwweb}await({$ENDIF}svc.Uploadfile(aLocalFile,
    sfn){$IFDEF fwweb}){$ENDIF};

  {astream.Free;
  except
    on e: exception do
    begin
     // alog.error('txdfiles.Upload ' + aserverfile, e.message);
    end;

   end;   }
end;

function txFS.UploadText(afn: string; aContent: string; FromRoot: boolean):
  boolean;
begin
  if fromroot then afn := doroot(afn);
  result := {$IFDEF fwweb}await({$ENDIF}svc.UploadfileText(afn, aContent){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetFile(afile: string; fromRoot: boolean): tbsfile;
begin
  //result:=nil;
  if FromRoot then afile := doRoot(afile);
  result := {$IFDEF fwweb}await({$ENDIF}svc.GetFile(afile){$IFDEF fwweb}){$ENDIF};
  mark(result);
end;

function txFS.GetItems(apath: string; amask: string; doDirs, doFiles, doRecurse:
  boolean; fromRoot: boolean): tbsfilelist;
begin
  if FromRoot then apath := doRoot(apath);
  result := {$IFDEF fwweb}await({$ENDIF}svc.GetItems(apath, amask, doDirs, DoFiles, doRecurse){$IFDEF fwweb}){$ENDIF};
  mark(result);
  if options.AutoCreateLookup then result.UpdateLookup;
end;

function txfs.getall(apath: string; FromRoot: boolean; aNoInfo: boolean = false): tbsfilelist;
begin
  if FromRoot then apath := doRoot(apath);
  result := {$IFDEF fwweb}await({$ENDIF}svc.GetAll(apath, aNoInfo){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetNames(apath: string; amask: string; doDirs, doFiles, doRecurse:
  boolean; fromRoot: boolean): string;
begin
  if FromRoot then apath := doRoot(apath);
  result := {$IFDEF fwweb}await({$ENDIF}svc.GetNames(apath, amask, doDirs, DoFiles, doRecurse){$IFDEF fwweb}){$ENDIF};

end;

function txFS.GetAllNames(apath: string; fromroot: boolean): string;
begin
  result := {$IFDEF fwweb}await({$ENDIF}GetNames(apath, '*.*', true, true, true, fromroot){$IFDEF fwweb}){$ENDIF};

end;

function txFS.GetFiles(apath: string; recurse,
  fromRoot: boolean): tbsfilelist;
begin
  if FromRoot then apath := doRoot(apath);
  result := {$IFDEF fwweb}await({$ENDIF}GetItems(apath, '*.*', false, true, recurse, false){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetFiles: tbsfilelist;
begin
  result := {$IFDEF fwweb}await({$ENDIF}GetItems(ActiveRoot.path, '*.*', false, true, true, false){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetFolders(apath: string; recurse,
  fromRoot: boolean): tbsfilelist;
begin
  if FromRoot then apath := doRoot(apath);
  result := {$IFDEF fwweb}await({$ENDIF}GetItems(apath, '*.*', true, false, recurse, false){$IFDEF fwweb}){$ENDIF};
end;

function txFS.GetIP: string;
begin
  try
    exit;
    result := {$IFDEF fwweb}await({$ENDIF}svc.MyIP{$IFDEF fwweb}){$ENDIF};
  except
    on e: exception do
    begin
      error := 'GetIP ' + e.message;
    end;

  end;
end;

function txFS.GetPathMap: tbsPathMap;
var
  s: string;
begin
  s := {$IFDEF fwweb}await({$ENDIF}svc.GetPathMap{$IFDEF fwweb}){$ENDIF};
  PathMap.PathMap.text := s;
  PathMap.UpdatePathMap;
end;

function txFS.GetServerDir: string;
begin
  result := {$IFDEF fwweb}await({$ENDIF}svc.Getserverdir{$IFDEF fwweb}){$ENDIF};

end;

procedure txFS.Init;
begin
  inherited;
  try
    AddINfo;
    PathMap := tbsPathMap.create;
    svc := Connection.xdclient.Service<iFS>;
    GetPathMap;
    if Pathmap.count <> 0 then
      ActiveRoot := Pathmap.pm(0);
    error := '';
    //AddInfoIP;
    Ready := true;
  except
    on e: exception do
    begin
      //   alog.error('Init', e.message);
      error := e.message;
      REady := false;
    end;

  end;

end;

procedure txFS.InternalStatus(aStatus: txDataStatus);
begin
  inherited;

end;

function txFS.IsNewer(aRemoteFile, aLocalFile: tbsfile): boolean;
begin
  Result := aremotefile.WriteTime > alocalfile.WriteTime;
end;

procedure txFS.mark(alist: tbsFileList);
var
  afile: tbsFile;
begin
  for afile in alist.files do
    afile.fsx := self;

end;

procedure txFS.mark(afile: tbsFile);
begin
  if assigned(afile) then afile.fsx := self;
end;

function txFS.NewFile(afn, atext: string): boolean;
begin
  result := {$IFDEF fwweb}await({$ENDIF}svc.uploadfiletext(afn, atext){$IFDEF fwweb}){$ENDIF};
end;

function txFS.RenameFile(afn, aNewfn: string): tbsFIle;
begin
  result := {$IFDEF fwweb}await({$ENDIF}svc.renameFile(afn, anewFN){$IFDEF fwweb}){$ENDIF};
end;

procedure txFS.SetError(const Value: string);
begin
  inherited;
  AddError(value);
end;

procedure txFS.AddError(aError: string);
begin
  if not assigned(xfsInfo) then xfsInfo := txfsInfo.create;
  xfsInfo.AddError(uri, aError);
end;

procedure txFS.AddInfo;
var
  aServer: txfsServerInfo;
begin
  if not assigned(xfsInfo) then xfsInfo := txfsInfo.create;
  aServer := xfsInfo.AddConnection(uri);

  aServer.ip := {$IFDEF fwweb}await({$ENDIF}GetIP{$IFDEF fwweb}){$ENDIF};
end;

procedure txFS.AddInfoIP;
var
  aServer: txfsServerInfo;
begin

  if not assigned(xfsInfo) then xfsInfo := txfsInfo.create;
  aServer := xfsInfo.getserver(uri);
  if assigned(aserver) then

    aServer.ip := {$IFDEF fwweb}await({$ENDIF}GetIP{$IFDEF fwweb}){$ENDIF};

end;

procedure txFS.AddClientInfo(s: string);
var
  aServer: txfsServerInfo;
begin

  if not assigned(xfsInfo) then xfsInfo := txfsInfo.create;
  aServer := xfsInfo.getserver(uri);
  if assigned(aserver) then

    aServer.AddService(s);

end;

procedure txFS.CheckReady;
begin
  if not Ready then exit;

end;

procedure txFS.CheckURI(var aURI: string);
var
  s: string;
begin
  if not assigned(options) then
    exit;

  if options.AutoURI = false then
    exit;

    exit;
  // alog.send('CheckURI',auri);
  inherited;
  s := lowercase(auri);
  if pos('http', s) <> 1 then
  begin
    auri := Options.URLStart + auri;
  end;
  if Options.DefaultURL <> '' then
  begin
    if pos(lowercase(Options.DefaultURL), s) = 0 then
      auri := auri + Options.DefaultURL;

  end;

  // alog.send(auri);

end;

constructor txFS.create(aURI: string);
begin
  inherited;
  Options := txfsOptions.create;
  Ready := false;
end;

function txFS.DeleteFile(afn: string): boolean;
begin
  result := {$IFDEF fwweb}await({$ENDIF}svc.DeleteFile(afn){$IFDEF fwweb}){$ENDIF};
end;



function txFS.DownloadText(aFile: tbsFile): string;
begin
  result := {$IFDEF fwweb}await({$ENDIF}svc.DownloadFileText(afile.FullFN){$IFDEF fwweb}){$ENDIF};
  afile.downloaded := true;

end;

function txFS.DownloadText(afile: string; FromRoot: boolean = true): string;
begin
  if FromRoot then afile := doroot(afile);

  result := {$IFDEF fwweb}await({$ENDIF}svc.DownloadFileText(afile){$IFDEF fwweb}){$ENDIF};
end;

{ txFSOPtions }

constructor txFSOPtions.create;
begin
  AutoURI := true;
  PreferHTTPs := true;
  DefaultURL := '/fs2';
  AutoCreateLookup := true;
  AutoIncludeRoot := true;
end;

function txFSOPtions.getURLStart: string;
begin
  if PreferHTTPs then
    result := 'https://'
  else
    result := 'http://';

end;

{ txFSInfo }

function txFSInfo.AddConnection(aServer: string): txfsServerInfo;
var
  server: txfsServerInfo;
begin
  Server := GetServer(aserver);
  Server.fConnections := Server.fConnections + 1;
  result := server;
end;

procedure txFSInfo.AddError(aServer, aError: string);
var
  server: txfsServerInfo;
begin
  Server := GetServer(aserver);
  server.addError(aError);
end;

constructor txFSInfo.create;
begin
  Servers := txfsServers.create;
end;

function txFSInfo.getCount: integer;
begin
  result := servers.count;
end;

function txFSInfo.GetServer(aURI: string): txfsServerInfo;
var
  aServer: txfsServerInfo;
begin
  for aServer in servers do
  begin
    if lowercase(aserver.Server) = lowercase(aURI) then
    begin
      result := aServer;
      exit;
    end;
  end;
  Result := txfsServerInfo.Create;
  result.Server := aURI;
  Servers.add(result);
end;

{ txfsServerInfo }

procedure txfsServerInfo.AddError(aError: string);
begin
  EL.add(aError);
end;

procedure txfsServerInfo.AddService(s: string);
begin
  sl.Add(s);
end;

constructor txfsServerInfo.create;
begin
  inherited;
  el := tStringlist.create;
  sl := tStringlist.create;
  xClass := 'txFS';
  //sl.Delimiter := ',';
  //el.Delimiter := ',';
end;

function txfsServerInfo.GetErrorCount: Integer;
begin
  result := sl.count;
end;

function txfsServerInfo.GetErrors: string;
begin
  result := el.text;
  //  result:=stringReplace(result, ',', ',<BR>', [rfIgnoreCase, rfReplaceALl]);
end;

function txfsServerInfo.GetServices: string;
begin
  Result := SL.text;
end;

procedure txfsServerInfo.SetServer(const Value: string);
begin
  fServer := Value;
  name := value;
end;

end.

