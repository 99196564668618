unit x.fs.types;

interface

uses
  x.ao, system.generics.collections,
  {$IFDEF MSWINDOWS}
  Bcl.Json.Attributes, liblogtest, quick.commons,
  {$ENDIF}
  {$IFDEF WEBLIB}
  web, liblogtest,
  {$ENDIF}
  classes;

type

  tbsFileList = class;
  tbsFile = class;

  TBsFilePath = class(tao)
  private
    FFullFN: string;
    FURL: string;
    FError: string;
  public
  published
    property FullFN: string read FFullFN write FFullFN;
    property URL: string read FURL write FURL;
    property Error: string read FError write FError;
  end;

  tbsChildren = tobjectlist<tbsFile>;
  {$IFNDEF fwweb}
  tbsLocalFile = class
  private
    fChecktime: tdateTime;
    FWriteTime: tdateTime;
    fcreatetime: tdateTime;
    faccesstime: tdateTime;
    FJustFN: string;
    FFullFN: string;
    FDownloaded: tDateTime;
    FSkipped: boolean;
    Fsize: int64;
    FAttr: integer;
    FChecked: tDateTime;
    FExists: boolean;
    Ferror: string;
    FServerFile: tbsFile;
    FisOlder: boolean;
    FisNewer: boolean;
    FisSame: boolean;
    function GetAccessTime: tDateTime;
    function GetAttr: integer;
    function GetCreateTime: tDateTime;
    function GetSize: int64;
    function GetWriteTime: tDateTIme;
    function GetExists: boolean;
    function GetJustFN: string;
    function GetIsOlder: boolean;
    function GetIsNewer: boolean;
    function GetIsSame: boolean;
  public
    property ServerFile: tbsFile read FServerFile write FServerFile;
    function SetPathExplicit(aLocalDir: string): string;
    function SetPathRelative(aLocalRoot, aServerRoot: string): string;
  published
    property JustFN: string read GetJustFN;
    property FullFN: string read FFullFN write FFullFN;
    property CheckTime: tDateTime read FCheckTime write FCheckTime;
    property WriteTime: tDateTIme read GetWriteTime write FWriteTime;
    property CreateTime: tDateTime read GetCreateTime write FCreateTime;
    property AccessTime: tDateTime read GetAccessTime write FAccessTime;
    property Downloaded: tDateTime read FDownloaded write FDownloaded;
    property Skipped: boolean read FSkipped write FSkipped;
    property Checked: tDateTime read FChecked write FChecked;
    property size: int64 read GetSize write Fsize;
    property Attr: integer read GetAttr write FAttr;
    property Exists: boolean read GetExists write FExists;
    property error: string read Ferror write Ferror;
    property isOlder: boolean read GetIsOlder;
    property isNewer: boolean read GetIsNewer;
    property isSame: boolean read GetIsSame;
  end;
  {$ENDIF}

  tbsFile = class(tao)
  private
    FFullFN: string;
    fExists: boolean;
    [JsonIgnore] fChecktime: tdateTime;
    [JsonIgnore] FWriteTime: tdateTime;
    [JsonIgnore] fcreatetime: tdateTime;
    [JsonIgnore] faccesstime: tdateTime;
    FSize: Int64;
    Fattr: integer;
    FIsDir: boolean;
    fonclient: boolean;
    fDownloaded: boolean;
    FError: string;
    Fdlskipped: boolean;
    FWriteTimeS: string;
    FAccessTimeS: string;
    FCreateTimeS: string;
    FCheckTimeS: string;
    FsvWriteTime: tdateTime;
    FsvAccessTime: tdateTime;
    FsvCreateTime: tdateTime;
    FsvCheckTime: tdateTime;
    FURL: string;
    Fext: string;
    FParent: string;
    fParentItem: tbsFile;
    fJustFN: string;
    [JsonIgnore] Fowner: tbsFileList;
    FisRoot: boolean;

    function GetAccessTime: tdateTime;
    function GetCheckTime: tdateTime;
    function GetCreateTime: tdateTime;
    function GetWriteTime: tdateTime;
    function GetParentItem: tbsFile;
    function GetChildItems: tbsChildren;
    function AddChild(aFile: tbsFile): integer;

  public
    [JsonIgnore] UIObject: tobject;
    [JsonIgnore] ThreadObject: tobject;
    [JsonIgnore] fsx: tobject;
    [JsonIgnore] FChildItems: tbsChildren;
    {$IFNDEF fwweb}
    [JsonIgnore] flocal: tbsLocalFile;
    function getLocal: tbsLocalFile;
    [JsonIgnore] property local: tbslocalfile read getLocal write Flocal;

    {$ENDIF}

    function download(lfn: string; ifNewer: boolean = true): tbsFile; overload;
    function download(ifNewer: boolean = true): tbsFile; overload;
    procedure Strip(var alist: tStringList; RemoveRoot: string = '');
    constructor create; override;
    property WriteTime: tdateTime read GetWriteTime;
    property AccessTime: tdateTime read GetAccessTime;
    property CreateTime: tdateTime read GetCreateTime;
    property Checktime: tdateTime read GetCheckTime;
    [JsonIgnore] property parentItem: tbsFile read GetParentItem;
    [JsonIgnore] property owner: tbsFileList read Fowner;
  published
    // Breaks web..
    {$IFNDEF WEBLIB}
    property svWriteTime: tdateTime read FsvWriteTime write FsvWriteTime;
    property svAccessTime: tdateTime read FsvAccessTime write FsvAccessTime;
    property svCreateTime: tdateTime read FsvCreateTime write FsvCreateTime;
    property svCheckTime: tdateTime read FsvCheckTime write FsvCheckTime;
    {$ENDIF}
    property FullFN: string read FFullFN write FFullFN;
    property WriteTimeS: string read FWriteTimeS write FWriteTimeS;
    property AccessTimeS: string read FAccessTimeS write FAccessTimeS;
    property CreateTimeS: string read FCreateTimeS write FCreateTimeS;
    property Size: Int64 read FSize write FSize;
    property attr: integer read Fattr write Fattr;
    property Exists: boolean read fExists write fExists;
    property CheckTimeS: string read FCheckTimeS write FCheckTimeS;
    property IsDir: boolean read FIsDir write FIsDir;
    property onClient: boolean read fonclient write fonclient;
    property downloaded: boolean read fDownloaded write fDownloaded;
    property dlskipped: boolean read Fdlskipped write Fdlskipped;
    property Error: string read FError write FError;
    property URL: string read FURL write FURL;
    property ext: string read Fext write Fext;
    property Parent: string read FParent write FParent;
    property Justfn: string read fJustFN write fJustFN;
    property isRoot: boolean read FisRoot write FisRoot;

  end;

  tbsFiles = taObjectList<tbsFile>;

  tbsFileLookup = tDictionary<string, tbsFile>;

  tbsFileList = class(tao)
  private
    Ffiles: tbsFiles;
    Froot: string;
    fExists: boolean;
    fChecktime: tdateTime;
    FURL: string;

    FRootItem: tbsFile;
  public
    [JsonIgnore] fFileLookup: tbsFileLookup;
    [JsonIgnore] property RootItem: tbsFile read FRootItem write FRootItem;
    constructor create; override;
    procedure log;
    procedure UpdateLookup;
    function lookup(aFullPath: string): tbsFile;
    procedure SetLocalPathsRelative(aLocalDir: string);
    destructor destroy; override;
  published
    property files: tbsFiles read Ffiles write Ffiles;
    property root: string read Froot write Froot;

    property Exists: boolean read fExists write fExists;
    property Checktime: tdateTime read fChecktime write fChecktime;
    property URL: string read FURL write FURL;
  end;

  tbsPathMapItem = class
  private
    FURL: string;
    FPath: string;
    FLocalPath: string;
  public
    constructor create(aURLPath: string = '');
    procedure SetFromString(s: string);
  published
    property URL: string read FURL write FURL;
    property Path: string read FPath write FPath;
    property LocalPath: string read FLocalPath write FLocalPath;
  end;

  tbsPathMap = class
  private
    Fmap: tStringList;
    fPathmap: tStringList;
    Ffn: string;
    FPathMapS: string;
    function getfn: string;
    function getCount: integer;
    function GetPathMapS: string;
    procedure SetPathMapS(const Value: string);
  public
    function pm(aIndex: integer): tbsPathMapItem;
    constructor create;
    procedure UpdatePathMap;
    function Load(afn: string = ''): boolean;
    procedure save(afn: string = '');
    property fn: string read getfn write Ffn;
    function toURL(aPath: string): string;
    function toPath(aURL: string): string;
    function RemoveRoot(aPath: string): string;
      property Pathmap: tStringList read fPathmap write fPathmap;

  published
    property PathMapS: string read GetPathMapS write SetPathMapS;
     property count: integer read getCount;
  end;



implementation

uses
  sysutils, dateutils, ioUtils, x.fs.client;
{ tbsFileList }

constructor tbsFileList.create;
begin
  inherited;
  RootItem := nil;
  {$IFDEF WEBLIB}
  console.log('tbsFileListCreate');
  {$ENDIF}
  files := tbsFiles.create;
  fFileLookup := tbsFileLookup.create;
end;

destructor tbsFileList.destroy;
begin
  {$IFNDEF fwweb}
 // files.free;
  {$ENDIF}
  inherited;
end;

procedure tbsFileList.log;
var
  aFile: tbsFile;
begin
 // {$IFDEF MSWINDOWS}
  alog.Send('FileList (' + inttostr(files.count) + ') ' + root, self);
  for aFile in files do
  begin
    alog.Send(aFile.FullFN, aFile);
  end;
 // {$ENDIF}
end;

function tbsFileList.lookup(aFullPath: string): tbsFile;
var
  aFile: tbsFile;
  s: string;
begin
  result := nil;

  if aFullPath = '' then
    exit;

  if assigned(fFileLookup) then
  begin

    s := lowercase(aFullPath);
    if s[Length(s)] = '\' then
      s := copy(s, 1, Length(s) - 1);
     //alog.send('Looking up ' +s);
    // alog.send('count', ffilelookup.count);
    if fFileLookup.TryGetValue(s, aFile) then
      result := aFile;
    //else
      //alog.Send('NOT FOUND');
  end else alog.send('no lookup');
end;

procedure tbsFileList.SetLocalPathsRelative(aLocalDir: string);
var
 afile: tbsFile;
begin
{$IFNDEF fwweb}
 for afile in files do
   begin
     afile.local.SetPathRelative(alocalDir, rootitem.FullFN);
   end;
   {$ENDIF}

end;

procedure tbsFileList.UpdateLookup;
var
  aFile, afile2: tbsFile;
  s: string;
begin
  for aFile in files do
  begin
    s := lowercase(aFile.FullFN);
    if s[Length(s)] = '\' then
      s := copy(s, 1, Length(s) - 1);

    aFile.Fowner := self;
    if aFile.isRoot then
      RootItem := aFile;

    if fFileLookup.TryGetValue(s, afile2) = false then
    begin
      fFileLookup.add(s, aFile);

    end;

  end;

  for aFile in files do
  begin
    if assigned(aFile.parentItem) then
      aFile.parentItem.AddChild(aFile);

  end;
  //alog.Send('FileLookup', fFileLookup.count);
end;

{ TbsFile }

constructor tbsFile.create;
begin
  inherited;
  FChildItems := tbsChildren.create;
  {$IFDEF WEBLIB}
  {$ENDIF}
end;

function tbsFile.download(ifNewer: boolean): tbsFile;
begin
  {$IFNDEF fwweb}
  if not assigned(fsx) then
    exit;
  result := txfs(fsx).download(self, ifNewer);
  {$ENDIF}
end;

function tbsFile.download(lfn: string; ifNewer: boolean): tbsFile;
begin
  {$IFNDEF fwweb}
  if not assigned(fsx) then
    exit;
  result := txfs(fsx).download(self, lfn, ifNewer);
  {$ENDIF}
end;

function tbsFile.GetAccessTime: tdateTime;
begin
  result := StrToDateTime(AccessTimeS);
end;

function tbsFile.GetCheckTime: tdateTime;
begin
  result := StrToDateTime(CheckTimeS);
end;

function tbsFile.GetCreateTime: tdateTime;
begin
  result := StrToDateTime(CreateTimeS);
end;
{$IFNDEF fwweb}

function tbsFile.getLocal: tbsLocalFile;
begin
  if not assigned(flocal) then
  begin
    flocal := tbsLocalFile.Create;
    flocal.ServerFile := self;
  end;
  result := fLocal;
end;
{$ENDIF}

function tbsFile.GetParentItem: tbsFile;
var
  aItem: tbsFile;
begin
  result := nil;
  if not assigned(owner) then
    exit;
  // alog.send('Looking up',parent);
  result := owner.lookup(Parent);
end;

function tbsFile.GetChildItems: tbsChildren;
begin
  result := nil;
  if not assigned(FChildItems) then
    exit;
  result := FChildItems;

end;

function tbsFile.AddChild(aFile: tbsFile): integer;
begin
  // if not assigned(fChildItems) then
  if FChildItems.indexof(aFile) = -1 then

    result := FChildItems.add(aFile);

end;

function tbsFile.GetWriteTime: tdateTime;
begin
  result := StrToDateTime(WriteTimeS);
end;

procedure tbsFile.Strip(var alist: tStringList; RemoveRoot: string = '');
var
  p, p2: integer;
  s, s2: string;
  i: integer;
  fns: string;
begin
  if not assigned(alist) then
    alist := tStringList.create
  else
    alist.clear;
  if RemoveRoot <> '' then
  begin
    fns := StringReplace(FullFN, RemoveRoot, '', [rfIgnoreCase] );
    if fns[1] = '\' then
      fns := copy(fns, 2, Length(fns));

  end
  else
    fns := FullFN;

  for i := 1 to Length(fns) do
  begin
    if fns[i] <> '\' then
    begin
      s2 := s2 + fns[i] ;
      s := s + fns[i] ;
    end
    else
    begin
      s2 := s2 + fns[i] ;
      if s[Length(s)] = '\' then
        s := copy(s, 1, Length(s) - 1);

      alist.add(s2 + '=' + s);
      s := '';
    end;
  end;
  alist.add(s2 + '=' + extractfilename(FullFN));

end;

{ tbsPathMap }

constructor tbsPathMap.create;
begin
  Pathmap := tStringList.create;
  Pathmap.Sorted := false;
end;

function tbsPathMap.getCount: integer;
begin
  result := Pathmap.count;
end;

function tbsPathMap.getfn: string;
begin
  // if ffn='' then

  // Result := combinepaths(path.EXEPATH, 'pathmap.txt', '\')
  // else
  result := Ffn;
end;

function tbsPathMap.GetPathMapS: string;
begin
  result:=fPathMap.text;
end;

function tbsPathMap.Load(afn: string): boolean;
begin
  try
    {$IFDEF MSWINDOWS}
    result := false;
    if fileexists(fn) = false then
      exit;

    Pathmap.LoadFromFile(fn);
    UpdatePathMap;
    result := true;
    {$ENDIF}
  except
    on e: exception do
    begin
      result := false;
    end;

  end;
end;

function tbsPathMap.pm(aIndex: integer): tbsPathMapItem;
begin
  result := tbsPathMapItem(Pathmap.Objects[aIndex] );
end;



procedure tbsPathMap.save(afn: string);
begin
  try
    alog.Send('Pathmapsave', fn);
    Pathmap.saveToFile(fn);
  except
    on e: exception do
    begin
      alog.Send('pathmapsaveerror', e.message);
    end;

  end;
end;

procedure tbsPathMap.SetPathMapS(const Value: string);
begin
  fPathMap.text:=value;
  UpdatePathMap;
end;

function tbsPathMap.toPath(aURL: string): string;
var
  i: integer;
  s: string;
begin
  s := aURL;
  for i := 1 to Pathmap.count do
  begin
    s := StringReplace(s, pm(i - 1).URL, pm(i - 1).Path, [rfIgnoreCase] );

  end;
  s := StringReplace(s, '/', '\', [rfIgnoreCase, rfReplaceAll] );
  result := s;
end;

function tbsPathMap.toURL(aPath: string): string;
var
  i: integer;
  s: string;
begin
  s := aPath;
  for i := 1 to Pathmap.count do
  begin
    s := StringReplace(s, pm(i - 1).Path, pm(i - 1).URL, [rfIgnoreCase] );

  end;
  s := StringReplace(s, '\', '/', [rfIgnoreCase, rfReplaceAll] );
  result := s;
end;

function tbsPathMap.RemoveRoot(aPath: string): string;
var
  i: integer;
  s: string;
begin
  s := aPath;
  for i := 1 to Pathmap.count do
  begin
    s := StringReplace(s, pm(i - 1).Path, '', [rfIgnoreCase] );

  end;
     if s='' then s:='\';

  result := s;

end;

procedure tbsPathMap.UpdatePathMap;
var
  i: integer;
  aItem: tbsPathMapItem;
begin
  for i := 1 to Pathmap.count do
  begin
    if assigned(Pathmap.Objects[i - 1] ) then
      aItem := tbsPathMapItem(Pathmap.Objects[i - 1] )
    else
    begin
      aItem := tbsPathMapItem.create(Pathmap[i - 1] );
      Pathmap.Objects[i - 1] := aItem;
    end;

  end;

end;

{ tbsPathMapItem }

constructor tbsPathMapItem.create(aURLPath: string);
begin
  if aURLPath <> '' then
    SetFromString(aURLPath);

end;

procedure tbsPathMapItem.SetFromString(s: string);
var
  p: integer;
begin
  p := pos('=', s);
  if p <> 0 then
  begin
    URL := copy(s, 1, p - 1);
    Path := copy(s, p + 1, Length(s));
  end;

end;

{ tbsLocalFile }
{$IFNDEF fwweb}
function tbsLocalFile.GetAccessTime: tDateTime;
begin
 if not Exists then exit;

  Result :=     tfile.GetLastAccessTime(fullfn);;
end;

function tbsLocalFile.GetAttr: integer;
var
 attr: tFileAttributes;
begin
if not Exists then exit;
  attr := tfile.GetAttributes(fullfn);
   tfile.FileAttributesToInteger(attr);
end;

function tbsLocalFile.GetCreateTime: tDateTime;
begin
if not Exists then exit;
  Result :=  tfile.GetCreationTime(fullfn);
end;

function tbsLocalFile.GetExists: boolean;
begin
  Result := tfile.exists(fullfn, false);
end;

function tbsLocalFile.GetIsNewer: boolean;
begin
 if not Exists then exit;
if ServerFile.svWriteTime < WriteTime then Result:=true else result:=false;
end;

function tbsLocalFile.GetIsOlder: boolean;
begin
if not Exists then exit;
if ServerFile.svWriteTime > WriteTime then Result:=true else result:=false;
end;

function tbsLocalFile.GetIsSame: boolean;
begin
result:=false;
if not Exists then exit;
//if double(ServerFile.svWriteTime) = double(WriteTime) then Result:=true else result:=false;
if SameDateTime(serverfile.svWriteTime, writeTime) then result:=true else result:=false;

end;




function tbsLocalFile.GetJustFN: string;
begin

 result:=extractFileName(fullfn);
end;

function tbsLocalFile.GetSize: int64;
begin
 result:=0;
if not Exists then exit;
  Result := tfile.GetSize(fullfn);
end;

function tbsLocalFile.GetWriteTime: tDateTIme;
begin
if not Exists then exit;
  Result :=  tfile.GetLastWriteTime(fullfn);
end;

function tbsLocalFile.SetPathExplicit(aLocalDir: string): string;
begin
 fFullFN:=CombinePaths(aLocalDir, ServerFIle.JustFN, '\');
 result:=fFullFN;
end;

function tbsLocalFile.SetPathRelative(aLocalRoot, aServerRoot: string): string;
var
  sfn, lfn: string;
begin
  sfn := aServerRoot;
  lfn:=aLocalRoot;

  if sfn[length(sfn)] <> '\' then
    sfn := sfn + '\';
  if lfn[Length(lfn)] <> '\' then
    lfn := lfn + '\';

  result := StringReplace(lowercase(serverfile.fullfn), sfn, lfn, [rfIgnoreCase] );
   fFullFN:=result;
end;

{$ENDIF}

initialization

  RegisterClass(TBSFile);
  RegisterClass(tbsFileList);
  //Pathmap := tbsPathMap.create;

end.

