{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2024                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCTouchKeyboard;

interface

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF MSWINDOWS}
{$DEFINE MSWINWEB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE MSWINWEB}
{$ENDIF}

uses
  SysUtils, Classes, Math, Variants, Types, UITypes,
  {$IFNDEF WEBLIB}
  UIConsts, IniFiles,
  {$ENDIF}
  {$IFDEF MSWINDOWS}
  Windows, Messages,
  {$ENDIF}
  {$IFDEF MACOS}
  {$IFNDEF IOS}
  MacApi.CocoaTypes, Macapi.Foundation, MacApi.CoreServices,
  MacApi.CoreFoundation, MacApi.CoreGraphics, MacApi.KeyCodes,
  {$ENDIF}
  {$ENDIF}
  {$IFDEF FMXLIB}
  {$IFDEF MSWINDOWS}
  FMX.Platform.Win,
  {$ENDIF}
  FMX.Types, WEBLib.Forms,
  {$IFDEF LINUX}
  FMUX.Api,
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  web, js, WEBLib.ExtCtrls,
  {$ENDIF}
  {$IFNDEF WEBLIB}
  System.Rtti, TypInfo,
  {$ENDIF}
  {$IFDEF VCLLIB}
  Vcl.ExtCtrls, Vcl.Forms,
  {$ENDIF}
  WEBLib.Controls, WEBLib.Graphics, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCCustomControl,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCCustomComponent, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCPopup, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCUtils;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 2; // Build nr.

  // version history
  // v1.0.0.0 : First release
  // v1.0.0.1 : Fixed : Issue with width and height in numeric and cellphone keyboard types
  // v1.0.0.2 : Fixed : Issue with crash upon closing in TTMSFNCPopupTouchKeyboard
  //          : Fixed : Issue with Highlight text colors not applied
  //          : Fixed : Issue with Keyboard property having the wrong type in TTMSFNCPopupTouchKeyboard

type
  TTMSFNCTouchKeyItem = class;

  TTMSFNCKeyboardType = (ktQWERTY, ktAZERTY, ktDVORAK, ktNUMERIC, ktCELLPHONE, ktQWERTZ{$IFDEF MSWINDOWS}, ktCustom{$ENDIF});

  TTMSFNCSpecialKey = (skNone, skAlt, skAltGr, skShift, skCaps, skCtrl, skNum, skScroll,
                 skReturn, skAdd, skDivide, skDecimal, skSubtract, skMultiply,
                 skTab, skWin, skApp, skBackSpace, skSpaceBar, skLeft, skRight, skUp, skDown, skNext, skPrior, skHome, skEnd,
                 skF1, skF2, skF3, skF4, skF5, skF6, skF7, skF8, skF9, skF10, skF11, skF12, skDelete, skEscape);

  TTMSFNCAutoSizeLayout = (aslKeys, aslKeyboard);

  TTMSFNCTouchKeyEvent = procedure(Sender: TObject; AIndex: Integer) of object;
  TTMSFNCDrawKeyEvent = procedure(Sender: TObject; AKey: TTMSFNCTouchKeyItem; AGraphics: TTMSFNCGraphics; ADown: Boolean; ARect: TRectF; var ADefaultDraw: Boolean) of Object;

  {$IFNDEF DELPHI9_LVL}
  TSelection = record
    StartPos, EndPos: Integer;
  end;
  {$ENDIF}

  TTMSFNCTouchKey = class(TTMSFNCCustomControl)
  private
    FCaption: string;
    FCurrentBitmap : TTMSFNCBitmap;
    FItem: TTMSFNCTouchKeyItem;
    FPictureNormalState: TTMSFNCBitmap;
    FPictureDownState: TTMSFNCBitmap;
    FKeyValue: Integer;
    FOnKeyDown: TKeyEvent;
    FBmp : TTMSFNCBitmap;
    FSpecialKey: TTMSFNCSpecialKey;
    FBorderColor: TTMSFNCGraphicsColor;
    FBorderColorDown: TTMSFNCGraphicsColor;
    FColor: TTMSFNCGraphicsColor;
    FTextColorDown: TTMSFNCGraphicsColor;
    FColorDown: TTMSFNCGraphicsColor;
    FTextColor: TTMSFNCGraphicsColor;
    FAltGrCaption: string;
    FShiftCaption: string;
    FDownState: Byte;
    FKeyNormalPosition : Boolean;
    FSmallFont: TTMSFNCGraphicsFont;
    FFont: TTMSFNCGraphicsFont;
    FAltGrKeyValue: Integer;
    FShiftKeyValue: Integer;
    FOldH, FOldW : Single;
    FOldX, FOldY : Single;
    FShortCut: string;
    FAutoPost: Boolean;
    FImageName: string;
    procedure SetCption(const Value: string);
    procedure SetPictureDownState(const Value: TTMSFNCBitmap);
    procedure SetPictureNormalState(const Value: TTMSFNCBitmap);
    procedure SetAltGrCaption(const Value: string);
    procedure SetAltrCaption(const Value: string);
    procedure SetBrderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetClor(const Value: TTMSFNCGraphicsColor);
    procedure SetTextColor(const Value: TTMSFNCGraphicsColor);
    function GetDownState: Boolean;
    procedure SetDownState(const Value: Boolean);
  protected
    procedure Draw({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure DrawBackground(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property TouchKeyItem: TTMSFNCTouchKeyItem read FItem write FItem;
    property OldH : Single read FOldH write FOldH;
    property OldW : Single read FOldW write FOldW;
    property OldX : Single read FOldX write FOldX;
    property OldY : Single read FOldY write FOldY;
    property Down: Boolean read GetDownState write SetDownState;
  published
    property AutoPost: Boolean read FAutoPost write FAutoPost default True;
    property ShiftCaption: string read FShiftCaption write SetAltrCaption;
    property AltGrCaption: string read FAltGrCaption write SetAltGrCaption;
    property Caption: string read FCaption write SetCption;
    property BorderColor: TTMSFNCGraphicsColor read FBorderColor write SetBrderColor default gcGray;
    property BorderColorDown: TTMSFNCGraphicsColor read FBorderColorDown write FBorderColorDown default gcBlack;
    property Color: TTMSFNCGraphicsColor read FColor write SetClor default gcSilver;
    property ColorDown: TTMSFNCGraphicsColor read FColorDown write FColorDown default gcGray;
    property TextColor: TTMSFNCGraphicsColor read FTextColor write SetTextColor default gcBlack;
    property TextColorDown: TTMSFNCGraphicsColor read FTextColorDown write FTextColorDown default gcBlack;
    property ImageName: string read FImageName write FImageName;
    property Height;
    property Width;
    {$IFDEF FMXLIB}
    property Position;
    {$ENDIF}
    property SpecialKey: TTMSFNCSpecialKey read FSpecialKey write FSpecialKey default skNone;
    property KeyValue: Integer read FKeyValue write FKeyvalue default -1;
    property ShiftKeyValue: Integer read FShiftKeyValue write FShiftKeyValue default -1;
    property AltGrKeyValue: Integer read FAltGrKeyValue write FAltGrKeyValue default -1;
    property PictureDownState : TTMSFNCBitmap read FPictureDownState write SetPictureDownState;
    property PictureNormalState : TTMSFNCBitmap read FPictureNormalState write SetPictureNormalState;
    property ShortCut: string read FShortCut write FShortCut;
    property OnKeyDown: TKeyEvent read FOnKeyDown write FOnKeyDown;
  end;

  TTMSFNCCustomTouchKeyboard = class;

  TTMSFNCTouchKeyItem = class(TCollectionItem)
  private
    FTouchKey: TTMSFNCTouchKey;
    FKbd: TTMSFNCCustomTouchKeyboard;
    function GetCaption: string;
    function GetX: Single;
    function GetY: Single;
    procedure SetCaption(const Value: string);
    procedure SetX(const Value: Single);
    procedure SetY(const Value: Single);
    procedure SetPictureDownState(const Value: TTMSFNCBitmap);
    procedure SetPictureNormalState(const Value: TTMSFNCBitmap);
    function GetPictureDownState: TTMSFNCBitmap;
    function GetPictureNormalState: TTMSFNCBitmap;
    function GetHeight: Single;
    function GetWidth: Single;
    procedure SetHeight(const Value: Single);
    procedure SetWidth(const Value: Single);
    function GetOldH: Single;
    function GetOldW: Single;
    procedure SetOldH(const Value: Single);
    procedure SetOldW(const Value: Single);
    function GetOldX: Single;
    function GetOldY: Single;
    procedure SetOldX(const Value: Single);
    procedure SetOldY(const Value: Single);
    function GetKeyValue: Integer;
    procedure SetKeyvalue(const Value: Integer);
    function GetBorderColor: TTMSFNCGraphicsColor;
    function GetBorderColorDown: TTMSFNCGraphicsColor;
    function GetColor: TTMSFNCGraphicsColor;
    function GetColorDown: TTMSFNCGraphicsColor;
    function GeTTMSFNCSpecialKey: TTMSFNCSpecialKey;
    function GetTextColor: TTMSFNCGraphicsColor;
    function GetTextColorDown: TTMSFNCGraphicsColor;
    procedure SetBorderColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBorderColorDown(const Value: TTMSFNCGraphicsColor);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetColorDown(const Value: TTMSFNCGraphicsColor);
    procedure SeTTMSFNCSpecialKey(const Value: TTMSFNCSpecialKey);
    procedure SetTexTTMSFNCGraphicsColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTexTTMSFNCGraphicsColorDown(const Value: TTMSFNCGraphicsColor);
    function GetShiftCaption: string;
    function GetAltGrCaption: string;
    procedure SetAltGrCaption(const Value: string);
    procedure SetShiftCaption(const Value: string);
    function GetAltGrKeyValue: Integer;
    function GetShiftKeyValue: Integer;
    procedure SetAltGrKeyValue(const Value: Integer);
    procedure SetShiftKeyValue(const Value: Integer);
    function GetShortCut: string;
    procedure SetShortCut(const Value: string);
    function GetAutoPost: Boolean;
    procedure SetAutoPost(const Value: Boolean);
    function GetHint: string;
    procedure SetHint(const Value: string);
    function GetImageName: string;
    procedure SetImageName(const Value: string);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
  protected
    function GetDisplayName: string; override;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
    property OldH: Single read GetOldH write SetOldH;
    property OldW: Single read GetOldW write SetOldW;
    property OldX: Single read GetOldX write SetOldX;
    property OldY: Single read GetOldY write SetOldY;
  published
    property X: Single read GetX write SetX;
    property Y: Single read GetY write SetY;
    property AutoPost: Boolean read GetAutoPost write SetAutoPost default True;
    property Caption: string read GetCaption write SetCaption;
    property ShiftCaption: string read GetShiftCaption write SetShiftCaption;
    property AltGrCaption: string read GetAltGrCaption write SetAltGrCaption;
    property KeyValue: Integer read GetKeyValue write SetKeyvalue default -1;
    property ShiftKeyValue: Integer read GetShiftKeyValue write SetShiftKeyValue default -1;
    property AltGrKeyValue: Integer read GetAltGrKeyValue write SetAltGrKeyValue default -1;
    property PictureDownState: TTMSFNCBitmap read GetPictureDownState write SetPictureDownState;
    property PictureNormalState: TTMSFNCBitmap read GetPictureNormalState write SetPictureNormalState;
    property Height: Single read GetHeight write SetHeight;
    property Width: Single read GetWidth write SetWidth;
    property SpecialKey : TTMSFNCSpecialKey read GeTTMSFNCSpecialKey write SeTTMSFNCSpecialKey default skNone;
    property BorderColor : TTMSFNCGraphicsColor read GetBorderColor write SetBorderColor default gcGray;
    property BorderColorDown : TTMSFNCGraphicsColor read GetBorderColorDown write SetBorderColorDown default gcBlack;
    property Color: TTMSFNCGraphicsColor read GetColor write SetColor default gcSilver;
    property ColorDown: TTMSFNCGraphicsColor read GetColorDown write SetColorDown default gcGray;
    property TextColor: TTMSFNCGraphicsColor read GetTextColor write SetTexTTMSFNCGraphicsColor default gcBlack;
    property TextColorDown: TTMSFNCGraphicsColor read GetTextColorDown write SetTexTTMSFNCGraphicsColorDown default gcBlack;
    property ImageName: string read GetImageName write SetImageName;
    property ShortCut: string read GetShortCut write SetShortCut;
    property Hint: string read GetHint write SetHint;
  end;

  TTMSFNCTouchKeyCollection = class(TCollection)
  private
    FOwner : TTMSFNCCustomTouchKeyboard;
    function GetItem(Index: Integer): TTMSFNCTouchKeyItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTouchKeyItem);
  protected
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomTouchKeyBoard);
    function Add: TTMSFNCTouchKeyItem;
    function Insert(index:Integer): TTMSFNCTouchKeyItem;
    property Items[Index: Integer]: TTMSFNCTouchKeyItem read GetItem write SetItem; default;
  end;

  TTMSFNCCustomTouchKeyboard = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    FNormalColor: TTMSFNCGraphicsColor;
    FSpecialColor: TTMSFNCGraphicsColor;
    FCapsTimer: TTimer;
    FKeys: TTMSFNCTouchKeyCollection;
    FOnKeyDown: TKeyEvent;
    FPictureNormalState: TTMSFNCBitmap;
    FPictureDownState: TTMSFNCBitmap;
    FSmallFont: TTMSFNCGraphicsFont;
    FKeyboardType: TTMSFNCKeyboardType;
    FOnDrawKey: TTMSFNCDrawKeyEvent;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FShift: TShiftState;
    FAutoPostKey : Boolean;
    FHighlightCaps: TTMSFNCGraphicsColor;
    FAutoCapsDisplay: Boolean;
    FHighlightAltGr: TTMSFNCGraphicsColor;
    FRepeatTimer: TTimer;
    FOnKeyClick: TTMSFNCTouchKeyEvent;
    FOldW,FOldH: Single;
    FCapsDown: Boolean;
    FOriginalW: Single;
    FOriginalH: Single;
    FDoAutoZoom: Boolean;
    FKeySpacing: Integer;
    FChangingScale: Boolean;
    {$IFDEF MACOS}
    FCommandFlag: UInt64;
    {$ENDIF}
    {$IFDEF MSWINDOWS}
    FPostWMCharOnly: Boolean;
    FRepeatTimerCount: Integer;
    FRepeatItemIndex: Integer;
    FRepeatHandle: THandle;
    {$ENDIF}
    FFont: TTMSFNCGraphicsFont;
    FBorderRounding: Integer;
    FUseActiveControlText: Boolean;
    FAllowAutoZoom: Boolean;
    procedure SetKeys(const Value: TTMSFNCTouchKeyCollection);
    procedure SetPictureDownState(const Value: TTMSFNCBitmap);
    procedure SetPictureNormalState(const Value: TTMSFNCBitmap);
    procedure SetSmallFont(const Value: TTMSFNCGraphicsFont);
    procedure SetKeyboardType(const Value: TTMSFNCKeyboardType);
    procedure AddKey(ACaption, AShiftCaption, AAltGrCaption: string; AKeyValue, AShiftKeyValue, AAltGrKeyValue: Integer; AImageName: string; AWidth, AHeight: Single; var AX: Single; AY: Single; ASpecialKey: TTMSFNCSpecialKey; AColor: TTMSFNCGraphicsColor = gcSilver);
    procedure NewRow(var X, Y : Single; Size : Integer);
    procedure PostNormalKeys(Index: Integer);
    procedure PostSpecialKeys(Key: Word; const pShift: TShiftState; SpecialKey: Boolean);
    {$IFDEF MSWINDOWS}
    function GetKeybdInputHandle: HWND;
    {$ENDIF}
    procedure PictureChanged(Sender: TObject);
    function GetVersionNr: Integer;
    procedure SetAutoCapsDisplay(const Value: Boolean);
    procedure SetHighlightAltGr(const Value: TTMSFNCGraphicsColor);
    procedure SetHighlightCaps(const Value: TTMSFNCGraphicsColor);
    function IsCapsDown: Boolean;
    procedure SetBorderRounding(const Value: Integer);
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
    procedure SetAllowAutoZoom(const Value: Boolean);
    procedure SetKeySpacing(const Value: Integer);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
  protected
    {$IFDEF VCLLIB}
    procedure CreateWnd; override;
    {$ENDIF}
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure UpdateControlAfterResize; override;
    function GetVersion: string; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure ItemKeyDown(Index: Integer);
    procedure ItemKeyUp(Index: Integer);
    procedure SyncEqualKeys(Index: Integer);
    procedure TurnOffShifts;
    procedure DrawKey(Sender: TObject; AKey: TTMSFNCTouchKeyItem; AGraphics: TTMSFNCGraphics; ADown: Boolean; ARect: TRectF; var ADefaultDraw: Boolean);
    procedure BuildQWERTYKeyBoard;
    procedure BuildQWERTZKeyBoard;
    procedure BuildAZERTYKeyBoard;
    procedure BuildDVORAKKeyBoard;
    procedure BuildNumericKeyBoard;
    procedure BuildCellPhoneKeyboard;
    function GetDefaultKeyboardWidth(AKeyboardType: TTMSFNCKeyboardType): Integer;
    function GetDefaultKeyboardHeight(AKeyboardType: TTMSFNCKeyboardType): Integer;
    procedure GetActiveControlText(var AText: string; var ASelPosStart: Integer; var ASelPosEnd: Integer);
    procedure SetActiveControlText(AText: string; ACaretPos: Integer);
    procedure InsertIntoString(var AText: string; AInsert: string; var ASelPosStart: Integer; ASelPosEnd: Integer);
    procedure PaintAllKeys;
    procedure StartTimer(AIndex: Integer);
    procedure StopTimer;
    procedure RepeatTimerProc(Sender: TObject);
    procedure CapsTimerProc(Sender: TObject);
    property Shift : TShiftState read FShift write FShift;
    property UseActiveControlText: Boolean read FUseActiveControlText write FUseActiveControlText;
    {$IFDEF MSWINDOWS}
    property KeybdInputHandle : HWND read GetKeybdInputHandle;
    {$ENDIF}
    property AllowAutoZoom: Boolean read FAllowAutoZoom write SetAllowAutoZoom default True;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property AutoPostKey : Boolean read FAutoPostKey write FAutoPostKey default True;
    property AutoCapsDisplay: Boolean read FAutoCapsDisplay write SetAutoCapsDisplay default True;
    property BorderRounding: Integer read FBorderRounding write SetBorderRounding default 5;
    property HighlightCaps: TTMSFNCGraphicsColor read FHighlightCaps write SetHighlightCaps default gcNull;
    property HighlightAltGr: TTMSFNCGraphicsColor read FHighlightAltGr write SetHighlightAltGr default gcNull;
    property BitmapContainer : TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property KeyboardType : TTMSFNCKeyboardType read FKeyboardType write SetKeyboardType;
    property Keys : TTMSFNCTouchKeyCollection read FKeys write SetKeys;
    property PictureDownState : TTMSFNCBitmap read FPictureDownState write SetPictureDownState;
    property PictureNormalState : TTMSFNCBitmap read FPictureNormalState write SetPictureNormalState;
    property SmallFont : TTMSFNCGraphicsFont read FSmallFont write SetSmallFont;
    property KeySpacing: Integer read FKeySpacing write SetKeySpacing default 2;
    property OnKeyClick: TTMSFNCTouchKeyEvent read FOnKeyClick write FOnKeyClick;
    property OnKeyDown: TKeyEvent read FOnKeyDown write FOnKeyDown;
    property OnDrawKey: TTMSFNCDrawKeyEvent read FOnDrawKey write FOnDrawKey;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure AutoZoom;
    procedure Zoom(AHorz, AVert: Double; AKeysOnly: Boolean = False; AAbsoluteZoom: Boolean = False);
    {$IFNDEF WEBLIB}
    procedure SaveKeybdLayout(AFileName: string);
    procedure LoadKeybdLayout(AFileName: string; AAutoSize: TTMSFNCAutoSizeLayout = aslKeyboard);
    {$ENDIF}
    procedure PostKey(AKey: Word; const AShift: TShiftState; AExtendedKeyBD: Boolean; AIndex: Integer);
    {$IFDEF MSWINDOWS}
    property PostWMCharOnly: Boolean read FPostWMCharOnly write FPostWMCharOnly;
    {$ENDIF}
  end;

  {$HINTS OFF}
  [ComponentPlatformsAttribute(TMSPlatformsWebDesktop)]
  {$HINTS ON}
  TTMSFNCTouchKeyboard = class(TTMSFNCCustomTouchKeyboard)
  published
    property AdaptToStyle;
    property AllowAutoZoom;
    property Align;
    property Fill;
    property Stroke;
    property Visible;
    property Font;
    property AutoPostKey;
    property AutoCapsDisplay;
    property BitmapContainer;
    property BorderRounding;
    property HighlightCaps;
    property HighlightAltGr;
    property KeyboardType;
    property KeySpacing;
    property Keys;
    property PictureDownState;
    property PictureNormalState;
    property SmallFont;
    property OnKeyClick;
    property OnKeyDown;
    property OnDrawKey;
  end;

  TTMSFNCPopupTouchKeyboardCloseButton = class(TTMSFNCCustomControl)
  end;

  TTMSFNCCustomPopupTouchKeyboard = class(TTMSFNCCustomComponent, ITMSFNCProductInfo)
  private
    FPopup: TTMSFNCNonFocusablePopup;
    FKeyboard: TTMSFNCTouchKeyBoard;
    FAutoCapsDisplay: Boolean;
    FAutoPostKey: Boolean;
    FHighlightAltGr: TTMSFNCGraphicsColor;
    FHighlightCaps: TTMSFNCGraphicsColor;
    FKeyboardType: TTMSFNCKeyboardType;
    FOnShow: TNotifyEvent;
    FOnKeyboardCreated: TNotifyEvent;
    FOnClose: TNotifyEvent;
    FMDown: TPointF;
    FPRect: TRectF;
    FIsDown: Boolean;
    FOnDrawKey: TTMSFNCDrawKeyEvent;
    FOnKeyDown: TKeyEvent;
    FOnKeyClick: TTMSFNCTouchKeyEvent;
    FCloseTimer: TTimer;
    procedure SetAutoCapsDisplay(const Value: Boolean);
    procedure SetAutoPostKey(const Value: Boolean);
    procedure SetHighlightAltGr(const Value: TTMSFNCGraphicsColor);
    procedure SetHighlightCaps(const Value: TTMSFNCGraphicsColor);
    procedure SeTTMSFNCKeyboardType(const Value: TTMSFNCKeyboardType);
    function GetKeyboard: TTMSFNCTouchKeyBoard;
  protected
    procedure DoCloseTimer(Sender: TObject); virtual;
    function GetInstance: NativeUInt; override;
    function GetDocURL: string; override;
    procedure DoPanelClose(Sender: TObject);
    function GetVersion: string; override;
    function GetScaledValue(AValue: Single): Single;
    {$IFDEF FMXLIB}
    procedure DoPanelMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Single);
    procedure DoPanelMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Single);
    procedure DoPanelMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Single);
    {$ENDIF}
    {$IFNDEF FMXLIB}
    procedure DoPanelMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    procedure DoPanelMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    procedure DoPanelMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Integer);
    {$ENDIF}
    procedure DoAfterPanelDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DoKeyClick(Sender: TObject; Index: Integer);
    procedure DoDrawKey(Sender: TObject; AKey: TTMSFNCTouchKeyItem; AGraphics: TTMSFNCGraphics; ADown: Boolean; ARect: TRectF; var ADefaultDraw: Boolean);
    procedure DoKeyDown(Sender: TObject; var Key: Word; {$IFDEF FMXLIB}var KeyChar: WideChar;{$ENDIF} Shift: TShiftState);
    property AutoCapsDisplay: Boolean read FAutoCapsDisplay write SetAutoCapsDisplay default False;
    property AutoPostKey: Boolean read FAutoPostKey write SetAutoPostKey default False;
    property HighlightAltGr: TTMSFNCGraphicsColor read FHighlightAltGr write SetHighlightAltGr default gcNull;
    property HighlightCaps: TTMSFNCGraphicsColor read FHighlightCaps write SetHighlightCaps default gcNull;
    property KeyboardType: TTMSFNCKeyboardType read FKeyboardType write SeTTMSFNCKeyboardType default ktQWERTY;
    property OnClose: TNotifyEvent read FOnClose write FOnClose;
    property OnShow: TNotifyEvent read FOnShow write FOnShow;
    property OnKeyboardCreated: TNotifyEvent read FOnKeyboardCreated write FOnKeyboardCreated;
    property OnKeyClick: TTMSFNCTouchKeyEvent read FOnKeyClick write FOnKeyClick;
    property OnKeyDown: TKeyEvent read FOnKeyDown write FOnKeyDown;
    property OnDrawKey: TTMSFNCDrawKeyEvent read FOnDrawKey write FOnDrawKey;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure ShowKeyboard;
    function GetVersionNr: Integer;
    procedure HideKeyboard;
    property Keyboard: TTMSFNCTouchKeyBoard read GetKeyboard;
  end;

  {$HINTS OFF}
  [ComponentPlatformsAttribute(TMSPlatformsDesktop)]
  {$HINTS ON}
  TTMSFNCPopupTouchKeyboard = class(TTMSFNCCustomPopupTouchKeyboard)
  published
    property AutoCapsDisplay;
    property AutoPostKey;
    property HighlightAltGr;
    property HighlightCaps;
    property KeyboardType;
    property OnClose;
    property OnShow;
    property OnKeyboardCreated;
    property OnKeyClick;
    property OnKeyDown;
    property OnDrawKey;
  end;

{$IFDEF MACOS}
{$IFNDEF IOS}
const
  CBLIB = '/System/Library/Frameworks/Carbon.framework/Frameworks/HIToolbox.framework/HIToolbox';

type
  TISInputSourceRef = Pointer;

  function TISCopyCurrentKeyboardInputSource: TISInputSourceRef; cdecl; external CBLIB name 'TISCopyCurrentKeyboardInputSource';
  function TISGetInputSourceProperty(inputSource: TISInputSourceRef; propertyKey: CFStringRef): Pointer; cdecl; external CBLIB name 'TISGetInputSourceProperty';
  function CFDataGetBytePtr(theData: CFDataRef): Pointer; cdecl; external CoreFoundationLib name 'CFDataGetBytePtr';
  function LMGetKbdType: UInt32; cdecl; external CoreServicesLib name 'LMGetKbdType';
  function UCKeyTranslate(keyLayoutPtr: Pointer; virtualKeyCode: UInt16; keyAction: UInt16; modifierKeyState: UInt16; keyBoardType: UInt32;
    keyTranslateOptions: OptionBits; deadKeyState: Pointer; maxStringLength: UniCharCount; actualStringLength: Pointer; unicodeString: array of UniChar): OSStatus; cdecl; external CoreServicesLib name 'UCKeyTranslate';
 {$ENDIF}
 {$ENDIF}

implementation

uses
  Generics.Collections, WEBLib.TMSFNCStyles, WEBLib.TMSFNCPanel;

{$R 'TMSFNCTouchKeyboard.res'}

const
  vkLCommand         = $3D;  {  61 }

type
  TTMSFNCPanelEx = class(TTMSFNCPanel);
  TTMSFNCNonFocusablePopupEx = class(TTMSFNCNonFocusablePopup);

{$IFDEF MACOS}
{$IFNDEF IOS}
type
  TTMSFNCVirtualKeyInfo = record
    PlatformKey: Word;
    KeyKind: TKeyKind;
  end;

var
  vKeyMapping: TDictionary<Word,TTMSFNCVirtualKeyInfo> = nil;
  kTISPropertyUnicodeKeyLayoutData_: Pointer;
{$ENDIF}
{$ENDIF}

{$IFDEF MACOS}
{$IFNDEF IOS}
function RegisterKeyMappingEx(const PlatformKey, VirtualKey: Word; const KeyKind: TKeyKind): Boolean;
var
  KeyInfo: TTMSFNCVirtualKeyInfo;
begin
  Result := False;
  if not Assigned(vKeyMapping) then
    vKeyMapping := TDictionary<Word,TTMSFNCVirtualKeyInfo>.Create;
  if not vKeyMapping.ContainsKey(VirtualKey) then
  begin
    KeyInfo.PlatformKey := PlatformKey;
    KeyInfo.KeyKind := KeyKind;
    vKeyMapping.Add(VirtualKey, KeyInfo);
    Result := True;
  end;
end;

function VirtualKeyToPlatformKey(const VirtualKey: Word; var KeyKind: TKeyKind): Word;
var
  KeyInfo: TTMSFNCVirtualKeyInfo;
begin
  Result := 0;
  KeyKind := TKeyKind.Unknown;
  if Assigned(vKeyMapping) and vKeyMapping.ContainsKey(VirtualKey) then
  begin
    KeyInfo := vKeyMapping[VirtualKey];
    Result := KeyInfo.PlatformKey;
    KeyKind := KeyInfo.KeyKind;
  end;
end;

procedure AddArrayEx(A: array of Word; Kind: TKeyKind);
var
  I: Integer;
begin
  for I := 0 to (Length(A) - 1) div 2 do
    RegisterKeyMappingEx(A[I * 2], A[I * 2 + 1], Kind);
end;

procedure RegisterVirtualKeysEx;
const
  {$IFNDEF DELPHIXE7_LVL}
  KEY_CURRENCY           =  93;
  KEY_UNDERLINE          =  94;
  {$ENDIF}

  TrivialKeys: array [0..71] of Word = (
    KEY_F1        ,     vkF1,
    KEY_F2        ,     vkF2,
    KEY_F3        ,     vkF3,
    KEY_F4        ,     vkF4,
    KEY_F5        ,     vkF5,
    KEY_F6        ,     vkF6,
    KEY_F7        ,     vkF7,
    KEY_F8        ,     vkF8,
    KEY_F9        ,     vkF9,
    KEY_F10       ,     vkF10,
    KEY_F11       ,     vkF11,
    KEY_F12       ,     vkF12,
    KEY_F13       ,     vkF13,
    KEY_F14       ,     vkF14,
    KEY_F15       ,     vkF15,
    KEY_F16       ,     vkF16,
    KEY_F17       ,     vkF17,
    KEY_F18       ,     vkF18,
    KEY_F19       ,     vkF19,
    KEY_F20       ,     vkF20,
    KEY_TAB       ,     vkTab,
    KEY_INS       ,     vkInsert,
    KEY_DEL       ,     vkDelete,
    KEY_HOME      ,     vkHome,
    KEY_END       ,     vkEnd,
    KEY_PAGUP     ,     vkPrior,
    KEY_PAGDN     ,     vkNext,
    KEY_UP        ,     vkUp,
    KEY_DOWN      ,     vkDown,
    KEY_LEFT      ,     vkLeft,
    KEY_RIGHT     ,     vkRight,
    KEY_NUMLOCK   ,     vkNumLock,
    KEY_PADENTER  ,     vkReturn,
    KEY_BACKSPACE ,     vkBack,
    KEY_ENTER     ,     vkReturn,
    KEY_ESC       ,     vkEscape);

  TextKeys: array [0..131] of Word = (
    KEY_SPACE        ,    vkSpace,
    KEY_NUMPAD0      ,    vkNumpad0,
    KEY_NUMPAD1      ,    vkNumpad1,
    KEY_NUMPAD2      ,    vkNumpad2,
    KEY_NUMPAD3      ,    vkNumpad3,
    KEY_NUMPAD4      ,    vkNumpad4,
    KEY_NUMPAD5      ,    vkNumpad5,
    KEY_NUMPAD6      ,    vkNumpad6,
    KEY_NUMPAD7      ,    vkNumpad7,
    KEY_NUMPAD8      ,    vkNumpad8,
    KEY_NUMPAD9      ,    vkNumpad9,
    KEY_PADDIV       ,    vkDivide,
    KEY_PADMULT      ,    vkMultiply,
    KEY_PADSUB       ,    vkSubtract,
    KEY_PADADD       ,    vkAdd,
    KEY_PADDEC       ,    vkDecimal,
    KEY_SEMICOLON    ,    vkSemicolon,
    KEY_EQUAL        ,    vkEqual,
    KEY_COMMA        ,    vkComma,
    KEY_MINUS        ,    vkMinus,
    KEY_PERIOD       ,    vkPeriod,
    KEY_SLASH        ,    vkSlash,
    KEY_TILDE        ,    vkTilde,
    KEY_LEFTBRACKET  ,    vkLeftBracket,
    KEY_BACKSLASH    ,    vkBackslash,
    KEY_RIGHTBRACKET ,    vkRightBracket,
    KEY_QUOTE        ,    vkQuote,
    KEY_PARA         ,    vkPara,
    KEY_1            ,    vk1,
    KEY_2            ,    vk2,
    KEY_3            ,    vk3,
    KEY_4            ,    vk4,
    KEY_5            ,    vk5,
    KEY_6            ,    vk6,
    KEY_7            ,    vk7,
    KEY_8            ,    vk8,
    KEY_9            ,    vk9,
    KEY_0            ,    vk0,
    KEY_Q            ,    vkQ,
    KEY_W            ,    vkW,
    KEY_E            ,    vkE,
    KEY_R            ,    vkR,
    KEY_T            ,    vkT,
    KEY_Y            ,    vkY,
    KEY_U            ,    vkU,
    KEY_I            ,    vkI,
    KEY_O            ,    vkO,
    KEY_P            ,    vkP,
    KEY_A            ,    vkA,
    KEY_S            ,    vkS,
    KEY_D            ,    vkD,
    KEY_F            ,    vkF,
    KEY_G            ,    vkG,
    KEY_H            ,    vkH,
    KEY_J            ,    vkJ,
    KEY_K            ,    vkK,
    KEY_L            ,    vkL,
    KEY_Z            ,    vkZ,
    KEY_X            ,    vkX,
    KEY_C            ,    vkC,
    KEY_V            ,    vkV,
    KEY_B            ,    vkB,
    KEY_N            ,    vkN,
    KEY_M            ,    vkM,
    KEY_CURRENCY     ,    vkOem102,
    KEY_UNDERLINE    ,    vkNone);

begin
  AddArrayEx(TrivialKeys, TKeyKind.Functional);
  AddArrayEx(TextKeys, TKeyKind.Usual);
end;

function GetTISAttributeObject(Attr: string): Pointer;
var
  CTLib: HMODULE;
begin
  CTLib := LoadLibrary(CBLIB);
  Result := Pointer(GetProcAddress(CTLib, PWideChar(Attr))^);
  FreeLibrary(CTLib);
end;

function kTISPropertyUnicodeKeyLayoutData: Pointer;
begin
  if kTISPropertyUnicodeKeyLayoutData_ = nil then
    kTISPropertyUnicodeKeyLayoutData_ := GetTISAttributeObject('kTISPropertyUnicodeKeyLayoutData');
  Result := kTISPropertyUnicodeKeyLayoutData_;
end;

function createStringforKey(keyCode: CGKeyCode): CFStringRef;
var
  currentKeyboard: TISInputSourceRef;
  layoutData: CFDataRef;
  keyboardLayout: Pointer;
  keysDown: UInt32;
  chars: array[0..3] of UniChar;
  realLength: UniCharCount;
begin
  currentKeyboard := TISCopyCurrentKeyboardInputSource;
  layoutData := TISGetInputSourceProperty(currentKeyboard, kTISPropertyUnicodeKeyLayoutData);

  keyboardLayout := CFDataGetBytePtr(layoutData);
  keysdown := 0;
    UCKeyTranslate(keyboardLayout,
                   keyCode,
                   3,
                   0,
                   LMGetKbdType(),
                   0,
                   @keysDown,
                   sizeof(chars) div sizeof(chars[0]),
                   @realLength,
                   chars);

   CFRelease(currentKeyboard);

  Result := CFStringCreateWithCharacters(kCFAllocatorDefault, chars, 1);
end;

function keyCodeForChar(c: Char): CGKeyCode;
var
  str: CFStringRef;
  i: Word;
  s: String;
begin
  Result := 128;
  for I := 0 to 127 do
  begin
    str := createStringforKey(i);
    if Assigned(str) then
    begin
      s := UTF8ToString(TNSString.Wrap(str).UTF8String);
      if s = c then
      begin
        Exit(I);
      end;
    end;
  end;
end;
{$ENDIF}
{$ENDIF}

{$IFDEF MSWINDOWS}
function IsComboBox(hwnd: THandle): Boolean;
var
  ClassName: array[0..255] of Char;
begin
  GetClassName(hwnd, ClassName, SizeOf(ClassName));

  if Pos('EDIT',Uppercase(StrPas(ClassName))) >  0 then
  begin
    GetClassName(GetParent(hwnd), ClassName, SizeOf(ClassName));
  end;

  Result := Pos('COMBO',Uppercase(StrPas(ClassName))) >  0;
end;
{$ENDIF}


{ TTMSFNCTouchKeyboard }

procedure TTMSFNCCustomTouchKeyboard.AddKey(ACaption, AShiftCaption,
  AAltGrCaption: string; AKeyValue, AShiftKeyValue, AAltGrKeyValue: Integer; AImageName: string;
  AWidth, AHeight: Single; var AX: Single; AY: Single; ASpecialKey: TTMSFNCSpecialKey;
  AColor: TTMSFNCGraphicsColor);
begin
  with FKeys do
  begin
    Add;
    Items[FKeys.Count - 1].AutoPost := True;
    Items[FKeys.Count - 1].Caption := ACaption;
    Items[FKeys.Count - 1].Color := AColor;
    Items[FKeys.Count - 1].ShiftCaption := AShiftCaption;
    Items[FKeys.Count - 1].AltGrCaption := AAltGrCaption;
    Items[FKeys.Count - 1].KeyValue := AKeyValue;
    Items[FKeys.Count - 1].ShiftKeyValue := AShiftKeyValue;
    Items[FKeys.Count - 1].AltGrKeyValue := AAltGrKeyValue;
    if not (csDesigning in Self.ComponentState) then
    begin
      if Assigned(BitmapContainer) then
        Items[FKeys.Count - 1].ImageName := AImageName;
    end
    else
      Items[FKeys.Count - 1].ImageName:= '';

    Items[FKeys.Count - 1].SpecialKey := ASpecialKey;
    Items[FKeys.Count - 1].Width := AWidth;
    Items[FKeys.Count - 1].Height := AHeight;
    Items[FKeys.Count - 1].X := AX;
    Items[FKeys.Count - 1].Y := AY;
    Items[FKeys.Count - 1].OldW := -1;
    Items[FKeys.Count - 1].OldH := -1;
    Items[FKeys.Count - 1].OldX := -1;
    Items[FKeys.Count - 1].OldY := -1;
    AX := AX + Items[FKeys.Count - 1].Width;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
  I: Integer;
begin
  inherited;

  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
  begin
    Fill.Color := c;

    for I := 0 to Keys.Count - 1 do
      Keys[I].FTouchKey.Fill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    Stroke.Color := c;

    for I := 0 to Keys.Count - 1 do
      Keys[I].FTouchKey.Stroke.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleDefaultButtonFillColor(c) then
  begin
     for I := 0 to Keys.Count - 1 do
      Keys[I].Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    for I := 0 to Keys.Count - 1 do
      Keys[I].BorderColorDown := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    for I := 0 to Keys.Count - 1 do
      Keys[I].ColorDown := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    FFont.Color := c;
    FSmallFont.Color := c;

    for I := 0 to Keys.Count - 1 do
    begin
      Keys[I].FTouchKey.FFont.Color := c;
      Keys[I].FTouchKey.FSmallFont.Color := c;
    end;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.AutoZoom;
var
  i: integer;
  fhorz: Double;
  fvert: Double;
begin
  if (FOriginalW = 0) or (FOriginalH = 0) or (csLoading in ComponentState) or (csReading in ComponentState) then
    Exit;

  if not AllowAutoZoom then
    Exit;

//  for I := 0 to Length(FCachedBitmaps) - 1 do
//  begin
//    c := FCachedBitmaps[I];
//    if Assigned(c.CachedBitmap) then
//    begin
//      c.CachedBitmap.Free;
//      c.CachedBitmap := nil;
//    end;
//    FCachedBitmaps[I] := c;
//  end;
//
//  SetLength(FCachedBitmaps, 0);

  fhorz := Width / Round(FOriginalW);
  fvert := Height / Round(FOriginalH);

  if (fhorz > 0) and (fvert >= 0) then
  begin
    for i := 0 to Keys.Count - 1 do
    begin
      if (Keys[i].OldW < 0) or (Keys[i].OldH < 0) then
      begin
        Keys[i].OldW := Keys[i].Width;
        Keys[i].OldH := Keys[i].Height;
        Keys[i].OldX := Keys[i].X;
        Keys[i].OldY := Keys[i].Y;
      end;
      Keys[i].width := Round(Keys[i].OldW * fhorz);
      Keys[i].height := Round(Keys[i].OldH * fvert);
      Keys[i].x := Round(Keys[i].OldX * fhorz);
      Keys[i].y := Round(Keys[i].OldY  * fvert);
    end;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.BuildAZERTYKeyBoard;
{$IFNDEF DELPHI_LLVM}
var
  CurrentX,
  CurrentY: Single;
  Size: Integer;
{$ENDIF}
begin
  {$IFNDEF DELPHI_LLVM}
  CurrentY := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  CurrentX := CurrentY;
  Size := 40;

  FDoAutoZoom := False;
  if not (csLoading in ComponentState) then
  begin
    Height := GetDefaultKeyboardHeight(ktAZERTY);
    Width  := GetDefaultKeyboardWidth(ktAZERTY);
  end;
  FOriginalH := Height;
  FOriginalW := Width;
  FDoAutoZoom := True;
  {$ENDIF}

  with Keys do
  begin
    {$IFDEF MSWINDOWS}
    AddKey('²', '³','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('&', '1','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('é', '2','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('"', '3','#', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('''', '4','',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('(', '5','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('§', '6','^',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('è', '7','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('!', '8','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ç', '9','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('à', '0','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(')', 'º','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', VK_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('^', '¨','[',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('$', '*',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', VK_RETURN, -1, -1, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ù', '%','´', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('µ', '£','`', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_LSHIFT, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>','\', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', '.','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ',','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(':', '/','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_RSHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_LCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', VK_LWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', VK_RWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', VK_APPS, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);
    AddKey('Ctrl', '','', VK_RCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}

    {$IFDEF MACOS}
    {$IFNDEF IOS}
    AddKey('@', '#','•', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('&', '1','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('é', '2','ë', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('"', '3','"', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('''', '4','',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('(', '5','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('§', '6','¶',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('è', '7','«', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('!', '8','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('ç', '9','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('à', '0','Ø', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(')', 'º','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('-', '_','—', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Backspace', '','', KEY_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, $A0A0A0);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', KEY_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, $A0A0A0);
    AddKey('A', '','æ', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Z', '','Â', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('E', '','ê', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('R', '','®', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('T', '','†', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Y', '','Ú', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('U', '','°', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('I', '','î', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('O', '','œ', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('P', '',('π'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('^', '¨','ô',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('$', '*','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', KEY_ENTER, -1, -1, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, $A0A0A0);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', KEY_CAPSLOCK, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, $A0A0A0);
    AddKey('Q', '',('‡'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('S', '',('Ò'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('D', '',('∂'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('F', '',('ƒ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('G', '',('fi'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('H', '',('Ì'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('J', '',('Ï'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('K', '',('È'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('L', '',('¬'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('M', '',('µ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('ù', '%','Ù', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('µ', '£','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, $A0A0A0);
    AddKey('<', '>',('≤'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('W', '',('‹'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('X', '',('≈'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('C', '',('©'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('V', '',('♦'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('B', '',('ß'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('N', '','~', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(',', '?',('∞'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(';', '.','...', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(':', '/',('÷'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('=', '+',('≠'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, $A0A0A0);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, $A0A0A0);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, $A0A0A0);
    AddKey('cmd', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skWin, $A0A0A0);
    AddKey('', '','', KEY_SPACE, -1, -1, '', Size * 7, Size, CurrentX, CurrentY, skSpaceBar);
    AddKey('cmd', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skWin, $A0A0A0);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, $A0A0A0);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, $A0A0A0);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF FMXLIB}
    {$IFDEF LINUX}
    AddKey('²', '³','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('&', '1','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('é', '2','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('"', '3','#', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('''', '4','',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('(', '5','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('§', '6','^',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('è', '7','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('!', '8','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ç', '9','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('à', '0','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(')', 'º','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', vkBack, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', vkTab, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('^', '¨','[',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('$', '*',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', vkReturn, -1, -1, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', vkCapital, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ù', '%','´', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('µ', '£','`', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', vkLShift, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>','\', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', '.','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ',','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(':', '/','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', vkRShift, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', vkLControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', vkLCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', vkMenu, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', vkSpace, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', vkRCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', vkApps, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);
    AddKey('Ctrl', '','', vkControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF WEBLIB}
    AddKey('²', '³','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('&', '1','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('é', '2','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('"', '3','#', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('''', '4','',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('(', '5','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('§', '6','^',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('è', '7','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('!', '8','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ç', '9','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('à', '0','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(')', 'º','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', VK_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('^', '¨','[',-1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('$', '*',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', VK_RETURN, -1, -1, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ù', '%','´', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('µ', '£','`', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>','\', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', '.','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ',','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(':', '/','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 7, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.BuildCellPhoneKeyboard;
var
  CurrentX,
  CurrentY: Single;
  Size: Integer;
begin
  CurrentY := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  CurrentX := CurrentY;
  Size := 40;

  FDoAutoZoom := False;
  if not (csLoading in ComponentState) then
  begin
    Height := GetDefaultKeyboardHeight(ktCELLPHONE);
    Width  := GetDefaultKeyboardWidth(ktCELLPHONE);
  end;
  FOriginalH := Height;
  FOriginalW := Width;
  FDoAutoZoom := True;

  with Keys do
  begin
  AddKey('7', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('8', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('9', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  // End Row #2
  NewRow(CurrentX, CurrentY, Size);
  AddKey('4', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('5', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('6', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  // End Row #3
  NewRow(CurrentX, CurrentY, Size);
  AddKey('1', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('2', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('3', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  // End Row #4
  NewRow(CurrentX, CurrentY, Size);
  AddKey('*', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  AddKey('0', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  {$IFDEF MSWINWEB}
  AddKey('Back', '', '', VK_BACK, -1, -1, '', Size, Size, CurrentX, CurrentY, skBackspace, FSpecialColor);
  {$ENDIF}
  {$IFDEF MACOS}
  AddKey('Back', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skBackspace, FSpecialColor);
  {$ENDIF}
  {$IFDEF FMXLIB}
  {$IFDEF LINUX}
  AddKey('Back', '', '', vkBack, -1, -1, '', Size, Size, CurrentX, CurrentY, skBackspace, FSpecialColor);
  {$ENDIF}
  {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.BuildDVORAKKeyBoard;
{$IFNDEF DELPHI_LLVM}
var
  CurrentX,
  CurrentY: Single;
  Size: Integer;
{$ENDIF}
begin
  {$IFNDEF DELPHI_LLVM}
  CurrentY := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  CurrentX := CurrentY;
  Size := 40;

  FDoAutoZoom := false;
  if not (csLoading in ComponentState) then
  begin
    Height := GetDefaultKeyboardHeight(ktDVORAK);
    Width  := GetDefaultKeyboardWidth(ktDVORAK);
  end;
  FOriginalH := Height;
  FOriginalW := Width;
  FDoAutoZoom := true;
  {$ENDIF}

  with Keys do
  begin
    {$IFDEF MSWINDOWS}
    AddKey('`', '~','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', VK_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('''', '"','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Enter', '','', VK_RETURN, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_LSHIFT, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey(';', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_RSHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_LCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', VK_LWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', VK_NONCONVERT, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', VK_RWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', VK_APPS, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);
    AddKey('Ctrl', '','', VK_RCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}

    {$IFDEF MACOS}
    {$IFNDEF IOS}
    AddKey('§', '±','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('1', '!',('¡'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('2', '@',('™'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('3', '#','£', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('4', '$',('¢'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('5', '%',('∞'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('6', '^','§', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('7', '&',('¶'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('8', '*',('•'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('9', '(',('ª'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('0', ')','°', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('[', '{','"', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(']', '}','''', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Backspace', '','', KEY_BACKSPACE, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, $A0A0A0);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', KEY_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, $A0A0A0);
    AddKey('''', '"',('Æ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(',', '<',('≤'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('.', '>',('≥'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('P', '',('∏'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Y', '',('Á'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('F', '',('Ï'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('G', '',('©'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('C', '',('Ç'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('R', '',('®'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('L', '','Ò', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('/', '?',('÷'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('=', '+',('≠'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', KEY_CAPSLOCK, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, $A0A0A0);
    AddKey('A', '','Å', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('O', '','Ø', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('E', '','´', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('U', '','¨', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('I', '','^', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('D', '','Î', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('H', '','Ó', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('T', '','†', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('N', '','˜', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('S', '','Í', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('-', '_','-', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('\', '|','«', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skNone);
    AddKey('Enter', '','', KEY_ENTER, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skReturn, $A0A0A0);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skShift, $A0A0A0);
    AddKey('`', '~','`', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey(';', ':','...', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Q', '',('Œ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('J', '',('Ô'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('K', '',('°'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('X', '',('≈'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('M', '',('Â'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('W', '',('∑'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('V', '',('√'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Z', '',('Ω'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, $A0A0A0);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, $A0A0A0);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, $A0A0A0);
    AddKey('cmd', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skWin, $A0A0A0);
    AddKey('', '','', KEY_SPACE, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar);
    AddKey('cmd', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, $A0A0A0);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, $A0A0A0);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, $A0A0A0);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF FMXLIB}
    {$IFDEF LINUX}
    AddKey('`', '~','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', vkBack, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', vkTab, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('''', '"','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', vkCapital, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Enter', '','', vkReturn, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', vkLShift, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey(';', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', vkRShift, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', vkControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', vkLCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', vkMenu, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', vkSpace, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', vkNonConvert, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', vkRCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', vkApps, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);
    AddKey('Ctrl', '','', vkControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF WEBLIB}
    AddKey('`', '~','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', VK_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('''', '"','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, -1, -1, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Enter', '','', VK_RETURN, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', (Size * 9) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey(';', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 7, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.BuildNumericKeyBoard;
{$IFNDEF DELPHI_LLVM}
var
  CurrentX,
  CurrentY: Single;
  Size: Integer;
{$ENDIF}
begin
  {$IFNDEF DELPHI_LLVM}
  CurrentY := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  CurrentX := CurrentY;
  Size := 40;

  FDoAutoZoom := False;
  if not (csLoading in ComponentState) then
  begin
    Height := GetDefaultKeyboardHeight(ktNUMERIC);
    Width  := GetDefaultKeyboardWidth(ktNUMERIC);
  end;
  FOriginalH := Height;
  FOriginalW := Width;
  FDoAutoZoom := True;
  {$ENDIF}

  with Keys do
  begin
    {$IFDEF MSWINWEB}
    AddKey('Num Lock', '', '', VK_NUMLOCK, -1, -1, '', Size, Size, CurrentX, CurrentY, skNum, FSpecialColor);
    AddKey('/', '', '', VK_DIVIDE, -1, -1, '', Size, Size, CurrentX, CurrentY, skDivide, FNormalColor);
    AddKey('*', '', '', VK_MULTIPLY, -1, -1, '', Size, Size, CurrentX, CurrentY, skMultiply, FNormalColor);
    AddKey('-', '','', VK_SUBTRACT, -1, -1, '', Size, Size, CurrentX, CurrentY, skSubtract, FNormalColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('7', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '', '', VK_ADD, -1, -1, '', Size, Size * 2, CurrentX, CurrentY, skAdd, FNormalColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('4', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('1', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Enter', '', '', VK_RETURN, -1, -1, '', Size, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('0', '', '', -1, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '', '', VK_DECIMAL, -1, -1, '', Size, Size, CurrentX, CurrentY, skDecimal, FNormalColor);
    {$ENDIF}

    {$IFDEF MACOS}
    {$IFNDEF IOS}
    AddKey('Num Lock', '', '', KEY_NUMLOCK, -1, -1, '', Size, Size, CurrentX, CurrentY, skNum, FSpecialColor);
    AddKey('/', '', '', KEY_DIVIDE, -1, -1, '', Size, Size, CurrentX, CurrentY, skDivide, FNormalColor);
    AddKey('*', '', '', KEY_MULTIPLY, -1, -1, '', Size, Size, CurrentX, CurrentY, skMultiply, FNormalColor);
    AddKey('-', '','', KEY_SUBTRACT, -1, -1, '', Size, Size, CurrentX, CurrentY, skSubtract, FNormalColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('7', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '', '', KEY_ADD, -1, -1, '', Size, Size * 2, CurrentX, CurrentY, skAdd, FNormalColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('4', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('1', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Enter', '', '', KEY_RETURN, -1, -1, '', Size, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('0', '', '', -1, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '', '', KEY_DECIMAL, -1, -1, '', Size, Size, CurrentX, CurrentY, skDecimal, FNormalColor);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF FMXLIB}
    {$IFDEF LINUX}
    AddKey('Num Lock', '', '', vkNumLock, -1, -1, '', Size, Size, CurrentX, CurrentY, skNum, FSpecialColor);
    AddKey('/', '', '', vkDivide, -1, -1, '', Size, Size, CurrentX, CurrentY, skDivide, FNormalColor);
    AddKey('*', '', '', vkMultiply, -1, -1, '', Size, Size, CurrentX, CurrentY, skMultiply, FNormalColor);
    AddKey('-', '','', vkSubtract, -1, -1, '', Size, Size, CurrentX, CurrentY, skSubtract, FNormalColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('7', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '', '', vkAdd, -1, -1, '', Size, Size * 2, CurrentX, CurrentY, skAdd, FNormalColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('4', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('1', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '', '', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Enter', '', '', vkReturn, -1, -1, '', Size, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('0', '', '', -1, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '', '', vkDecimal, -1, -1, '', Size, Size, CurrentX, CurrentY, skDecimal, FNormalColor);
    {$ENDIF}
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.BuildQWERTYKeyBoard;
{$IFNDEF DELPHI_LLVM}
var
  CurrentX,
  CurrentY: Single;
  Size: Integer;
{$ENDIF}
begin
  {$IFNDEF DELPHI_LLVM}
  CurrentY := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  CurrentX := CurrentY;
  Size := 40;

  FDoAutoZoom := false;
  if not (csLoading in ComponentState) then
  begin
    Height := GetDefaultKeyboardHeight(ktQWERTY);
    Width  := GetDefaultKeyboardWidth(ktQWERTY);
  end;
  FOriginalH := Height;
  FOriginalW := Width;
  FDoAutoZoom := true;
  {$ENDIF}

  with Keys do
  begin
    {$IFDEF MSWINDOWS}
    AddKey('`', '~','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','|', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Back', '','', VK_BACK, -1, -1, '', Size, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', VK_RETURN, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '"','', -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_LSHIFT, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_RSHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_LCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', VK_LWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', VK_RWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', VK_APPS, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);

    AddKey('->', '','', VK_RIGHT, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skRight, FSpecialColor);
    {$ENDIF}

    {$IFDEF MACOS}
    {$IFNDEF IOS}
    AddKey('`§', ('±'),'', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','¡', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@',('€'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#',('£'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$',('¢'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%',('∞'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^',('§'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&',('¶'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*',('•'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(',('ª'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','°', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','-', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+',('≠'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', KEY_BACK, -1, -1, '', (Size * 2), Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', KEY_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('Q', '',('œ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '',('∑'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','´', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '',('®'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '',('†'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '',('¥'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','¨', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','^', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '',('Ø'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '',('π'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','"', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','''', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + 5;
    AddKey('Enter', '','', KEY_ENTER, 0, 0, '', (Size * 6) / 4.5, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', KEY_CAPSLOCK, 0, 0, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '',('å'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '',('ß'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '',('∂'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '',('ƒ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '',('©'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','’', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '',('Δ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','°', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '',('¬'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ':','...', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('''', '"',('æ'), -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|',('«'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', -1, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('`', '~','`', -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '',('Ω'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '',('≈'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','ç', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '',('√'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '',('∫'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '',('~'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','µ', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<',('≤'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>',('≥'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?',('÷'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 8) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('cmd', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('', '','', KEY_SPACE, -1, -1, '', Size * 7.5, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('cmd', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF FMXLIB}
    {$IFDEF LINUX}
    AddKey('`', '~','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','|', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Back', '','', vkBack, -1, -1, '', Size, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', vkTab, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', vkReturn, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', vkCapital, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('''', '"','', -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  //  AddKey('Ç', '','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', vkShift, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    //AddKey('<', '>','', -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', vkShift, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', vkControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', vkLCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', vkMenu, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', vkSpace, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', vkRCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', 135, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);

    AddKey('->', '','', vkRight, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skRight, FSpecialColor);
    {$ENDIF}
    {$ENDIF}
    {$IFDEF WEBLIB}
    AddKey('`', '~','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','|', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '@','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '#','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '^','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '*','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', '(','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', ')','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('=', '+','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('\', '|','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Back', '','', VK_BACK, -1, -1, '', Size, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FSpecialColor);
    AddKey('Q', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('[', '{','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(']', '}','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', VK_RETURN, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(';', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '"','', -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
  //  AddKey('Ç', '','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    //AddKey('<', '>','', -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', '<','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', '>','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('/', '?','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', Size * 3, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 7, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.BuildQWERTZKeyBoard;
{$IFNDEF DELPHI_LLVM}
var
  CurrentX,
  CurrentY: Single;
  Size: Integer;
{$ENDIF}
begin
  {$IFNDEF DELPHI_LLVM}
  CurrentY := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  CurrentX := CurrentY;
  Size := 40;

  FDoAutoZoom := false;
  if not (csLoading in ComponentState) then
  begin
    Height := GetDefaultKeyboardHeight(ktQWERTZ);
    Width  := GetDefaultKeyboardWidth(ktQWERTZ);
  end;
  FOriginalH := Height;
  FOriginalW := Width;
  FDoAutoZoom := true;
  {$ENDIF}

  with Keys do
  begin
    {$IFDEF MSWINDOWS}
    AddKey('^', '°','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '"','²', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '§','³', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '/','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '(','[', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', ')',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', '=','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ß', '?','\', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('´', '`','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', VK_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FNormalColor);
    AddKey('Q', '','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ü', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '*','~', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', VK_RETURN, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ö', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ä', '','', -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('#', '''','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_LSHIFT, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>','', -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', ';','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_RSHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_LCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', VK_LWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', VK_RWIN, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', VK_APPS, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);
    AddKey('Ctrl', '','', VK_RCONTROL, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}

    {$IFDEF MACOS}
    {$IFNDEF IOS}
    AddKey('^', '°','"', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!',('¡'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '"','"', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '§',('¶'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$',('¢'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','[', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '&',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '/','|', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '(','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', ')','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', '=',('≠'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ß', '?',('¿'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('´', '`','''', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', KEY_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', KEY_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FNormalColor);
    AddKey('Q', '',('«'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '',('∑'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '',('®'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '',('†'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '',('Ω'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','"', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','/', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '',('Ø'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '',('π'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ü', '',('•'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '*',('±'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', KEY_RETURN, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', KEY_CAPSLOCK, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '',('å'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '',',', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '',('∂'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '',('ƒ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '',('©'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '',('ª'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '',('°'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '',('Δ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ö', '',('œ'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ä', '',('æ'), -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('#', '''','''', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>',('≤'), -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '',('¥'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '',('≈'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','ç', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '',('√'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '',('∫'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '',('~'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','µ', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', ';',('∞'), -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', ':','...', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','-', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', -1, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('cmd', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('', '','', KEY_SPACE, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('cmd', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('alt', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('ctrl', '','', -1, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF FMXLIB}
    {$IFDEF LINUX}
    AddKey('^', '°','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '"','²', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '§','³', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '/','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '(','[', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', ')',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', '=','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ß', '?','\', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('´', '`','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', vkBack, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', vkTab, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FNormalColor);
    AddKey('Q', '','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ü', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '*','~', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', vkReturn, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', vkCapital, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ö', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ä', '','', -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('#', '''','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', vkLShift, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>','', -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', ';','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', vkRShift, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', vkLControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Win', '','', vkLCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Alt', '','', vkMenu, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', vkSpace, -1, -1, '', Size * 6, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Win', '','', vkRCommand, -1, -1, '', Size, Size, CurrentX, CurrentY, skWin, FSpecialColor);
    AddKey('Menu', '','', vkApps, -1, -1, '', Size, Size, CurrentX, CurrentY, skApp, FSpecialColor);
    AddKey('Ctrl', '','', vkRControl, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
    {$ENDIF}

    {$IFDEF WEBLIB}
    AddKey('^', '°','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('1', '!','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('2', '"','²', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('3', '§','³', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('4', '$','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('5', '%','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('6', '&','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('7', '/','{', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('8', '(','[', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('9', ')',']', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('0', '=','}', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('ß', '?','\', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('´', '`','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Backspace', '','', VK_BACK, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skBackSpace, FSpecialColor);
    // End Row #1
    NewRow(CurrentX, CurrentY, Size);
    AddKey('->', '<-','', VK_TAB, -1, -1, '', (Size * 6) div 4, Size, CurrentX, CurrentY, skTab, FNormalColor);
    AddKey('Q', '','@', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('W', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('E', '','€', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('R', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('T', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Z', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('U', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('I', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('O', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('P', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ü', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('+', '*','~', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    CurrentX := CurrentX + (Size div 4);
    AddKey('Enter', '','', VK_RETURN, 0, 0, '', (Size * 5) div 4, Size * 2, CurrentX, CurrentY, skReturn, FSpecialColor);
    // End Row #2
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Caps Lock', '','', VK_CAPITAL, 0, 0, '', (Size * 7) div 4, Size, CurrentX, CurrentY, skCaps, FSpecialColor);
    AddKey('A', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('S', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('D', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('F', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('G', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('H', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('J', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('K', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('L', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ö', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Ä', '','', -1, -1,-1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('#', '''','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    // End Row #3
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', (Size * 5) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    AddKey('<', '>','', -1, -1 , -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Y', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('X', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('C', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('V', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('B', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('N', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('M', '','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey(',', ';','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('.', ':','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('-', '_','', -1, -1, -1, '', Size, Size, CurrentX, CurrentY, skNone, FNormalColor);
    AddKey('Shift', '','', VK_SHIFT, -1, -1, '', (Size * 11) div 4, Size, CurrentX, CurrentY, skShift, FSpecialColor);
    // End Row #4
    NewRow(CurrentX, CurrentY, Size);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    AddKey('Alt', '','', VK_MENU, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAlt, FSpecialColor);
    AddKey('', '','', VK_SPACE, -1, -1, '', Size * 7, Size, CurrentX, CurrentY, skSpaceBar, FNormalColor);
    AddKey('Alt Gr', '','', 0, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skAltGr, FSpecialColor);
    AddKey('Ctrl', '','', VK_CONTROL, -1, -1, '', Size * 2, Size, CurrentX, CurrentY, skCtrl, FSpecialColor);
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.CapsTimerProc(Sender: TObject);
var
  State: ShortInt;
  FNewCapsDown: Boolean;
begin
  {$IFDEF MSWINDOWS}
  State := GetKeyState(VK_CAPITAL);
  {$ENDIF}
  {$IFDEF MACOS}
  State := 0;
  if IsCapsDown then
  State := 1;
  {$ENDIF}
  {$IFDEF LINUX}
  State := 0;
  if IsCapsDown then
  State := 1;
  {$ENDIF}
  FNewCapsDown := (State and $1) <> 0;
  if FNewCapsDown <> FCapsDown then
  begin
    Invalidate;
    FCapsDown := FNewCapsDown;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.ChangeDPIScale(M, D: Integer);
begin
  FChangingScale := True;
  inherited;
  Font.Height := TTMSFNCUtils.MulDivInt(Font.Height, M, D);
  SmallFont.Height := TTMSFNCUtils.MulDivInt(SmallFont.Height, M, D);
  FChangingScale := False;
end;

constructor TTMSFNCCustomTouchKeyboard.Create(AOwner: TComponent);
var
  State: ShortInt;
begin
  inherited Create(AOwner);

  AllowFocus := False;
  FChangingScale := False;

  FNormalColor := gcWhite;
  FSpecialColor := gcWhite;
  FAllowAutoZoom := True;
  FKeySpacing := 2;
  {$IFDEF WEBLIB}
  FUseActiveControlText := True;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  FUseActiveControlText := False;
  {$ENDIF}

  {$IFNDEF VCLLIB}
  ClipChildren := True;
  {$ENDIF}

  Fill.Kind := gfkSolid;
  Fill.Color := {$IFDEF FMXLIB}gcAlpha or{$ENDIF}$ECECEC;

  Stroke.Kind := gskSolid;
  Stroke.Color := gcLightGray;

  FPictureNormalState := TTMSFNCBitmap.Create;
  FPictureDownState := TTMSFNCBitmap.Create;

  FPictureDownState.OnChange := @PictureChanged;
  FPictureNormalState.OnChange := @PictureChanged;

  {$IFDEF MSWINDOWS}
  State := GetKeyState(VK_CAPITAL);
  {$ENDIF}
  {$IFDEF MACOS}
  State := 0;
  FCommandFlag := 0;
  {$ENDIF}
  if (State = 1) then
  begin
    FShift := [ssShift];
    FCapsDown := True;
  end
  else
  begin
    FShift := [];
    FCapsDown := False;
  end;

  FKeys := TTMSFNCTouchKeyCollection.Create(self);
  FAutoCapsDisplay := True;

  FDoAutoZoom := False;
  Height := 200;
  Width  := 160;
  FDoAutoZoom := True;
  FAutoPostKey := True;
  FHighlightCaps := gcNull;
  FHighlightAltGr := gcNull;

  FFont := TTMSFNCGraphicsFont.Create;
  {$IFDEF VCLLIB}
  FFont.Size := 6;
  {$ENDIF}
  {$IFDEF FMXLIB}
  FFont.Size := 12;
  {$ENDIF}
  {$IFDEF WEBLIB}
  FFont.Size := 9;
  {$ENDIF}
  FFont.Style := [TFontStyle.fsBold];

  FSmallFont := TTMSFNCGraphicsFont.Create;
  {$IFDEF VCLLIB}
  FSmallFont.Size := 5;
  {$ENDIF}
  {$IFDEF FMXLIB}
  FSmallFont.Size := 10;
  {$ENDIF}
  {$IFDEF WEBLIB}
  FSmallFont.Size := 8;
  {$ENDIF}
  FSmallFont.Style := [TFontStyle.fsBold];

  KeyboardType := ktQWERTY;
  FRepeatTimer := TTimer.Create(self);
  FRepeatTimer.Interval := 200;
  FRepeatTimer.Enabled := False;
  FRepeatTimer.OnTimer := RepeatTimerProc;
  FOldW := -1;
  FOldH := -1;
  FCapsTimer := TTimer.Create(self);
  FCapsTimer.Interval := 250;
  FCapsTimer.Enabled := True;
  FCapsTimer.OnTimer := CapsTimerProc;

  FBorderRounding := 5;
end;

{$IFDEF VCLLIB}
procedure TTMSFNCCustomTouchKeyboard.CreateWnd;
begin
  FChangingScale := True;
  inherited;
  FChangingScale := False;
end;
{$ENDIF}

destructor TTMSFNCCustomTouchKeyboard.Destroy;
begin
  FFont.Free;
  FKeys.Free;
  FPictureNormalState.Free;
  FPictureDownState.Free;
  FSmallFont.Free;
  FRepeatTimer.Free;
  FCapsTimer.Free;
  inherited;
end;

procedure TTMSFNCCustomTouchKeyboard.DrawKey(Sender: TObject; AKey: TTMSFNCTouchKeyItem; AGraphics: TTMSFNCGraphics; ADown: Boolean; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(FOnDrawKey) then
    FOnDrawKey(Sender, AKey, AGraphics, ADown, ARect, ADefaultDraw);
end;

procedure TTMSFNCCustomTouchKeyboard.GetActiveControlText(var AText: string; var ASelPosStart: Integer; var ASelPosEnd: Integer);
{$IFNDEF WEBLIB}
var
  Ctx: TRttiContext;
  Prop: TRttiProperty;
  selLength: Integer;
{$ENDIF}
begin
  ASelPosStart := -1;
  ASelPosEnd := -1;
  AText := '';
  {$IFDEF WEBLIB}

  if (UpperCase(document.activeElement.tagName) = 'TEXTAREA') or (UpperCase(document.activeElement.tagName) = 'INPUT') then
  begin
    AText := TJSHTMLInputElement(document.activeElement).value;
    ASelPosStart := TJSHTMLInputElement(document.activeElement).selectionStart;
    ASelPosEnd := TJSHTMLInputElement(document.activeElement).selectionEnd;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  selLength := 0;
  {$IFDEF FMXLIB}
  if Assigned(Screen.FocusControl) then
  begin
    Prop := Ctx.GetType(Screen.FocusControl.GetObject.ClassType).GetProperty('Text');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      AText := Prop.GetValue(Screen.FocusControl.GetObject).ToString;

    Prop := Ctx.GetType(Screen.FocusControl.GetObject.ClassType).GetProperty('SelStart');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      ASelPosStart := Prop.GetValue(Screen.FocusControl.GetObject).AsInteger;

    Prop := Ctx.GetType(Screen.FocusControl.GetObject.ClassType).GetProperty('SelLength');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      selLength := Prop.GetValue(Screen.FocusControl.GetObject).AsInteger;
  end;
  {$ENDIF}
  {$IFDEF VCLLIB}
  if Assigned(Screen.ActiveControl) then
  begin
    Prop := Ctx.GetType(Screen.ActiveControl.ClassType).GetProperty('Text');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      AText := Prop.GetValue(Screen.ActiveControl).ToString;

    Prop := Ctx.GetType(Screen.ActiveControl.ClassType).GetProperty('SelStart');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      ASelPosStart := Prop.GetValue(Screen.ActiveControl).AsInteger;

    Prop := Ctx.GetType(Screen.ActiveControl.ClassType).GetProperty('SelLength');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      selLength := Prop.GetValue(Screen.ActiveControl).AsInteger;
  end;
  {$ENDIF}
  ASelPosEnd := ASelPosStart + selLength;
  {$ENDIF}
end;

function TTMSFNCCustomTouchKeyboard.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomTouchKeyboard.GetDefaultKeyboardHeight(
  AKeyboardType: TTMSFNCKeyboardType): Integer;
begin
  Result := 0;
  case AKeyboardType of
    ktQWERTY, ktAZERTY, ktDVORAK, ktQWERTZ{$IFDEF MSWINDOWS},ktCustom{$ENDIF}: Result := {$IFDEF FMXLIB}200{$ELSE}202{$ENDIF};
    ktNUMERIC: Result := {$IFDEF FMXLIB}200{$ELSE}202{$ENDIF};
    ktCELLPHONE: Result := {$IFDEF FMXLIB}160{$ELSE}162{$ENDIF};
  end;
end;

function TTMSFNCCustomTouchKeyboard.GetDefaultKeyboardWidth(
  AKeyboardType: TTMSFNCKeyboardType): Integer;
begin
  Result := 0;
  case AKeyboardType of
    ktQWERTY, ktAZERTY, ktDVORAK, ktQWERTZ{$IFDEF MSWINDOWS},ktCustom{$ENDIF}: Result := {$IFDEF FMXLIB}600{$ELSE}602{$ENDIF};
    ktNUMERIC: Result := {$IFDEF FMXLIB}160{$ELSE}162{$ENDIF};
    ktCELLPHONE: Result := {$IFDEF FMXLIB}120{$ELSE}122{$ENDIF};
  end;
end;

{$IFDEF MSWINDOWS}
function TTMSFNCCustomTouchKeyboard.GetKeybdInputHandle: HWND;
begin
  Result := GetFocus;
end;
{$ENDIF}

function TTMSFNCCustomTouchKeyboard.GetVersion: string;
var
  Vn: Integer;
begin
  Vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(Vn))) + '.' + IntToStr(Lo(Hiword(Vn))) + '.' +
    IntToStr(Hi(Loword(Vn))) + '.' + IntToStr(Lo(Loword(Vn)));
end;

function TTMSFNCCustomTouchKeyboard.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER, REL_VER), MakeWord(MIN_VER, MAJ_VER));
end;

procedure TTMSFNCCustomTouchKeyboard.InsertIntoString(var AText: string;
  AInsert: string; var ASelPosStart: Integer; ASelPosEnd: Integer);
begin
  if ASelPosEnd > ASelPosStart then
  begin
    Delete(AText, ASelPosStart + 1, ASelPosEnd - ASelPosStart);
    System.Insert(AInsert, AText, ASelPosStart + 1);
    Inc(ASelPosStart);
  end
  else
  begin
    System.Insert(AInsert, AText, ASelPosStart + 1);
    Inc(ASelPosStart);
  end;
end;

function TTMSFNCCustomTouchKeyboard.IsCapsDown: Boolean;
begin
  Result := FCapsDown;
end;

procedure TTMSFNCCustomTouchKeyboard.ItemKeyDown(Index: Integer);
var
  Key : Word;
  I: Integer;
  {$IFDEF FMXLIB}
  KeyChar: WideChar;
  {$ENDIF}
  text: string;
  selStart, selEnd: Integer;
  {$IFDEF MACOS}
  {$IFNDEF IOS}
  EventSourceRef: CGEventSourceRef;
  KeyDownRef, KeyUpRef: CGEventRef;
  CharKey: Word;
  {$ENDIF}
  {$ENDIF}
begin
  with Keys.Items[Index] do
  begin
    Key := Word(KeyValue);

    if FAutoPostKey then
    begin
      if ShortCut <> '' then
      begin
        if FUseActiveControlText then
        begin
          GetActiveControlText(text, selStart, selEnd);
          for I := 1 to Length(ShortCut) do
          begin
            Inc(selStart);
            System.Insert(Keys.Items[Index].ShortCut[I], text, selStart);
            SetActiveControlText(text, selStart);
          end;
        end
        else
        begin
          for I := 1 to length(ShortCut) do
          begin
            {$IFDEF MSWINDOWS}
            if not FPostWMCharOnly then
              SendMessage(KeybdInputHandle, WM_KEYDOWN, Ord(ShortCut[I]), 0);

            SendMessage(KeybdInputHandle, WM_CHAR, Ord(ShortCut[I]), 0);

            if not FPostWMCharOnly then
              SendMessage(KeybdInputHandle, WM_KEYUP, Ord(ShortCut[I]), 0);
            {$ENDIF}
            {$IFDEF MACOS}
            {$IFNDEF IOS}
            KeyDownRef := nil;
            KeyUpRef := nil;
            EventSourceRef := CGEventSourceCreate(kCGEventSourceStateHIDSystemState);
            if EventSourceRef = nil then RaiseLastOSError;
            try
              CharKey := keyCodeForchar(ShortCut[1]);
              if CharKey > 127 then
                CharKey := keyCodeForChar(LowerCase(ShortCut)[1]);

              {$HINTS OFF}
              {$IF COMPILERVERSION < 34}
              KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, 1); //1 = key down
              {$ELSE}
              KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, True); //1 = key down
              {$IFEND}
              {$HINTS ON}
              if KeyDownRef = nil then
                RaiseLastOSError;

              {$HINTS OFF}
              {$IF COMPILERVERSION < 34}
              KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, 0);  //0 = key up
              {$ELSE}
              KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, False);  //0 = key up
              {$IFEND}
              {$HINTS ON}
              if KeyUpRef = nil then
                RaiseLastOSError;

              CGEventPost(kCGHIDEventTap, KeyDownRef);
              CGEventPost(kCGHIDEventTap, KeyUpRef);
            finally
              if KeyDownRef <> nil then CFRelease(KeyDownRef);
              if KeyUpRef <> nil then CFRelease(KeyUpRef);
              CFRelease(EventSourceRef);
            end;
            {$ENDIF}
            {$ENDIF}

            {$IFDEF FMXLIB}
            {$IFDEF LINUX}
            FmuxGenKeyDown(Ord(ShortCut[I]), #0, FmuxShiftStateToState([]));
            FmuxGenKeyUp(Ord(ShortCut[I]), #0, FmuxShiftStateToState([]));
            {$ENDIF}
            {$ENDIF}
          end;
        end;
      end
      else
      begin
        if not (SpecialKey = skNone)  then
        begin
          if KeyValue = -1 then
            {$IFDEF MSWINWEB}
            case SpecialKey of
              skUp: Key := VK_UP;
              skDown: Key := VK_DOWN;
              skRight: Key := VK_RIGHT;
              skLeft: Key := VK_LEFT;
              skNext: Key := VK_NEXT;
              skPrior: Key := VK_PRIOR;
              skHome: Key := VK_HOME;
              skEnd: Key := VK_END;
              skSpaceBar: Key := VK_SPACE;
              skReturn: Key := VK_RETURN;
              skBackSpace: Key := VK_BACK;
              skF1: Key := VK_F1;
              skF2: Key := VK_F2;
              skF3: Key := VK_F3;
              skF4: Key := VK_F4;
              skF5: Key := VK_F5;
              skF6: Key := VK_F6;
              skF7: Key := VK_F7;
              skF8: Key := VK_F8;
              skF9: Key := VK_F9;
              skF10: Key := VK_F10;
              skF11: Key := VK_F11;
              skF12: Key := VK_F12;
              skDelete: Key := VK_DELETE;
              skEscape: Key := VK_ESCAPE;
              skDivide: Key := VK_DIVIDE;
              skMultiply: Key := VK_MULTIPLY;
              skSubtract: Key := VK_SUBTRACT;
              skAdd: Key := VK_ADD;
              skDecimal: Key := VK_DECIMAL;
              skTab: Key := VK_TAB;
            end;
            {$ENDIF}
            {$IFDEF MACOS}
            case SpecialKey of
              skUp: Key := VKUP;
              skDown: Key := VKDOWN;
              skRight: Key := VKRIGHT;
              skLeft: Key := VKLEFT;
              skNext: Key := VKNEXT;
              skPrior: Key := VKPRIOR;
              skHome: Key := VKHOME;
              skEnd: Key := VKEND;
              skSpaceBar: Key := VKSPACE;
              skReturn: Key := VKRETURN;
              skBackSpace: Key := VKBACK;
              skF1: Key := VKF1;
              skF2: Key := VKF2;
              skF3: Key := VKF3;
              skF4: Key := VKF4;
              skF5: Key := VKF5;
              skF6: Key := VKF6;
              skF7: Key := VKF7;
              skF8: Key := VKF8;
              skF9: Key := VKF9;
              skF10: Key := VKF10;
              skF11: Key := VKF11;
              skF12: Key := VKF12;
              skDelete: Key := VKDELETE;
              skEscape: Key := VKESCAPE;
              skWin: Key := vkLCommand;
              skDivide: Key := VKDIVIDE;
              skMultiply: Key := VKMULTIPLY;
              skSubtract: Key := VKSUBTRACT;
              skAdd: Key := VKADD;
              skDecimal: Key := VKDECIMAL;
              skTab: Key := VKTAB;
            end;
            {$ENDIF}
            {$IFDEF LINUX}
            case SpecialKey of
              skUp: Key := vkUp;
              skDown: Key := vkDown;
              skRight: Key := vkRight;
              skLeft: Key := vkLeft;
              skNext: Key := vkNext;
              skPrior: Key := vkPrior;
              skHome: Key := vkHome;
              skEnd: Key := vkEnd;
              skSpaceBar: Key := vkSpace;
              skReturn: Key := vkReturn;
              skBackSpace: Key := vkBack;
              skF1: Key := vkF1;
              skF2: Key := vkF2;
              skF3: Key := vkF3;
              skF4: Key := vkF4;
              skF5: Key := vkF5;
              skF6: Key := vkF6;
              skF7: Key := vkF7;
              skF8: Key := vkF8;
              skF9: Key := vkF9;
              skF10: Key := vkF10;
              skF11: Key := vkF11;
              skF12: Key := vkF12;
              skDelete: Key := vkDelete;
              skEscape: Key := vkEscape;
              skDivide: Key := vkDivide;
              skMultiply: Key := vkMultiply;
              skSubtract: Key := vkSubtract;
              skAdd: Key := vkAdd;
              skDecimal: Key := vkDecimal;
              skTab: Key := vkTab;
            end;
            {$ENDIF}
            PostSpecialKeys(Key, Shift, False);
        end
        else
          PostNormalKeys(Index);
      end;
    end;

    if (SpecialKey in [skShift, skAlt, skCtrl, skAltGr]) then
      SyncEqualKeys(Index)
    else
    begin
      TurnOffShifts;
    end;

    if Assigned(FOnKeyDown) then
      FOnKeyDown(Self.Keys.Items[Index], Key, {$IFDEF FMXLIB}KeyChar,{$ENDIF} Shift);

    if (SpecialKey = skApp) then
      Keys.Items[Index].FTouchKey.FKeyNormalPosition := True;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.ItemKeyUp(Index: Integer);
begin
  if Assigned(FOnKeyClick) then
    FOnKeyClick(Self, Index);
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomTouchKeyboard.LoadKeybdLayout(AFileName: string; AAutoSize: TTMSFNCAutoSizeLayout = aslKeyboard);
var
  IniFile      : TIniFile;
  I, TotalKeys : Integer;

begin
  IniFile := TIniFile.Create(AFileName);

  with Keys do
  begin
    TotalKeys := IniFile.ReadInteger('Keys', 'Count', 0);
    Clear;

    try
      for I := 0 to TotalKeys - 1 do
      begin
        Add;
        Items[I].Caption        := IniFile.ReadString  ('Key' + IntToStr(I), 'Caption', '');
        Items[I].ShiftCaption   := IniFile.ReadString  ('Key' + IntToStr(I), 'ShiftCaption', '');
        Items[I].AltGrCaption   := IniFile.ReadString  ('Key' + IntToStr(I), 'AltGrCaption', '');
        Items[I].KeyValue       := IniFile.ReadInteger ('Key' + IntToStr(I), 'KeyValue', -1);
        Items[I].ShiftKeyValue  := IniFile.ReadInteger ('Key' + IntToStr(I), 'ShiftKeyValue', -1);
        Items[I].AltGrKeyValue  := IniFile.ReadInteger ('Key' + IntToStr(I), 'AltGrKeyValue', -1);

        if Assigned((GetOwner as TTMSFNCTouchKeyboard).BitmapContainer) then
          Items[I].ImageName := IniFile.ReadString('Key' + IntToStr(I), 'ImageName', '')
        else
          Items[I].ImageName   := '';

        Items[I].SpecialKey     := TTMSFNCSpecialKey(IniFile.ReadInteger ('Key' + IntToStr(I), 'SpecialKey', 0));

        Items[I].Color          := StringToColor(IniFile.ReadString  ('Key' + IntToStr(I), 'Color', ''));
        Items[I].ColorDown      := StringToColor(IniFile.ReadString  ('Key' + IntToStr(I), 'ColorDown', ''));
        Items[I].BorderColor    := StringToColor(IniFile.ReadString  ('Key' + IntToStr(I), 'BorderColor', ''));
        Items[I].BorderColorDown:= StringToColor(IniFile.ReadString  ('Key' + IntToStr(I), 'BorderColorDown', ''));
        Items[I].TextColor      := StringToColor(IniFile.ReadString  ('Key' + IntToStr(I), 'TextColor', ''));
        Items[I].TextColorDown  := StringToColor(IniFile.ReadString  ('Key' + IntToStr(I), 'TextColorDown', ''));

        Items[I].Width          := IniFile.ReadInteger ('Key' + IntToStr(I), 'Width', 40);
        Items[I].Height         := IniFile.ReadInteger ('Key' + IntToStr(I), 'Height', 40);
        Items[I].X              := IniFile.ReadInteger ('Key' + IntToStr(I), 'X', 0);
        Items[I].Y              := IniFile.ReadInteger ('Key' + IntToStr(I), 'Y', 0);
        Items[I].AutoPost       := IniFile.ReadInteger ('Key' + IntToStr(I), 'AutoPost', 1) = 1;
      end;

      if AAutoSize = aslKeyboard then
      begin
        FOriginalW := inifile.ReadFloat('KeyBoard', 'Width', Width);
        FOriginalH := inifile.ReadFloat('KeyBoard', 'Height', Height);
        AutoZoom;
      end
      else
      begin
        FOriginalW := Width;
        FOriginalH := Height;
        {$IFDEF VCLLIB}
        Width := inifile.ReadInteger('KeyBoard', 'Width', Width);
        Height := inifile.ReadInteger('KeyBoard', 'Height', Height);
        {$ENDIF}
        {$IFDEF FMXLIB}
        Width := inifile.ReadFloat('KeyBoard', 'Width', Width);
        Height := inifile.ReadFloat('KeyBoard', 'Height', Height);
        {$ENDIF}
        AutoZoom;
      end;
    finally
      IniFile.Free;
    end;
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomTouchKeyboard.NewRow(var X, Y: Single; Size: Integer);
begin
  X := {$IFDEF FMXLIB}0{$ELSE}1{$ENDIF};
  Y := Y + Size;
end;

procedure TTMSFNCCustomTouchKeyboard.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;

  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomTouchKeyboard.PaintAllKeys;
var
  I: Integer;
begin
  for I := 0 to Keys.Count - 1 do
    Keys[I].FTouchKey.Invalidate;
end;

procedure TTMSFNCCustomTouchKeyboard.PictureChanged(Sender: TObject);
begin
  Invalidate;
end;

procedure TTMSFNCCustomTouchKeyboard.PostKey(AKey: Word; const AShift: TShiftState; AExtendedKeyBD: Boolean; AIndex: Integer);
begin
  if not (Self.Keys.Items[AIndex].SpecialKey = skNone)  then
    PostSpecialKeys(AKey, AShift, AExtendedKeyBD)
  else
    PostNormalKeys(AIndex);
end;

procedure TTMSFNCCustomTouchKeyboard.PostNormalKeys(Index: Integer);
type
  TKeyVal = (kvNormal, kvShifted, kvAtlGr, kvCapital {$IFDEF MACOS}, kvCmd {$ENDIF});
var
  KeyCaption: string;
  text: string;
  selStart, selEnd: Integer;
  KeyVal: TKeyVal;
  KVal, I : Integer;
  {$IFDEF MSWINDOWS}
  isCombo: Boolean;
  {$ENDIF}
  isCaps: Boolean;
  {$IFDEF MACOS}
  {$IFNDEF IOS}
  EventSourceRef: CGEventSourceRef;
  KeyDownRef, KeyUpRef: CGEventRef;
  CharKey: Word;
  flags: Uint64;
  {$ENDIF}
  {$ENDIF}
begin
  KeyCaption := '';
  KVal := -1;
  isCaps := False;
  {$IFDEF MACOS}
  {$IFNDEF IOS}
  flags :=  0;
  {$ENDIF}
  {$ENDIF}

  for I := 0 to Keys.Count - 1 do
  begin
    if (Keys[I].SpecialKey = skCaps) then
    begin
      if Keys[I].FTouchKey.FDownState > 0 then
        isCaps := True;
    end;
  end;

  with Keys.Items[Index] do
  begin
    if ((ssShift in Shift) or (isCaps)) and not (SpecialKey = skShift) then
    begin
      KeyVal := kvShifted;
    end
    else if IsCapsDown then
      KeyVal := kvCapital
    else if ((ssCtrl in Shift) and (ssAlt in Shift)) and not (SpecialKey = skAltGr) then
      KeyVal := kvAtlGr
    {$IFDEF MACOS}
    else if SpecialKey = skWin then
      KeyVal := kvCmd
    {$ENDIF}
    else
      KeyVal := kvNormal;

    case KeyVal of
      kvNormal  :
      begin
        {$IFNDEF WEBLIB}
        KeyCaption := SysUtils.AnsiLowerCase(Caption);
        {$ENDIF}
        {$IFDEF WEBLIB}
        KeyCaption := LowerCase(Caption);
        {$ENDIF}
        KVal := KeyValue;
      end;
      kvShifted :
      begin
        if ShiftCaption = '' then
          KeyCaption := Caption
        else
          KeyCaption := ShiftCaption;

        KVal := ShiftKeyValue;
      end;

      kvAtlGr   : KeyCaption := AltGrCaption;
      kvCapital : KeyCaption := Caption
    end;

    {$IFDEF MACOS}
    {$IFNDEF IOS}
    if keycodeforchar(KeyCaption[1]) > 127 then
    begin
      KeyCaption := Caption;
      KVal := KeyValue;
    end;
    {$ENDIF}
    {$ENDIF}
  end;

  if (KVal <> -1) and (Keys.Items[Index].AutoPost) then
  begin
    if FUseActiveControlText then
    begin
      GetActiveControlText(text, selStart, selEnd);
      InsertIntoString(text, Chr(kVal), selStart, selEnd);
      SetActiveControlText(text, selStart);
    end
    else
    begin
      {$IFDEF MSWINDOWS}
      isCombo := IsComboBox(KeybdInputHandle);

      if not FPostWMCharOnly and not isCombo then
        SendMessage(KeybdInputHandle, WM_KEYDOWN, KVal, 0);

      SendMessage(KeybdInputHandle, WM_CHAR, KVal, 0);

      if not FPostWMCharOnly and not isCombo then
        SendMessage(KeybdInputHandle, WM_KEYUP, KVal, 0);
      {$ENDIF}
      {$IFDEF MACOS}
      {$IFNDEF IOS}
      KeyDownRef := nil;
      KeyUpRef := nil;
      EventSourceRef := CGEventSourceCreate(kCGEventSourceStateHIDSystemState);
      if EventSourceRef = nil then
        RaiseLastOSError;
      try
        CharKey := keyCodeForchar(KeyCaption[1]);
        if CharKey > 127 then
          CharKey := keyCodeForChar(LowerCase(KeyCaption)[1]);

        {$HINTS OFF}
        {$IF COMPILERVERSION < 34}
        KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, 1); //1 = key down
        {$ELSE}
        KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, True); //1 = key down
        {$IFEND}
        {$HINTS ON}
        if KeyDownRef = nil then
          RaiseLastOSError;

        if (KeyVal = kvCapital) or (KeyVal = kvShifted) then
          flags := flags or kCGEventFlagMaskShift;

        if (KeyVal = kvAtlGr) then
          flags := flags or kCGEventFlagMaskAlternate;

        if (KeyVal = kvCmd) then
          flags := flags or kCGEventFlagMaskCommand;

        {$HINTS OFF}
        {$IF COMPILERVERSION < 34}
        KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, 0);  //0 = key up
        {$ELSE}
        KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, False);  //0 = key up
        {$IFEND}
        {$HINTS ON}
        if KeyUpRef = nil then
          RaiseLastOSError;

        flags := CGEventGetFlags(KeyDownRef) or flags or FCommandFlag;
        CGEventSetFlags(KeyDownRef, flags);

        CGEventPost(kCGHIDEventTap, KeyDownRef);
        CGEventPost(kCGHIDEventTap, KeyUpRef);
      finally
        if KeyDownRef <> nil then
          CFRelease(KeyDownRef);
        if KeyUpRef <> nil then
          CFRelease(KeyUpRef);
        CFRelease(EventSourceRef);
      end;
      {$ENDIF}
      {$ENDIF}
      {$IFDEF FMXLIB}
      {$IFDEF LINUX}
      FmuxGenKeyDown(KVal, #0, FmuxShiftStateToState(Shift));
      FmuxGenKeyUp(KVal, #0, FmuxShiftStateToState(Shift));
      {$ENDIF}
      {$ENDIF}
    end;
  end
  else if (KeyCaption <> '') and (Keys.Items[Index].AutoPost) then
  begin
    if FUseActiveControlText then
    begin
      GetActiveControlText(text, selStart, selEnd);
      InsertIntoString(text, KeyCaption[1], selStart, selEnd);
      SetActiveControlText(text, selStart);
    end
    else
    begin
      {$IFDEF MSWINDOWS}
      isCombo := IsComboBox(KeybdInputHandle);

      SendMessage(KeybdInputHandle, WM_CHAR, Ord(KeyCaption[1]), 0);

      if not FPostWMCharOnly and not isCombo then
      begin
        if (Upcase(KeyCaption[1]) >= '0') and
           (Upcase(KeyCaption[1]) <= 'Z') then
        SendMessage(KeybdInputHandle, WM_KEYUP, Ord(Upcase(KeyCaption[1])), 0);
      end;
      {$ENDIF}
      {$IFDEF MACOS}
      {$IFNDEF IOS}
      KeyDownRef := nil;
      KeyUpRef := nil;
      EventSourceRef := CGEventSourceCreate(kCGEventSourceStateHIDSystemState);
      if EventSourceRef = nil then
        RaiseLastOSError;
      try
        CharKey := keyCodeForchar(KeyCaption[1]);
        if CharKey > 127 then
          CharKey := keyCodeForChar(LowerCase(KeyCaption)[1]);

        {$HINTS OFF}
        {$IF COMPILERVERSION < 34}
        KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, 1); //1 = key down
        {$ELSE}
        KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, True); //1 = key down
        {$IFEND}
        {$HINTS ON}
        if KeyDownRef = nil then
          RaiseLastOSError;

          if (KeyVal = kvCapital) or (KeyVal = kvShifted) then
            flags := flags or kCGEventFlagMaskShift;

          if (KeyVal = kvAtlGr) then
            flags := flags or kCGEventFlagMaskAlternate;

          if (KeyVal = kvCmd) then
            flags := flags or kCGEventFlagMaskCommand;

        {$HINTS OFF}
        {$IF COMPILERVERSION < 34}
        KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, 0);  //0 = key up
        {$ELSE}
        KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, CharKey, False);  //0 = key up
        {$IFEND}
        {$HINTS ON}
        if KeyUpRef = nil then
          RaiseLastOSError;

        flags := CGEventGetFlags(KeyDownRef) or flags or FCommandFlag;
        CGEventSetFlags(KeyDownRef, flags);
        CGEventPost(kCGHIDEventTap, KeyDownRef);
        CGEventPost(kCGHIDEventTap, KeyUpRef);
      finally
        if KeyDownRef <> nil then
          CFRelease(KeyDownRef);
        if KeyUpRef <> nil then
          CFRelease(KeyUpRef);
        CFRelease(EventSourceRef);
      end;
      {$ENDIF}
      {$ENDIF}
      {$IFDEF FMXLIB}
      {$IFDEF LINUX}
      FmuxGenKeyDown(0, KeyCaption[1], FmuxShiftStateToState([]));
      FmuxGenKeyUp(0, KeyCaption[1], FmuxShiftStateToState(Shift));
      {$ENDIF}
      {$ENDIF}
    end;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.PostSpecialKeys(Key: Word; const pShift: TShiftState; SpecialKey: Boolean);
{$IFDEF MSWINDOWS}
type
  TShiftKeyInfo = record
    Shift: Byte ;
    VKey: Byte ;
  end;
  ByteSet = set of 0..7;

const
  ShiftKeys: array [1..3] of TShiftKeyInfo =
    ((Shift: Ord(ssCtrl ) ; VKey : VK_CONTROL),
    ( Shift: Ord(ssShift) ; VKey : VK_SHIFT  ),
    ( Shift: Ord(ssAlt  ) ; VKey : VK_MENU   )) ;
{$ENDIF}
var
  text: string;
  selStart, selEnd: Integer;
  {$IFDEF MSWINDOWS}
  Flag: DWORD;
  bShift: ByteSet absolute pShift;
  I, Res: Integer;
  {$ENDIF}
  {$IFDEF MACOS}
  {$IFNDEF IOS}
  EventSourceRef: CGEventSourceRef;
  KeyDownRef, KeyUpRef: CGEventRef;
  {$ENDIF}
  {$ENDIF}
begin
  if FUseActiveControlText then
  begin
    GetActiveControlText(text, selStart, selEnd);
    case Key of
//      VK_UP:
//      VK_DOWN:
//      VK_RIGHT:
//      VK_LEFT:
//      VK_NEXT:
//      VK_PRIOR:
//      VK_HOME:
//      VK_END:
      {$IFDEF MSWINWEB}VK_DECIMAL{$ELSE}vkDecimal{$ENDIF}: InsertIntoString(text, '.', selStart, selEnd);
      {$IFDEF MSWINWEB}VK_ADD{$ELSE}vkAdd{$ENDIF}: InsertIntoString(text, '+', selStart, selEnd);
      {$IFDEF MSWINWEB}VK_SUBTRACT{$ELSE}vkSubtract{$ENDIF}: InsertIntoString(text, '-', selStart, selEnd);
      {$IFDEF MSWINWEB}VK_MULTIPLY{$ELSE}vkMultiply{$ENDIF}: InsertIntoString(text, '*', selStart, selEnd);
      {$IFDEF MSWINWEB}VK_DIVIDE{$ELSE}vkDivide{$ENDIF}: InsertIntoString(text, '/', selStart, selEnd);
      {$IFDEF MSWINWEB}VK_TAB{$ELSE}vkTab{$ENDIF}: InsertIntoString(text, #9, selStart, selEnd);
      {$IFDEF MSWINWEB}VK_SPACE{$ELSE}vkSpace{$ENDIF}: InsertIntoString(text, ' ', selStart, selEnd);
      {$IFDEF MSWINWEB}VK_RETURN{$ELSE}vkReturn{$ENDIF}: InsertIntoString(text, #13#10, selStart, selEnd);
      {$IFDEF MSWINWEB}VK_BACK{$ELSE}vkBack{$ENDIF}:
      begin
        if Length(text) > 0 then
        begin
          if selEnd > selStart then
            Delete(text, selStart + 1, selEnd - selStart)
          else
          begin
            Delete(text, selStart, 1);
            Dec(selStart);
          end;
        end;
      end;
      {$IFDEF MSWINWEB}VK_DELETE{$ELSE}vkDelete{$ENDIF}:
      begin
        if Length(text) > 0 then
        begin
          if selEnd > selStart then
            Delete(text, selStart + 1, selEnd - selStart)
          else
            Delete(text, selStart + 1, 1);
        end;
      end;
    end;
    SetActiveControlText(text, selStart);
  end
  else
  begin
    {$IFDEF MSWINDOWS}
     if Key = VK_CAPITAL then
       FCapsDown := not FCapsDown;

     if Key = VK_SHIFT then
       FCapsDown := False;

     // pressing shift keys
     for I := 1 to 3 do
     begin
       if ShiftKeys[I].Shift in bShift then
         keybd_event(ShiftKeys[I].VKey, MapVirtualKey(ShiftKeys[I].VKey, 0), 0, 0) ;
     end;

     // apply KEYEVENTF_EXTENDEDKEY if specialkey selected
     if SpecialKey then
       Flag := KEYEVENTF_EXTENDEDKEY
     else
       Flag := 0;

     if (Key <> VK_TAB) Then
     begin
       if (Key in [VK_LEFT,VK_RIGHT,VK_UP,VK_DOWN]) then
       begin
         SendMessage(KeybdInputHandle, WM_KEYDOWN, Key, 0);
         SendMessage(KeybdInputHandle, WM_KEYUP, Key, 0);
       end
       else
       begin
         // pressing the actual key
         keybd_event(Key, MapvirtualKey(Key, 0), Flag, 0) ;
         Flag := Flag or KEYEVENTF_KEYUP;
         // releasing the actual key
         keybd_event(Key, MapvirtualKey(Key, 0), Flag, 0) ;
       end;
     end
     else
     begin
       Res := SendMessage(KeybdInputHandle, WM_GETDLGCODE, VK_TAB, 0);

       if Res and DLGC_WANTTAB = DLGC_WANTTAB then
       begin
         SendMessage(KeybdInputHandle, WM_KEYDOWN, VK_TAB, 0);
         SendMessage(KeybdInputHandle, WM_CHAR, VK_TAB, 0);
         SendMessage(KeybdInputHandle, WM_KEYUP, VK_TAB, 0);
       end
       else
       begin
         PostMessage(KeybdInputHandle, WM_KEYDOWN, VK_TAB, 0);
       end;
     end;

     // Releasing shift keys
     for I := 3 downto 1 do
     begin
       if ShiftKeys[I].Shift in bShift then
         keybd_event(ShiftKeys[I].VKey, MapVirtualKey(ShiftKeys[I].VKey, 0), KEYEVENTF_KEYUP, 0) ;
     end;
     {$ENDIF}
     {$IFDEF MACOS}
     {$IFNDEF IOS}
     if Key = KEY_CAPITAL then
     begin
       FCapsDown := not FCapsDown;
     end;

     if Key = vkShift then
       FCapsDown := False;

    if (Key = vkLCommand) then
    begin
      FCommandFlag := FCommandFlag xor kCGEventFlagMaskCommand;
      Exit;
    end;

    if (Key <> VKTAB) Then
    begin
      if (Key in [VKLEFT,VKRIGHT,VKUP,VKDOWN]) then
      begin
        //
      end
      else
      begin
        // pressing the actual key
        KeyDownRef := nil;
        KeyUpRef := nil;
        EventSourceRef := CGEventSourceCreate(kCGEventSourceStateHIDSystemState);
        if EventSourceRef = nil then
          RaiseLastOSError;

        try
          {$HINTS OFF}
          {$IF COMPILERVERSION < 34}
          KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, Key, 1); //1 = key down
          {$ELSE}
          KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, Key, True); //1 = key down
          {$IFEND}
          {$HINTS ON}
          if KeyDownRef = nil then
            RaiseLastOSError;

          if FCapsDown then
            CGEventSetFlags(KeyDownRef, kCGEventFlagMaskAlphaShift)
          else
            CGEventSetFlags(KeyDownRef, kCGEventFlagMaskNonCoalesced);

          if not FCapsDown then
          begin
            {$HINTS OFF}
            {$IF COMPILERVERSION < 34}
            KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, Key, 0);  //0 = key up
            {$ELSE}
            KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, Key, False);  //0 = key up
            {$IFEND}
            {$HINTS ON}
            if KeyUpRef = nil then
              RaiseLastOSError;
          end;
          CGEventPost(kCGHIDEventTap, KeyDownRef);

          if not FCapsDown then
            CGEventPost(kCGHIDEventTap, KeyUpRef);
        finally
          if KeyDownRef <> nil then
            CFRelease(KeyDownRef);

          if KeyUpRef <> nil then
            CFRelease(KeyUpRef);

          CFRelease(EventSourceRef);
        end;
      end;
    end
    else
    begin
     KeyDownRef := nil;
     KeyUpRef := nil;
     EventSourceRef := CGEventSourceCreate(kCGEventSourceStateHIDSystemState);
     if EventSourceRef = nil then
       RaiseLastOSError;
     try
       {$HINTS OFF}
       {$IF COMPILERVERSION < 34}
       KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, KEY_TAB, 1); //1 = key down
       {$ELSE}
       KeyDownRef := CGEventCreateKeyboardEvent(EventSourceRef, KEY_TAB, True); //1 = key down
       {$IFEND}
       {$HINTS ON}
       if KeyDownRef = nil then
         RaiseLastOSError;

       {$HINTS OFF}
       {$IF COMPILERVERSION < 34}
       KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, KEY_TAB, 0);  //0 = key up
       {$ELSE}
       KeyUpRef := CGEventCreateKeyboardEvent(EventSourceRef, KEY_TAB, False);  //0 = key up
       {$IFEND}
       {$HINTS ON}
       if KeyUpRef = nil then
         RaiseLastOSError;
       CGEventPost(kCGHIDEventTap, KeyDownRef);
       CGEventPost(kCGHIDEventTap, KeyUpRef);
     finally
       if KeyDownRef <> nil then
         CFRelease(KeyDownRef);
       if KeyUpRef <> nil then
         CFRelease(KeyUpRef);
       CFRelease(EventSourceRef);
     end;
    end;

    {$ENDIF}
    {$ENDIF}
    {$IFDEF FMXLIB}
    {$IFDEF LINUX}
    if Key = vkCapital then
      FCapsDown := not FCapsDown;

    if Key = vkShift then
      FCapsDown := False;

    FmuxGenKeyDown(Key, #0, FmuxShiftStateToState([]));
    FmuxGenKeyUp(Key, #0, FmuxShiftStateToState([]));
    {$ENDIF}
    {$ENDIF}
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.RepeatTimerProc(Sender: TObject);
begin
  {$IFDEF MSWINDOWS}
  Inc(FRepeatTimerCount);
  if (FRepeatTimerCount > 2) then
  begin
    if FRepeatHandle = GetFocus then
      ItemKeyDown(FRepeatItemIndex)
    else
      StopTimer;
  end;
  {$ENDIF}
  {$IFNDEF MSWINDOWS}
  StopTimer;
  {$ENDIF}
end;

procedure TTMSFNCCustomTouchKeyboard.ResetToDefaultStyle;
var
  I: Integer;
begin
  inherited;

  Fill.Color := {$IFDEF FMXLIB}gcAlpha or{$ENDIF}$ECECEC;
  Stroke.Color := gcLightGray;
  FFont.Color := gcBlack;
  FSmallFont.Color := gcBlack;

  for I := 0 to Keys.Count - 1 do
  begin
    Keys[I].FTouchKey.Fill.Color := {$IFDEF FMXLIB}gcAlpha or{$ENDIF}$ECECEC;
    Keys[I].FTouchKey.Stroke.Color := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$DADADA;
    Keys[I].BorderColor := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$DADADA;
    Keys[I].BorderColorDown := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$B5B5B5;
    Keys[I].Color := gcWhite;
    Keys[I].ColorDown := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$F0F0F0;
    Keys[I].FTouchKey.FFont.Color := gcBlack;
    Keys[I].FTouchKey.FSmallFont.Color := gcBlack;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomTouchKeyboard.SaveKeybdLayout(AFileName: string);
var
  IniFile : TIniFile;
  I: Integer;
begin
  IniFile := TIniFile.Create(AFileName);

  With Keys do
  begin
    IniFile.WriteInteger('Keys','Count', Count);
    try
      for I := 0 to Count - 1 do
      begin
        IniFile.WriteString('Key' + IntToStr(I), 'Caption', Items[I].Caption);
        IniFile.WriteString('Key' + IntToStr(I), 'ShiftCaption', Items[I].ShiftCaption);
        IniFile.WriteString('Key' + IntToStr(I), 'AltGrCaption', Items[I].AltGrCaption);

        IniFile.WriteInteger('Key' + IntToStr(I), 'KeyValue', Items[I].KeyValue);
        IniFile.WriteInteger('Key' + IntToStr(I), 'ShiftKeyValue', Items[I].ShiftKeyValue);
        IniFile.WriteInteger('Key' + IntToStr(I), 'AltGrKeyValue', Items[I].AltGrKeyValue);

        IniFile.WriteString('Key' + IntToStr(I), 'ImageName', Items[I].ImageName);
        IniFile.WriteInteger('Key' + IntToStr(I), 'SpecialKey', Integer(Items[I].SpecialKey));
        IniFile.WriteInteger('Key' + IntToStr(I), 'Color', Items[I].Color);
        IniFile.WriteInteger('Key' + IntToStr(I), 'ColorDown', Items[I].ColorDown);
        IniFile.WriteInteger('Key' + IntToStr(I), 'BorderColor', Items[I].BorderColor);
        IniFile.WriteInteger('Key' + IntToStr(I), 'BorderColorDown', Items[I].BorderColorDown);
        IniFile.WriteInteger('Key' + IntToStr(I), 'TextColor', Items[I].TextColor);
        IniFile.WriteInteger('Key' + IntToStr(I), 'TextColorDown', Items[I].TextColorDown);
        IniFile.WriteFloat('Key' + IntToStr(I), 'Width', Items[I].Width);
        IniFile.WriteFloat('Key' + IntToStr(I), 'Height', Items[I].Height);
        IniFile.WriteFloat('Key' + IntToStr(I), 'X', Items[I].X);
        IniFile.WriteFloat('Key' + IntToStr(I), 'Y', Items[I].Y);

        if Items[I].AutoPost then
          IniFile.WriteInteger('Key' + IntToStr(I), 'AutoPost', 1)
        else
          IniFile.WriteInteger('Key' + IntToStr(I), 'AutoPost', 0);
      end;

      {$IFDEF VCLLIB}
      Inifile.WriteInteger('KeyBoard', 'Width', Width);
      Inifile.WriteInteger('KeyBoard', 'Height', Height);
      {$ENDIF}
      {$IFDEF FMXLIB}
      Inifile.WriteFloat('KeyBoard', 'Width', Width);
      Inifile.WriteFloat('KeyBoard', 'Height', Height);
      {$ENDIF}
    finally
      IniFile.Free;
    end;
  end;
end;
{$ENDIF}

procedure TTMSFNCCustomTouchKeyboard.SetActiveControlText(AText: string;
  ACaretPos: Integer);
{$IFNDEF WEBLIB}
var
  Ctx: TRttiContext;
  Prop: TRttiProperty;
{$ENDIF}
begin
  {$IFDEF WEBLIB}
  if (UpperCase(document.activeElement.tagName) = 'TEXTAREA') or (UpperCase(document.activeElement.tagName) = 'INPUT') then
  begin
    TJSHTMLInputElement(document.activeElement).value := AText;
    TJSHTMLInputElement(document.activeElement).selectionStart := ACaretPos;
    TJSHTMLInputElement(document.activeElement).selectionEnd := ACaretPos;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFDEF FMXLIB}
  if Assigned(Screen.FocusControl) then
  begin
    Prop := Ctx.GetType(Screen.FocusControl.GetObject.ClassType).GetProperty('Text');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      Prop.SetValue(Screen.FocusControl.GetObject, AText);

    Prop := Ctx.GetType(Screen.FocusControl.GetObject.ClassType).GetProperty('SelStart');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      Prop.SetValue(Screen.FocusControl.GetObject, ACaretPos);
  end;
  {$ENDIF}
  {$IFDEF VCLLIB}
  if Assigned(Screen.ActiveControl) then
  begin
    Prop := Ctx.GetType(Screen.ActiveControl.ClassType).GetProperty('Text');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      Prop.SetValue(Screen.ActiveControl, AText);

    Prop := Ctx.GetType(Screen.ActiveControl.ClassType).GetProperty('SelStart');
    if (Prop <> nil) and (Prop.Visibility in [mvPublic, mvPublished]) then
      Prop.SetValue(Screen.ActiveControl, ACaretPos);
  end;
  {$ENDIF}
  {$ENDIF}
end;

procedure TTMSFNCCustomTouchKeyboard.SetAllowAutoZoom(const Value: Boolean);
begin
  if FAllowAutoZoom <> Value then
  begin
    FAllowAutoZoom := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.SetAutoCapsDisplay(const Value: Boolean);
begin
  FAutoCapsDisplay := Value;
  Invalidate;
end;

procedure TTMSFNCCustomTouchKeyboard.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomTouchKeyboard.SetBorderRounding(const Value: integer);
begin
  if FBorderRounding <> Value then
  begin
    FBorderRounding := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.SetFnt(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
  Invalidate;
end;

procedure TTMSFNCCustomTouchKeyboard.SetHighlightAltGr(const Value: TTMSFNCGraphicsColor);
begin
  if FHighlightAltGr <> Value then
  begin
    FHighlightAltGr := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.SetHighlightCaps(const Value: TTMSFNCGraphicsColor);
begin
  if FHighlightCaps <> Value then
  begin
    FHighlightCaps := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.SetKeyboardType(const Value: TTMSFNCKeyboardType);
begin
  FKeyboardType := Value;
  Keys.Clear;
  Shift := [];

  case Value of
    ktQWERTY    : BuildQWERTYKeyBoard;
    ktQWERTZ    : BuildQWERTZKeyBoard;
    ktAZERTY    : BuildAZERTYKeyBoard;
    ktDVORAK    : BuildDVORAKKeyBoard;
    ktNUMERIC   : BuildNumericKeyBoard;
    ktCELLPHONE : BuildCellPhoneKeyBoard;
    {$IFDEF MSWINDOWS}
    ktCustom    : ;
    {$ENDIF}
  end;

  if FDoAutoZoom then
    AutoZoom;
end;

procedure TTMSFNCCustomTouchKeyboard.SetKeys(const Value: TTMSFNCTouchKeyCollection);
begin
  FKeys.Assign(Value);
end;

procedure TTMSFNCCustomTouchKeyboard.SetKeySpacing(const Value: Integer);
begin
  if FKeySpacing <> Value then
  begin
    FKeySpacing := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.SetPictureDownState(const Value: TTMSFNCBitmap);
begin
  FPictureDownState.Assign(Value);
end;

procedure TTMSFNCCustomTouchKeyboard.SetPictureNormalState(const Value: TTMSFNCBitmap);
begin
  FPictureNormalState.Assign(Value);
end;

procedure TTMSFNCCustomTouchKeyboard.SetSmallFont(const Value: TTMSFNCGraphicsFont);
begin
  FSmallFont.Assign(Value);
end;

procedure TTMSFNCCustomTouchKeyboard.StartTimer(AIndex: Integer);
begin
  {$IFDEF MSWINDOWS}
  FRepeatItemIndex := AIndex;
  FRepeatTimerCount := 0;
  FRepeatHandle := GetFocus;
  {$ENDIF}
  FRepeatTimer.Enabled := True;
end;

procedure TTMSFNCCustomTouchKeyboard.StopTimer;
begin
  FRepeatTimer.Enabled := False;
  {$IFDEF MSWINDOWS}
  FRepeatTimerCount := 0;
  {$ENDIF}
end;

procedure TTMSFNCCustomTouchKeyboard.SyncEqualKeys(Index: Integer);
var
  I: Integer;
  Ds: Integer;
  Kn: Boolean;
  Sk: TTMSFNCSpecialKey;

begin
  with Keys.Items[Index] do
  begin
    Ds := FTouchKey.FDownState;
    Kn := FTouchKey.FKeyNormalPosition;
    Sk := SpecialKey;

    for I := 0 to Keys.Count - 1 do
    begin
      if (Keys[I].SpecialKey = Sk) then
      begin
        Keys[I].FTouchKey.FDownState := Ds;
        Keys[I].FTouchKey.FKeyNormalPosition := Kn;
        Keys[I].FTouchKey.Invalidate;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.TurnOffShifts;
var
  I: Integer;
  Invalid: Boolean;
begin
  for I := 0 to Keys.Count - 1 do
  begin
    if (Keys[I].SpecialKey = skCaps) then
    begin
      if Keys[I].FTouchKey.FDownState > 0 then
        Exit;
    end;
  end;

  for I := 0 to Keys.Count - 1 do
  begin
    if (Keys[I].SpecialKey = skShift) then
    begin
      Keys[I].FTouchKey.FDownState := 0;
      Keys[I].FTouchKey.Invalidate;
      Keys[I].FTouchKey.FKeyNormalPosition := True;
    end;
  end;

  Invalid := ssShift in Shift;
  Shift  := Shift - [ssShift];

  if Invalid then
    Invalidate;
end;

procedure TTMSFNCCustomTouchKeyboard.UpdateControlAfterResize;
begin
  inherited;
  if not FChangingScale then
  begin
    if FDoAutoZoom then
      AutoZoom;
  end;
end;

procedure TTMSFNCCustomTouchKeyboard.Zoom(AHorz, AVert: Double; AKeysOnly, AAbsoluteZoom: Boolean);
var
  I: Integer;
begin
  FDoAutoZoom := False;
  if (FOldH < 0) Then
  begin
    FOldW := Width;
    FOldH := Height;
  end;

  for I := 0 to Keys.Count - 1 do
  begin
    with Keys[I] do
    begin
      if (OldW < 0) Then
      begin
        OldW := width;
        OldH := height;
        OldX := X;
        OldY := Y;
      end;

      if AAbsoluteZoom Then
      begin
        Width := Round(Width * AHorz);
        Height := Round(Height * AVert);
        X := Round(X * AHorz);
        Y := Round(Y * AVert);
      end
      else
      begin
         Width := Round(OldW * AHorz);
         Height := Round(OldH * AVert);
         X := Round(OldX * AHorz);
         Y := Round(OldY * AVert);
      end;
    end;
  end;

  if not AKeysOnly then
  begin
    iF AAbsoluteZoom then
    begin
      Width := Round(Width * AHorz) + FKeySpacing;
      Height := Round(Height * AVert) + FKeySpacing;
    end
    else
    begin
      Width := Round(FOldW * AHorz) + FKeySpacing;
      Height := Round(FOldH * AVert) + FKeySpacing;
    end;
  end;
  FDoAutoZoom := True;
end;

{ TTMSFNCTouchKey }

constructor TTMSFNCTouchKey.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
  AllowFocus := False;
  FAutoPost := True;
  FBmp := TTMSFNCBitmap.Create;
  FPictureNormalState := TTMSFNCBitmap.Create;
  FPictureDownState := TTMSFNCBitmap.Create;

  if not IsBitmapEmpty(FPictureDownState) then
    FCurrentBitmap := FPictureNormalState
  else
    FCurrentBitmap := TTMSFNCTouchKeyboard(Owner).PictureNormalState;

  FKeyValue := -1;
  FShiftKeyValue := -1;
  FAltGrKeyValue := -1;
  FFont := TTMSFNCTouchKeyboard(Owner).Font;
  FSmallFont := TTMSFNCTouchKeyboard(Owner).SmallFont;

  // defoult colors for none picture keys
  FBorderColor := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$DADADA;
  FBorderColorDown := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$B5B5B5;
  FColor := gcWhite;
  FColorDown := {$IFDEF FMXLIB}gcAlpha or {$ENDIF}$F0F0F0;
  FTextColor := gcBlack;
  FTextColorDown := gcBlack;
  FImageName := '';
  // initial counter state
  FDownState := 0;
  FKeyNormalPosition := True;

  Fill.Kind := gfkSolid;
  Fill.Assign(TTMSFNCCustomTouchKeyboard(Owner).Fill);

  // default size of keys
  Height := 40;
  Width := 40;
  FOldH := -1;
  FOldW := -1;
  FOldX := -1;
  FOldY := -1;
end;

destructor TTMSFNCTouchKey.Destroy;
begin
  FPictureNormalState.Free;
  FPictureDownState.Free;
  FBmp.Free;
  inherited;
end;

procedure TTMSFNCTouchKey.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  VMargin, HMargin: Single;
  DefaultDraw : Boolean;
  Paintshift, Paintaltgr: Boolean;
  Cap, fs: string;
  FOffset, BorderRounding: Integer;
  R: TRectF;
  Left, Top: Single;
begin
  inherited;

  R := LocalRect;

  VMargin := 5;
  HMargin := 10;
  FOffset := 0;

  DefaultDraw := True;

  if not FKeyNormalPosition then
    FOffset := 1;

  if (SpecialKey = skCaps) then
  begin
    FKeyNormalPosition := not TTMSFNCCustomTouchKeyboard(Owner).IsCapsDown;

    if not FKeyNormalPosition then
      FDownState := 2;
  end;

  BorderRounding := (Owner as TTMSFNCCustomTouchKeyboard).BorderRounding;

  AGraphics.Fill.Assign((Owner as TTMSFNCCustomTouchKeyboard).Fill);
  AGraphics.Stroke.Assign((Owner as TTMSFNCCustomTouchKeyboard).Stroke);
  InflateRectEx(r, -ScalePaintValue((Owner as TTMSFNCCustomTouchKeyboard).FKeySpacing) / 2, -ScalePaintValue((Owner as TTMSFNCCustomTouchKeyboard).FKeySpacing) / 2);

  AGraphics.Fill.Kind := gfkSolid;
  AGraphics.Stroke.Kind := gskSolid;

  {$IFDEF DELPHI6_LVL}
  (Owner as TTMSFNCTouchKeyboard).DrawKey(Owner, FItem, AGraphics, FDownState > 1, R, DefaultDraw);
  {$ELSE}
  (GetOwner as TTMSFNCCustomTouchKeyboard).DrawKey(Owner, FItem, AGraphics, FDownState > 1, R, DefaultDraw);
  {$ENDIF}

  if not DefaultDraw then
    Exit;

  // Checking for Key state
  if FKeyNormalPosition then
  begin
    // checking if there's a bitmap in key or Keyboard
    if (IsBitmapEmpty(FPictureDownState) and IsBitmapEmpty(TTMSFNCCustomTouchKeyboard(Owner).PictureDownState)) then
    begin
      AGraphics.Fill.Color := FColor;
      AGraphics.Stroke.Color := BorderColor;
    end
    else
    begin
      if not IsBitmapEmpty(FPictureDownState) then
        FCurrentBitmap := FPictureNormalState
      else
        FCurrentBitmap := TTMSFNCCustomTouchKeyboard(Owner).PictureNormalState;
    end;
  end
  else
  begin
    if IsBitmapEmpty(FPictureDownState) and
        IsBitmapEmpty(TTMSFNCCustomTouchKeyboard(Owner).PictureDownState) then
    begin
      AGraphics.Fill.Color := FColorDown;
      AGraphics.Stroke.Color := FBorderColorDown;
    end
    else
    begin
      if not IsBitmapEmpty(FPictureDownState) then
        FCurrentBitmap := FPictureDownState
      else
        FCurrentBitmap := TTMSFNCCustomTouchKeyboard(Owner).PictureDownState;
    end;
  end;

  if not IsBitmapEmpty(FCurrentBitmap) then
  begin
    // drawing current state bitmap
    FBmp.Assign(FCurrentBitmap);

    Left := R.Left;
    if FBmp.Width <= RectWidthEx(R) then
      Left := ((R.Left + RectWidthEx(R)) / 2) - (FBmp.Width / 2) + 5;

    Top := R.Top;
    if FBmp.Height <= RectHeightEx(R) then
      Top := ((R.Top + RectHeightEx(R)) / 2) - (FBmp.Height / 2) + 2;

    AGraphics.DrawBitmap(RectF(Left, Top, Left + FBmp.Width, Top + FBmp.Height), FBmp);
  end
  else
  begin
    // drawing current state roundedrectangle
    AGraphics.DrawRoundRectangle(R, BorderRounding, [gcTopLeft, gcTopRight, gcBottomLeft, gcBottomRight], gcrmShrinkAll);
  end;

  // drawing transparent text
  {$IFDEF DELPHI6_LVL}
  if Assigned((Owner as TTMSFNCCustomTouchKeyboard).BitmapContainer) and (ImageName <> '') then
  {$ELSE}
  if Assigned((GetOwner as TTMSFNCCustomTouchKeyboard).BitmapContainer) and (ImageName <> '') then
  {$ENDIF}
  begin
    {$IFDEF DELPHI6_LVL}
    FBmp.Assign((Owner as TTMSFNCCustomTouchKeyboard).BitmapContainer.FindBitmap(ImageName));
    {$ELSE}
    FBmp.Assign((GetOwner as TTMSFNCCustomTouchKeyboard).BitmapContainer.FindBitmap(ImageName));
    {$ENDIF}

    AGraphics.DrawBitmap(R, FBmp);
  end
  else
  begin
    // Long caption or ShiftCaption or AltGrCaption = SmallFont else Font
    if (Length(Caption) > 1) or (Length(ShiftCaption) > 0) or (Length(AltGrCaption) > 0) then
      AGraphics.Font.Assign(FSmallFont)
    else
      AGraphics.Font.Assign(FFont);

    if FKeyNormalPosition then
    begin
       AGraphics.Font.Color := TextColor;
    end
    else
      AGraphics.Font.Color := TextColorDown;

    // FShiftCaption // FAltGrCaption
    Paintshift := (ssShift in TTMSFNCCustomTouchKeyboard(Owner).Shift) or TTMSFNCCustomTouchKeyboard(Owner).IsCapsDown;
    Paintaltgr := (ssCtrl in TTMSFNCCustomTouchKeyboard(Owner).Shift) and (ssAlt in TTMSFNCCustomTouchKeyboard(Owner).Shift);

    if Paintaltgr then
      Paintshift := False;

    Cap := FCaption;

    if TTMSFNCCustomTouchKeyboard(Owner).AutoCapsDisplay and (length(FCaption) = 1) then
    begin
      if Paintshift then
        Cap := AnsiUpperCase(FCaption)
      else
        Cap := AnsiLowerCase(FCaption);
    end;


    if (FShiftCaption = '') then
    begin
      AGraphics.DrawText(R, Cap, False, gtaCenter, gtaCenter);
    end
    else
    begin
      AGraphics.DrawText(RectF(R.Left + HMargin + FOffset, R.Top, R.Right, R.Bottom - VMargin), Cap, False, gtaLeading, gtaTrailing);
      if paintshift and (TTMSFNCCustomTouchKeyboard(Owner).HighlightCaps <> gcNull) then
        AGraphics.Font.Color := TTMSFNCCustomTouchKeyboard(Owner).HighlightCaps;

      fs := FShiftCaption;
      {$IFDEF VCLLIB}
      if fs = '&' then
        fs := '&&';
      {$ENDIF}
      AGraphics.DrawText(RectF(r.Left + HMargin + FOffset, R.Top + FOffset, R.Right, R.Bottom), fs, False, gtaLeading, gtaLeading);
    end;

    if FKeyNormalPosition then
    begin
      AGraphics.Font.Color := TextColor
    end
    else
      AGraphics.Font.Color := TextColorDown;

    if paintaltgr and (TTMSFNCCustomTouchKeyboard(Owner).HighlightAltGr <> gcNull) then
    begin
      AGraphics.Font.Color := TTMSFNCCustomTouchKeyboard(Owner).HighlightAltGr
    end;

    if Length(AltGrCaption) > 0 then
      AGraphics.DrawText(RectF(R.Left, R.Top, R.Right - HMargin - FOffset, R.Bottom - VMargin), FAltGrCaption, False, gtaTrailing, gtaTrailing);
  end;
end;

procedure TTMSFNCTouchKey.DrawBackground(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  //
end;

function TTMSFNCTouchKey.GetDownState: Boolean;
begin
  Result := FDownState > 0;
end;

procedure TTMSFNCTouchKey.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  if FSpecialKey in [skShift, skAlt, skAltGr, skCaps, skCtrl, skNum, skScroll {$IFDEF MACOS}, skWin{$ENDIF}] then
  begin
    FDownState := FDownState + 1;

    case FSpecialKey of
      skShift, skCaps : TTMSFNCTouchKeyboard(Owner).Shift :=
           TTMSFNCTouchKeyboard(Owner).Shift + [ssShift];
      skAlt   : TTMSFNCTouchKeyboard(Owner).Shift :=
           TTMSFNCTouchKeyboard(Owner).Shift + [ssAlt];
      skCtrl  : TTMSFNCTouchKeyboard(Owner).Shift :=
           TTMSFNCTouchKeyboard(Owner).Shift + [ssCtrl];
      skAltGr : TTMSFNCTouchKeyboard(Owner).Shift :=
           TTMSFNCTouchKeyboard(Owner).Shift + [ssCtrl, ssAlt];
      end;

    // if FDownState greater then zero then key change to DownState
    if (FDownState > 0) then
      FKeyNormalPosition := False;

    if (TTMSFNCTouchKeyboard(Owner).AutoCapsDisplay) or
       (TTMSFNCTouchKeyboard(Owner).HighlightCaps <> gcNull) or
       (TTMSFNCTouchKeyboard(Owner).HighlightAltGr <> gcNull) then
    begin
      if Assigned(TTMSFNCTouchKeyboard(Owner).PictureNormalState) and not
        IsBitmapEmpty(TTMSFNCTouchKeyboard(Owner).PictureNormalState)  then
        begin
          TTMSFNCTouchKeyboard(Owner).Invalidate;
        end;

      TTMSFNCTouchKeyboard(Owner).PaintAllKeys;
    end;
  end
  else
  begin
    if (FSpecialKey <> skApp) Then
      TTMSFNCTouchKeyboard(Owner).StartTimer(TouchKeyItem.Index);
  end;

  FKeyNormalPosition := False;

  Invalidate;

  inherited;

  CaptureEx;

  if (FSpecialKey in [skShift, skCaps, skAlt, skCtrl, skAltGr{$IFDEF MACOS}, skWin{$ENDIF}]) then
    TTMSFNCTouchKeyboard(Owner).ItemKeyDown(TouchKeyItem.Index);
end;

procedure TTMSFNCTouchKey.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  inherited;

  ReleaseCaptureEx;

  // is this specialkey?
  if FSpecialKey in [skShift, skAlt, skAltGr, skCaps, skCtrl, skNum, skScroll {$IFDEF MACOS}, skWin{$ENDIF}] then
  begin
    if (FDownState > 1) then       // is this its second MouseUp?
    begin
      FDownState := 0;
      case FSpecialKey of
        skShift, skCaps:
            TTMSFNCTouchKeyboard(Owner).Shift :=
                TTMSFNCTouchKeyboard(Owner).Shift - [ssShift];
        skAlt   : TTMSFNCTouchKeyboard(Owner).Shift :=
            TTMSFNCTouchKeyboard(Owner).Shift - [ssAlt];
        skCtrl  : TTMSFNCTouchKeyboard(Owner).Shift :=
            TTMSFNCTouchKeyboard(Owner).Shift - [ssCtrl];
        skAltGr : TTMSFNCTouchKeyboard(Owner).Shift :=
            TTMSFNCTouchKeyboard(Owner).Shift - [ssCtrl, ssAlt];
        end;

      if (TTMSFNCTouchKeyboard(Owner).AutoCapsDisplay) or
         (TTMSFNCTouchKeyboard(Owner).HighlightCaps <> gcNull) or
         (TTMSFNCTouchKeyboard(Owner).HighlightAltGr <> gcNull) then
      begin
        if Assigned(TTMSFNCTouchKeyboard(Owner).PictureNormalState) and not
          IsBitmapEmpty(TTMSFNCTouchKeyboard(Owner).PictureNormalState)  then
          TTMSFNCTouchKeyboard(Owner).Invalidate;

        TTMSFNCTouchKeyboard(Owner).PaintAllKeys;
      end;
    end
    else
      Exit;
  end
  else
  begin
    TTMSFNCTouchKeyboard(Owner).PaintAllKeys;
    TTMSFNCTouchKeyboard(Owner).ItemKeyDown(TouchKeyItem.Index);
    TTMSFNCTouchKeyboard(Owner).StopTimer;
  end;

  // if FDownState equal to zero then key can change
  if FDownState = 0 then
    FKeyNormalPosition := True;

  Invalidate;

  if (TouchKeyItem.SpecialKey in [skShift, skAlt, skCtrl, skAltGr]) then
    TTMSFNCTouchKeyboard(Owner).SyncEqualKeys(TouchKeyItem.Index);


  TTMSFNCTouchKeyboard(Owner).ItemKeyUp(TouchKeyItem.Index);
end;

procedure TTMSFNCTouchKey.SetAltGrCaption(const Value: string);
begin
  FAltGrCaption := Value;
  Invalidate;
end;

procedure TTMSFNCTouchKey.SetAltrCaption(const Value: string);
begin
  FShiftCaption := Value;
  Invalidate;
end;

procedure TTMSFNCTouchKey.SetBrderColor(const Value: TTMSFNCGraphicsColor);
begin
  FBorderColor := Value;
  Invalidate;
end;

procedure TTMSFNCTouchKey.SetCption(const Value: string);
begin
  FCaption := Value;
  Invalidate;
end;

procedure TTMSFNCTouchKey.SetClor(const Value: TTMSFNCGraphicsColor);
begin
  FColor := Value;
  Invalidate;
end;

procedure TTMSFNCTouchKey.SetDownState(const Value: Boolean);
begin
  FDownState := FDownState + 1;
end;

procedure TTMSFNCTouchKey.SetPictureDownState(const Value: TTMSFNCBitmap);
begin
  FPictureDownState.Assign(Value);
end;

procedure TTMSFNCTouchKey.SetPictureNormalState(const Value: TTMSFNCBitmap);
begin
  FPictureNormalState.Assign(Value);
  FCurrentBitmap := FPictureNormalState;
  Invalidate;
end;

procedure TTMSFNCTouchKey.SetTextColor(const Value: TTMSFNCGraphicsColor);
begin
  FTextColor := Value;
  Invalidate;
end;

{ TTMSFNCTouchKeyItem }

procedure TTMSFNCTouchKeyItem.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCTouchKeyItem) then
  begin
    X := (Source as TTMSFNCTouchKeyItem).X;
    Y := (Source as TTMSFNCTouchKeyItem).Y;
    AutoPost := (Source as TTMSFNCTouchKeyItem).AutoPost;
    Caption := (Source as TTMSFNCTouchKeyItem).Caption;
    ShiftCaption := (Source as TTMSFNCTouchKeyItem).ShiftCaption;
    AltGrCaption := (Source as TTMSFNCTouchKeyItem).AltGrCaption;
    KeyValue := (Source as TTMSFNCTouchKeyItem).Keyvalue;
    ShiftKeyValue := (Source as TTMSFNCTouchKeyItem).ShiftKeyValue;
    AltGrKeyValue := (Source as TTMSFNCTouchKeyItem).AltGrKeyValue;
    PictureDownState.Assign((Source as TTMSFNCTouchKeyItem).PictureDownState);
    PictureNormalState.Assign((Source as TTMSFNCTouchKeyItem).PictureNormalState);
    Height := (Source as TTMSFNCTouchKeyItem).Height;
    Width := (Source as TTMSFNCTouchKeyItem).Width;
    SpecialKey := (Source as TTMSFNCTouchKeyItem).SpecialKey;
    BorderColor := (Source as TTMSFNCTouchKeyItem).BorderColor;
    BorderColorDown := (Source as TTMSFNCTouchKeyItem).BorderColorDown;
    Color := (Source as TTMSFNCTouchKeyItem).Color;
    ColorDown := (Source as TTMSFNCTouchKeyItem).ColorDown;
    TextColor := (Source as TTMSFNCTouchKeyItem).TextColor;
    TextColorDown := (Source as TTMSFNCTouchKeyItem).TextColorDown;
    ImageName := (Source as TTMSFNCTouchKeyItem).ImageName;
    ShortCut:= (Source as TTMSFNCTouchKeyItem).ShortCut;
  end;
end;

constructor TTMSFNCTouchKeyItem.Create(ACollection: TCollection);
begin
  inherited Create(ACollection);
  FKbd := (ACollection as TTMSFNCTouchKeyCollection).FOwner;

  FTouchKey := TTMSFNCTouchKey.Create(FKbd);
  FTouchKey.Parent := fkbd;
  FTouchKey.TouchKeyItem := Self;
  FTouchKey.Stored := False;

  // setting initial values
  FTouchKey.Left := 0;
  FTouchKey.Top := 0;
  FTouchKey.Width := 40;
  FTouchKey.Height := 40;
  FTouchKey.SpecialKey := skNone;
end;

destructor TTMSFNCTouchKeyItem.Destroy;
begin
  FTouchKey.Free;
  inherited;
end;

function TTMSFNCTouchKeyItem.GetAltGrCaption: string;
begin
  Result := FTouchKey.AltGrCaption;
end;

function TTMSFNCTouchKeyItem.GetAltGrKeyValue: Integer;
begin
  Result := FTouchKey.AltGrKeyValue;
end;

function TTMSFNCTouchKeyItem.GetAutoPost: Boolean;
begin
  Result := FTouchKey.AutoPost;
end;

function TTMSFNCTouchKeyItem.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := nil;
  if Assigned(FKbd) then
    Result := FKbd.BitmapContainer;
end;

function TTMSFNCTouchKeyItem.GetBorderColor: TTMSFNCGraphicsColor;
begin
  Result := FTouchKey.BorderColor;
end;

function TTMSFNCTouchKeyItem.GetBorderColorDown: TTMSFNCGraphicsColor;
begin
  Result := FTouchKey.BorderColorDown;
end;

function TTMSFNCTouchKeyItem.GetCaption: string;
begin
  Result := FTouchKey.Caption;
end;

function TTMSFNCTouchKeyItem.GetColor: TTMSFNCGraphicsColor;
begin
  Result := FTouchKey.Color;
end;

function TTMSFNCTouchKeyItem.GetColorDown: TTMSFNCGraphicsColor;
begin
  Result := FTouchKey.ColorDown;
end;

function TTMSFNCTouchKeyItem.GetDisplayName: string;
begin
  Result := 'Key ' + Caption;
end;

function TTMSFNCTouchKeyItem.GetHeight: Single;
begin
  Result := FTouchKey.Height;
end;

function TTMSFNCTouchKeyItem.GetHint: string;
begin
  Result := FTouchKey.Hint;
end;

function TTMSFNCTouchKeyItem.GetImageName: string;
begin
  Result := FTouchKey.ImageName;
end;

function TTMSFNCTouchKeyItem.GetKeyValue: Integer;
begin
  Result := FTouchKey.KeyValue;
end;

function TTMSFNCTouchKeyItem.GetOldH: Single;
begin
   Result := FTouchKey.OldH;
end;

function TTMSFNCTouchKeyItem.GetOldW: Single;
begin
   Result := FTouchKey.OldW;
end;

function TTMSFNCTouchKeyItem.GetOldX: Single;
begin
   Result := FTouchKey.OldX;
end;

function TTMSFNCTouchKeyItem.GetOldY: Single;
begin
   Result := FTouchKey.OldY;
end;

function TTMSFNCTouchKeyItem.GetPictureDownState: TTMSFNCBitmap;
begin
  Result := FTouchKey.PictureDownState;
end;

function TTMSFNCTouchKeyItem.GetPictureNormalState: TTMSFNCBitmap;
begin
  Result := FTouchKey.PictureNormalState;
end;

function TTMSFNCTouchKeyItem.GetShiftCaption: string;
begin
  Result := FTouchKey.ShiftCaption;
end;

function TTMSFNCTouchKeyItem.GetShiftKeyValue: Integer;
begin
  Result := FTouchKey.ShiftKeyValue;
end;

function TTMSFNCTouchKeyItem.GetShortCut: string;
begin
  Result := FTouchKey.ShortCut;
end;

function TTMSFNCTouchKeyItem.GeTTMSFNCSpecialKey: TTMSFNCSpecialKey;
begin
  Result := FTouchKey.SpecialKey;
end;

function TTMSFNCTouchKeyItem.GetTextColor: TTMSFNCGraphicsColor;
begin
  Result := FTouchKey.TextColor;
end;

function TTMSFNCTouchKeyItem.GetTextColorDown: TTMSFNCGraphicsColor;
begin
  Result := FTouchKey.TextColorDown;
end;

function TTMSFNCTouchKeyItem.GetWidth: Single;
begin
  Result := FTouchKey.Width;
end;

function TTMSFNCTouchKeyItem.GetX: Single;
begin
  Result := FTouchKey.Left;
end;

function TTMSFNCTouchKeyItem.GetY: Single;
begin
  Result := FTouchKey.Top;
end;

procedure TTMSFNCTouchKeyItem.SetAltGrCaption(const Value: string);
begin
  FTouchKey.AltGrCaption := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetAltGrKeyValue(const Value: Integer);
begin
  FTouchKey.AltGrKeyValue := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetAutoPost(const Value: Boolean);
begin
  FTouchKey.AutoPost := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetBorderColor(const Value: TTMSFNCGraphicsColor);
begin
  FTouchKey.BorderColor := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetBorderColorDown(const Value: TTMSFNCGraphicsColor);
begin
  FTouchKey.BorderColorDown := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetCaption(const Value: string);
begin
  FTouchKey.Caption := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  FTouchKey.Color := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetColorDown(const Value: TTMSFNCGraphicsColor);
begin
  FTouchKey.ColorDown := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetHeight(const Value: Single);
begin
  FTouchKey.Height := Round(Value);
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetHint(const Value: string);
begin
  FTouchKey.Hint := Value;
  FTouchKey.ShowHint := (Value <> '');
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetImageName(const Value: string);
begin
  FTouchKey.ImageName := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetKeyvalue(const Value: Integer);
begin
  FTouchKey.KeyValue := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetOldH(const Value: Single);
begin
   FTouchKey.OldH := Value;
end;

procedure TTMSFNCTouchKeyItem.SetOldW(const Value: Single);
begin
   FTouchKey.OldW := Value;
end;

procedure TTMSFNCTouchKeyItem.SetOldX(const Value: Single);
begin
   FTouchKey.OldX := Value;
end;

procedure TTMSFNCTouchKeyItem.SetOldY(const Value: Single);
begin
   FTouchKey.OldY := Value;
end;

procedure TTMSFNCTouchKeyItem.SetPictureDownState(const Value: TTMSFNCBitmap);
begin
  FTouchKey.PictureDownState.Assign(Value);
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetPictureNormalState(const Value: TTMSFNCBitmap);
begin
  FTouchKey.PictureNormalState.Assign(Value);
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetShiftCaption(const Value: string);
begin
  FTouchKey.ShiftCaption := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetShiftKeyValue(const Value: Integer);
begin
  FTouchKey.ShiftKeyValue := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetShortCut(const Value: string);
begin
  FTouchKey.ShortCut := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SeTTMSFNCSpecialKey(const Value: TTMSFNCSpecialKey);
begin
  FTouchKey.SpecialKey := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetTexTTMSFNCGraphicsColor(const Value: TTMSFNCGraphicsColor);
begin
  FTouchKey.TextColor := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetTexTTMSFNCGraphicsColorDown(const Value: TTMSFNCGraphicsColor);
begin
  FTouchKey.TextColorDown := Value;
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetWidth(const Value: Single);
begin
  FTouchKey.Width := Round(Value);
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetX(const Value: Single);
begin
  FTouchKey.Left := Round(Value);
  FTouchKey.Invalidate;
end;

procedure TTMSFNCTouchKeyItem.SetY(const Value: Single);
begin
  FTouchKey.Top := Round(Value);
  FTouchKey.Invalidate;
end;

{ TTMSFNCTouchKeyCollection }

function TTMSFNCTouchKeyCollection.Add: TTMSFNCTouchKeyItem;
begin
  Result := TTMSFNCTouchKeyItem(inherited Add);
end;

constructor TTMSFNCTouchKeyCollection.Create(AOwner: TTMSFNCCustomTouchKeyBoard);
begin
  inherited Create(TTMSFNCTouchKeyItem);
  FOwner := AOwner;
end;

function TTMSFNCTouchKeyCollection.GetItem(Index: Integer): TTMSFNCTouchKeyItem;
begin
  Result := TTMSFNCTouchKeyItem(inherited Items[Index]);
end;

function TTMSFNCTouchKeyCollection.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCTouchKeyCollection.Insert(index: Integer): TTMSFNCTouchKeyItem;
begin
  Result := TTMSFNCTouchKeyItem(inherited Insert(Index));
end;

procedure TTMSFNCTouchKeyCollection.SetItem(Index: Integer; const Value: TTMSFNCTouchKeyItem);
begin
  inherited Items[index] := Value;
end;

{ TTMSFNCCustomPopupTouchKeyboard }

constructor TTMSFNCCustomPopupTouchKeyboard.Create(AOwner: TComponent);
begin
  inherited;
  FKeyboard := TTMSFNCTouchKeyBoard.Create(Self);
  FKeyboard.OnKeyClick := DoKeyClick;
  FKeyboard.OnKeyDown := @DoKeyDown;
  FKeyboard.OnDrawKey := DoDrawKey;
  FCloseTimer := TTimer.Create(Self);
  FCloseTimer.Enabled := False;
  FCloseTimer.Interval := 10;
  FCloseTimer.OnTimer := DoCloseTimer;
end;

destructor TTMSFNCCustomPopupTouchKeyboard.Destroy;
begin
  FCloseTimer.Free;
  FKeyboard.Free;
  inherited;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.DoAfterPanelDraw(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  InflateRectEx(ARect, GetScaledValue(-10), GetScaledValue(-10));
  AGraphics.Stroke.Color := gcWhite;
  AGraphics.Stroke.Width := 2;
  AGraphics.Stroke.Kind := gskSolid;
  AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Right, ARect.Bottom));
  AGraphics.DrawLine(PointF(ARect.Right, ARect.Top), PointF(ARect.Left, ARect.Bottom));
end;

procedure TTMSFNCCustomPopupTouchKeyboard.DoCloseTimer(Sender: TObject);
begin
  FCloseTimer.Enabled := False;
  if TTMSFNCNonFocusablePopupEx(FPopup).IsOpen then
    FPopup.Deactivate;

  if Assigned(FOnClose) then
    Onclose(Self);

  FPopup := nil;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.DoDrawKey(Sender: TObject;
  AKey: TTMSFNCTouchKeyItem; AGraphics: TTMSFNCGraphics; ADown: Boolean; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnDrawKey) then
    OnDrawKey(Self, AKey, AGraphics, ADown, ARect, ADefaultDraw);
end;

procedure TTMSFNCCustomPopupTouchKeyboard.DoKeyClick(Sender: TObject; Index: Integer);
begin
  if Assigned(OnKeyClick) then
    OnKeyClick(Self, Index);
end;

procedure TTMSFNCCustomPopupTouchKeyboard.DoKeyDown(Sender: TObject; var Key: Word;
  {$IFDEF FMXLIB}var KeyChar: WideChar;{$ENDIF} Shift: TShiftState);
begin
  if Assigned(OnKeyDown) then
    OnKeyDown(Self, Key, {$IFDEF FMXLIB}KeyChar,{$ENDIF} Shift);
end;

function TTMSFNCCustomPopupTouchKeyboard.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomPopupTouchKeyboard.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

function TTMSFNCCustomPopupTouchKeyboard.GetKeyboard: TTMSFNCTouchKeyBoard;
begin
  Result := FKeyboard;
end;

function TTMSFNCCustomPopupTouchKeyboard.GetScaledValue(AValue: Single): Single;
var
  sc: Single;
begin
  {$IFNDEF FMXLIB}
  sc := TTMSFNCUtils.GetDPIScale(Self, 96);
  {$ENDIF}
  {$IFDEF FMXLIB}
  sc := 1.0;
  {$ENDIF}

  Result := sc * AValue;
end;

function TTMSFNCCustomPopupTouchKeyboard.GetVersion: string;
var
  Vn: Integer;
begin
  Vn := GetVersionNr;
  Result := IntToStr(Hi(Hiword(Vn))) + '.' + IntToStr(Lo(Hiword(Vn))) + '.' +
    IntToStr(Hi(Loword(Vn))) + '.' + IntToStr(Lo(Loword(Vn)));
end;

function TTMSFNCCustomPopupTouchKeyboard.GetVersionNr: Integer;
begin
  Result := MakeLong(MakeWord(BLD_VER, REL_VER), MakeWord(MIN_VER, MAJ_VER));
end;

procedure TTMSFNCCustomPopupTouchKeyboard.HideKeyboard;
begin
  if Assigned(FPopup) then
  begin
    FPopup.Deactivate;
    FPopup := nil;
  end;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.SetAutoCapsDisplay(const Value: Boolean);
begin
  FAutoCapsDisplay := Value;
  FKeyboard.AutoCapsDisplay := Value;
  FKeyboard.Invalidate;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.SetAutoPostKey(const Value: Boolean);
begin
  FAutoPostKey := Value;
  FKeyboard.AutoPostKey := Value;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.SetHighlightAltGr(const Value: TTMSFNCGraphicsColor);
begin
  FHighlightAltGr := Value;
  FKeyboard.HighlightAltGr := Value;
  FKeyboard.Invalidate;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.SetHighlightCaps(const Value: TTMSFNCGraphicsColor);
begin
  FHighlightCaps := Value;
  FKeyboard.HighlightCaps := Value;
  FKeyboard.Invalidate;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.SeTTMSFNCKeyboardType(const Value: TTMSFNCKeyboardType);
begin
  FKeyboardType := Value;
  FKeyboard.KeyboardType := Value;
  FKeyboard.Invalidate;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.ShowKeyboard;
var
  Pnl, T: TTMSFNCPanel;
  R: TTMSFNCPopupTouchKeyboardCloseButton;
  p: TPointF;
begin
  if not Assigned(FPopup) then
  begin
    FPopup := TTMSFNCNonFocusablePopup.Create(Self);

    FMDown := PointF(-1,-1);

    if Assigned(OnKeyboardCreated) then
      OnKeyboardCreated(Self);

    FKeyboard.AllowAutoZoom := False;
    {$IFDEF FMXLIB}
    FPopup.ClipChildren := False;
    {$ENDIF}

    Pnl := TTMSFNCPanel.Create(FPopup);
    Pnl.Header.Visible := False;
    {$IFDEF FMXLIB}
    Pnl.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    Pnl.Align := alClient;
    {$ENDIF}

    T := TTMSFNCPanel.Create(Pnl);
    T.Header.Visible := False;
    {$IFDEF FMXLIB}
    T.Align := TAlignLayout.Top;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    T.Align := alTop;
    {$ENDIF}
    T.Height := 30;
    T.OnMouseDown := DoPanelMouseDown;
    T.OnMouseMove := DoPanelMouseMove;
    T.OnMouseUp := DoPanelMouseUp;
    T.Parent := Pnl;

    R := TTMSFNCPopupTouchKeyboardCloseButton.Create(T);
    {$IFDEF FMXLIB}
    R.Align := TAlignLayout.Right;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    R.Align := alRight;
    {$ENDIF}
    R.Fill.Color := gcRed;
    R.Stroke.Kind := gskNone;
    R.Width := 30;
    R.Parent := T;
    R.OnClick := DoPanelClose;

    R.OnAfterDraw := DoAfterPanelDraw;

    {$IFDEF FMXLIB}
    FKeyboard.Align := TAlignLayout.Client;
    {$ENDIF}
    {$IFNDEF FMXLIB}
    FKeyboard.Align := alClient;
    {$ENDIF}

    FPopup.Width := FKeyboard.Width;
    FPopup.Height := FKeyboard.Height + T.Height;

    FKeyboard.Parent := Pnl;
    FPopup.ContentControl := Pnl;

    FKeyboard.Fill.Kind := gfkSolid;

    p := TTMSFNCUtils.GetMousePos;
    FPopup.Placement := ppAbsolute;
    FPopup.PlacementRectangle.Left := P.X;
    FPopup.PlacementRectangle.Top := P.Y;
    FPopup.PlacementRectangle.Right := P.X + GetScaledValue(FKeyboard.GetDefaultKeyboardWidth(KeyboardType));
    FPopup.PlacementRectangle.Bottom := P.Y + GetScaledValue(30 + FKeyboard.GetDefaultKeyboardHeight(KeyboardType));

    FPopup.Activate;

    if Assigned(OnShow) then
      OnShow(Self);

    if Assigned(OnClose) then
      TTMSFNCNonFocusablePopupEx(FPopup).OnClosePopup := DoPanelClose;
  end;
end;

procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelClose(Sender: TObject);
begin
  FCloseTimer.Enabled := True;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFNDEF FMXLIB}
procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
{$ENDIF}
begin
  FMDown := TTMSFNCUtils.GetMousePos;
  FIsDown := True;

  FPRect := FPopup.PlacementRectangle.Rect;

  TTMSFNCPanelEx(Sender).CaptureEx;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFNDEF FMXLIB}
procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Integer);
{$ENDIF}
var
  dx,dy,w,h: Single;
  r: TRectF;
  p: TPointF;
begin
  P := TTMSFNCUtils.GetMousePos;

  if FIsDown then
  begin
    dx := p.X - FMDown.X;
    dy := p.Y - FMDown.Y;

    r := FPRect;
    w := r.Right - r.Left;
    h := r.Bottom - r.Top;

    r.Left := r.Left + dx;
    r.Top := r.Top + dy;
    r.Right := r.Left + w;
    r.Bottom := r.Top + h;

    FPopup.PlacementRectangle.Left := r.Left;
    FPopup.PlacementRectangle.Top := r.Top;
    FPopup.PlacementRectangle.Right := r.Right;
    FPopup.PlacementRectangle.Bottom := r.Bottom;
    TTMSFNCNonFocusablePopupEx(FPopup).PopupForm.ApplyPlacement;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFNDEF FMXLIB}
procedure TTMSFNCCustomPopupTouchKeyboard.DoPanelMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
{$ENDIF}
begin
  FIsDown := False;
  TTMSFNCPanelEx(Sender).ReleaseCaptureEx;
end;

{$IFDEF MACOS}
{$IFNDEF IOS}
initialization
  RegisterVirtualKeysEx;
finalization
  FreeAndNil(vKeyMapping);
{$ENDIF}
{$ENDIF}

end.
