unit x.filter;

interface

uses
  classes, sysutils, system.Generics.Collections,  liblogtest,



  {$IFDEF fwfmx}
    FMX.tmsfnctreeview, fmx.tmsfnctreeviewdata, vcl.TMSFNCCustomTreeView;
  {$ENDIF}
  {$IFDEF fwvcl}
     vcl.tmsfnctreeview, vcl.tmsfnctreeviewdata, vcl.TMSFNCCustomTreeView;
  {$ENDIF}
  {$IFDEF fwweb}
       vcl.tmsfnctreeview, vcl.tmsfnctreeviewdata, vcl.TMSFNCCustomTreeView;
  {$ENDIF}





type
  txFilterItem = class
  private
    FName: string;
    Fvalue: string;
    FStr: string;
    function getStr: string;
  public
  published
    property Name: string read FName write FName;
    property value: string read Fvalue write Fvalue;
    property Str: string read getStr write FStr;
  end;

  txFilterItems = tDictionary<String,txFilterItem>;

  txFilter = class
  private
    Fss: string;
    Fitems: txFilterItems;
    FDef: string;
    FTrimLHS: boolean;
    FFieldNames: tStringList;
    Ftv: ttmsfncCustomTreeView;
    FDefCol: String;
    procedure setss(const Value: string);
    function GetDef: string;
  public
    constructor create;
    procedure reset;
    procedure logFilter;
    procedure CreateFilters;
  published
    property ss: string read Fss write setss;
    property Def: string read GetDef write FDef;
    property DefCol: String read FDefCol write FDefCol;
    property items: txFilterItems read Fitems write Fitems;
    property TrimLHS: boolean read FTrimLHS write FTrimLHS;
    property FieldNames: tStringList read FFieldNames write FFieldNames;
    property tv: ttmsfncCustomTreeView read Ftv write Ftv;
  end;
implementation

{ txFilter }

constructor txFilter.create;
begin

  Items := txFilterItems.Create;
trimLHS:=true;
FIeldNames:=tStringList.create;
fieldnames.sorted:=true;
//fieldnames.Duplicates:=dupIgnore;
end;

procedure txFilter.CreateFilters;
var
 afilter: TTMSFNCTreeViewFilterData;
 apair: tPair<string, txFilterItem>;
 af: txFilterItem;
 acol: string;
 tvCol: ttmsfncTreeViewColumn;
begin
 if not assigned(tv) then exit;
 tv.filter.BeginUpdate;
 tv.filter.Clear;

   if def<>'' then
   begin
       tvCol:=tv.findColumnByName(defcol);
       if assigned(tvCol) then
       begin
      afilter:=tv.filter.add;
      afilter.Column:=tvcol.index;
      afilter.Condition:=def;
      afilter.CaseSensitive:=false;
      alog.send('Filter:def: '  , afilter.Condition);
       end else alog.error('Cannot find column', def);
   end;

 for apair in items do
   begin
    af:=apair.value;
    if Fieldnames.indexofname(af.name)<>-1 then acol:=fieldnames.values[af.name] else acol:=af.name;
     alog.send('col', acol);
    tvCol:=tv.findColumnByName(acol);
    if assigned(tvCOl) then
    begin
      afilter:=tv.filter.add;
      afilter.Column:=tvcol.Index;
      afilter.CaseSensitive:=false;
      afilter.Condition:=af.value;
      alog.send('Filter: ' +acol, afilter.Condition);
    end else alog.send('cant find column', acol);

   end;
   tv.filter.EndUpdate;
   tv.ApplyFilter;


end;

function txFilter.GetDef: string;
var
 apair: tpair<string, txfilterItem>;
 begin
   result:=ss;
   for apair in items do
    begin
      result:=stringreplace(result, apair.value.str, '', [rfREplaceAll, rfIgnorecase]);
    end;

end;

procedure txFilter.logFilter;
var
 apair: tpair<string, txfilterItem>;
begin
 alog.send('******Filter*******');

 alog.send('Def:', def);
 for apair in items do
   alog.send(apair.value.Name , apair.value.value);
end;

procedure txFilter.reset;
begin
 items.clear;
 def:='';
 if assigned(tv) then
 begin
   tv.filter.BeginUpdate;
   tv.RemoveFilters;
   tv.filter.EndUpdate;
 end;
end;

procedure txFilter.setss(const Value: string);
var
 i: integer;
 s, slc: string;
 p: integer;
 sn,sv: string;
 aItem: txFilterItem;
 sp: tArray<string>;
begin
  Fss := Value;

  sp:=fss.split([' '], '"','"');




  for i := 1 to length(sp) do
    begin
      s:=sp[i-1];
      p:=pos(':', s);
      if p<>0 then
      begin
        sn:=copy(s,1,p-1);
        sv:=copy(s,p+1, length(s));
        if trimLHS then sv:=trimleft(sv);

        if items.TryGetValue(sn, aitem ) then aitem.value:=sv else
        begin
          aitem:=txFilterItem.create;
          aitem.name:=sn;
          aitem.value:=sv;
          aitem.str:=s;
          items.add(sn,aitem);
        end;

      end;
    end;

end;

{ txFilterItem }

function txFilterItem.getStr: string;
begin
  Result := name + ':' + value;
end;

end.

